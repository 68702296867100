<template>
<div class="all">
    <div class="line" style="height:40px"></div>
 <div class="main">
  <div class="main-one" style="margin-top: 30px; padding-top:10px">
        <div class="one-l">
          <span
            class="one-item tab cursorstyle"
            :class="activeAdd == '' ? 'addActive tabactive' : ''"
            @click="selectAdd('')"
            >全部地区</span
          >
        </div>
        <div class="one-r">
          <span
            v-for="(item, x) in addressList"
            :key="x + 1"
            class="one-item cursorstyle"
            :class="activeAdd == x ? 'addbackActive' : ''"
            @click="selectAdd(x)"
            >{{ item }}</span
          >
        </div>
      </div>

         <!-- 这个地方显示二级行政区域 -->
       <div class="country" v-show="countyList.length > 0 && activeAdd !== ''"> 
            <div class="main-one " style="padding-top: 10px;">
          <div class="one-l" style="padding: 4px 2px;">
            <span
              class="one-item tab cursorstyle"
              :class="countyActive == '' ? 'countryActive' : ''"
              @click="selectCounty('')"
              >全部地区</span
            >
          </div>
          <div class="one-r">
            <span
              v-for="(item, y) in countyList"
              :key="y"
              class="one-item cursorstyle"
              :class="countyActive == item.id ? 'countryActive' : ''"
              @click="selectCounty(item.id)"
              >{{ item.name }}</span
            >
          </div>
        </div>
       </div>

        <div style=height:10px;background:#ffffff></div>
        <div class="imgtab">
          <div class="item cursorstyle" @click="toPage('videolist')">
              <img src="../../assets/tabimg/kan.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('articlelist')">
              <img src="../../assets/tabimg/du.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('medialist')">
              <img src="../../assets/tabimg/ting.png" alt="">
          </div>
        </div>
        <div class="dropmenu">
          <el-dropdown @command="handleClickDown" placement="bottom">
              <span class="hottxt cursorstyle">
                {{downactiveTxt}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,downx) in downList" :key='downx' :command="item">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
     
    <div class="photobox">
      <waterfall :col="col" :data="imgsArr" :gutterWidth="colWidth" @loadmore="loadmore">
        <template>
          <div class="cell-item cursorstyle photoZoom" v-for="(item, index) in imgsArr" :key="index" @click="toDetail(item.id)">
            <img
              v-if="item.cover"
              :src="item.cover"
              alt="加载错误"
              class="cell-image"
            />
            <div class="item-body">
              <div class="item-desc">{{ item.title }}</div>
              <div class="item-footer">
                <div class="item-footer-l">
                  <div
                    v-if="item.user_info.avatar"
                    class="avatar"
                    :style="{
                      backgroundImage: `url(${item.user_info.avatar})`,
                    }"
                  ></div>
                  <div class="name">{{ item.user_info.username }}</div>
                </div>
                <div class="item-footer-r" @click.stop="like(item)">
                  <div class="like" v-if="item.is_like!== 0">
                   
                    <img src="../../assets/img/mlike.png" alt="" />
                       <span>喜欢</span>
                  </div>
                  <div class="like" v-if="item.is_like == 0">
                     
                    <img src="../../assets/img/munlike.png" alt="" />
                     <span>喜欢</span>
                  </div>
                </div>

                <!-- <div class="like" :class="item.liked?'active':''">
                <i></i>
                <div class="like-total">{{item.like}}</div>
              </div> -->
              </div>
            </div>
          </div>
        </template>
      </waterfall>
        <!-- <loading :show="loading"/> -->
    </div>
            <!-- 无内容显示 -->
      <div class="empty" v-show="emptyShow">
          <div class="emptyone">
            <img src="../../assets/img/empty.png" alt="" class="emptyimg">
          </div>
          <div class="emptytxt">
            <p>暂时没有数据，过会再来看看吧</p>
          </div>
      </div>
  </div>
</div>
 
</template>

<script>

import { cateFen, address, photoslist,photolike ,atlasTag} from "@/api";
import {NewArealist} from "../../assets/js/area"
import loading from "../../components/loading";
export default {
  components: {
    loading
  },
  props: {
    title: String,
  },
  data() {
    return {
      downList: [], //
      addressList: [], //
      activeAdd: "",
      downactive: "",
      page: 1,
      imgsArr: [],
      col: 4,
      colWidth: 20,
      loading: false,
      emptyShow:false,
          allCounty:[],
      countyList:[],
      countyActive:'',
      downactiveTxt:'全部分类'

    };
  },
  watch: {},
  computed: {
    itemWidth() {
      return 133 * 0.5 * (document.documentElement.clientWidth / 375);
    },
    gutterWidth() {
      return 10 * 0.5 * (document.documentElement.clientWidth / 375);
    },
  },
  methods: {
   
        toPage:function(e){
   
       const { href } = this.$router.resolve({
        path: `/${e}`,
      });
      window.open(href, "_blank");
    },
    handleClickDown:function(e){
      // console.log(e)
      this.downactive = e.id;
      this.downactiveTxt = e.name
      this.page = 1;
      this.getList();
    },
    like:function(e){
      photolike({
        atlas:e.id
      }).then(res=>{
        if (res.code == 200) {
          e.is_like = e.is_like == 0 ? 1:0
        }else{
           this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
        }
      })
    },
    selectAdd: function(x) {
      this.activeAdd = x;
      this.imgsArr = []
        this.countyActive = ''
        this.page = 1
           if (this.activeAdd !== '') {
          this.getCounty()
      }
      this.getPhoto()
    },
      selectCounty:function(e){
          this.imgsArr = []
      this.countyActive = e
       this.getPhoto()
    },
    selectDow: function(e) {
      console.log(e);
        this.imgsArr = []
      this.downactive = e;
      this.page = 1
      this.getPhoto()
    },
    getPhoto: function() {
      var _this = this
      _this.emptyShow = false
         let address = _this.countyActive !== '' ?  _this.countyActive : _this.activeAdd
      photoslist({
        area: address,
        classification: _this.downactive || '',
        page: _this.page,
      }).then((res) => {
        _this.imgsArr = _this.imgsArr.concat(res.data);
        if (_this.imgsArr.length == 0) {
           _this.emptyShow = true
        }
      });
    },
    loadmore:function(){
     this.loading = true;
     this.page++
     this.getPhoto()
      setTimeout(() => {
        // this.data = this.data.concat(this.originData, this.originData);
        this.loading = false;
      }, 1000);
    },
    // 图片详情
    toDetail:function(e){
      this.$router.push({
        path:'/photodetail',
        query:{
          id:e
        }
      })
    },
      getCounty:function(){
       if (this.activeAdd !== '') {
      this.countyList = this.allCounty.filter(item => item.id == this.activeAdd)[0].data || []

          }
    }
  },
  created() {
     this.activeAdd = this.$route.query.id || '';
      this.allCounty = NewArealist()
    
  },
  mounted() {
    cateFen({
      type: 3,
    }).then((res) => {
      if (res) {
           let a = {
          name:'全部分类',
          id:''
        }
        res.data.unshift(a)
        this.downList = res.data;
      }
    });
    address().then((res) => {
      if (res) {
        this.addressList = res.data;
      }
    });
      // this.getTag()
    this.$nextTick(function() {
      this.getPhoto();
    });
     this.getCounty()
  },
};
</script>
<style lang="scss" scoped>
.all{
 background-color: #f7f7f7;
  min-height: 800px;
}
.main {
  width: 1200px;
  margin: 0 auto;
 
}
.main-one {
  display: flex;
  background-color: #ffffff;
  // margin: 0 10px;
  .one-l {
    padding: 4px 8px;
  }
  .tab {
    white-space: nowrap;
    padding: 4px 8px;
  }
  .one-r {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .one-item {
      margin-right: 16px;
    padding: 4px 10px 10px 10px;
    }
  }
}
.addActive {
  background: #e35111;
  border-radius: 2px;
  color: #fff;
}
.addbackActive{
 background-color: #f5f4f4;  
}

.countryActive{
  color: #e35111;
}
// .tabactive{
//      padding: 4px 8px;
// }
#content {
  position: absolute; /*必须*/
  top: 32px; /*top必须，大小可控制*/
  bottom: 0; /*bottom必须，一直延申到当前块底部*/
  width: 100%;
}

.main-container {
  display: flex;
  .main-content {
    flex: 1;
    background: #66677c;
    height: 100vh;
    overflow: auto;
  }
  .slide-menu {
    flex: 0 0 200px;
    width: 200px;
    .operations {
      position: fixed;
      right: 0;
      width: 200px;
      top: 0;
      bottom: 0;
      background-color: #333;
      box-sizing: border-box;
      padding: 20px;
      .item {
        margin-bottom: 20px;
        .title {
          padding-bottom: 10px;
          text-align: left;
          color: #fff;
        }
      }
      .github {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
      }
    }
  }
}
//
.empty{
  padding: 60px 0;
  .emptyone{
    display: flex;
    justify-content: center;
  }
  .emptyimg{
    width: 250px;
  }
  .emptytxt{
    text-align: center;
    margin-top: 10px;
    p{
      color: #757575;
      font-size: 20px;
    }
  }
}
.country{
  background-color: #fff;
  padding-left: 80px;
      padding-right: 20px;
  .main-one {
    background-color: #f7f7f7;
  }
}

.dropmenu{
  padding: 20px 0;
  .hottxt{
    color: #333333;
    font-size: 12px;
  }
}
.imgtab{
  margin-top: 20px;
  display: flex;
      justify-content: space-between;
  // justify-content: space-around;
  // .item{
  //   flex: 1;

  // }
}
</style>

<style lang='scss'>

.photobox {
  background-color: #f7f7f7;
  padding-bottom: 30px;
  .cell-item {
    background-color: #fff;
    overflow: hidden;
    padding: 13px 13px 0 13px;
    margin: 10px;
    border-radius: 4px;
    .item-body{
        padding: 10px 0;
    }
    .cell-image {
      width: 100%;
    }
    .item-footer {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .item-footer-l {
        display: flex;
        align-items: center;
        flex: 1;
        .name{
          margin-left: 10px;
        }
      }
      .item-footer-r {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
      }
    }
    .avatar {
      width: 23px;
      height: 23px;
      background-size: contain;
      border-radius: 50%;
    }
    .like {
        display: flex;
        align-items: center;
        span{
          font-size: 12px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;
margin-left: 5px;
        }
      img {
        width: 20px;
      }
    }
  }
}
.country{
  background-color: #fff;
  padding: 0 10px;
  .main-one {
    background-color: #f7f7f7;
  }
}
</style>
