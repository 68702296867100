<template>
  <div class="all" >
      <!-- 右侧报名 -->
      <div class="rightfix cursorstyle hidden-sm-and-down"  @click="toShow()" v-show="rules.enroll">
            <img src="../../../../assets/active20216/right.png" alt="" />
      </div>
      <!-- 报名截止 -->
       <div class="rightfix cursorstyle hidden-sm-and-down"  @click="toShow()" v-show="!rules.enroll">
            <img src="../../../../assets/active20216/stop.png" alt="" />
      </div>
    <el-row>
      <!-- 上方显示的banana图片 -->
      <el-col :span="24" :xs="24">
        <!-- pC -->
        <div class="banana hidden-xs-only" >
          <img
            :src="procressInfo.url"
            alt=""
            class="pre"
          />
        </div>
        <!-- mobile -->
        <div class="banana hidden-sm-and-up" >
          <img
             v-lazy="procressInfo.url"
            alt=""
            class="pre"
          />
        </div>
      </el-col>
    </el-row>
    <!-- tab -->

    <el-col :span="24" class="nav" >
      <div>
        <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
          <el-col :md="6" :xs="6">
            <div class="item">
             <a href="#1">
                <!-- <img src="../../../../assets/active20216/nav1.png" alt="" /> -->
                <span>活动简介</span>
              </a>
              </div>
  
          </el-col>
          <el-col :md="6" :xs="6">
         
            <div class="item">
               <a href="#2">
                <!-- <img src="../../../../assets/active20216/nav1.png" alt="" /> -->
                <span>创作人</span>
              </a>
            </div>
          </el-col>
          <el-col :md="6" :xs="6">
            <div class="item">
               <a href="#videobox">
                  <span>全部作品</span>
            </a>
            </div>
           
          </el-col>
          <el-col :md="6" :xs="6">
            <div class="item">
                <a href="#4">
               <span>留言板</span>
            </a>
            </div>
          </el-col>
        </el-col>
      </div>
    </el-col>

    <!-- 中间需要显示的内容 -->
    <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }" id="1">
      <div class="tabbox">
        <img src="../../../../assets/active20216/tab1.jpg" alt="" />
        <div v-html="procressInfo.introduction" style="white-space: pre-wrap;" class="mt39 mb78 f14">

        </div>
      </div>
    </el-col>

    <!-- 流程 -->
    <el-col :span="24" class="bgf cusinfo">
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
        <div class="tabbox" style="marginBottom:62px">
          <img src="../../../../assets/active20216/tab2.jpg" alt="" />
        </div>
        <!-- 报名期 -->
        <el-col :md="8" :xs="24" >
          <div class="cusinfoboxitem">
            <div class="cusinfobox">
                <div class="number" :class="prostatus == 'collect' ?'processbgactive':'processbgactiveno'">
               1
          </div>
           <div class="info" >
             <div class="txt">
               <p class="name">报名期</p>
              <p class="time"> {{procressInfo.collect}}</p>
             </div>
            <!-- Triangle -->
            <div class="triangle" :class="prostatus == 'collect' ?'processtrianglebgactive':'processtrianglebgactiveno'">
            </div>
          </div>
          <div class="info-one" :class="prostatus == 'collect' ?'processtrinfoactive':'processtrinfoactiveno'">
            <div v-html="procressInfo.collect_content" style="white-space: pre-wrap;"></div>
          </div>
          </div>
          </div>
          
          
        </el-col>
        <!-- 评选 -->
          <el-col :md="8" :xs="24" >
            <div class="cusinfoboxitem">
                  <div class="cusinfobox">
                    <div class="number" :class="prostatus == 'exhibition' ?'processbgactive':'processbgactiveno'">
               2
          </div>
           <div class="info" >
             <div class="txt">
               <p class="name">评选期</p>
              <p class="time"> {{procressInfo.exhibition}}</p>
             </div>
            <!-- Triangle -->
            <div class="triangle" :class="prostatus == 'exhibition' ?'processtrianglebgactive':'processtrianglebgactiveno'">
            </div>
          </div>
          <div class="info-one" :class="prostatus == 'exhibition' ?'processtrinfoactive':'processtrinfoactiveno'">
             <div v-html="procressInfo.exhibition_content" style="white-space: pre-wrap;"></div>
          </div>
            </div>
            </div>
        
        
        </el-col>
        <!-- 展映 -->
         <el-col :md="8" :xs="24" >
           <div class="cusinfoboxitem"> 
               <div class="cusinfobox">
                  <div class="number" :class="prostatus == 'selection' ?'processbgactive':'processbgactiveno'">
               3
          </div>
           <div class="info" >
             <div class="txt">
               <p class="name">展映期</p>
              <p class="time"> {{procressInfo.selection}}</p>
             </div>
            <!-- Triangle -->
            <div class="triangle" :class="prostatus == 'selection' ?'processtrianglebgactive':'processtrianglebgactiveno'">
            </div>
          </div>
          <div class="info-one" :class="prostatus == 'selection' ?'processtrinfoactive':'processtrinfoactiveno'">
            <div v-html="procressInfo.selection_content" style="white-space: pre-wrap;"></div>
          </div>
           </div>
           </div>
         
         
        </el-col>

        
      </el-col>
    </el-col>
    <!--评委  -->
    <el-col
      :xs="{ span: 22, offset: 1 }"
      :sm="{ span: 16, offset: 4 }"
      class="main-three"
      v-if="JudgesList.length > 0"
    >
      <div class="tabbox" style="marginBottom:62px">
        <img src="../../../../assets/active20216/tab7.jpg" alt="" />
      </div>
      <el-col :md="8" :xs="24" v-for="(item, x) in JudgesList" :key="x">
        <div class="item">
          <div class="item-one">
            <div class="avatar">
              <img :src="item.avatar" alt="" />
            </div>
          </div>
          <div class="item-two">
            <p class="name">{{item.name}}</p>
            <p class="info">{{item.identity}}</p>
            <p class="info">{{item.introduction}}</p>
          </div>
        </div>
      </el-col>
    </el-col>

    <!-- 征片要求 -->
    <el-col :span="24" class="bgf activemain-four">
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
        <div class="tab">
          <img src="../../../../assets/active20216/tab8.jpg" alt="" />
        </div>
        <el-col :sm="{ span: 16, offset: 5 }">
          <div class="txt">
            <div v-html="procressInfo.requirement" style="white-space: pre-wrap;" class="mt39 mb78 f14">

         </div>
          </div>
        </el-col>
      </el-col>
    </el-col>

    <!-- 报名须知 -->
    <el-col :span="24" class=" activemain-four" style="paddingBottom:98px">
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
        <div class="tab">
          <img src="../../../../assets/active20216/tab9.jpg" alt="" />
        </div>
        <el-col :sm="{ span: 16, offset: 5 }">
          <div class="txt">
             <div v-html="procressInfo.instructions" style="white-space: pre-wrap;" class="mt39 mb78 f14">

         </div>
          </div>
        </el-col>
      </el-col>
    </el-col>

    <!-- 奖品设置 -->
    
    <el-col :span="24" class="bgf activemain-four main-five">
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
       

        <div class="tab" style="marginBottom: 50px">
          <img src="../../../../assets/active20216/tab10.jpg" alt="" />
        </div>
          <el-col :md="8" :xs="24"  v-for="(item,px) in prizesList" :key=px>
            <div class="item">
              <div class="item-two" >
                      <div class="item-two-l"><img src="../../../../assets/active20216/leftv.png" alt=""></div>
                      <div class="item-two-c">{{item.title}}</div>
                      <div class="item-two-l"><img src="../../../../assets/active20216/rightv.png" alt=""></div>
                    </div>
            </div>
          </el-col>
        </el-col>
        
      <!-- <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
        <div class="join">
          <img
            src="../../../../assets/active20216/add.jpeg"
            alt=""
            @click="toShow()"
            class="cursorstyle hidden-sm-and-down"
            v-show="rules.enroll"
          />
          <p class="time">截止日期2021.9.23</p>
        </div>
      </el-col> -->
    </el-col>

    <!-- 作者 -->
    <el-col
      :xs="{ span: 22, offset: 1 }"
      :sm="{ span: 16, offset: 4 }"
      class="main-six"
      id="2"
      v-if="auths.length >0"
    >
      <div class="tab" >
        <img src="../../../../assets/active20216/tab11.jpg" alt="" />
      </div>
      <div style="position:relative">
        <swiper
          class="swiper"
          :options="swiperOption"
          v-if="swiperOption !== null"
          ref="mySwiper"
        >
          <swiper-slide class="swiperbox">
            <div class="itemList">
              <el-col
                :sm="{ span: 4, offset: 0 }"
                :xs="{ span: 8, offset: 0 }"
                v-for="(item, ax) in auths"
                :key="ax"
              >
                <div class="item cursorstyle" @click="toCrea(item.id)">
                  <div class="item-one">
                    <img v-lazy="item.avatar" alt="" />
                  </div>
                  <div class="item-two">
                    <p class="name">{{ item.username }}</p>
                    <p class="info">
                      {{ item.area_text || "河南" }}
                    </p>
                    <p class="info">{{ item.identity_text || "暂无职业" }}</p>
                  </div>
                </div>
              </el-col>
            </div>
          </swiper-slide>
          <div
            class="swiper-button-prev "
            :class="authPage == 1 ? 'authno' : 'authda'"
            @click.stop="handleCurrentChange(1)"
            v-show="auth_last_page !== 1"
          ></div>
          <div
            class="swiper-button-next"
            :class="authPage < auth_last_page ? 'authda' : ''"
            @click.stop="handleCurrentChange(2)"
            v-show="auth_last_page !== 1"
          ></div>
        </swiper>
      </div>
    </el-col>

    <!-- zuopin设置 -->
    <el-col :span="24" class="bgf activemain-four main-seven" id="videobox" >
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }" v-if="videoEndList.length > 0">
        <div class="tab">
          <img src="../../../../assets/active20216/tab12.jpg" alt="" />
        </div>
        <!-- 上方TAB选择 -->
        <div class="videotabbox" v-show="rules.exhibition">
              <div class="videotab" v-for="(item,tabx) in videotab" 
              :key='tabx'  @click="selectVideoTab(item)">
              <span class="name cursorstyle" :class="videotanA == item.index ? 'videotabActive':''"> {{item.name}}</span>
              </div>
        </div>
         
      
        <!-- 获奖作品列表 -->
        <div class="awardedbox" v-show="videotanA == 0">
             <el-row :gutter="20">
               <el-col
              :xs="{ span: 22, offset: 1 }"
              :sm="{ span: 12, offset: 0 }"
              :gutter="20"
               v-for="(item, endx) in videoEndList"
              :key="endx"
              
              >
              <div class="item " >
                    <div class="item-two" >
                      <div class="item-two-l"><img src="../../../../assets/active20216/leftv.png" alt=""></div>
                      <div class="item-two-c">{{item.prize_info.title}}</div>
                      <div class="item-two-l"><img src="../../../../assets/active20216/rightv.png" alt=""></div>
                    </div>
                    <div class="item-one cursorstyle" >
                      <div  v-lazy:background-image="item.video_info.cover"   class="bg photoZoomBg"  @click.stop="toVideoPage(item.video_id)"></div>
                      <div class="title">
                        <p>{{item.video_info.title}}</p>
                      </div>
                      <div class="avatar" @click.stop="toCrea(item.user_id)">
                        <img v-lazy="item.user_info.avatar" alt="">
                        <span class="name"> {{ item.user_info.username }}</span>
                       
                      </div>
                    </div>
                    <div class="item-three">
                      <div class="item-t-l"><img src="../../../../assets/active20216/rightd.png" alt=""></div>
                       <div class="item-t-c">{{item.evaluate}}</div>
                       <div class="item-t-l"><img src="../../../../assets/active20216/leftd.png" alt=""></div>
                    </div>
              </div>
              
              </el-col>
             </el-row>
        </div>
        <!-- 作品列表 -->
        <div class="card-box" v-show="videotanA == 1">
          <el-row :gutter="20">
            <el-col
              :xs="{ span: 22, offset: 1 }"
              :sm="{ span: 8, offset: 0 }"
              v-for="(item, saindex) in newArticle"
              :key="saindex"
              ><div
                class="grid-content cursorstyle "
                style=" margin-bottom: 30px;"
                @click="toVideoPage(item.video_id)"
              >
                <div class="card-box-one photoZoom">
                  <!-- <img v-lazy="item.cover" alt="" style="height:100%"/> -->
                  <div
                    v-lazy:background-image="item.video_info.cover"
                    class="bg photoZoomBg"
                  ></div>

                  <span class="transtime">{{
                    item.video_info.play_time | transTime
                  }}</span>
                </div>
                <div class="card-box-two">
                  <p>{{ item.video_info.title }}</p>
                </div>
                <div class="card-box-three">
                  <span>{{ item.user_info.area_text }}</span>
                  <span class="line">{{ item.user_info.identity_tex }}</span>
                </div>
                <div class="card-box-four" v-if="item.user_info">
                  <div class="card-box-for-img">
                    <img v-lazy="item.user_info.avatar" alt="" />
                  </div>
                  <div class="card-box-for-author">
                    <span>{{ item.user_info.username }}</span>
                  </div>
                  <div class="card-box-cl">
                    <el-button class="sub" size="mini" @click.stop="vote(item)" :disabled="!rules.vote">投票 | {{item.vote_num}}</el-button>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
          <div class="videpNav">
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :total="videotTotal"
              :page-size="6"
              :page-count="video_last_page"
              :current-page="videoPage"
              @current-change="handleVideoCurrentChange"
              :hide-on-single-page="true"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-col>
    <!-- 底部提示信息 -->
    <el-col :span="24" class="hidden-sm-and-up">
      <div class="mobile-fix">
        <p>请前往电脑端报名参加 报名时间{{procressInfo.collect}}</p>
      </div>
    </el-col>

    <!-- 奖品设置 -->
    <!-- <el-col :span="24" class="bgf " style="text-align:center">
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
        <div class="tab">
          <img src="../../../../assets/active20216/tab13.jpg" alt="" />
        </div>
      </el-col>
    </el-col> -->

    <el-col id="4">
      <Activecomm ref="commb"></Activecomm>
    </el-col>
    <!-- 我的作品内容 -->
    <Mylist ref="per"></Mylist>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import Mylist from "@/components/active/activemylist";
import Activecomm from "@/components/active/activecomment";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { activAuthors, activWorks, meVideo ,activeVote,getWeConfig,activeRule,activeExhibition,activeDetail} from "@/api";
import "swiper/css/swiper.css";
import wx from "weixin-js-sdk"
export default {
  components: {
    Mylist,
    Activecomm,
    Swiper,
    SwiperSlide,
  },
  props: {},
  data() {
    return {
      ip: "",
      h: "",
      w: "",
      swiperOption: {
        loop: false,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      newArticle: [],
      auths: [],
      authPage: 1,
      auth_last_page: null,

      videoList: [],
      videoPage: 1,
      video_last_page: null,
      videotTotal: 0,

      navSt:false,

      ip:'',
      JudgesList:[
       
      ],
      rules:'',

      videotab:[
        {name:"获奖作品",index:0},
        {name:"全部作品",index:1},
      ],
      videotanA:null,
      videoEndList:[],
      prostatus:'',
      procressInfo:'',
      prizesList:[],
      id:1
    };
  },
  watch: {},
  computed: {},
  methods: {
    getDeta:function(){
      activeDetail({
        activity:this.id
      }).then(res=>{
        this.prostatus = res.data.activity_progress
        this.procressInfo = res.data.activity
        this.JudgesList = res.data.judges
        this.prizesList = res.data.prizes
      })
    },
    selectVideoTab:function(e){
      this.videotanA = e.index
    },
    getScuu:function(){
      activeExhibition().then(res=>{
        this.videoEndList = res.data
      })
    },
    vote:function(e){
      activeVote({
        work:e.id
      }).then(res=>{
        if(res.code == 200){
           this.$message({
            message: res.msg,
            type: "success",
          });
          e.vote_num++
        }
      })
    },
    toShow: function() {
      this.$refs.per.show();
    },
    getAuth: function() {
      activAuthors({
        page: this.authPage,
        activity:this.id
      }).then((res) => {
        this.auths = res.data.data;
        this.authPage = res.data.current_page;
        this.auth_last_page = res.data.last_page;
      });
    },
    changeAuthPage: function() {
      console.log("11");
    },
    handleCurrentChange: function(e) {
      if (e == 1) {
        if (this.authPage > 0) {
          this.authPage--;
        }
      } else {
        this.authPage++;
      }
      this.getAuth();
    },
    getVideo: function() {
      activWorks({
        activity:this.id,
        page: this.videoPage,
        per: 6,
      }).then((res) => {
        this.ip = res.ip
        this.newArticle = res.data.data.filter(item => item.video_info);
        this.videoPage = res.data.current_page;
        this.video_last_page = res.data.last_page;
        this.videotTotal = res.data.total;
      });
      activeRule({
         id:this.id,
      }).then(res=>{
        this.rules = res.data
        if (res.data.exhibition) {
          this.getScuu()
          this.videotanA = 0
        }else{
          this.videotanA = 1
        }
      })
    },
    handleVideoCurrentChange: function(e) {
      this.videoPage = e;
      this.getVideo();
      // var a =   document.querySelector("#videobox")
      document.querySelector("#videobox").scrollIntoView(true)
    },
    toCrea: function(e) {
      if (this.w < 768) {
        window.open(`https://h5.cvhenan.com/#/pages/user/creator?id=${e}`)
      } else {
         const { href } = this.$router.resolve({
        path: "/creatorcenter",
        query: {
          id: e,
        },
       });
      window.open(href, "_blank");
      }
     
    },
    toVideoPage: function(e) {
      if (this.w < 768) {
        window.open(`https://h5.cvhenan.com/#/pages/detail/videodetail?id=${e}`)
      } else {
          const { href } = this.$router.resolve({
          path: "/videodetail",
          query: {
            id: e,
          },
        });
        window.open(href, "_blank");
      }
     
    },
    isWx:function(){
        let en = window.navigator.userAgent.toLowerCase()
        // 匹配en中是否含有MicroMessenger字符串
        if (en.match(/MicroMessenger/i) == 'micromessenger') {
        // alert("在微信",11111)
          this.share()
        }
        if(en.match(/MicroMessenger/i) != 'micromessenger'){
          // alert("不在微信",2222)
          //  this.share()
        }
    },
    share:function(url){
				// let url = ''
				// let os = ""
				// uni.getSystemInfo({
				// 	success: function(res) {
				// 		os = res.system;
				// 	}
				// });
				// if(os.indexOf("ios") == -1){
				// 	url = window.location.href
				// }else{
				// 	url = window.location.href.split("#")[0]
					
				// }
				getWeConfig({
					url: window.location.href.split("#")[0]
				}).then(res=>{
					// uni.showModal({
					// 	 title: '提示',
					// 	  content: res.data.url,
					// })
					wx.config({
					  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					  appId: res.data.app_id, // 必填，公众号的唯一标识
					  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
					  nonceStr: res.data.nonce_str, // 必填，生成签名的随机串
					  signature: res.data.signature,// 必填，签名
					  jsApiList: [
						  'updateAppMessageShareData',
						  'updateTimelineShareData',
					  ] // 必填，需要使用的JS接口列表
					});
					var self = this
					wx.ready(function(){
					  // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
					   wx.updateAppMessageShareData({ 
					      title: "活动内测", // 分享标题
					      desc: 'C位河南-河南文化传播交流平台', // 分享描述
					      link: document.location.toString(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:'https://benzhongwenhua.oss-cn-beijing.aliyuncs.com/uploads/20210622/9c11e1bb23d777672e8b02b6f907e9e7.jpg',
					      success: function () {
					        // 设置成功
					      }
					    });
						wx.updateTimelineShareData({
						   title: '活动内测', // 分享标题
						   desc: 'C位河南-河南文化传播交流平台', // 分享描述
						   link: document.location.toString().split('?')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:'https://benzhongwenhua.oss-cn-beijing.aliyuncs.com/uploads/20210622/9c11e1bb23d777672e8b02b6f907e9e7.jpg',
						   success: function () {
						     // 设置成功
						   }
						 });
					});
			
				})
			 
			},
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.getAuth();
    this.getVideo();
    this.isWx()
    this.getDeta()
    this.w = document.body.clientWidth
  },
};
</script>
<style lang="scss" scoped>
.all{
  height: 100%;
  overflow: auto;
}
.cusinfobox{
  padding: 0 30px;
  position: relative;
     max-width: 223px;
}
.f14{
  font-size: 15px;
}
.cusinfoboxitem{
  display: flex;
  justify-content: center;
}
.banana {
  .pre {
    width: 100%;
  }
}
.processbgactive{
   background: #FF8F1C;
}
.processbgactiveno{
  background: #C1220E;
}
.processtrianglebgactive{
   background:linear-gradient(to top right, #FF8F1C 0%, #FF8F1C calc(50% - 1.5px), #FF8F1C 50%, rgba(0, 0, 0, 0) calc(50% + 1.5px), rgba(0, 0, 0, 0) 100%);
}
.processtrianglebgactiveno{
   background:linear-gradient(to top right, #C1220E 0%, #C1220E calc(50% - 1.5px), #C1220E 50%, rgba(0, 0, 0, 0) calc(50% + 1.5px), rgba(0, 0, 0, 0) 100%);
}
.processtrinfoactive{
   background: linear-gradient(0deg, #FAD921, #FF8F1C);
   color: #ffffff;
}
.processtrinfoactiveno{
   border:1px solid #C1220E;
   color: #333333;
}
.rightfix{
  position: fixed;
  top: 30%;
  right: 10%;
  z-index:100
}

.mobile-fix {
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: orange;
 
  width: 100%;
  text-align: center;
  z-index: 99;
  p{
     padding: 20px;
  }
}

.tabbox {
  // display: flex;
  // align-items: center;
  text-align: center;
  margin-top: 79px;
  p {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}

.mt39 {
  margin-top: 39px;
}
.mb78 {
  margin-bottom: 78px;
}
.bgf {
  background-color: #f7f7f7;
  padding-bottom: 98px;
}
.cusinfo{
  //   .info{
  //   margin-bottom: 30px;
  //   text-align: center;
  //   .name{
  //     font-size: 20px;
  //     color: #333333;
  //     font-weight: bold;
  //      margin-bottom: 10px;
  //   }
  //   .time{
  //       font-size: 16px;
  //     color: #333333;
  //   }
  // }
   .number{
    position: absolute;
   
    border-radius: 50%;
    width: 30px;
    height: 30px;
    z-index: 90;
    text-align: center;
    line-height: 30px;
    top: -20px;
    left: 10px;
    font-size: 30px;
    color: #fff;
    font-style: italic;
    font-family: monospace;
  }
  .info{
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    height: 88px;
    overflow: hidden;
  }
  .triangle{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
   
    
  }
  .txt{
    position: relative;
    z-index: 9;
      text-align: center;
    margin-top: 10px;
    .name{
      font-size: 22px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #161616;
    }
    .time{
      // font-size: 28px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #161616;
padding: 0 20px;
    }
  }
  .info-one{
    margin-top: 20px;
    height: 260px;
   
border-radius: 8px;
  padding:30px 15px;
  margin-bottom: 60px;
  font-size: 14px;
  }
 
}
.imgtwo {
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }

}

.main-three {
  margin-bottom: 40px;
  .item {
    margin-bottom: 20px;
    .item-one {
      display: flex;
      justify-content: center;
      .avatar {
        width: 134px;
        height: 134px;
        padding: 4px;
        overflow: hidden;
        display: flex;
        border: 1px solid #ff8f1c;
        border-radius: 50%;
        img {
          width: 134px;
          border-radius: 50%;
        }
      }
    }

    .item-two {
      text-align: center;
      .name {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        margin-top: 23px;
        margin-bottom: 19px;
      }
      .info {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

.activemain-four {
  .tab {
    margin-top: 79px;
    text-align: center;
  }
  .txt {
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 20px;
    }
  }
}

.nav {
  background-color: #c1220e;
  // height: 50px;
  position: sticky;
  top: 0;
  z-index: 99;
  margin-top: -10px;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 10px;
    img {
      max-width: 100%;
      height: 30px;
    }
    a{
     text-decoration: none;
     color: #fff;
    font-weight: bold;
    // font-size: 20px;
    font-size: .2rem;
    }
  }
}
@media screen and (max-width:720px) {
  .nav a{
    font-size: 16px !important;
  }
}
.main-five {
  .join {
    text-align: center;
  }
  .time {
    text-align: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }
   .item-two{
        display: flex;
        // justify-content: center;
        justify-content: space-around;
        margin-bottom: 50px;
        padding: 0 20px;
        .item-two-l{
        width: 18px;
        display: flex;
    align-items: center;
        img{
          max-width: 100%;
        }
      }
      .item-two-c{
        // line-height: 18px;
        color: rgb(195, 145, 63);
        display: flex;
            align-items: center;
            margin: 0 30px;
    font-weight: bold;
    font-size: 20px;

      }
      }
}

.main-six {
  padding: 80px 0px;
  .swiper-container {
    --swiper-navigation-color: #f2d012; /* 单独设置按钮颜色 */
  }
  .tab {
    margin-top: 79px;
    margin-bottom: 50px;
    text-align: center;
  }
  .swiperbox {
    // height: 300px;
    .swiper_btn {
      position: absolute;
    }
    .swiper-button-prev {
      width: 0.36rem;
      height: 0.28rem;
      // background: url("路径") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next {
      width: 0.36rem;
      height: 0.28rem;
      // background: url("路径") no-repeat;
      background-size: 100% 100%;
    }

    .itemList {
      // border: 1px solid;
      overflow: hidden;
      .item {
        margin: 18px 0;
        .item-one {
          display: flex;
          justify-content: center;
          img {
            max-width: 78px;
            border-radius: 50%;
            overflow: hidden;
            height: 78px;
          }
        }
        .item-two {
          margin-top: 10px;
          text-align: center;
          .name {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
          .info {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
}

.main-seven {
  .card-box {
    overflow: hidden;
    margin-top: 40px;
    .card-box-one {
      height: 160px;
      overflow: hidden;
      position: relative;
      .bg {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        // height: 100%;
      }
      .cat {
        position: absolute;
        bottom: 10px;
        // right: 60px;
      }
      .r60 {
        right: 60px;
      }
      .r10 {
        right: 10px;
      }
    }
    .card-box-two {
      margin-top: 20px;
      margin-left: 16px;
      margin-right: 10px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card-box-three {
      margin-top: 10px;
      margin-left: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .line {
        margin-left: 12px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
      }
    }
    .card-box-four {
      display: flex;
      padding: 11px 16px;
      align-items: center;
      .card-box-for-img {
        width: 24px;
        height: 24px;
      }
      .card-box-for-img img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .card-box-for-author {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
      }
      .card-box-for-job {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
        margin-left: 16px;
      }
      .card-box-cl {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
    .card-box-five {
      text-indent: 2em;
      padding: 3px 16px;
      color: #bebebe;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    background: #ffffff;
  }
}

.authno {
  // opacity: 0.35;
  // cursor: auto;
  // pointer-events: none;
}
.authda {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
.videpNav {
  text-align: center;
}


.sub{
   background-color: #e35111;
  border-color: #e35111;
  color: #ffffff;
}
.is-disabled:hover {
  color: #ffffff;
  cursor: not-allowed;
  background-image: none;
  background-color: rgba(227, 81, 17, 0.5);
  border-color: rgba(227, 81, 17, 0.5);
}
.is-disabled {
  background-color: rgba(227, 81, 17, 0.5) !important;
  border-color: rgba(227, 81, 17, 0.5) !important;
}

.main-seven{
  .videotabbox{
    margin: 50px 0;
    display: flex;
    .videotab{
      flex: 1;
      display: flex;
      justify-content: center;
      .name{
        display: inline-block;
          font-size: 17px;
        border: 1px solid #e35111;
        color: #e35111;
        border-radius: 1rem;
          padding: 5px 20px;
      }
    
    }
  }

  .awardedbox{
    margin-top: 20px;
    .item{
        //  margin-bottom: 30px;
         margin: 0 20px 30px 20px;
      .item-one{
        height: 270px;
      overflow: hidden;
      position: relative;
      .bg {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        // height: 100%;
      }
      .title{
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: 15px;
        color: #ffffff;
      }
      .avatar{
        position: absolute;
        top: 20px;
        left: 20px;
        display: flex;
        align-items: center;
        img{
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .name{
          margin-left: 15px;
          color: #ffffff;
          font-size: 14px;
        }
      }
      }
      .item-two{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .item-two-l{
        width: 18px;
        display: flex;
    align-items: center;
        img{
          max-width: 100%;
        }
      }
      .item-two-c{
        // line-height: 18px;
        color: rgb(195, 145, 63);
        display: flex;
            align-items: center;
            margin: 0 30px;
    font-weight: bold;
    font-size: 20px;

      }
      }
      .item-three{
        display:flex;
        align-items: center;
        padding: 20px 0;
        .item-t-c{
          flex: 1;
          color: #02053a;
          font-size: 14px;
          padding: 0 20px;
        }
        .item-t-l{
            width: 30px;
            img{
              width: 100%;
            }
        }
      }
      
    }
  }
}

.videotabActive{
  background-color: #e35111;
  color: #ffffff !important; 
}
</style>
<style lang="scss">
.videpNav .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
.videpNav .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #e35111;
}
.videpNav .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #ffffff;
}
</style>
