<template>
  <div class="all">
    <el-dialog title="提交展映作品" :visible.sync="dialogTableVisible">
      <div class="main">
        <div class="list">
          <el-row :gutter="10">
       
            <el-col :span="6" v-for="(item, x) in videoList" :key="x" >
              <div class="item cursorstyle" @click="toUpload(item.id)">
                <div class="item-one card-box-one">
                  <div
                    v-lazy:background-image="item.cover"
                    class="bg photoZoomBg"
                  ></div>
                </div>
                <div class="item-two">
                  <p class="title">{{item.title}}</p>
                  <p class="info">{{item.label_text}}</p>
                </div>
                <div class="check " @click.stop="check(x,item.id)">
                   <input :id="x" name="radio" type="radio" class="ra" :checked="isCheck == x">
                    <label for="radio-1" class="radio-label cursorstyle"></label>
                </div>
              </div>
            
            </el-col>

          </el-row>

            <!-- 分页 -->
            <div class="pagen" v-show="total !== 0">
                <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :current-page="currentPage"
            :page-size="8"
            @current-change="handleCurrentChange"
            :hide-on-single-page="true"
            :small="true"
            >
          </el-pagination>
            </div>
          
            <!-- 无数据提示 -->
      <div class="videoem" v-show="total == 0 ">
        <div class="emptyone">
          <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
        </div>
        <div class="emptytxt">
          <p>还没有作品哦！</p>
        </div>
      </div>
        </div>

        <div class="main-two">
            <div class="sub" v-show="total !== 0 ">
              <el-button type="danger" :disabled="isCheck == null" @click="sub">确认提交</el-button>
            </div>
            <div class="txt">
              <p>展映作品还未上传到C位河南？点击 <span class="cursorstyle" @click="toUploadPage">此处去上传</span></p>
            </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { meVideo,joinActive } from "@/api";
export default {
  props: {},
  data() {
    return {
      page: 1,
      dialogTableVisible: false,
      cover:
        "https://benzhongwenhua.oss-cn-beijing.aliyuncs.com/atlas/file/1623831161221.jpg",
      isCheck:null,
      videoList:[],
      currentPage:0,
      total:null,
      checkid:'',
      allPage:'',
    };
  },
  watch: {},
  computed: {},
  methods: {
    getList: function() {
      meVideo({
        per:8,
        page:this.page
      }).then((res) => {
        this.videoList = res.data.data.filter(item=>item.check_status == 1)
        this.total = res.data.total
        this.currentPage = res.data.current_page
        this.allPage = res.data.last_page
      });
    },
    show: function(e) {
      this.dialogTableVisible = true;
      this.getList()
    },
    close:function(){
       this.dialogTableVisible = false
    },
    check:function(e,id){
      this.isCheck = e
      this.checkid = id
    },
    handleCurrentChange:function(e){
      this.page = e
      this.isCheck = null
      this.getList()
    },
    toUpload:function(e){
       const { href } = this.$router.resolve({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },
    toUploadPage:function(){
      const { href } = this.$router.resolve({
        path: "/publicvideo",
      });
      window.open(href, "_blank");
    },
    sub:function(){
      joinActive({
        work:this.checkid
      }).then(res=>{
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.close()
          }
      })

    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.main {
  .list {
    // padding: 20px;
    .item {
      position: relative;
      .item-one {
      }
      .card-box-one {
        height: 100px;
        overflow: hidden;
        position: relative;
         border-radius: 5px;
          overflow: hidden;
        .bg {
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
         
        }
        img {
          width: 100%;
          // height: 100%;
        }
      }

      .item-two{
        margin-bottom: 10px;
        margin-top: 5px;
        .title{
          color: #333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .info{
          color: #A9A9A9;
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .check{
        // width: ;
        position: absolute;
        top:0;
        right:20px;
        .ra{
          opacity: 0;
        }
        .ra:checked+label{
           background-color: #fe6d32;
            border: 1px solid #fe6d32;
        }
        .ra:checked+label::after{
            position: absolute;
            content: "";
            width: 5px;
            height: 10px;
            top: 3px;
            left: 6px;
            border: 2px solid #fff;
            border-top: none;
            border-left: none;
            transform: rotate(45deg);
        }
        .radio-label{
           position: absolute;
            left: 5px;
            top: 3px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #999;
            background-color: rgba(0,0,0,.3);
        }
      }
    }
  }

  .main-two{
    padding-top: 20px;
    .sub{
      display: flex;
      justify-content: center;
    }
    .txt{
      text-align: center;
      margin-top: 10px;
      span{
        color: orange;
      }
    }
  }
}
.videoem{
   text-align: center;
  .emptyone{
    text-align: center;
  }
  .emptyimg{
    max-width: 100%;
    height: 100px;
  }
  .emptytxt{
    color: #999;
  }
}
.pagen{
  text-align: center;
  margin: 5px;
}
</style>
<style lang="scss">
.pagen .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
.pagen .el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: #e35111 ;
}
.pagen .el-pagination.is-background .el-pager li:not(.disabled).active{
  color: #ffffff;
}
</style>
