<template>
  <div class="all">
    <div class="main">
      <div
        class="main-one swipermask"
        :style="{
          backgroundImage: 'url(' + swiperBack + ')',
          backgroundSize: 'cover',
        }"
      >
        <!-- 视频播放 -->
        <div class="videobox">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            style="object-fit:fill"
            :options="playerOptions"
            :x5-video-player-fullscreen="true"
            @pause="onPlayerPause($event)"
            @play="onPlayerPlay($event)"
            @fullscreenchange="onFullscreenChange($event)"
            @click="fullScreen"
          >
          </video-player>
        </div>
      </div>
      <div class="main-two">
        <div class="main-l">
          <p class="title">{{ video.title }}</p>
          <div class="main-l-one">
            <div class="one-l" >
                 <div class="num">
            <img src="../../assets/img/show.png" alt="" />
            <p>{{ video.total_scan }}</p>

            <img
              src="../../assets/img/time.png"
              alt=""
              style="margin-left:20px; width:11px;height:11px"
            />
            <p>{{ video.created_at }}</p>
            <p @click="showDia(id,'video')" style="margin-left:5px" class="cursorstyle">举报</p>
          </div>
            </div>
            <div class="one-r">
              <div
                class="likebutton cursorstyle"
                @click="like"
                :class="is_like == 0 ? 'unlikeactive' : 'likeactive'"
              >
                <img
                  src="../../assets/img/likef.png"
                  alt=""
                  v-show="is_like == 0"
                />
                <span style="margin-left:5px" v-show="is_like == 0">喜欢</span>
                <span style="margin-left:5px" v-show="is_like == 1"
                  >已喜欢</span
                >
                <span style="margin-left:10px">{{ video.like_num }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="num">
            <img src="../../assets/img/show.png" alt="" />
            <p>{{ video.total_scan }}</p>

            <img
              src="../../assets/img/time.png"
              alt=""
              style="margin-left:20px; width:11px;height:11px"
            />
            <p>{{ video.created_at }}</p>
          </div> -->
          <el-divider class="driveLine"></el-divider>
          <div v-if="video.description !== ''">
            <div class="content">
              <p style="text-indent:2em;letter-spacing: 2px;">
                {{ video.description }}
              </p>
            </div>
            <el-divider class="driveLine"></el-divider>
          </div>

          <div class="label">
            <div class="label-one">
              <img src="../../assets/img/label.png" alt="" />
              <p>标签</p>
            </div>
            <div class="labeltxt">
              <span
                v-for="(item, labindex) in video.label_text"
                :key="labindex"
                >{{ item }}</span
              >
            </div>
          </div>
          <div class="commbox">
            <comment :info="info" v-if="info.id !== ''"></comment>
          </div>
        </div>
        <div class="main-r">
          <div style="background-color: #ffffff; padding: 20px;">
            <div class="r-txt">
              <p>创作人</p>
            </div>
            <div class="box cursorstyle" >
              <div class="r-one "   @click="toUserDetail()">
                <img :src="user.avatar" alt="" />
              </div>
              <div class="r-two"  @click="toUserDetail()">
                <p>{{ user.username }}</p>
                <p>{{ user.identity_text }}</p>
              </div>
              <div v-if="Me">
                <div
                  class="r-three cursorstyle"
                  v-show="is_follow == 0"
                  @click.stop="follow"
                >
                  <span>关注</span>
                </div>
                <div
                  class="r-four cursorstyle"
                  v-show="is_follow == 1"
                  @click.stop="canfollow"
                >
                  <span>已关注</span>
                </div>
              </div>
            </div>
            <!-- <div class="de">
              <p>{{ user.introduction }}</p>
            </div> -->
          </div>
          <!-- 下方 -->
          <div class="main-r-two" v-if="other.length > 0">
            <div class="one-box">
              <span>TA的其他作品</span>
              <span  class="cursorstyle" @click="toUserMore()">更多</span>
            </div>
              <div class="two-box creatorother cursorstyle" v-for="(item, o) in other"
              :key="o"
              @click="toMe(item.id)">
              <div class="two-box-l videoImg">
                <!-- <img v-lazy="item.cover" alt=""> -->
                  <div v-lazy:background-image="item.cover" class="bg photoZoomBg"  ></div>
                 <span class="transtime">{{item.play_time | transTime}}</span>
              </div>
              <div class="two-box-r">
                <div class="title"> <p>{{item.title}}</p></div>
                 <div class="info" style="padding-top: 17px;">
                    <div class="info-l">
                       <img src="../../assets/img/show.png" alt="" class="iconone" style="    width: 20px;"/>
                       <span>{{ item.total_scan }}</span>
                    </div>
                    <div class="info-r">
                      <img src="../../assets/img/time.png" alt="" class="iconone" />
                       <span>{{ item.created_at | timeNew }}</span>
                    </div>
                 </div>
              </div>
          </div>
          </div>

          <div class="main-r-two" v-if="likeList.length > 0">
            <div class="one-box">
              <span>猜你喜欢</span>
              <span class="cursorstyle"  @click="toMore()">更多</span>
            </div>
            <div class="two-box creatorlike cursorstyle"  v-for="(item, x) in likeList"
              :key="x"
              @click="toMe(item.id)">
              <div class="two-box-l videoImg">
                <!-- <img v-lazy="item.cover" alt=""> -->
                  <div v-lazy:background-image="item.cover" class="bg photoZoomBg"  ></div>
                  <span class="transtime">{{item.play_time | transTime}}</span>
              </div>
              <div class="two-box-r">
                <div class="title"> <p>{{item.title}}</p></div>
                <div class="name" style="padding-top: 0px;"><p>{{item.user_info.username}}</p></div>
                 <div class="info" style="padding-top: 2px;">
                    <div class="info-l">
                       <img src="../../assets/img/show.png" alt="" class="iconone" style="    width: 20px;"/>
                       <span>{{ item.total_scan }}</span>
                    </div>
                    <div class="info-r">
                      <img src="../../assets/img/time.png" alt="" class="iconone" />
                       <span>{{ item.created_at | timeNew }}</span>
                    </div>
                 </div>
              </div>
          </div>
          </div>
        </div>
      </div>
      <div class="line" style="height:20px"></div>
        <report ref="rep"></report>
    </div>

  </div>
</template>

<script>
import {
  videoDetail,
  CanclefollowCreator,
  videoLike,
  followCreator,
} from "@/api";
import report from "../../components/report";
import comment from "../../components/comments";
export default {
  components: {
    comment,
    report
  },
  props: {},
  data() {
    return {
      info: {
        id: "",
        type: "video",
      },
      id: "",
      is_follow: null,
      is_like: null,
      video: "",
      swiperBack: "",
      other: [],
      likeList: [],
      user: "",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src:
              "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
          },
        ],
        poster: "你的封面地址",
        width: document.documentElement.clientWidth,
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      Me: null,
    };
  },
  watch: {
    $route(to, from) {
      // 判断跳转的路径是否你要监听的路径，我这里为 '/search'
      if (to.path == "/videodetail") {
        // 通过路径获取新的 keywords 值
        this.id = to.query.id;
        // 通过新的参数值获取新的数据
        // this.getDetail();
        this.$router.go(0);
      }
    },
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
      showDia:function(e,type){
        let info={
          id:e,
          type:type
        }
      this.$refs.rep.show(info)
    },
      commDia:function(e){
       e.comment_type = '2'
       e.type="comment"
       this.$refs.rep.show(e)
    },
      toMore:function(){
      this.$router.push({
        path:'/videolist'
      })
    },
    toUserMore:function(){
      this.$router.push({
        path:'/creatorcenter',
        query:{
          type:'0',
           id:this.video.user_id
        }
      })
    },
     toUserDetail:function(){
      this.$router.push({
        path:'/creatorcenter',
        query:{
          id:this.video.user_id
        }
      })
    },
    fullScreen() {
      const player = this.$refs.videoPlayer.player;
      player.requestFullscreen(); //调用全屏api方法
      player.isFullscreen(true);
      player.play();
    },
    onPlayerPlay(player) {
      player.play();
    },
    onPlayerPause(player) {
      // alert("pause");
    },
    getDetail: function() {
      videoDetail({
        video: this.id,
      }).then((res) => {
        this.is_follow = res.data.is_follow;
        this.is_like = res.data.is_like;
        this.video = res.data.video;
        this.swiperBack = res.data.video.cover;
        this.other = res.data.others;
        this.likeList = res.data.like;
        this.user = res.data.video.user_info;
        this.playerOptions.sources[0].src = res.data.video.url;
        this.playerOptions.poster = res.data.video.cover.split("?")[0];
        let m = JSON.parse(localStorage.getItem("user"));
        if (m) {
           if (res.data.video.user_id == m.id) {
          this.Me = false;
        } else {
          this.Me = true;
        }
        }else{
           this.Me = true;
        }
       
      });
    },
    like: function() {
      videoLike({
        video: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_like = this.is_like == 0 ? 1 : 0;
          if (this.is_like == 0 && this.video.like_num !== 0) {
            this.video.like_num--;
          } else {
            this.video.like_num++;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 关注
    follow: function() {
      followCreator({
        creator: this.video.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 1;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 取消关注
    canfollow: function() {
      CanclefollowCreator({
        creator: this.video.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 0;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    toMe: function(e) {
      this.$router.push({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
    },
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.info.id = this.$route.query.id;
    this.$nextTick(function() {
      this.getDetail();
    });
  },
};
</script>
<style lang="scss" scoped>
.swipermask::after {
  height: 573px;
}
.main {
  background-color: #f7f7f7;
  position: relative;
  .main-one {
    // height: 540px;
    .videobox {
      margin: 0 auto;
      width: 1020px;
      //   height: 540px;
      .video-player {
        //    height: 540px;
        video {
          height: 540px;
        }
      }
    }
  }
  .main-two {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #f7f7f7;
    .main-l {
      padding: 28px 30px;
      background-color: #ffffff;
      flex: 1;
      .title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .main-l-one {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .one-l {
          display: flex;
          flex: 1;
        }
        .one-r {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          .likebutton {
            display: inline-block;
            border-radius: 2px;
            padding: 10px 16px;
            span {
              font-size: 12px;
            }
            img {
              width: 13px;
            }
          }
        }
      }
      .num {
        display: flex;
        align-items: center;
        img {
          width: 15px;
        }
        p {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-left: 5px;
          color: #999999;
        }
      }
      .content {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .label {
        .label-one {
          display: flex;
          align-items: center;
          img {
            width: 14px;
          }
          p {
            margin-left: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
        .labeltxt {
          margin-top: 22px;
          span {
            font-size: 12px;
            background-color: #f7f7f7;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            padding: 7px 12px;
            margin-right: 24px;
          }
        }
      }
    }
    .main-r {
      width: 400px;
      margin-left: 20px;
        
      // padding: 20px;
      .r-txt {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .box {
        display: flex;
        margin-top: 20px;
          align-items: center;
      }
      .r-one {
        background-color: #ffffff;
        img {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }
      }
      .r-two {
        margin-left: 13px;
        flex: 1;
      }
      .r-two p:nth-child(1) {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .r-two p:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .r-three {
        color: #ffffff;
        span {
          padding: 7px 18px;
          background: #e35111;
          border-radius: 2px;
        }
      }
      .r-four {
        //   color: #f7f7f7;
        span {
         padding: 7px 18px;
        background: #ffffff;
        color: #333333;
        border-radius: 2px;
        border: 1px solid #dedede;
        }
      }
      .de {
        p {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}

.likeactive {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
.unlikeactive {
  background-color: #e35111;
  border: 1px solid #e35111;
  color: #ffffff;
}

.main-r-two {
  margin-top: 20px;
  background-color: #ffffff;
  padding-bottom: 10px;
    .bg{
     background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .one-box {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: baseline;
  }
  .one-box span:nth-child(1) {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .one-box span:nth-child(2) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .two-box {
    padding: 0 20px 0px 20px;
    .box-item {
      display: flex;
      margin-bottom: 10px;
      .item-l {
        img {
          width: 141px;
          height: 80px;
        }
      }
      .item-r {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .info-box {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
      .item-r p:nth-child(1) {
        flex: 1;
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        // padding-top: 8px;
      }
      .item-r p:nth-child(2) {
        // flex: 1;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
  }
}
</style>
