<template>
  <div class="all">
    <div class="swiper ">
      <div class="swiper-box">
        <!-- <el-carousel
          height="450px"
          @change="swiperchange"
          type="card"
          indicator-position="none"
        >
          <el-carousel-item
            v-for="(item, x) in img"
            :key="x"
            class="swiperitembox "
            :class="activeSwiper == x ? 'activeSwiperImgBg':''"
          >
           
            <div
             @mouseover="item.hover = true"
              @mouseleave="item.hover = false"
                :class="activeSwiper == x ? '':'activeSwiperFilter'"
            >
             <div class="swiperhover"  v-show="item.hover"></div>
              <img :src="item.url" alt="" class="swiperimage"   />
            
              <span v-show="item.hover" @click="openMaxSwiper">查看大图</span>
              
            </div>
          </el-carousel-item>
        </el-carousel> -->
        <div class="thumb-example">
          <!-- swiper1 -->
          <swiper
            class="swiper gallery-top"
            :options="swiperOptionTop"
            ref="swiperTop"
          >
            <swiper-slide class="slidebox" v-for="(item, x) in img" :key="x">
               
                <div class="swipbox"  >
                   <img :src="item.url" alt="" class="swiperimg" />
                </div>
                <div class="pre">
                  <span class="cursorstyle" @click="openMaxSwiper(x)">查看大图</span>
                </div>
            </swiper-slide>

            <div
              class="swiper-button-next swiper-button-white"
              slot="button-next"
            ></div>
            <div
              class="swiper-button-prev swiper-button-white"
              slot="button-prev"
            ></div>
          </swiper>
          <!-- swiper2 Thumbs -->
          <swiper
            class="swiper gallery-thumbs"
            :options="swiperOptionThumbs"
            ref="swiperThumbs"
          >
            <swiper-slide
              class="slide-1 cursorstyle"
              v-for="(item, y) in img"
              :key="y"
            >
              <div>
                <img :src="item.url" alt="" class="swiperimg" />
              </div>

              /></swiper-slide
            >
          </swiper>
        </div>
      </div>
    </div>
    <div class="line" style="height:20px"></div>
    <div class="main">
      <div class="main-l">
        <p class="title">{{ atlas.title }}</p>
        <div class="main-l-one">
          <div class="one-l">
            <div class="num">
              <img src="../../assets/img/show.png" alt="" />
              <p>{{ atlas.total_scan }}</p>
              <img
                src="../../assets/img/time.png"
                alt=""
                style="margin-left:20px; width:11px;height:11px"
              />
              <p>{{ atlas.created_at }}</p>
              <p
                @click="showDia(id, 'atlas')"
                style="margin-left:5px"
                class="cursorstyle"
              >
                举报
              </p>
            </div>
          </div>
          <div class="one-r">
            <div
              class="likebutton cursorstyle"
              @click="like"
              :class="is_like == 0 ? 'unlikeactive' : 'likeactive'"
            >
              <img
                src="../../assets/img/likef.png"
                alt=""
                v-show="is_like == 0"
              />
              <span style="margin-left:5px" v-show="is_like == 0">喜欢</span>
              <span style="margin-left:5px" v-show="is_like == 1">已喜欢</span>
              <span style="margin-left:10px">{{ atlas.like_num }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="num">
          <img src="../../assets/img/show.png" alt="" />
          <p>{{ atlas.total_scan }}</p>
          <img
            src="../../assets/img/time.png"
            alt=""
            style="margin-left:20px; width:11px;height:11px"
          />
          <p>{{ atlas.created_at }}</p>
        </div> -->
        <el-divider class="driveLine"></el-divider>
        <div v-if="atlas.description !== ''">
          <div class="content">
            <p style="text-indent:2em;letter-spacing: 2px">
              {{ atlas.description }}
            </p>
          </div>
          <el-divider class="driveLine"></el-divider>
        </div>

        <div class="label">
          <div class="label-one">
            <img src="../../assets/img/label.png" alt="" />
            <p>标签</p>
          </div>
          <div class="labeltxt">
            <span
              v-for="(item, labindex) in atlas.label_text"
              :key="labindex"
              >{{ item }}</span
            >
          </div>
        </div>
        <div class="commbox">
          <comment :info="info" v-if="info.id !== ''"></comment>
        </div>
      </div>
      <div class="main-r">
        <div style="background-color: #ffffff; padding: 20px;">
          <div class="r-txt">
            <p>创作人</p>
          </div>
          <div class="box cursorstyle">
            <div class="r-one " @click="toUserDetail()">
              <img :src="user.avatar" alt="" />
            </div>
            <div class="r-two" @click="toUserDetail()">
              <p>{{ user.username }}</p>
              <p>{{ user.identity_text }}</p>
            </div>
            <div v-if="Me">
              <div
                class="r-three cursorstyle"
                v-show="is_follow == 0"
                @click.stop="follow"
              >
                <span>关注</span>
              </div>
              <div
                class="r-four cursorstyle"
                v-show="is_follow == 1"
                @click.stop="canfollow"
              >
                <span>已关注</span>
              </div>
            </div>
          </div>
          <!-- <div class="de">
            <p>{{ user.introduction }}</p>
          </div> -->
        </div>
        <!-- 下方 -->
        <div class="main-r-two" v-if="other.length > 0">
          <div class="one-box">
            <span>TA的其他作品</span>
            <span class="cursorstyle" @click="toUserMore()">更多</span>
          </div>
          <div
            class="two-box creatorother cursorstyle"
            v-for="(item, o) in other"
            :key="o"
            @click="toMe(item.id)"
          >
            <div class="two-box-l photoImg">
              <!-- <img v-lazy="item.cover" alt="" /> -->
               <div v-lazy:background-image="item.cover" class="bg photoZoomBg" ></div>
            </div>
            <div class="two-box-r">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="info" style="padding-top: 30px;">
                <div class="info-l">
                  <img
                    src="../../assets/img/show.png"
                    alt=""
                    class="iconone"
                    style="    width: 20px;"
                  />
                  
                  <span>{{ item.total_scan }}</span>
                </div>
                <div class="info-r">
                  <img src="../../assets/img/time.png" alt="" class="iconone" />
                  <span>{{ item.created_at | timeNew }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-r-two" v-if="likeList.length > 0">
          <div class="one-box">
            <span>猜你喜欢</span>
            <span class="cursorstyle" @click="toMore()">更多</span>
          </div>
          <div
            class="two-box creatorlike cursorstyle"
            v-for="(item, x) in likeList"
            :key="x"
            @click="toMe(item.id)"
          >
            <div class="two-box-l photoImg">
              <!-- <img v-lazy="item.cover" alt="" /> -->
               <div v-lazy:background-image="item.cover" class="bg photoZoomBg" ></div>
            </div>
            <div class="two-box-r">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="name" style="padding-top: 10px;">
                <p>{{ item.user_info.username }}</p>
              </div>
              <div class="info" style="padding-top: 6px;">
                <div class="info-l">
                  <img
                    src="../../assets/img/show.png"
                    alt=""
                    class="iconone"
                    style="    width: 20px;"
                  />
                  <span>{{ item.total_scan }}</span>
                </div>
                <div class="info-r">
                  <img src="../../assets/img/time.png" alt="" class="iconone" />
                  <span>{{ item.created_at | timeNew }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line" style="height:20px"></div>

    <!-- 全凭swiper -->
    <el-dialog
      :visible.sync="dialogTableVisibleSwiper"
      :fullscreen="true"
      class="fullswiper"
    >
      <div>
        <span
          class="el-icon-close cursorstyle"
          @click="dialogTableVisibleSwiper = false"
        ></span>
        <el-carousel
          height="100vh"
          @change="swiperchangeOne"
          type="card"
          indicator-position="none"
          :autoplay="false"
        >
          <el-carousel-item
            v-for="(item, x) in img"
            :key="x"
            class="swiperitembox"
          >
            <div class="swiper_item">
              <img
                :src="item.url"
                alt=""
                class="swiperimage"
                v-show="actionSwiperOne == x"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-dialog>
    <report ref="rep"></report>
  </div>
</template>

<script>
let vm = null;
import comment from "../../components/comments";
import report from "../../components/report";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import 'swiper/css/swiper.css'
import {
  photodetail,
  photolike,
  CanclefollowCreator,
  followCreator,
} from "@/api";
export default {
  components: {
    comment,
    report,
    Swiper,
    SwiperSlide,
  },
  props: {},
  data() {
    return {
      dialogTableVisibleSwiper: false,
      id: "",
      img: [], //轮播图片
      imgBg: [], //北京图片
      cover: "",
      atlas: "",
      user: "",
      is_follow: null,
      is_like: null,
      other: [],
      likeList: [],
      info: {
        id: "",
        type: "photo",
      },
      Me: null,
      activeSwiper: 0,
      actionSwiperOne: 0,
      newActive:0,
      swiperOptionTop: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          click: function() {
            let i = this.realIndex;
            // vm.changeEnd(i);
            // console.log(i)
          },
        },
      },
      swiperOptionThumbs: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
        on: {
          click: function() {
            let i = this.realIndex;
            // vm.changeEnd(i);
          },
        },
      },
    };
  },
  watch: {
    $route(to, from) {
      // 判断跳转的路径是否你要监听的路径，我这里为 '/search'
      if (to.path == "/photodetail") {
        // 通过路径获取新的 keywords 值
        this.id = to.query.id;
        // 通过新的参数值获取新的数据
        // this.getDetail();
        this.$router.go(0);
      }
    },
  },
  computed: {},
  methods: {
    changeEnd: function(e) {
      console.log(e);
    },
    showDia: function(e, type) {
      let info = {
        id: e,
        type: type,
      };
      this.$refs.rep.show(info);
    },
    commDia: function(e) {
      e.comment_type = "3";
      e.type = "comment";
      this.$refs.rep.show(e);
    },
    toMore: function() {
      this.$router.push({
        path: "/medialist",
      });
    },
    toUserMore: function() {
      this.$router.push({
        path: "/creatorcenter",
        query: {
          type: "3",
          id: this.atlas.user_id,
        },
      });
    },
    toUserDetail: function() {
      this.$router.push({
        path: "/creatorcenter",
        query: {
          id: this.atlas.user_id,
        },
      });
    },
    openMaxSwiper: function(e) {
      console.log(e)
      this.newActive = e
      this.dialogTableVisibleSwiper = true;
    },
    swiperchangeOne: function(e) {
      this.actionSwiperOne = e;
    },
    getDetail: function() {
      photodetail({
        atlas: this.id,
      }).then((res) => {
        // this.img = res.data.atlas.images;
        let arr = [];
        res.data.atlas.images.map((item) => {
          let ob = {
            url: item,
            hover: false,
          };
          arr.push(ob);
        });
        this.img = arr;
        this.cover = res.data.atlas.cover;
        this.atlas = res.data.atlas;
        this.user = res.data.atlas.user_info;
        this.is_follow = res.data.is_follow;
        this.is_like = res.data.is_like;
        this.other = res.data.others;
        this.likeList = res.data.like;
        let m = JSON.parse(localStorage.getItem("user"));
        if (m) {
          if (res.data.atlas.user_id == m.id) {
            this.Me = false;
          } else {
            this.Me = true;
          }
        } else {
          this.Me = true;
        }
      });
    },
    swiperchange: function(e) {
      this.activeSwiper = e;
    },
    like: function() {
      photolike({
        atlas: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_like = this.is_like == 0 ? 1 : 0;
          if (this.is_like == 0 && this.atlas.like_num !== 0) {
            this.atlas.like_num--;
          } else {
            this.atlas.like_num++;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 关注
    follow: function() {
      followCreator({
        creator: this.atlas.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 1;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 取消关注
    canfollow: function() {
      CanclefollowCreator({
        creator: this.atlas.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 0;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    toMe: function(e) {
      this.$router.push({
        path: "/photodetail",
        query: {
          id: e,
        },
      });
    },
  },
  created() {
    vm = this;
  },
  mounted() {
    this.id = this.$route.query.id;
    this.info.id = this.$route.query.id;
    this.$nextTick(() => {
      this.getDetail();

      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
    // this.$nextTick(() => {
    //     const swiperTop = this.$refs.swiperTop.$swiper
    //     const swiperThumbs = this.$refs.swiperThumbs.$swiper
    //     swiperTop.controller.control = swiperThumbs
    //     swiperThumbs.controller.control = swiperTop
    //   })
  },
};
</script>
<style lang="scss" scoped>
.thumb-example {
  height: 92vh;
  // background-color: $black;
}

.swiper {
  background-color: #000000;
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  &.gallery-top {
    height: 80%;
    width: 100%;
  }
  &.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    // padding: $gap 0;
  }
  &.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}

.all {
  background-color: #f7f7f7;
  position: relative;
}
.swipermask::after {
  height: 450px;
}
.activeSwiperFilter {
  filter: blur(15px);
}
.activeSwiperImgBg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  // background-color: red;
}
.thumb-example {
  .gallery-top {
    .slidebox {
      display: flex;
      justify-content: center;
      //  &:hover + .pre{
      //     background-color: orange;
      // }
         &:hover .swipbox {
           width:100%;
          background-color: rgba(0, 0, 0, 0.3);
        }
         &:hover  .pre{
          display: block;
        }
        .swipbox{
          display: flex;
           width:100%;
          justify-content: center;
          pointer-events: none;
          img{
            z-index: -1;
          }
        }
        .pre {
          // width: 100%;
          position: absolute;
          top: 49%;
          z-index: 99;
          color: #fff;
          display: flex;
          align-items: center;
          // justify-content: center;
          /* width: 100%; */
          height: 100%;
          display: none;
          span {
            border: 1px solid #ffffff;
            padding: 10px 20px;
          }
        }
    }
    .swiperimg {
      height: 100%;
    }
  }
  .gallery-thumbs {
    padding: 10px 0;
    .swiperimg {
      // max-height: 100%;
      width: 100%;
    }
  }
}
.swiperhover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  top: 0;
  left: 0;
}
.swiper {
  height: 92vh;
  .swiper-box {
    // width: 1200px;
    // margin: 0 auto;
    .swiperimage {
      width: 100%;
      height: 100%;
    }
    .swiperitembox {
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        position: absolute;
        border: 1px solid #ffffff;
        padding: 10px 20px;
        top: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        z-index: 100;
      }
    }
  }
}

.fullswiper {
  .el-icon-close {
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 20px;
    color: #ffffff;
    z-index: 999;
  }
  // .swiperitembox {
  //   // overflow: scroll;
  //   //  opacity: 0;
  // }
  // padding: 5%;
  background-color: rgba(0, 0, 0, 0.3);
  .swiperimage {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    max-height: 100%;
  }
  .swiper_item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.main {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  background-color: #f7f7f7;
  .main-l {
    padding: 28px 30px;
    background-color: #ffffff;
    flex: 1;
    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .main-l-one {
      display: flex;
      align-items: center;
      .one-l {
        display: flex;
        flex: 1;
      }
      .one-r {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        .likebutton {
          display: inline-block;
          border-radius: 2px;
          padding: 10px 16px;
          span {
            font-size: 12px;
          }
          img {
            width: 13px;
          }
        }
      }
    }
    .num {
      display: flex;
      align-items: center;
      img {
        width: 15px;
      }
      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left: 5px;
      }
    }
    .content {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .label {
      .label-one {
        display: flex;
        align-items: center;
        img {
          width: 14px;
        }
        p {
          margin-left: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
      }
      .labeltxt {
        margin-top: 22px;
        span {
          font-size: 12px;
          background-color: #f7f7f7;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          padding: 7px 12px;
          margin-right: 24px;
        }
      }
    }
  }
  .main-r {
    width: 400px;
    margin-left: 20px;
    // padding: 20px;
    .r-txt {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .box {
      display: flex;
      margin-top: 20px;
      align-items: center;
    }
    .r-one {
      background-color: #ffffff;
      img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
    }
    .r-two {
      margin-left: 13px;
      flex: 1;
    }
    .r-two p:nth-child(1) {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .r-two p:nth-child(2) {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .r-three {
      color: #ffffff;
      span {
        padding: 7px 18px;
        background: #e35111;
        border-radius: 2px;
      }
    }
    .r-four {
      //   color: #f7f7f7;
      span {
        padding: 7px 18px;
        background: #ffffff;
        color: #333333;
        border-radius: 2px;
        border: 1px solid #dedede;
      }
    }
    .de {
      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

.likeactive {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
.unlikeactive {
  background-color: #e35111;
  border: 1px solid #e35111;
  color: #ffffff;
}

.main-r-two {
  margin-top: 20px;
  background-color: #ffffff;
  padding-bottom: 10px;
  .one-box {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: baseline;
  }
  .bg{
     background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .one-box span:nth-child(1) {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .one-box span:nth-child(2) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
</style>

<style lang="scss">
.fullswiper {
  .el-dialog {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .el-dialog__header {
    padding: 0;
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    font-size: 30px;
  }
  .el-carousel__mask {
    background-color: rgba(0, 0, 0, 0);
  }
  .el-icon-arrow-right {
    font-size: 50px;
  }
  .el-icon-arrow-left {
    font-size: 50px;
  }
}
</style>
