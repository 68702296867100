<template>
  <div class="all">
    <div class="main">
      <div class="main-search">
        <span><i class="el-icon-search"></i></span>
        <input
          type="text"
          placeholder="请输入要搜索的内容"
          class="inputstyle"
          v-model="key"
        />
        <span class="searchtxt cursorstyle" @click="searchcontent">搜索</span>
      </div>
      <div class="main-content">
        <div class="main-one">
          <div class="one-r">
            <span
              v-for="(item, x) in options"
              :key="x"
              class="one-item cursorstyle"
              :class="type == item.type ? 'addActive' : ''"
              @click="selectAdd(item.type)"
              >{{ item.name }}</span
            >
          </div>
        </div>

        <!-- 视频内容 -->
        <div class="videobox" v-show="type == 'video'">
          <div class="card-box">
            <el-row :gutter="20">
              <el-col :span="6" v-for="(item, saindex) in List" :key="saindex"
                ><div
                  class="grid-content cursorstyle"
                  style=" margin-bottom: 30px;"
                  @click="toVideo(item.id)"
                >
                  <div class="card-box-one photoZoom">
                    <img v-lazy="item.cover" alt="" />
                  </div>
                  <div class="card-box-two">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="card-box-three">
                    <span>{{ item.area }}</span>
                    <span class="line">{{ item.label_text }}</span>
                  </div>
                  <div class="card-box-play">
                    <img src="../../assets/img/play.png" alt="" class="play" />
                    <span style="margin-left:10px">{{ item.total_scan }}</span>
                    <!-- <img src="../../assets/img/unlike.png" alt="" class="play" style="margin-left:24px">
                      <span style="margin-left:10px">123</span> -->
                  </div>
                  <div class="card-box-four" v-if="item.user_info">
                    <div class="card-box-for-img">
                      <img v-lazy="item.user_info.avatar" alt="" />
                    </div>
                    <div class="card-box-for-author">
                      <span>{{ item.user_info.username }}</span>
                    </div>
                    <div class="card-box-for-job">
                      <span>{{ item.user_info.identity_text }}</span>
                    </div>
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
        </div>

        <!-- 音频内容 -->
        <div class="mediabox" v-show="type == 'audio'">
          <div class="item-box" v-show="List.length > 0">
            <div
              class="item cursorstyle"
              v-for="(item, x) in List"
              :key="x"
              @click="toDetail(item.id)"
            >
              <div class="item-one photoZoom">
                <img v-lazy="item.cover" alt="" />
              </div>
              <div class="item-two">
                <p>{{ item.title }}</p>
              </div>
              <div class="item-three">
                <img :src="item.user_info.avatar" alt="" />
                <span>{{ item.user_info.username }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 图片 -->
        <div class="photobox" v-show="type == 'atlas'">
          <div class="card-box">
            <el-row :gutter="20">
              <el-col :span="6" v-for="(item, saindex) in List" :key="saindex"
                ><div
                  class="grid-content cursorstyle"
                  style=" margin-bottom: 30px;"
                  @click="tophoto(item.id)"
                >
                  <div class="card-box-one photoZoom">
                    <img v-lazy="item.cover" alt="" />
                  </div>
                  <div class="card-box-two">
                    <p>{{ item.title }}</p>
                  </div>
                  <!-- <div class="card-box-three">
                  <span>{{ item.area }}</span>
                  <span class="line">{{ item.label_text }}</span>
                </div> -->
                  <div class="card-box-play">
                    <img src="../../assets/img/show.png" alt="" class="play" />
                    <span style="margin-left:10px">{{ item.total_scan }}</span>
                    <!-- <img src="../../assets/img/unlike.png" alt="" class="play" style="margin-left:24px">
                      <span style="margin-left:10px">123</span> -->
                  </div>
                  <div class="card-box-four" v-if="item.user_info">
                    <div class="card-box-for-img">
                      <img v-lazy="item.user_info.avatar" alt="" />
                    </div>
                    <div class="card-box-for-author">
                      <span>{{ item.user_info.username }}</span>
                    </div>
                    <!-- <div class="card-box-for-job">
                    <span>{{ item.user_info.identity_text }}</span>
                  </div> -->
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
        </div>

        <!-- 文章列表 -->
        <div class="article-box" v-show="type == 'article'">
          <div
            class="articlebox cursorstyle"
            v-for="(item, z) in List"
            :key="z"
            @click="toArticle(item.id)"
          >
            <div class="article-l photoZoom">
              <img v-lazy="item.cover" alt="" srcset="" />
            </div>
            <div class="article-r">
              <div class="article-r-one">
                <p>{{ item.title }}</p>
              </div>
              <div class="article-r-two">
                <p>
                  {{ item.description }}
                </p>
              </div>
              <div class="article-r-three">
                <div class="three-r">
                  <!-- <div class="r-one">
                  <img src="../../assets/img/time.png" alt="" class="iconone" />
                  <p>刚刚</p>
                </div> -->
                  <div class="r-one" style="margin-left: 10px;">
                    <img
                      src="../../assets/img/show.png"
                      alt=""
                      class="icontwo"
                    />
                    <p>{{ item.total_scan }}</p>
                  </div>
                </div>

                <div class="three-l">
                  <!-- <img :src="item.user_info.avatar" alt="" class="avatar" /> -->
                  <p>{{ item.created_at | time }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 创作人信息 -->
        <div class="creatbox" v-show="type == 'creator'">
          <el-row :gutter="20">
            <el-col
              :span="8"
              v-for="(item, crx) in creatorList"
              :key="crx"
              class="item-box"
            >
              <div class="grid-content item cursorstyle " @click="toMe(item.id)">
                <div class="item-l">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="item-r">
                  <div class="one">
                    <p class="txt-one">{{ item.username }}</p>
                    <p class="txt-two" v-if="item.identity_text !== ''">
                      {{ item.identity_text }} /{{ item.area_text }}
                    </p>
                    <p class="txt-three">
                      <span @click.stop="toFans(item,'0')" style="color:#999999"> 粉丝</span>
                      <span>{{ item.fans }}</span>
                      <span @click.stop="toFans(item,'1')" style="color:#999999">   关注</span>
                    
                      <span>{{ item.follow }}</span>
                    </p>
                    <p class="txt-four">{{ item.introduction }}</p>
                  </div>
                  <div class="two" v-if="user.id !== item.id">
                <span @click.stop="follow(item)" v-if="item.is_follow == 0" class="two-one cursorstyle">关注</span>
                <span @click.stop="canclefollow(item)" v-if="item.is_follow == 1" class="two-two cursorstyle">已关注</span>
              </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 下方分页 -->
        <div class="page" v-show="datanum !== 0 && allpage > 1">
          <el-pagination
            background
            layout=" pager, next"
            :total="totalNUm"
            :current-page="page"
            @current-change="handleCurrentChange"
            @next-click="handleNext"
          >
          </el-pagination>
        </div>

        <!-- 无数据提示 -->
        <div class="empty" v-show="datanum == 0">
          <div class="emptyone">
            <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
          </div>
          <div class="emptytxt">
            <p>暂时没有数据，过会再来看看吧</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { search,
followCreator,
CanclefollowCreator } from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      type: "video",
      options: [
        { name: "视频", type: "video" },
        { name: "音频", type: "audio" },
        { name: "图集", type: "atlas" },
        { name: "文章", type: "article" },
        { name: "创作人", type: "creator" },
      ],
      List: [],
      creatorList:[],
      page: 1,
      totalNUm: null,
      datanum: 1,
      key: "",
      user:'',
      allpage:0
    };
  },
  watch: {},
  computed: {},
  filters: {
    time: function(val) {
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      let time1 = new Date().getTime(); //当前的时间戳
      let time2 = Date.parse(new Date(val)); //指定时间的时间戳
      let time = time1 - time2;

      let result = null;
      if (time < 0) {
        result = "刚刚";
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
    redfont:function(val){
      
    },
  },
  methods: {
       toFans:function(e,type){
      this.$router.push({
        path:'/fans',
        query:{
          id:e.id,
          type:type
        }
      })
    },
    searchcontent: function() {
      this.page = 1;
      this.getList();
    },
    getList: function() {
      search({
        key: this.key,
        type: this.type,
        page: this.page,
      }).then((res) => {
        // this.List = res.data.data;
        // console.log(res.data.data);
        if (this.type == "creator") {
          this.creatorList = res.data.data
        }else{
            this.List = res.data.data;
        }
        this.datanum = res.data.data.length;
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page
      });
    },
    selectAdd: function(x) {
      this.page = 1;
      this.type = x;
      this.getList();
      // this.page = 1;
      // this.getList()
    },
    handleCurrentChange: function(val) {
      this.page = val;
      // this.getList()
      // this.selectNav()
      this.getList();
    },
    handleNext: function(val) {
      this.page = val;
      // this.getList()
      this.getList();
    },

    toVideo: function(e) {
      // console.log(e)
      this.$router.push({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
    },
    toDetail: function(e) {
      this.$router.push({
        path: "/mediadetail",
        query: {
          id: e,
        },
      });
    },

    tophoto: function(e) {
      this.$router.push({
        path: "/photodetail",
        query: {
          id: e,
        },
      });
    },

       follow:function(e){
      followCreator({
        creator:e.id
      }).then(res=>{
        if (res.code == 200) {
          e.is_follow = 1
          e.fans++
        }else{
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      })
    },
    canclefollow:function(e){
       CanclefollowCreator({
        creator:e.id
      }).then(res=>{
        if (res.code == 200) {
          e.is_follow = 0
          if (e.fans > 0) {
             e.fans--
          }
        }else{
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      })
    },

    toMe: function(e) {
      console.log(e);
      this.$router.push({
        path: "/creatorcenter",
        query: {
          id: e,
        },
      });
    },

    toArticle: function(e) {
      this.$router.push({
        path: "/articledetail",
        query: {
          id: e,
        },
      });
    },
  },
  created() {},
  mounted() {
    this.key = this.$route.query.key;
    // console.log( this.$route.query.key)
    this.getList();
     let user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
    }
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  padding: 40px 0;
  .main {
    width: 1200px;
    margin: 0 auto;
  }
}

.main-search {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  padding-left: 20px;
  .el-icon-search {
    font-size: 20px;
  }
  .inputstyle {
    flex: 1;
    background: none;
    outline: none;
    border: 0px;
    padding-left: 10px;
  }
  .searchtxt {
    display: inline-block;
    background-color: #e35111;
    // width: 100%;
    padding: 15px 40px;
        font-size: 14px;
    color: #ffffff;
  }
}
.addActive {
  background: #e35111;
  border-radius: 2px;
  color: #fff;
}
.main-content {
  .main-one {
    display: flex;
    margin-top: 30px;
    // background-color: #ffffff;
    //   margin: 0 10px;
    .one-l {
      padding: 4px 8px;
    }
    .tab {
      white-space: nowrap;
      padding: 4px 8px;
    }
    .one-r {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .one-item {
        margin-right: 20px;
        padding: 4px 8px;
        margin-bottom: 15px;
      }
    }
  }
  .videobox {
    .card-box {
      overflow: hidden;
      .grid-content {
        background-color: #ffffff;
      }
      .card-box-one {
        height: 170px;
        overflow: hidden;
        img {
          width: 100%;
          // height: 100%;
        }
      }
      .card-box-two {
        margin-top: 20px;
        margin-left: 16px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .card-box-three {
        margin-top: 10px;
        margin-left: 16px;
        .line {
          margin-left: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
      .card-box-four {
        display: flex;
        padding: 11px 16px;
        align-items: center;
        .card-box-for-img {
          width: 24px;
          height: 24px;
        }
        .card-box-for-img img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .card-box-for-author {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-left: 10px;
        }
        .card-box-for-job {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
          margin-left: 16px;
        }
      }
      .card-box-five {
        text-indent: 2em;
        padding: 3px 16px;
        color: #bebebe;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .card-box-play {
        margin-left: 16px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .play {
          width: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
    }
  }

  .mediabox {
    .item-box {
      display: flex;
      flex-wrap: wrap;
      // padding: 10px;
      padding: 10px;
      border-radius: 5px;
      background-color: #ffffff;
      .item {
        width: 20%;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .item-one {
          img {
            // width: 100%;
            // width: 100%;
            width:140px;
            height: 140px;
            border-radius: 8px;
          }
        }
        .item-two {
          margin-top: 20px;
          p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .item-three {
          margin-top: 14px;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .photobox {
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      background: #ffffff;
    }
    .card-box {
      overflow: hidden;
      .card-box-one {
        height: 150px;
        padding: 11px;
        overflow: hidden;
        display: flex;
    align-items: center;
    justify-content: center;
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
      .card-box-two {
        margin-top: 20px;
        margin-left: 16px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .card-box-three {
        margin-top: 10px;
        margin-left: 16px;
        .line {
          margin-left: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
      .card-box-four {
        display: flex;
        padding: 11px 16px;
        align-items: center;
        .card-box-for-img {
          width: 24px;
          height: 24px;
        }
        .card-box-for-img img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .card-box-for-author {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-left: 10px;
        }
        .card-box-for-job {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
          margin-left: 16px;
        }
      }
      .card-box-five {
        text-indent: 2em;
        padding: 3px 16px;
        color: #bebebe;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .card-box-play {
        margin-left: 16px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .play {
          width: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
    }
  }

  .article-box {
    .articlebox {
      height: 240px;
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
      .article-l {
        img {
          width: 360px;
          height: 240px;
        }
      }
      .article-r {
        flex: 1;
        background-color: #ffffff;
        padding: 25px 22px 20px 20px;
        display: flex;
        flex-direction: column;
        .article-r-one {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #1d1d1d;
        }
        .article-r-two {
          flex: 1;
          padding-top: 28px;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
        .article-r-three {
          display: flex;
          .three-l {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
            //  justify-content: space-between;
          }
          .three-r {
            flex: 1;
            display: flex;
            align-items: center;
            //   justify-content: flex-end;
            //   justify-content: flex-end;
            .r-one {
              display: flex;
              align-items: center;
            }
            .iconone {
              width: 14px;
            }
            .icontwo {
              width: 19px;
            }
            p {
              margin-left: 10px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
          p {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }

  .creatbox {
    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    .item-box {
      height: 200px;
      margin-bottom: 30px;
      overflow: hidden;
    }
    .item {
      display: flex;
      background-color: #fff;
      padding: 36px 29px;
      height: 100%;
      margin-bottom: 10px;
      .item-l {
        img {
          width: 78px;
          height: 78px;
          border-radius: 50%;
        }
      }
      .item-r {
        padding-left: 14px;
        overflow: hidden;
        .two {
          margin-top: 14px;
        }
        .one {
          .txt-one {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
          .txt-two {
            margin-top: 7px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
          .txt-three {
            margin-top: 13px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            span {
              font-size: 10px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
            }
          }
          .txt-four {
            height: 13px;
            margin-top: 11px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .two {
          span {
            display: inline-block;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fefefe;
            border-radius: 2px;
            padding: 8px 17px;
          }
          .two-one {
            background: #e35111;
          }
          .two-two {
            background-color: #dedede;
          }
        }
      }
    }
  }
}



.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 20px;
    }
  }
}
</style>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
