<template>
  <div class="main"></div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){
      window.location.href = "https://benzhongwenhua.oss-cn-beijing.aliyuncs.com/app/1.0.0.apk"
  }
}
</script>
<style lang="scss" scoped>

</style>