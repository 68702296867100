<template>
  <div class="all">
    <div class="main">
      <!-- 上方图片 -->
      <el-row :span="24" :xs="24">
        <el-col :span="24">
          <div class="main-one hidden-xs-only">
            <img
              src="../../assets/img/adschool/banana.png"
              alt=""
              class="banana"
            />
          </div>
          <div class="main-one hidden-sm-and-up">
            <img
              src="../../assets/img/adschool/bananamobile.jpeg"
              alt=""
              class="banana"
            />
          </div>
        </el-col>
      </el-row>

      <!-- 文字信息 -->

      <div class="main-two ">
        <el-row :span="24" :xs="24">
          <el-col :xs="{ span: 22, offset: 1 }">
            <div class="title">
              <div class="title-one">
                <img
                  src="../../assets/img/adschool/adtitle4.png"
                  alt=""
                  class="title-image"
                />
              </div>
              <div class="title-two titleone">
                <p class="name ">中原广告研究院</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
            <p class="two-two-one">
              中原广告研究院成立于2013年，注册地址位于郑州中原国家广告产业园，是中原地区唯一的广告行业专业智库。
            </p>
            <p class="two-two-txt two-two-one">
              研究院以数字广告营销和广告产业发展为主要研究方向。
            </p>
            <p class="two-two-one">
              中原广告研究院开展了系列化的理论研究与市场开发。“品牌舆情管理”系统BPSM；“数据沟通营销”广告运作新范式DCM；“广告产业发展水平评价指数”AIDI、广告产业园区评价指数AIPI、广告产业竞争力评价指数AICI等，基于科学的评价体系与方法，准确地洞悉区域广告产业发展、广告产业园区发展和广告产业竞争力的基本样貌，并据此指导和推动区域广告产业的科学发展。
            </p>
          </el-col>
        </el-row>
        <!-- <div class="mc-96 ">
         
        </div> -->
      </div>

      <!-- 研究员介绍 -->
      <div class="main-three">
        <el-row :gutter="10">
          <el-col :xs="{ span: 22, offset: 1 }">
            <div class="title">
              <div class="title-one">
                <img
                  src="../../assets/img/adschool/adtitle3.png"
                  alt=""
                  class="title-image"
                />
              </div>
              <div class="title-two titleone">
                <p class="name">研究员介绍</p>
              </div>
            </div>
          </el-col>

          <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
            <div class="three-box">
              <div class="three-box-main">
                <div class="item-box">
                  <el-col
                    :md="8"
                    :xs="24"
                    v-for="(item, x) in researcher"
                    :key="x"
                  >
                    <div class="item">
                         <div class="item-one">
                        <div class="avatar">
                          <img
                            :src="
                              require(`../../assets/img/adschool/people/${x +
                                1}.png`)
                            "
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="item-two">
                        <div class="name">
                          <span>
                            {{ item.name }}
                          </span>
                        </div>
                        <div class="info">
                          <p v-for="(item1, ix) in item.info" :key="ix">
                            {{ item1 }}
                          </p>
                        </div>
                      </div>
                     
                    </div>
                  </el-col>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 成员介绍 -->
      <div class="main-four-a">
        <div class="four-box">
          <el-row :span="24" :xs="24">
            <el-col :xs="{ span: 22, offset: 1 }">
              <div class="title">
                <div class="title-one">
                  <img
                    src="../../assets/img/adschool/adtitle5.png"
                    alt=""
                    class="title-image"
                  />
                </div>
                <div class="title-two titletwo">
                  <p class="name">研究院专家组成员介绍</p>
                </div>
              </div>
            </el-col>

            <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
              <div class="info">
                <p>
                  河南梦之队企业管理咨询有限公司董事长尹邦湘、大豫网终身顾问周振林、郑州宏承号茶业公司董事长郭力、北京赢销力企业管理咨询有限公司总裁王冠群、河南大河图文传播有限公司副总经理徐云峰、河南省大众创业导师资深营销与管理咨询师窦惠忠、郑州心灯文化传播有限公司总经理施维克、渔社CEO刘永、以及彭诗金、杜红超、贾福春、盛鹏、赵玉刚、周立春、马二伟、周鹍鹏、尚恒志、张帅旗、李惊雷、楚明钦等等近50名国内外学界和业界专家。
                </p>
              </div>
            </el-col>

            <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
              <div class="photo">
                <div class="item">
                  <img src="../../assets/img/adschool/me.png" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 作品 -->
      <div class="main-seven hidden-xs-only" id="videobox" v-if="list.length > 0">
        <div class="seven-box">
          <el-row :span="24" :xs="24">
            <el-col :span="24">
              <div class="title">
                <div class="title-one">
                  <img
                    src="../../assets/img/adschool/adtitle6.png"
                    alt=""
                    class="title-image"
                  />
                </div>
                <div class="title-two">
                  <p class="name">成员作品</p>
                </div>
              </div>
            </el-col>
          </el-row>

          <!-- 下方列表 -->
          <div class="creabox">
            <div class="main">
              <el-row>
                <el-col
                  :span="24"
                  v-for="(item, x) in list"
                  :key="x"
                  class="item-box "
                >
                  <!-- 左边显示的内容 -->
                  <div class="col-l">
                    <div class="grid-content item  ">
                      <div class="item-l cursorstyle" @click="toMe(item.id)">
                        <img v-lazy="item.avatar" alt="" />
                        <div class="two" v-if="myId !== item.id">
                          <span
                            @click.stop="follow(item)"
                            v-if="item.is_follow == 0"
                            class="two-one cursorstyle"
                            >关注</span
                          >
                          <span
                            @click.stop="canclefollow(item)"
                            v-if="item.is_follow == 1"
                            class="two-two cursorstyle"
                            >已关注</span
                          >
                        </div>
                      </div>
                      <div class="item-r">
                        <div class="one">
                          <p class="txt-one">{{ item.username }}</p>
                          <p class="txt-two">
                            <img
                              src="../../assets/img/hot.png"
                              alt=""
                              class="hoticon"
                            />
                            <span class="hottxt">{{ item.real_pop }}</span>
                            <!-- <span class="transtime">{{item.play_time | transTime}}</span> -->
                          </p>
                          <p class="txt-three ">
                            <span
                              @click.stop="toFans(item, '0')"
                              class="cursorstyle"
                              style="    font-weight: normal;
    color: #999999;"
                            >
                              粉丝</span
                            >
                            <span
                              style="margin-right:5px"
                              class="cursorstyle"
                              @click.stop="toFans(item, '0')"
                              >{{ item.fans }}</span
                            >
                            <span
                              @click.stop="toFans(item, '1')"
                              class="cursorstyle"
                              style="font-weight: normal;
                      color: #999999;"
                            >
                              关注
                            </span>
                            <span
                              class="cursorstyle"
                              @click.stop="toFans(item, '1')"
                              >{{ item.follow }}</span
                            >
                          </p>
                          <p class="txt-four">
                            {{ item.introduction || "这个人很懒，什么都没写" }}
                          </p>
                        </div>
                        <!-- <div class="two">
                  <span
                    @click.stop="follow(item)"
                    v-if="item.is_follow == 0"
                    class="two-one cursorstyle"
                    >关注</span
                  >
                  <span
                    @click.stop="canclefollow(item)"
                    v-if="item.is_follow == 1"
                    class="two-two cursorstyle"
                    >已关注</span
                  >
                </div> -->
                      </div>
                    </div>
                  </div>
                  <!-- 右边显示内容 -->
                  <div class="col-r">
                    <div class="r-item">
                      <div
                        class="r-item-box photoZoom cursorstyle"
                        @click="toDetail(item1.media_id, item1.type)"
                        v-for="(item1, vi) in item.medias"
                        :key="vi"
                      >
                        <div
                          v-lazy:background-image="item1.cover"
                          class="bg photoZoomBg"
                        ></div>
                        <span class="r-item-title">{{ item1.title }}</span>
                        <div class="typeimg">
                          <img
                            src="../../assets/img/henantab/tab4.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 2"
                          />
                          <img
                            src="../../assets/img/henantab/tab2.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 4"
                          />
                          <img
                            src="../../assets/img/henantab/tab6.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 3"
                          />
                          <img
                            src="../../assets/img/henantab/tab8.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 1"
                          />
                        </div>
                      </div>

                      <!-- 无数据的时候提示 -->
                      <div class="nodata" v-if="item.medias.length == 0">
                        <div class="n-empty">
                          <img src="../../assets/img/empty.png" alt="" />
                          <p>该创作者还没有发布作品哦！</p>
                        </div>
                        <div></div>
                        <div class="butt" v-if="myId !== item.id">
                          <el-button
                            type="danger"
                            plain
                            size="small"
                            class="show"
                            @click="urgeC(item.id)"
                            >催更一下</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>

              <div class="page" v-show="!emptyShow && last_page > 1">
                <el-pagination
                  background
                  layout=" pager, next"
                  :total="totalNUm"
                  :current-page="page"
                  @current-change="handleCurrentChange"
                  @next-click="handleNext"
                  :hide-on-single-page="true"
                >
                </el-pagination>
              </div>

              <div class="empty" v-show="emptyShow">
                <div class="emptyone">
                  <img
                    src="../../assets/img/empty.png"
                    alt=""
                    class="emptyimg"
                  />
                </div>
                <div class="emptytxt">
                  <p>暂时没有数据，过会再来看看吧</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 小屏幕展示作品 -->
      <div class="main-eight hidden-sm-and-up" id="videoboxsm" v-if="list.length > 0">
        <el-row :gutter="24">
          <el-col :xs="{ span: 22, offset: 1 }">
             <div class="title">
                <div class="title-one">
                  <img
                    src="../../assets/img/adschool/adtitle6.png"
                    alt=""
                    class="title-image"
                  />
                </div>
                <div class="title-two titleone">
                  <p class="name">成员作品</p>
                </div>
              </div>
          </el-col>
          <!-- 作品列表 -->
          <div class="itembox">
            <el-col :span="24"   v-for="(item, xsm) in list" :key='xsm'>
                <div class="item">
                <div class="item-one">
                  <div class="item-one-l" @click="toMe(item.id)">
                    <img v-lazy="item.avatar" alt="" class="avatar">
                  </div>
                  <div class="item-one-c" @click="toMe(item.id)">
                    <p class="name">{{item.nickname}}</p>
                    <p class="de">{{item.introduction}}</p>
                  </div>
                  <div class="item-one-r" v-if="myId !== item.id">
                      <span class="fo"     @click.stop="canclefollow(item)"
                            v-if="item.is_follow == 1">已关注</span>
                      <span class="nofo" 
                         @click.stop="follow(item)"
                            v-if="item.is_follow == 0">关注</span>
                  </div>
                </div>
                <div class="item-two">
                
                     <div class="item-p"  @click="toDetail(item1.media_id, item1.type)"
                        v-for="(item1, vi) in item.medias"
                        :key="vi" v-if="vi<2">
                        <div  v-lazy:background-image="item1.cover"
                          class="bg photoZoomBg"></div>
                         <div class="type">
                           <img
                            src="../../assets/img/henantab/tab4.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 2"
                          />
                          <img
                            src="../../assets/img/henantab/tab2.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 4"
                          />
                          <img
                            src="../../assets/img/henantab/tab6.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 3"
                          />
                          <img
                            src="../../assets/img/henantab/tab8.png"
                            alt=""
                            srcset=""
                            v-if="item1.type == 1"
                          />
                           </div> 
                    </div>
                 
                 <!-- 无数据展示 -->
                 <div class="nodata" v-if="item.medias.length == 0">
                     <div class="n-empty">
                          <img src="../../assets/img/empty.png" alt="" />
                          <p>该创作者还没有发布作品哦！</p>
                        </div>
                 </div>
                 

                </div>
            </div>
            </el-col>
          
          </div>

          <!-- 分页 -->
          <el-col :span="24">
             <div class="page" >
                      <el-pagination
                        background
                        layout=" pager, next"
                        :total="totalNUm"
                        :current-page="page"
                        @current-change="handleCurrentChange"
                        @next-click="handleNext"
                        :hide-on-single-page="true"
                      >
                      </el-pagination>
                    </div>
          </el-col>
           
        </el-row>
      </div>
      <!-- 企业服务 -->
      <div class="main-five">
        <div class="five-box">
          <el-row :gutter="24">
            <el-col  :xs="{ span: 22, offset: 1 }">
              <div class="five-box-title">
                <div class="title-one">
                  <img
                    src="../../assets/img/adschool/adtitle2.png"
                    alt=""
                    class="title-image"
                  />
                </div>
                <div class="title-two titleone">
                  <p class="name">企业服务</p>
                </div>
              </div>
            </el-col>

            <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
              <div class="box-one hidden-xs-only">
                <div class="one-l" style="flex:1">
                  <div class="item">
                    <div class="title">
                      <p>创新创业培训</p>
                    </div>
                    <div class="info">
                      <p>创新创业SIYB/网创培训</p>
                      <p>高校学生创业孵化器</p>
                    </div>
                  </div>

                  <div class="item">
                    <div class="title">
                      <p>广告科学研究</p>
                    </div>
                    <div class="info">
                      <p>研究院以数字广告营销和</p>
                      <p>广告产业发展为主要研究方向；</p>
                    </div>
                  </div>
                </div>

                <div class="one-l" style="flex:1">
                  <div class="item">
                    <div class="title">
                      <p>广告营销策划</p>
                    </div>
                    <div class="info">
                      <p>策划、设计、制作、发布、</p>
                      <p>代理国内外广告业务；</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title">
                      <p>企业咨询服务</p>
                    </div>
                    <div class="info">
                      <p>企业管理咨询；会议及展览服务；</p>
                      <p>广告工程设计服务；数据处理服务；</p>
                      <p>商务咨询服务等；</p>
                    </div>
                  </div>
                </div>
                <div class="one-l one-r" style="flex:1">
                  <div class="item">
                    <div class="title">
                      <p>传统媒体及新媒体咨询服务</p>
                    </div>
                    <div class="info">
                      <p>
                        研究院与传统主流媒体达成战略合作伙伴，
                        腾讯大豫网深度合作，
                      </p>
                      <p>同时也与一些新媒体签订合作关系，</p>
                      <p>比如今日头条河南分公司、</p>
                      <p>腾讯大豫网深度合作，</p>
                      <p>为企业提供头条、抖音广告投放及咨询服务。</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="hidden-sm-and-up">
                <el-col :span="24">
                  <div class="box-two">
                    <div class="box-two-item">
                      <el-col :xs="{ span: 20, offset: 2 }" >
                          <div class="item">
                        <div class="title">
                          <p>广告营销策划</p>
                        </div>
                        <div class="info">
                          <p>策划、设计、制作、发布、</p>
                          <p>代理国内外广告业务；</p>
                        </div>
                      </div>
                      </el-col>
                        <el-col :xs="{ span: 20, offset: 2 }">
                          <div class="item">
                            <div class="title">
                              <p>广告科学研究</p>
                            </div>
                            <div class="info">
                              <p>研究院以数字广告营销和</p>
                              <p>广告产业发展为主要研究方向；</p>
                            </div>
                          </div>
                      </el-col>
                      <el-col :xs="{ span: 20, offset: 2 }">
                           <div class="item">
                    <div class="title">
                      <p>创新创业培训</p>
                    </div>
                    <div class="info">
                      <p>创新创业SIYB/网创培训</p>
                      <p>高校学生创业孵化器</p>
                    </div>
                  </div>
                      </el-col>
                        <el-col :xs="{ span: 20, offset: 2 }">
                          
                     <div class="item">
                    <div class="title">
                      <p>企业咨询服务</p>
                    </div>
                    <div class="info">
                      <p>企业管理咨询；会议及展览服务；</p>
                      <p>广告工程设计服务；数据处理服务；</p>
                      <p>商务咨询服务等；</p>
                    </div>
                  </div>
                      </el-col>
                        <el-col :xs="{ span: 20, offset: 2 }">
                          <div class="item">
                    <div class="title">
                      <p>传统媒体及新媒体咨询服务</p>
                    </div>
                    <div class="info">
                      <p>
                        研究院与传统主流媒体达成战略合作伙伴，
                        腾讯大豫网深度合作，
                      </p>
                      <p>同时也与一些新媒体签订合作关系，</p>
                      <p>比如今日头条河南分公司、</p>
                      <p>腾讯大豫网深度合作，</p>
                      <p>为企业提供头条、抖音广告投放及咨询服务。</p>
                    </div>
                  </div>
                      </el-col>
                    </div>
                  </div>
                </el-col>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 合作单位 -->
      <div class="main-six">
       
        <div class="six-box">
           <el-row :gutter="10">
          <el-col  :xs="{ span: 22, offset: 1 }">
               <div class="title">
            <div class="title-one">
              <img
                src="../../assets/img/adschool/adtitle1.png"
                alt=""
                class="title-image"
              />
            </div>
            <div class="title-two titleone">
              <p class="name">合作单位</p>
            </div>
          </div>
          </el-col>

           <el-col  :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
                <div class="item-box">
            <el-col 
             :md="6"
            :lg="8"
            v-for="(item, cox) in coList" :key="cox"
            >
            <div class="item" >
              <div class="item-img">
                <img
                  :src="
                    require(`../../assets/img/adschool/coll/co${cox + 1}.png`)
                  "
                  alt=""
                />
              </div>
              <p class="name">{{ item.name }}</p>
            </div>
            </el-col>
          </div>
           </el-col>
       
        </el-row>
        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import {
  creatorList,
  followCreator,
  CanclefollowCreator,
  cooperation,
  urge,
} from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      coList: [
        { name: "河南省广告协会" },
        { name: "中原广告产业园发展有限公司" },
        { name: "今日头条河南分公司" },
        { name: "河南电视台" },
        { name: "腾讯河南分公司" },
        { name: "大河报" },
        { name: "大河网" },
        { name: "河南商报" },
        { name: "挂牌公司服务协会" },
      ],
      researcher: [
        { name: "颜景毅", info: ["中原广告研究院院长", "郑州大学博士生导师"] },
        {
          name: "王鲁昌",
          info: [
            "河南财经政法大学",
            "文化传播学院广告系教授",
            "郑州大学博士生导师",
          ],
        },
        { name: "胡庆十", info: ["河南龙马影视", "有限公司总经理"] },
        {
          name: "胡本中",
          info: [
            "河南本中文化科技",
            "有限公司董事长",
            "“C位河南”平台创始人",
            "资深导演",
          ],
        },
        {
          name: "郭   力",
          info: [
            "郑州宏承号茶业公司董事长",
            "河南民营经济研究会副秘书长",
            "河南省商业经济学会副秘书长",
            "河南师范大学兼职教授、研究员",
            "河南省辛亥革命研究中心副主任",
          ],
        },
        {
          name: "马二伟",
          info: ["武汉大学广告学博士", "郑州大学新闻与", "传播学院教授"],
        },
        {
          name: "周鹍鹏",
          info: [
            "河海大学技术经济及管理博士",
            "武汉大学媒介经营管理博士后",
            "郑州大学新闻传播学院教授",
            "河南文化产业发展研究基地主任",
          ],
        },
        {
          name: "许俊义",
          info: [
            "金犊奖筹备委员会大陆委员",
            "河南影视集团艺术中心导师",
            "河南工业大学对外",
            "宣传专家团成员",
          ],
        },
        {
          name: "王冠群",
          info: [
            "北京赢销力企业管理",
            "咨询有限公司总裁",
            "西北工业大学管理学院外聘教授",
            "渠道网、和讯网、市场部",
            "网专栏专家",
            "《糖烟酒》《新食品》专家团专家",
          ],
        },
        {
          name: "盛  鹏",
          info: [
            "商业模式设计师",
            "战略专家",
            "品牌与营销专家",
            "“买点”理论创始人",
            "正见咨询创始人",
            "凯尚公关顾问",
          ],
        },
        {
          name: "施维克",
          info: [
            "郑州心灯文化传播",
            "有限公司总经理",
            "郑州柴瓷文化研究会副会长",
          ],
        },
        {
          name: "窦惠忠",
          info: [
            "河南省大众创业导师",
            "资深营销与管理咨询师",
            "职业策划师",
            "培训导师",
          ],
        },
      ],
      list: [],
      page: 1,
      totalNUm: null,
      emptyShow: false,
      last_page: 0,
      myId: 0,
      w:0
    };
  },
  watch: {},
  computed: {},
  methods: {
    urgeC: function(e) {
      urge({
        creator: e,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    getList: function() {
      cooperation({
        page: this.page,
        per: 9,
        cooperation: "research",
      }).then((res) => {
        this.list = res.data.data;
        this.last_page = res.data.last_page;
        if (res.data.data == 0) {
          this.emptyShow = true;
        } else {
          this.emptyShow = false;
        }
        this.totalNUm = res.data.total;
      });
    },
    toFans: function(e, type) {
      this.$router.push({
        path: "/fans",
        query: {
          id: e.id,
          type: type,
        },
      });
    },
    follow: function(e) {
      followCreator({
        creator: e.id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 1;
          e.fans++;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    canclefollow: function(e) {
      CanclefollowCreator({
        creator: e.id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 0;
          if (e.fans > 0) {
            e.fans--;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    handleCurrentChange: function(val) {
      this.page = val;
      this.getList();
      if(this.w < 768){
         document.querySelector("#videoboxsm").scrollIntoView(true);
      }else{
        
         document.querySelector("#videobox").scrollIntoView(true);
      }
     
    },
    handleNext: function(val) {
      this.page = val;
      this.getList();
    },
    toMe: function(e) {
      console.log(e);
       if (this.w < 768) {
         window.open(`https://h5.cvhenan.com/#/pages/user/creator?id=${e}`)
       }else{
         this.$router.push({
        path: "/creatorcenter",
        query: {
          id: e,
        },
      });
       }
      
    },

    // tom
    toDetail: function(e, type) {
      if(this.w < 768){
        switch (type) {
          case 2:
               window.open(`https://h5.cvhenan.com/#/pages/detail/videodetail?id=${e}`)
            break;
             case 4:
               window.open(`https://h5.cvhenan.com/#/pages/detail/articledetail?id=${e}`)
            break;
             case 3:
               window.open(`https://h5.cvhenan.com/#/pages/detail/photodetail?id=${e}`)
            break;
        
          default:
            break;
        }
      }else{
          switch (type) {
        case 2:
          let fourOne = this.$router.resolve({
            path: "/videodetail",
            query: {
              id: e,
            },
          });
          window.open(fourOne.href, "_blank");
          break;
        case 4:
          let fourTwo = this.$router.resolve({
            path: "/articledetail",
            query: {
              id: e,
            },
          });
          window.open(fourTwo.href, "_blank");
          break;
        case 3:
          let fourThree = this.$router.resolve({
            path: "/photodetail",
            query: {
              id: e,
            },
          });
          window.open(fourThree.href, "_blank");
          break;
        case 1:
          let fourF = this.$router.resolve({
            path: "/mediadetail",
            query: {
              id: e,
            },
          });
          window.open(fourF.href, "_blank");
          break;

        default:
          break;
      }
      }
    
    },
  },
  created() {
    let user = localStorage.getItem("user");
    if (user) {
      let info = JSON.parse(user);
      this.myId = info.id;
    }

    this.w = document.body.clientWidth
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.all{
  overflow: hidden;
}
.mc-12 {
  width: 1200px;
  margin: 0 auto;
}
.mc-96 {
  width: 960px;
  margin: 0 auto;
}
.titleone{
  font-size: 36px;
  margin-top: -60px;
}
.titletwo{
  font-size: 36px;
  margin-top: -60px;
}
@media screen and (max-width: 768px) {
  .titleone{
      font-size: 20px;
       margin-top: -35px;
    }
    .titletwo{
    font-size: 20px;
       margin-top: -35px;
}
}
.main-one {
  .banana {
    // max-width: 100%;
    width: 100%;
  }
}

.main-two {
  background-color: #f7f7f7;
  padding: 70px 0;
  .two-two-one {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-indent: 20px;
    line-height: 28px;
  }
  .two-two-txt {
    padding: 30px 0;
  }
  .title {
    margin-bottom: 60px;
    .title-one {
      display: flex;
      justify-content: center;
      .title-image {
        max-width: 100%;
      }
    }
    .title-two {
      display: flex;
      justify-content: center;
  
        // background-image: url(../../assets/img/adschool/adtitle4.png);
      .name {
        // font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        letter-spacing: 8px;
        font-size: inherit;
        background: linear-gradient(
          -90deg,
          #4728b1 0.5126953125%,
          #a85ccd 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.main-three {
  padding-top: 90px;
  padding-bottom: 100px;
  .title {
    margin-bottom: 60px;
    .title-one {
      display: flex;
      justify-content: center;
      .title-image {
        max-width: 100%;
      }
    }
    .title-two {
      display: flex;
      justify-content: center;
      // margin-top: -60px;
      .name {
        // font-size: 36px;
        font-size: inherit;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        letter-spacing: 8px;
        background: linear-gradient(
          -90deg,
          #4728b1 0.5126953125%,
          #a85ccd 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .three-box-main {
    // width: 1080px;

    // margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 3px 13px 3px rgba(0, 0, 0, 0.22);
    //   border: 1px s;
    @media screen and (max-width: 720px){
      .item{
        margin-bottom: 30px !important;
      }
    }
    .item-box {
      padding: 78px 56px 0px 56px;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 100%;
        margin-bottom: 100px;
        // height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .item-one {
          display: flex;
          justify-content: center;
          width: 192px;
          .avatar {
            width: 100px;
            height: 100px;
            overflow: hidden;
            border-radius: 50%;
            border: 2px solid #b099f6;
            box-sizing: border-box;
            img {
              max-width: 100%;
            }
          }
        }
        .item-two {
          margin-top: 26px;
          height: 140px;
          width: 192px;
          position: relative;
          border: 1px solid #1c1c1c;
          box-shadow: 8px 8px 0px 0px rgba(154, 125, 244, 0.8);
          .name {
            display: inline-block;
            position: absolute;
            top: -12px;
            left: 56px;
            background-color: #ffffff;
            width: 76px;
            text-align: center;
            span {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3f15bf;
              line-height: 24px;

              background: linear-gradient(
                -90deg,
                #4728b1 0.5126953125%,
                #a85ccd 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            &::after {
              content: "";
              position: absolute;
              right: -4px;
              top: 7px;
              width: 8px;
              height: 8px;
              background-color: #3f15bf;
              border-radius: 4px;
            }
            &::before {
              content: "";
              position: absolute;
              left: -4px;
              top: 7px;
              width: 8px;
              height: 8px;
              background-color: #3f15bf;
              border-radius: 4px;
            }
          }

          .info {
            padding-top: 15px;
            p {
              text-align: center;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.main-four-a {
  background-color: #f7f7f7;
  padding-top: 90px;
  .four-box {
    // width: 1080px;
    // margin: 0 auto;
    .title {
      margin-bottom: 60px;
      .title-one {
        display: flex;
        justify-content: center;
        .title-image {
          max-width: 100%;
        }
      }
      .title-two {
        display: flex;
        justify-content: center;
        .name {
          font-size: inherit;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #635c5c;
          letter-spacing: 8px;
          background: linear-gradient(
            -90deg,
            #4728b1 0.5126953125%,
            #a85ccd 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .info {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      background: #f7f7f7;
      border: 1px solid #000000;
      padding: 45px 40px;
      box-shadow: -8px 8px 0px 0px rgba(154, 125, 244, 0.8);
    }
    .photo {
      //   display: flex;
      padding: 60px 0;
      // margin-top: 60px;
      .item {
        img {
          max-width: 100%;
        }
      }
    }
  }
}

.main-five {
  background-color: #ffffff;
  .five-box {
    .five-box-title {
      padding: 80px 0;
      .title-one {
        display: flex;
        justify-content: center;
        .title-image {
          max-width: 100%;
        }
      }
      .title-two {
        display: flex;
        justify-content: center;
        // margin-top: -60px;
        .name {
          font-size: inherit;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          letter-spacing: 8px;
          background: linear-gradient(
            -90deg,
            #4728b1 0.5126953125%,
            #a85ccd 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .box-one {
      // width: 1080px;
      // margin: 0 auto;
      display: flex;
      .one-l{
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        // flex: 2;
        .item {
          margin-bottom: 40px;
          .title {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #4b2ab2;
            position: relative;
            margin-bottom: 15px;
            &::before {
              content: "";
              position: absolute;
              left: -15px;
              top: 9px;
              width: 8px;
              height: 8px;
              background-color: #3f15bf;
              border-radius: 4px;
            }
          }
          .info {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .one-r {
        // flex: 1;
        .item {
          width: 100%;
        }
      }
    }

    .box-two{
       .item {
          margin-bottom: 40px;
          .title {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #4b2ab2;
            position: relative;
            margin-bottom: 15px;
            &::before {
              content: "";
              position: absolute;
              left: -15px;
              top: 9px;
              width: 8px;
              height: 8px;
              background-color: #3f15bf;
              border-radius: 4px;
            }
          }
          .info {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
    }
  }
}

.main-six {
  background-color: #f7f7f7;
  padding-top: 100px;
  .six-box {
    // width: 1080px;
    // margin: 0 auto;
    .title {
      margin-bottom: 60px;
      .title-one {
        display: flex;
        justify-content: center;
        .title-image {
          max-width: 100%;
        }
      }
      .title-two {
        display: flex;
        justify-content: center;
        .name {
          font-size: inherit;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          letter-spacing: 8px;
          background: linear-gradient(
            -90deg,
            #4728b1 0.5126953125%,
            #a85ccd 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .item-box {
      display: flex;
      flex-wrap: wrap;
      .item {
        // width: 20%;
        margin-bottom: 60px;
        .item-img {
          display: flex;
          justify-content: center;
        }
        img {
          height: 40px;
        }
        .name {
          text-align: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 10px;
        }
      }
    }
  }
}

.main-seven {
  background-color: #f1f1f1;
  padding-top: 100px;
  padding-bottom: 100px;
  .seven-box {
    width: 1200px;
    margin: 0 auto;
    .title {
      margin-bottom: 60px;
      .title-one {
        display: flex;
        justify-content: center;
        .title-image {
          max-width: 100%;
        }
      }
      .title-two {
        display: flex;
        justify-content: center;
        margin-top: -60px;
        .name {
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          letter-spacing: 8px;
          background: linear-gradient(
            -90deg,
            #4728b1 0.5126953125%,
            #a85ccd 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .creabox {
      .main {
        .item-box {
          height: 200px;
          // margin-bottom: 30px;
          overflow: hidden;
          display: flex;
          border-bottom: 1px solid #f1f1f1;
        }
        .item {
          display: flex;
          background-color: #fff;
          padding: 36px 29px;
          margin-bottom: 10px;
          height: 100%;
          .item-l {
            img {
              width: 78px;
              height: 78px;
              border-radius: 50%;
            }
            .two {
              text-align: center;
              margin-top: 15px;
              span {
                display: inline-block;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #fefefe;
                border-radius: 2px;
                padding: 8px 17px;
              }
              .two-one {
                background: #e35111;
              }
              .two-two {
                background-color: #dedede;
              }
            }
          }
          .item-r {
            padding-left: 25px;
            width: 92px;
            .two {
              margin-top: 14px;
            }
            .one {
              .txt-one {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
              }
              .txt-two {
                margin-top: 7px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;
                .hoticon {
                  width: 18px;
                }
                .hottxt {
                  margin-left: 15px;
                }
              }
              .txt-three {
                margin-top: 13px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                span {
                  font-size: 10px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #333333;
                }
              }
              .txt-four {
                // height: 13px;
                margin-top: 11px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
            // .two {
            //   span {
            //     display: inline-block;
            //     font-size: 12px;
            //     font-family: Microsoft YaHei;
            //     font-weight: 400;
            //     color: #fefefe;
            //     border-radius: 2px;
            //     padding: 8px 17px;
            //   }
            //   .two-one {
            //     background: #e35111;
            //   }
            //   .two-two {
            //     background-color: #dedede;
            //   }
            // }
          }
        }
        .col-r {
          background-color: #fff;
          flex: 1;
          // padding-left: 154px;
          padding-right: 20px;
          .r-item {
            display: flex;
            align-items: center;
            // justify-content: flex-end;
            padding-left: 48px;
            height: 100%;
            .r-item-box {
              position: relative;
              width: 272px;
              // flex: 1;
              padding: 0 10px;
              // padding: 30px 5px;
              height: 100%;
              overflow: hidden;
              display: flex;
              align-items: center;
              height: 153px;
              .bg {
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
              }

              img {
                width: 100%;
                //  height: 153px;
              }
              // img {
              //   width: 100%;
              //   height: 153px;
              // }
              .r-item-title {
                position: absolute;
                bottom: 12px;
                left: 25px;
                z-index: 99;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 60%;
              }
              .typeimg {
                position: absolute;
                bottom: 8px;
                right: 17px;
              }
            }
            // 无数据提示
            .nodata {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              color: #808080;
              position: relative;
              padding-top: 100px;
              .n-empty {
                text-align: center;
                // height: 100%;
                img {
                  width: 60px;
                }
                p {
                  margin-top: 10px;
                }
              }
              .butt {
                // position: absolute;
                // top:50%;
                // right:50%;
                display: none;
                text-align: center;
                margin-top: 10px;
              }
              &:hover > .butt {
                display: block;
              }
            }
          }
        }
      }
      .el-col {
        border-radius: 4px;
      }
      .bg-purple-dark {
        background: #99a9bf;
      }
      .bg-purple {
        background: #d3dce6;
      }
      .bg-purple-light {
        background: #e5e9f2;
      }
      .grid-content {
        border-radius: 4px;
        min-height: 36px;
      }
      .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
      }

      .empty {
        padding: 60px 0;
        .emptyone {
          display: flex;
          justify-content: center;
        }
        .emptyimg {
          width: 250px;
        }
        .emptytxt {
          text-align: center;
          margin-top: 10px;
          p {
            color: #757575;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.main-eight{
  padding: 60px 0;
  background-color: #f1f1f1;
   .title {
    margin-bottom: 60px;
    .title-one {
      display: flex;
      justify-content: center;
      .title-image {
        max-width: 100%;
        height: 50px;
      }
    }
    .title-two {
      display: flex;
      justify-content: center;
  
        // background-image: url(../../assets/img/adschool/adtitle4.png);
      .name {
        // font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        letter-spacing: 8px;
        font-size: inherit;
        background: linear-gradient(
          -90deg,
          #4728b1 0.5126953125%,
          #a85ccd 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .itembox{
     .item{
       background-color: #ffffff;
       margin: 10px;
           padding: 20px 0;
    border-radius: 3px;
       .item-one{
         display: flex;
         align-items: center;
         justify-content: space-between;
         
        .item-one-l{
              padding-left: 10px;
          .avatar{
            border-radius: 50%;
            width: 40px;
          }
        }
        .item-one-r{
          display:flex;
          align-items: center;
          padding: 0 10px;
          .fo{
            background-color: #dedede;
            display: inline-block;
            color: #fff;
            padding: 5px 8px;
            border-radius: 3px;width: 40px;
            text-align: center;
          }
          .nofo{
             background-color: #e35111;
            display: inline-block;
            color: #fff;
            padding: 5px 8px;
            border-radius: 3px;width: 40px;
            text-align: center;
          }
        }
        .item-one-c{
         flex: 1;
          padding-left: 10px;
          overflow: hidden;
          .name{
            font-weight: bold;
          }
          .de{
            color: #a9a9a9;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
       }

       .item-two{
         display: flex;
         .item-p{
               width: 50%;
            height: 100px;
            padding: 10px;
            position: relative;
            .bg {
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                    border-radius: 3px;
              }

            .type{
                  position: absolute;
              bottom: 20px;
              right: 20px;
            }
         }
         .nodata{
           text-align: center;
           flex:1;
           .n-empty{
             img{
               width:50px
             }
             p{
               color:#a9a9a9;
             }
           }
         }
       }
    }
  }
 
}


</style>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
