<template>
  <div id="app">
    <CircleMenu :menus="menus" />
  </div>
</template>

<script>
import CircleMenu from "../../components/circleMenu";

export default {
  name: "App",
  components: {
    CircleMenu,
  },
  data() {
    return {
      menus: [
        {
          name: "测试菜单1",
          desc:
            "测试菜单1说明测试菜单1说明测试菜单1说明测试菜单1说明测试菜单1说明测试菜单1说明测试菜单1说明测试菜单1说明测试菜单1说明",
        },
        {
          name: "测试菜单2",
          desc:
            "测试菜单2说明测试菜单2说明测试菜单2说明测试菜单2说明测试菜单2说明测试菜单2说明",
        },
        {
          name: "测试菜单3",
          desc: "测试菜单3说明",
        },
        {
          name: "测试菜单4",
          desc: "测试菜单4说明测试菜单4说明测试菜单4说明",
        },
      ],
    };
  },
};
</script>

<style>

</style>
