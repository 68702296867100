<template>
  <div class="all">
    <div class="line" style="height:40px"></div>
    <div class="main">
      <div class="main-one" style="margin-top: 30px; padding-top:10px">
        <div class="one-l">
          <span
            class="one-item tab cursorstyle"
            :class="activeAdd == '' ? 'addActive tabactive' : ''"
            @click="selectAdd('')"
            >河南</span
          >
        </div>
        <div class="one-r">
          <span
            v-for="(item, x) in addressList"
            :key="x + 1"
            class="one-item cursorstyle"
            :class="activeAdd == x ? 'addbackActive' : ''"
            @click="selectAdd(x,item)"
            >{{ item }}</span
          >
        </div>
      </div>

         <!-- 这个地方显示二级行政区域 -->
       <div class="country" v-show="countyList.length > 0 && activeAdd !== ''"> 
            <div class="main-one " style="padding-top: 10px;">
          <div class="one-l" style="padding: 4px 2px;">
            <span
              class="one-item tab cursorstyle"
              :class="countyActive == '' ? 'countryActive' : ''"
              @click="selectCounty('')"
              >{{addressList[activeAdd]}}市</span
            >
          </div>
          <div class="one-r">
            <span
              v-for="(item, y) in countyList"
              :key="y"
              class="one-item cursorstyle"
              :class="countyActive == item.id ? 'countryActive' : ''"
              @click="selectCounty(item.id)"
              >{{ item.name }}</span
            >
          </div>
        </div>
       </div>

        <div style=height:10px;background:#ffffff></div>

         <div class="empetbox" v-show="activeAdd == ''" style="height:30px">

        </div>
        <div class="typebox" v-show="activeAdd !== ''">
         
            <div class="item">
              <div class="item-box cursorstyle" @click="typeclick('')">
                <span :class="typeActive == '' ? 'typeactive':''">全部分类</span>
              </div>
              <div class="item-box cursorstyle" @click="typeclick('2')">
                  <!-- <img src="../../assets/img/henantab/tab3.png" alt="" srcset="" class="photo" v-show="typeActive !== 'video'">
                  <img src="../../assets/img/henantab/tab4.png" alt="" srcset="" class="photo" v-show="typeActive == 'video'"> -->
                <span :class="typeActive == '2' ? 'typeactive':''">视频</span>
              </div>
               <div class="item-box cursorstyle" @click="typeclick('4')">
                  <!-- <img src="../../assets/img/henantab/tab3.png" alt="" srcset="" class="photo" v-show="typeActive !== 'article'">
                  <img src="../../assets/img/henantab/tab4.png" alt="" srcset="" class="photo" v-show="typeActive == 'video'"> -->
                <span :class="typeActive == '4' ? 'typeactive':''">文章</span>
              </div>
                 <div class="item-box cursorstyle" @click="typeclick('3')">
                  <!-- <img src="../../assets/img/henantab/tab3.png" alt="" srcset="" class="photo" v-show="typeActive !== 'article'">
                  <img src="../../assets/img/henantab/tab4.png" alt="" srcset="" class="photo" v-show="typeActive == 'video'"> -->
                <span :class="typeActive == '3' ? 'typeactive':''">图集</span>
              </div>
                    <div class="item-box cursorstyle" @click="typeclick('1')">
                  <!-- <img src="../../assets/img/henantab/tab3.png" alt="" srcset="" class="photo" v-show="typeActive !== 'article'">
                  <img src="../../assets/img/henantab/tab4.png" alt="" srcset="" class="photo" v-show="typeActive == 'video'"> -->
                <span :class="typeActive == '1' ? 'typeactive':''">音频</span>
              </div>
            </div>
        </div>
        <!-- <div class="imgtab">
          <div class="item cursorstyle" @click="toPage('articlelist')">
              <img src="../../assets/tabimg/du.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('photolist')">
              <img src="../../assets/tabimg/shang.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('medialist')">
              <img src="../../assets/tabimg/ting.png" alt="">
          </div>
        </div> -->
        <!-- <div class="dropmenu">
          <el-dropdown @command="handleClickDown" placement="bottom">
              <span class="hottxt cursorstyle">
                {{downactiveTxt}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,downx) in downList" :key='downx' :command="item">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div> -->
      <!-- <div class="main-one" style="padding-top: 20px;">
        <div class="one-l">
          <span
            class="one-item tab cursorstyle"
            :class="downactive == '' ? 'addActive tabactive' : ''"
            @click="selectDow('')"
            >全部分类</span
          >
        </div>
        <div class="one-r">
          <span
            v-for="(item, y) in downList"
            :key="y"
            class="one-item cursorstyle"
            :class="downactive == item.id ? 'addActive' : ''"
            @click="selectDow(item.id)"
            >{{ item.name }}</span
          >
        </div>
      </div> -->
      <div class="main-two">
        <div class="card-box">
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, saindex) in videoList"
              :key="saindex"
              ><div class="grid-content cursorstyle h300" style=" margin-bottom: 30px;"  @click="toCatePage(item)">
                <div class="card-box-one photoZoom">
                  <!-- <img v-lazy="item.cover" alt="" style="height:100%"/> -->
                   <div v-lazy:background-image="item.cover+'?x-oss-process=image/resize,w_500'" class="bg photoZoomBg"></div>
                     <div class="cat" :class="item.type == 1 || item.type == 2 ? 'r60':'r10'">
                        <img src="../../assets/img/henantab/tab4.png" alt="" srcset="" v-if="item.type == 2">
                         <img src="../../assets/img/henantab/tab2.png" alt="" srcset="" v-if="item.type == 4">
                          <img src="../../assets/img/henantab/tab6.png" alt="" srcset="" v-if="item.type == 3">
                           <img src="../../assets/img/henantab/tab8.png" alt="" srcset="" v-if="item.type == 1">
                    </div>
                  <span class="transtime" v-if="item.type == 1 || item.type == 2">{{item.play_time | transTime}}</span>
                </div>
                <div class="card-box-two">
                  <p>{{ item.title }}</p>
                </div>
                <div class="card-box-three">
                  <span>{{ item.area || "河南"}}</span>
                  <span class="line">{{ item.label_text }}</span>
                </div>
                <div class="card-box-play">
                  <img src="../../assets/img/show.png" alt="" class="play" />
                  <span style="margin-left:10px">{{ item.total_scan }}</span>
                  <!-- <img src="../../assets/img/unlike.png" alt="" class="play" style="margin-left:24px">
                      <span style="margin-left:10px">123</span> -->
                </div>
                <div class="card-box-four" v-if="item.user_info">
                  <div class="card-box-for-img">
                    <img v-lazy="item.user_info.avatar" alt="" />
                  </div>
                  <div class="card-box-for-author">
                    <span>{{ item.user_info.username }}</span>
                  </div>
                  <div class="card-box-for-job">
                    <span>{{ item.user_info.identity_text }}</span>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page" v-show="!emptyShow && totalPage > 1">
        <el-pagination
          background
          layout=" pager, next"
          :total="totalNUm"
          :current-page="page"
          @current-change="handleCurrentChange"
          @next-click="handleNext"
        >
        </el-pagination>
      </div>
      <!-- 淑女为空 -->
      <div class="empty" v-show="emptyShow">
        <div class="emptyone">
          <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
        </div>
        <div class="emptytxt">
          <p>暂时没有数据，过会再来看看吧</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cateFen, address, mediaList } from "@/api";
import {NewArealist} from "../../assets/js/area"
export default {
  components: {},
  props: {},
  data() {
    return {
      addressList: "",
      downList: "",
      activeAdd: "",
      downactive: "",
      page: 1,
      videoList: [],
      emptyShow: false,
      totalPage: null,
      totalNUm: null,
      allCounty:[],
      countyList:[],
      countyActive:'',
      downactiveTxt:'全部分类',
      defaultAddress:'',

    typeActive:''
    };
  },
  watch: {},
  computed: {},
  methods: {
    typeclick:function(e){
      // console.log(e)
      this.typeActive = e
      this.page= 1
      this.getList();
    },
    toCatePage:function(e){
      switch (e.type) {
        case 1:
      //         this.$router.push({
      //   path: "/mediadetail",
      //   query: {
      //     id: e.media_id,
      //   },
      // });
          let fourF = this.$router.resolve({
            path: "/mediadetail",
            query: {
              id: e.media_id,
            },
          });
          window.open(fourF.href, "_blank");
          break;
           case 2:
      //      this.$router.push({
      //   path: "/videodetail",
      //   query: {
      //     id: e.media_id,
      //   },
      // });
           let fourOne = this.$router.resolve({
            path: "/videodetail",
            query: {
              id: e.media_id ,
            },
          });
          
          window.open(fourOne.href, "_blank");
          break;
           case 3:
      //          this.$router.push({
      //   path: "/photodetail",
      //   query: {
      //     id: e.media_id,
      //   },
      // });
               let fourThree = this.$router.resolve({
            path: "/photodetail",
            query: {
              id: e.media_id,
            },
          });
          window.open(fourThree.href, "_blank");
          break;
           case 4:
      //           this.$router.push({
      //   path: "/articledetail",
      //   query: {
      //     id: e.media_id,
      //   },
      // });
             let fourTwo = this.$router.resolve({
            path: "/articledetail",
            query: {
              id:  e.media_id ,
            },
          });
          window.open(fourTwo.href, "_blank");
          break;
      
        default:
          break;
      }
    },
    toPage:function(e){
         const { href } = this.$router.resolve({
        path: `/${e}`,
      });
      window.open(href, "_blank");
    },
    handleClickDown:function(e){
      // console.log(e)
      this.downactive = e.id;
      this.downactiveTxt = e.name
      this.page = 1;
      this.getList();
    },
    selectAdd: function(x,e) {
        this.activeAdd = x;
      this.countyActive = ''
      this.defaultAddress = e
      this.page = 1;
    
      if (this.activeAdd !== '') {
          this.getCounty()
      }
      this.getList()
    },
    selectDow: function(e) {
      this.downactive = e;
      this.page = 1;
      this.getList();
    },
      selectCounty:function(e){
      this.countyActive = e
       this.getList()
    },
    getList: function() {
      var _this = this;
      _this.emptyShow = false;
       let address = _this.countyActive !== '' ?  _this.countyActive : _this.activeAdd
      mediaList({
        area: address,
        page: _this.page,
        per: 8,
        type:this.typeActive
      }).then((res) => {
        this.totalPage = res.data.last_page;
        this.totalNUm = +res.data.total;
        _this.videoList = res.data.data;
        if (this.videoList.length == 0) {
          this.emptyShow = true;
        } else {
          this.emptyShow = false;
        }
      });
    },
    handleCurrentChange: function(val) {
      this.page = val;
      this.getList();
    },
    handleNext: function(val) {
      this.page = val;
      this.getList();
    },
    toVideo: function(e) {
      // console.log(e)
      this.$router.push({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
    },
     getCounty:function(){
      //  console.log(this.activeAdd)
       if (this.activeAdd !== '') {
            this.countyList = this.allCounty.filter(item => item.id == this.activeAdd)[0].data || []

       }
    }
  },
  created() {
     this.activeAdd = this.$route.query.id || '';
      this.typeActive = this.$route.query.type || '';
      this.allCounty = NewArealist()
  },
  mounted() {
    cateFen({
      type: 2,
    }).then((res) => {
      if (res) {
        let a = {
          name:'全部分类',
          id:''
        }
        res.data.unshift(a)
        this.downList = res.data;
      }
    });
    address().then((res) => {
      if (res) {
        this.addressList = res.data;
      }
    });
    this.$nextTick(function() {
      this.getList();
      
    });

   this.getCounty()
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  min-height: 800px;
  .main {
    width: 1200px;
    margin: 0 auto;
  }
}

.main-one {
  display: flex;
  background-color: #ffffff;
  //   margin: 0 10px;
  .one-l {
    padding: 4px 8px;
  }
  .tab {
    white-space: nowrap;
    padding: 4px 8px;
  }
  .one-r {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .one-item {
     margin-right: 16px;
    padding: 4px 10px 10px 10px;
      // padding-bottom: 15px;
    }
  }
}
.addActive {
  background: #e35111;
  border-radius: 2px;
  color: #fff;
}
.addbackActive{
 background-color: #f5f4f4;  
}

.countryActive{
  color: #e35111;
}
.main-two {
  // margin-top: 20px;
  .card-box {
    overflow: hidden;
    .card-box-one {
      height: 160px;
      overflow: hidden;
      position: relative;
      .bg{
       background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    }
      img{
            width: 100%;
    // height: 100%;
      }
       .cat{
    position: absolute;
    bottom: 10px;
    // right: 60px;
  }
  .r60{
    right: 60px;
  }
  .r10{
    right: 10px;

  }
    }
    .card-box-two {
      margin-top: 20px;
      margin-left: 16px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card-box-three {
      margin-top: 10px;
      margin-left: 16px;
      .line {
        margin-left: 12px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
      }
    }
    .card-box-four {
      display: flex;
      padding: 11px 16px;
      align-items: center;
      .card-box-for-img {
        width: 24px;
        height: 24px;
      }
      .card-box-for-img img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .card-box-for-author {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
      }
      .card-box-for-job {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
        margin-left: 16px;
      }
    }
    .card-box-five {
      text-indent: 2em;
      padding: 3px 16px;
      color: #bebebe;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .card-box-play {
      margin-left: 16px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .play {
        width: 12px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
      }
    }
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background: #ffffff;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

//
.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 20px;
    }
  }
}

.country{
  background-color: #fff;
  padding-left: 56px;
      padding-right: 20px;
  .main-one {
    background-color: #f7f7f7;
  }
}

.dropmenu{
  padding: 20px 0;
  .hottxt{
    color: #333333;
    font-size: 12px;
  }
}
.imgtab{
  margin-top: 20px;
  display: flex;
      justify-content: space-between;
  // justify-content: space-around;
  // .item{
  //   flex: 1;

  // }
}

.typebox{
  .item{
    display: flex;
    align-items: center;
    padding: 20px 0;
    .item-box{
      display: flex;
      align-items: center;
      margin-right: 15px;
      img{
        width: 15px;
        height: 15px;
        margin-right: 8px;
      }
      span{
        color: #333333;
        font-size: 12px;
      }
    }
  }
}

.typeactive{
  color: #e35111 !important;
}
</style>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
