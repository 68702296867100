<template>
  <div class="all" v-cloak>
    <div class="main">
      <div class="main-one">
        <span>上传视频</span>
        <span
          >C位河南作品仅用于个人展示，不涉及商业用途，请确保影片自己参与，无需商业版权</span
        >
      </div>
      <div v-if="editstatus == 1">
        <div
          class="main-two uploadmedia"
          :class="is_Show ? 'active' : 'noactive'"
        >
          <!-- video标签 -->
          <div class="hideVideoBox">
            <video
              style="width:1px; opacity: 0;"
              @timeupdate="localchange($event)"
              controls="controls"
              autoplay
              muted
              id="upvideo"
            >
              <source src="" />
            </video>
          </div>
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :data="up_param"
            :before-upload="beforeUpload"
            :on-progress="uploadProgress"
            :on-success="uploadSucc"
            :on-error="uploaderr"
            drag
            multiple
            :show-file-list="false"
            :auto-upload="true"
            v-show="is_Show"
            ref="videoup"
          >
            <div class="upload-one">
              <!-- <i class="el-icon-upload"></i> -->
              <span class="el-icon-upload uploadicon"></span>
              <span class="upload-one-txt">将文件拖动到此处开始上传</span>
            </div>
            <div class="upload-two">
              <span
                >视频仅用于个人展示，不涉及商业用途，请确保影片自己参与，无需商业版权</span
              >
            </div>
            <div class="upload-thre">
              <span>点击选择文件</span>
            </div>
            <div class="upload-four">
              <span>仅支持
                <span style="color:#e35111">MP4/MOV/MKV/M4V</span>
               格式，单个文件大小不超过1024M.</span>
            </div>
          </el-upload>
          <!-- 上传进度 -->
          <div class="showpre" v-show="!is_Show">
            <div style="width:100%">
              <el-progress
                :text-inside="true"
                :stroke-width="17"
                :percentage="uploadPress"
                :color="progressColor"
                class="pressLine"
              ></el-progress>
            </div>

            <div style="display:flex" @click="remove" class="cursorstyle">
              <span
                class="el-icon-circle-close close"
                style="color: #409EFF; margin-left: 10px"
              ></span>
              <!-- <span class="el-icon-circle-check success" style="color: #E35111; margin-left: 10px"></span> -->
            </div>
          </div>
        </div>
      </div>

      <!-- 编辑页面显示的视频 -->
      <div class="edirvideo" v-if="editstatus == 2">
        <div>
          <video :src="mediaUrl" controls :poster="cover"></video>
        </div>

        <div class="deleteButtone">
          <el-popconfirm
            title="确定要删除该视频吗？"
            @confirm="editVideoDelete"
          >
            <!-- <el-button slot="reference">删除</el-button> -->
            <el-button type="danger" icon="el-icon-delete" slot="reference"
              >删除该视频</el-button
            >
          </el-popconfirm>
        </div>
      </div>

      <div class="main-three">
        <div class="sub">
          <div class="sub-l">
            <div class="block">
              <div class="image-box cursorstyle" @click="clicp">
                <div slot="error" class="image-slot nopic" v-if="cover == ''">
                  <i class="el-icon-picture-outline"></i>
                  <br />
                  <p>点击选择本地图片</p>
                </div>
                <img
                  :src="cover"
                  alt=""
                  v-if="cover !== ''"
                  style="width: 100%;height: 100%;"
                />
              </div>
              <!-- <el-upload
                class="avatar-uploader nopic"
                :action="uploadUrl"
                :data="up_param"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                ref="pictureUpload"
                accept=".jpg, .png .jpeg"
                name="file"
              >
                <img v-if="cover" :src="cover" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
            </div>
            <div class="subbox">
              <el-button
                type="primary"
                :disabled="openSt"
                @click="openVideoPre"
                class="subBg"
                >{{ subvideoTxt }}</el-button
              >
            </div>
            <div class="uptxt">
              <p>支持JPG、JPEG、PNG格式</p>
              <p>选择视频封面请等待视频上传完毕</p>
            </div>
          </div>
          <div class="sub-r">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              label-position="left"
            >
              <el-form-item label="视频标题" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="分类" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择分类"
                  class="w100"
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="(item, y) in downList"
                    :key="y"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="授权方式" prop="videoType">
                <el-select
                  v-model="ruleForm.videoType"
                  placeholder="请选择授权方式"
                  class="w100"
                >
                  <el-option
                    :label="item"
                    :value="y"
                    v-for="(item, y) in videoType"
                    :key="y"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="标签" prop="selectvalue">
                <div class="w100">
                  <el-select
                    v-model="ruleForm.selectvalue"
                    class="selectwidth w100"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请选择或按Enter创建标签"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div>
                  <el-tag
                    type="success"
                    class="tagm cursorstyle"
                    v-for="(item, tagindex) in options"
                    :key="tagindex"
                    @click="tagselect(item)"
                    >{{ item.value }}</el-tag
                  >
                </div>
                <div>
                  <p
                    style="font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;"
                  >
                    最多添加5个标签，每个标签内容不能重复
                  </p>
                </div>
              </el-form-item>
              <el-form-item label="视频简介" prop="desc">
                <el-input
                  type="textarea"
                  v-model="ruleForm.desc"
                  resize="none"
                ></el-input>
              </el-form-item>

         <el-form-item label="活动区域" required>
              <el-col :span="11">
                <el-form-item prop="address">
                  <el-select
                    v-model="ruleForm.address"
                    placeholder="所在区域"
                    class="selectwidth"
                    style="width: 100%;"
                  >
                    <el-option
                      :label="item"
                      :value="item"
                      v-for="(item, x) in addressList"
                      :key="x"
                      >{{ item }}</el-option
                    >
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <div
                style="width: 20px;
    height: 20px;
    float: left;"
              ></div> -->
              <el-col class="line" :span="2" style=" text-align: center;">-</el-col>
              <el-col :span="11">
                <el-form-item prop="county">
                  <el-select
                    v-model="ruleForm.county"
                    placeholder="详细地址"
                    class="selectwidth"
                    style="width: 100%;"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.name"
                      v-for="(item, clindex) in countyList"
                      :key="clindex"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>

              <el-form-item class="subbox">
                <!-- <el-button type="primary" @click="submitForm('ruleForm')"
                >立即创建</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                <!-- <div class="subbutton" @click="submitForm('ruleForm')">
                  <span> 发布视频</span>
                </div> -->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="main-four">
        <span @click="submitForm('ruleForm')" class="cursorstyle"
          >发布视频</span
        >
      </div>
      <clip
        :osslist="osslist"
        :imgh="imgh"
        @changeclicp="clicp"
        @getmsg="getMsgFormSon"
        ref="clic"
      ></clip>
      <div class="line" style="height:40px"></div>

      <!-- 编辑页面的弹窗 -->
      <div class="timebox">
        <el-dialog
          title="选择封面"
          :visible.sync="centerDialogVisible"
          width="60%"
          center
        >
          <!-- <span>需要注意的是内容是默认不居中的</span> -->
          <!-- 显示滑块的初始值 -->
          <div>
            <div class="img-box">
              <img :src="videoPpreview" alt="" />
              <!-- <div class="item" v-for="(item,videoindex) in localvideoList" :key='videoindex'>
                  <img :src="item" alt="">
              </div> -->
            </div>
            <div class="solid-block">
              <!-- <span class="demonstration">选择视频封面</span> -->
              <el-slider
                v-model="value1"
                @input="handinput()"
                class="slidestyle"
                :step="videoStep"
                :max="videoMax"
                :min="1"
                :format-tooltip="formatTooltip"
              ></el-slider>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" class="subBg" @click="subVideoCover"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import clip from "../../components/clipPhoto";
import { NewArealist } from "../../assets/js/area";
import {
  cateFen,
  address,
  ossconfig,
  publicvideo,
  videotype,
  editVideo,
  updateVideo,
  videoTag
} from "@/api";
export default {
  components: {
    clip,
  },
  props: {},
  data() {
    var checkTag = (rule, value, callback) => {
      if (value.length == 0) {
        return callback(new Error("标签不能为空"));
      } else {
        callback();
      }
    };
    return {
      downList: "",
      addressList: "",
      osslist: "",
      uploadUrl: "",
      up_param: {
        key: "",
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        success_action_status: "",
        callback: "",
      },
      uploadPress: 0,
      progressColor: "#EFA625",
      is_Show: true,
      mediaUrl: "",
      rules: {
        name: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 255,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        desc: [
          { required: false, message: "请输入描述信息", trigger: "blur" },
          {
            min: 1,
            max: 240,
            message: "长度在 1 到 240 个字符",
            trigger: "blur",
          },
        ],
        selectvalue: [
          { required: true, validator: checkTag, trigger: "change" },
        ],
        address: [
          { required: true, message: "请选择所在区域", trigger: "change" },
        ],
        videoType: [
          { required: true, message: "请选择授权方式", trigger: "change" },
        ],
          county: [
          { required: true, message: "请选择详细地址", trigger: "change" },
        ],
      },
      options: [
        
      ],
      ruleForm: {
        name: "",
        region: "",
        desc: "",
        selectvalue: [],
        address: "",
        videoType: "",
        county:''
      },
      cover: "",
      videoType: "",
      imgh: {
        width: 240,
        height: 135,
      },
      clicpShow: false,
      centerDialogVisible: false,
      value1: 0,
      videoTime: 0,
      fileName: "",
      videoPpreview: "",
      openSt: true,
      localVideoUrl: "",
      localvideoList: [],
      videoStep: 1,
      videoMax: 100,
      t: 0,
      subvideoTxt: "请选择视频封面",
      id: "",
      editstatus: 0,
         allCounty: [],
      countyList: [],
      addressID:''
    };
  },
  watch: {
    "ruleForm.selectvalue": {
      handler(val) {
        var self = this;
        let list = JSON.parse(JSON.stringify(val));
        if (list.length >= 6) {
          self.$message({
            showClose: true,
            message: "最多上传5个标签",
            type: "warning",
          });
          setTimeout(() => {
            self.ruleForm.selectvalue.splice(5, 1);
          }, 1000);
        }
      },
    },
        "ruleForm.address": {
      handler(val) {
        let addressnewList =  this.allCounty.filter((item) => item.name == val);
         this.addressID = addressnewList[0].id
        this.countyList = addressnewList[0].data || []
        if (this.countyList.length == 0) {
          this.ruleForm.county = this.ruleForm.address;
        } else {
          this.ruleForm.county = "";
        }
        //  console.log(this.countyList)
      },
    },
    'ruleForm.county': {
      handler(val){
        if (this.countyList.length > 0  && val !== '') {
           let id = this.countyList.filter(item=>item.name == val)[0].id
           this.addressID = id
        }
        // console.log(val)
      }
    },
  },
  computed: {},
  methods: {
      getTag:function(){
      videoTag().then(res=>{
        var arr = []
          res.data.map(item=>{
            let a = {
              value:item.title,
              label:item.title
            }
            arr.push(a)
          })
        this.options = arr
      })
    },
    editVideoDelete: function() {
      this.mediaUrl = "";
      // this.cover = "";
      this.editstatus = 1;
    },
    isAllEqual: function(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
        hash[arr[i]] = true;
      }
      return false;
    },
    transTime(time) {
      let duration = parseInt(time);
      let minute = parseInt(duration / 60)
        .toString()
        .padStart(2, "0");
      let sec = (duration % 60).toString().padStart(2, "0");
      return `${minute}:${sec}`;
    },
    formatTooltip: function() {
      return this.transTime(this.value1 * this.t);
    },
    clicp: function() {
      // this.clicpShow = !this.clicpShow
      this.$refs.clic.show();
    },
    getMsgFormSon: function(data) {
      // console.log(data)
      this.cover = data;
      // console.log(this.cover);
    },
    // 创建视频的url  file对象
    toBlob: function(e) {
      let url = URL.createObjectURL(e);
      var video = document.getElementById("upvideo");
      video.src = url;
      // console.log(url);
      // video.play()
      // this.localVideoUrl = url
      // this.localchange();
      //   setTimeout(()=>{
      //  this.localchange()
      //   },800)
    },
    // 视频处理完毕
    localchange: function(e) {
      var video = document.getElementById("upvideo");
      // this.creatImg(du)
      var length = Math.floor(video.duration);
      // 这个地方计算滑块的移距离

      // 如果视频市场小于100s .每隔一秒截取一次。最多100张
      // console.log(length)
      if (length <= 100) {
        // 小于100s
        this.t = 1;
        this.videoMax = length;
      } else {
        // 大于100s
        this.t = Math.ceil(length / 100);
      }
      // switch (length) {
      //   case length <= 100:
      //     this.t = 1;
      //     this.videoMax = length;
      //     break;

      //   default:
      //     break;
      // }
      // if (video.currentTime > length) return false;
      if (parseInt(video.currentTime) >= parseInt(length)) {
        this.videoMax = this.localvideoList.length - 1;
        this.subvideoTxt = "视频封面截取";
        this.openSt = false;
        return false;
      } else {
        // var text = ""
        // var i = 0;
        // do {

        //     i++
        //     console.log(i)
        // } while (i < 5);
        video.currentTime = parseInt(video.currentTime + this.t);
        // Math.parseInt(video.currentTime) += this.t;
        this.creatImg(video);
        this.subvideoTxt =
          "正在生成视频封面" +
          Math.floor((video.currentTime / length) * 100) +
          "%";
      }
    },

    // 创建视频标签
    creatImg: function(video) {
      var canvas = document.createElement("canvas"); // 创建一个画布
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = document.createElement("img");
      var img_url = canvas.toDataURL("image/png"); // 获取图片的url
      // console.log(img_url)
      var ob = {
        url: "",
        data: "",
      };
      let bl = this.dataURLtoBlob(img_url);
      ob.url = URL.createObjectURL(bl);
      ob.data = bl;
      this.localvideoList.push(ob);
      // console.log(URL.createObjectURL(bl))
      // this.localvideoList.push(URL.createObjectURL(bl));
      // img.src = img_url;
      // // var blob = new Blob(img_url);
      // img.height = 60;
      // img.addEventListener('click',function (ele){
      //    document.getElementById('img').src = this.getAttribute('src');
      // });
    },
    checkVideoTIme:function(file){
        // let videoUrl = URL.createObjectURL(file);
        // let audioElement = new Audio(videoUrl);
        // audioElement.addEventListener("loadedmetadata", () => {
        //   this.videoTime = audioElement.duration;
        //   if (this.videoTime > 12) {
        //     this.$message({
        //       type: "warning",
        //       message: "视频时长不能超过20分钟",
        //     });
        //     this.$refs.videoup.abort();
        //     return;
        //   }
          
        // });
         return new Promise(resolve => {
          let videoElement = document.createElement('video');
          videoElement.src = URL.createObjectURL(file);
          videoElement.addEventListener('loadedmetadata', function() {
            resolve({
              duration: videoElement.duration,
            });
          });
        });
    },
    beforeUpload(file) {
      console.log(file);
      this.fileName = file.name;
      // this.$refs.videoup.abort();
      if (file.length == 0) {
        alert("请选择文件");
        return false;
      } else {

         var getSuffix = function(fileName) {
              var pos = fileName.lastIndexOf(".");
              var suffix = "";
              if (pos != -1) {
                suffix = fileName.substring(pos);
              }
              return suffix;
            };

            let namelength = getSuffix(file.name);
           this.ruleForm.name = file.name.substring(
              0,
              file.name.length - namelength.length
            );
            var filename = new Date().getTime() + getSuffix(file.name);
           this.up_param.key =this.osslist.dir + filename;
           this.up_param.OSSAccessKeyId =this.osslist.accessid;
           this.up_param.policy =this.osslist.policy;
           this.up_param.Signature =this.osslist.signature;
           this.up_param.callback =this.osslist.callback;
           this.up_param.success_action_status = 200;

           //  验证文件格式
        var FileExt = file.name.replace(/.+\./, "");
        console.log(FileExt);
        // console.log(FileExt.toUpperCase());
        if (["MP4", "MOV","MKV",'M4V'].indexOf(FileExt.toUpperCase()) === -1) {
          this.$message({
            type: "warning",
            message: "请检查上传文件的类型",
          });
          this.$refs.videoup.abort();
          return false;
        }
        var size = file.size / 1024 / 1024;
        console.log(size)
        if (size >= 1024) {
          this.$message({
            type: "warning",
            message: "文件上传的大小不能超过1024M",
          });
          this.$refs.videoup.abort();
          return false;
        }

        this.checkVideoTIme(file).then(res=>{
           this.videoTime = res.duration;
          if (res.duration > 1800) {
            this.$message({
              type: "warning",
              message: "视频时长不能超过30分钟",
            });
            this.$refs.videoup.abort();
            return;
          
          } else {
            this.toBlob(file);
            var fileUrl =
              this.osslist.host + "/" + this.osslist.dir + filename;
            // this.mediaUrl = fileUrl;
            this.is_Show = !this.is_Show;
          }
        })
          // 获取视频时间长度
        // let videoUrl = URL.createObjectURL(file);
        // let audioElement = new Audio(videoUrl);
        // audioElement.addEventListener("loadedmetadata", () => {
        //   this.videoTime = audioElement.duration;
        //   console.log(this.videoTime )
        //   if (this.videoTime > 12) {
        //     this.$message({
        //       type: "warning",
        //       message: "视频时长不能超过20分钟",
        //     });
        //     this.$refs.videoup.abort();
        //     return;
        //   }
          
        // });

        // return
     
        
      
        // // return
        //    this.toBlob(file);
        //     var _self = this;
        //     var getSuffix = function(fileName) {
        //       var pos = fileName.lastIndexOf(".");
        //       var suffix = "";
        //       if (pos != -1) {
        //         suffix = fileName.substring(pos);
        //       }
        //       return suffix;
        //     };

        //     let namelength = getSuffix(file.name);
        //     _self.ruleForm.name = file.name.substring(
        //       0,
        //       file.name.length - namelength.length
        //     );
        //     var filename = new Date().getTime() + getSuffix(file.name);
        //     _self.up_param.key = _self.osslist.dir + filename;
        //     _self.up_param.OSSAccessKeyId = _self.osslist.accessid;
        //     _self.up_param.policy = _self.osslist.policy;
        //     _self.up_param.Signature = _self.osslist.signature;
        //     _self.up_param.callback = _self.osslist.callback;
        //     _self.up_param.success_action_status = 200;
        //     var fileUrl =
        //       _self.osslist.host + "/" + _self.osslist.dir + filename;
        //     this.mediaUrl = fileUrl;
        //     this.is_Show = !this.is_Show;
      }
    },
    uploadSucc:function(response,file,fileList){
      console.log(response)
      if (response.status == "success" && response.file_name !== '') {
          this.mediaUrl  =  response.file_name
      }
    },
    loadxml:function(text){
     var str = text;
//创建文档对象
var parser=new DOMParser();
var xmlDoc=parser.parseFromString(str,"text/xml");
console.log(xmlDoc)

//提取数据
var countrys = xmlDoc.getElementsByTagName('DataSource');

var arr = [];

for (var i = 0; i < countrys.length; i++) {
arr.push(countrys[i].textContent);
};
//console.log(arr);
return arr;

    },
    uploaderr:function(err, file, fileList){
    //  console.log(JSON.stringify(err))
      //  console.log(file)
      //   console.log(fileList)
      this.$message({
          showClose: true,
          message: "上传失败！请重新上传",
          type: "error",
        });
     this.mediaUrl = "";
      // this.cover = "";
      this.is_Show = true;
    },

    uploadProgress: function(event, file, fileList) {
      this.uploadPress = Math.trunc(event.percent);
      if (this.uploadPress == 100) {
        this.progressColor = "#67c23a";
        // this.openSt = !this.openSt;
      }
      //   console.log(file);
    },
    remove: function() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //   this.$message({
          //     type: "success",
          //     message: "删除成功!",
          //   });
          //   this.$refs.videoup.about()
          //   this.$refs.videoup.clearFiles()
          this.is_Show = !this.is_Show;
          this.mediaUrl = "";
          this.uploadPress = 0;
          this.openSt = !this.openSt;
          this.videoPpreview = "";
          this.cover = "";
          this.progressColor = "#EFA625";
          this.localvideoList = [];
        })
        .catch(() => {
          //   this.$message({
          //     type: "info",
          //     message: "已取消删除",
          //   });
        });
    },
    tagselect: function(e) {
      let list = this.ruleForm.selectvalue;
      let a = list.filter((item) => item == e.value);
      if (a.length == 0) {
        this.ruleForm.selectvalue.push(e.value);
      } else {
        this.$message({
          showClose: true,
          message: "标签内容不能重复",
          type: "error",
        });
      }
    },
    submitForm(formName) {
      if (this.cover == "") {
        this.$message({
          showClose: true,
          message: "请选择封面",
          type: "error",
        });
        return;
      }
      if (this.mediaUrl == "") {
        this.$message({
          showClose: true,
          message: "请选择视频",
          type: "error",
        });
        return;
      }

      if (this.uploadPress !== 100) {
        this.$message({
          showClose: true,
          message: "请选择视频或等待视频上传完成再尝试",
          type: "error",
        });
        return;
      }

      // 检测标签内容是否重复
      if (this.ruleForm.selectvalue.length > 6) {
        this.$message({
          showClose: true,
          message: "标签长度最多为5个",
          type: "error",
        });
        return;
      }

      // 检测重复项
      if (this.isAllEqual(this.ruleForm.selectvalue)) {
        this.$message({
          showClose: true,
          message: "标签内容不能重复",
          type: "error",
        });
        return;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
     

          // 编辑状态
          if (this.id !== "") {
             this.$confirm('请确认作品拍摄地是否正确!', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '返回修改',
          type: 'warning'
        }).then(() => {
           updateVideo({
              title: this.ruleForm.name,
              cover: this.cover,
              area: this.addressID,
              file: this.mediaUrl,
              auth_type: this.ruleForm.videoType,
              classification: this.ruleForm.region,
              label: this.ruleForm.selectvalue.join(","),
              description: this.ruleForm.desc,
              play_time: this.videoTime,
              video: this.id,
            }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success",
                });
                // setTimeout(() => {}, 1000);
                setTimeout(() => {
                  this.$router.push({
                    path: "/myworks",
                    query: {
                      type: "0",
                    },
                  });
                }, 1000);
              } else {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "error",
                });
              }
            });
        }).catch(() => {
                
        });
           
          } else {
            // 发布状态
            this.$confirm('请确认作品拍摄地是否正确!', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '返回修改',
          type: 'warning'
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
            publicvideo({
              title: this.ruleForm.name,
              cover: this.cover,
              area: this.addressID,
              file: this.mediaUrl,
              auth_type: this.ruleForm.videoType,
              classification: this.ruleForm.region,
              label: this.ruleForm.selectvalue.join(","),
              description: this.ruleForm.desc,
              play_time: this.videoTime,
            }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success",
                });
                // setTimeout(() => {}, 1000);
                setTimeout(() => {
                  this.$router.push({
                    path: "/myworks",
                    query: {
                      type: "0",
                    },
                  });
                }, 1000);
              } else {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "error",
                });
              }
            });
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
          
          }
        } else {
          // console.log("error submit!!");
          this.$message({
            showClose: true,
            message: "请检查提交的内容",
            type: "error",
          });
          return false;
        }
      });
    },
    beforeAvatarUpload: function(file) {
      if (file.length == 0) {
        alert("请选择文件");
        return false;
      } else {
        var _self = this;
        var getSuffix = function(fileName) {
          var pos = fileName.lastIndexOf(".");
          var suffix = "";
          if (pos != -1) {
            suffix = fileName.substring(pos);
          }
          return suffix;
        };
        var filename = new Date().getTime() + getSuffix(file.name);
        _self.up_param.key = _self.osslist.dir + filename;
        _self.up_param.OSSAccessKeyId = _self.osslist.accessid;
        _self.up_param.policy = _self.osslist.policy;
        _self.up_param.Signature = _self.osslist.signature;
        _self.up_param.callback = _self.osslist.callback;
        _self.up_param.success_action_status = 200;
        var fileUrl = _self.osslist.host + "/" + _self.osslist.dir + filename;
        // _self.files.push(fileUrl);
        setTimeout(() => {
          this.cover = fileUrl;
        }, 500);
      }
    },
    // 截取视频封面
    openVideoPre: function() {
      // this.videoPpreview = `${this.mediaUrl}?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_300,m_fast`;
      this.videoPpreview = this.localvideoList[1].url;
      this.centerDialogVisible = !this.centerDialogVisible;
    },
    // 数据改变触发
    handinput: function() {
      // 阿里云上传
      // let value = Math.round((Math.floor(this.videoTime) / 100) * this.value1);
      // let t = value * 1000;
      // this.videoPpreview = `${this.mediaUrl}?x-oss-process=video/snapshot,t_${t},f_jpg,w_0,h_300,m_fast`;

      // 本地预览视频
      // console.log(this.value1)
      this.videoPpreview = this.localvideoList[this.value1].url;
    },
    // 确定上传图片
    subVideoCover: function() {
      let bl = this.localvideoList[this.value1].data;
      // let files = new window.File([this.blob], file.name, {type: file.type})
      // 这个地方图片上传
      var getSuffix = function(fileName) {
        var pos = fileName.lastIndexOf(".");
        var suffix = "";
        if (pos != -1) {
          suffix = fileName.substring(pos);
        }
        return suffix;
      };
      let formData = new FormData();
      var filename = new Date().getTime() + ".png";
      console.log(filename);
      console.log(this.osslist);
      formData.append("key", this.osslist.dir + filename);
      formData.append("OSSAccessKeyId", this.osslist.accessid);
      formData.append("policy", this.osslist.policy);
      formData.append("Signature", this.osslist.signature);
      formData.append("callback", this.osslist.callback);
      formData.append("success_action_status", 200);
      formData.append("file", bl);
      var data = formData;
      console.log(this.osslist);
      axios.post(this.osslist.host, data).then((res) => {
        // console.log(res)
        if (res.status == 200) {
          var fileUrl = this.osslist.host + "/" + this.osslist.dir + filename;
          console.log(fileUrl);
          this.cover = fileUrl;
          this.centerDialogVisible = !this.centerDialogVisible;
          // this.loading = false
          //  console.log(fileUrl)
          //   insert(fileUrl);
        } else {
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        }
      });
      // let url = this.videoPpreview + "&v=" + Math.random();
      // let test = this.urlToBase64(url).then((res) => {
      //   if (res) {
      //     let bl = this.dataURLtoBlob(res);
      //     // 这个地方图片上传
      //     var getSuffix = function(fileName) {
      //       var pos = fileName.lastIndexOf(".");
      //       var suffix = "";
      //       if (pos != -1) {
      //         suffix = fileName.substring(pos);
      //       }
      //       return suffix;
      //     };
      //     let formData = new FormData();
      //     var filename = new Date().getTime() + ".png";
      //     console.log(filename);
      //     console.log(this.osslist);
      //     formData.append("key", this.osslist.dir + filename);
      //     formData.append("OSSAccessKeyId", this.osslist.accessid);
      //     formData.append("policy", this.osslist.policy);
      //     formData.append("Signature", this.osslist.signature);
      //     formData.append("success_action_status", 200);
      //     formData.append("file", bl);
      //     var data = formData;
      //     console.log(this.osslist);
      //     axios.post(this.osslist.host, data).then((res) => {
      //       // console.log(res)
      //       if (res.status == 200) {
      //         var fileUrl =
      //           this.osslist.host + "/" + this.osslist.dir + filename;
      //         console.log(fileUrl);
      //         this.cover = fileUrl;
      //         this.centerDialogVisible = !this.centerDialogVisible;
      //         // this.loading = false
      //         //  console.log(fileUrl)
      //         //   insert(fileUrl);
      //       } else {
      //         this.$message({
      //           showClose: true,
      //           message: "上传失败",
      //           type: "error",
      //         });
      //       }
      //     });
      //   }
      // });
    },
    urlToBase64(url) {
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function() {
          let canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;
          // 将图片插入画布并开始绘制
          canvas.getContext("2d").drawImage(image, 0, 0);
          // result
          let result = canvas.toDataURL("image/png");
          resolve(result);
        };
        // CORS 策略，会存在跨域问题https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
        // 图片加载失败的错误处理
        image.onerror = () => {
          reject(new Error("图片流异常"));
        };
      });
    },
    dataURLtoBlob: function(dataurl) {
      if (dataurl) {
        // console.log(dataurl)
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }
    },

    setVideoImg(url) {
      this.$nextTick(() => {
        // 创建视频
        let video = document.createElement("video");
        video.controls = "controls";
        video.crossOrigin = "anonymous";
        // 播放地址
        let source = document.createElement("source");
        source.src = url;
        source.type = "video/mp4";
        video.appendChild(source);
        // 这很重要
        video.currentTime = 0.1;
        // 当前帧的数据已加载
        video.addEventListener("loadeddata", () => {
          // 创建画布
          var canvas = document.createElement("canvas");
          canvas.width = video.videoWidth * 0.5;
          canvas.height = video.videoHeight * 0.5;
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          // 转码添加
          var img = document.createElement("img");
          let src = canvas.toDataURL("image/png");
          img.src = src;
          // 给特定vue节点添加
          this.$refs["video-img-xxx"].appendChild(img);
        });
      });
    },
  },
  created() {
       let list = NewArealist()
   
    list.map(item=>{
       let a = {
          id:item.id,
           pid: item.pid,
        name: `${item.name}市`,
        level: item.level,
        }
       item.data.push(a)
    })
     var allA = [
      { "id": '',
        "pid": '',
        "name": "河南",
        "level": '',
        "data": [{
            "id": '',
            "pid": '',
            "name": "河南",
            "level": ''
        }],}
    ]
    this.allCounty =  [...allA,...list]
  },
  mounted() {
    cateFen({
      type: 2,
    }).then((res) => {
      if (res) {
        this.downList = res.data;
      }
    });
    address().then((res) => {
      if (res) {
           res.data[0] = '河南'
        this.addressList = res.data;
      }
    });
    videotype().then((res) => {
      this.videoType = res.data;
    });
    this.getTag()
    this.$nextTick(() => {
      ossconfig({
        file_type: "file",
        media_type: "video",
      }).then((res) => {
        this.osslist = res.data;
        this.uploadUrl = res.data.host;
      });
    });
    // 获取内容
    // console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      editVideo({
        video: this.$route.query.id,
      }).then((res) => {
        this.ruleForm.name = res.data.title;
        this.cover = res.data.cover;
        this.ruleForm.selectvalue = res.data.label_text.split(",");
        this.ruleForm.desc = res.data.description;
        // this.ruleForm.address = res.data.area;
           let addarray = res.data.area.split('-')
        this.ruleForm.address = addarray[0]
        setTimeout(()=>{
          this.ruleForm.county = addarray[1]
        },200)
        this.ruleForm.videoType = res.data.auth_type;
        this.mediaUrl = res.data.url;
        this.ruleForm.region = res.data.classification_id;
        this.editstatus = 2;
        this.uploadPress = 100;
        this.videoTime = res.data.play_time;
      });
    } else {
      this.editstatus = 1;
    }
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  .main {
    width: 1080px;
    margin: 0 auto;
    .main-one {
      padding-top: 40px;
      padding-bottom: 35px;
    }
    .main-one span:nth-child(1) {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .main-one span:nth-child(2) {
      margin-left: 10px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
}
// 编辑视频显示的样式
.edirvideo {
  background-color: #ffffff;
  padding: 20px;
  div {
    display: flex;
    justify-content: center;
  }
  .deleteButtone {
    margin-top: 20px;
  }
  video {
    height: 300px;
  }
}
.main-two {
  //   height: 469px;
  background-color: #ffffff;
  .upload-demo {
    .el-upload-dragger {
      width: 100%;
    }
    .el-upload {
      width: 100%;
      height: 100%;
    }
    .upload-one {
      margin-top: 80px;
      height: 100%;
      .el-icon-upload {
        width: 32px;
        height: 29px;
      }
      .uploadicon {
        font-size: 30px;
      }
      .upload-one-txt {
        margin-left: 20px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
    .upload-two {
      margin-top: 20px;
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
    .upload-thre {
      margin-top: 44px;
      span {
        display: inline-block;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        background: #e35111;
        border-radius: 2px;
        padding: 14px 48px;
      }
    }
    .upload-four {
      margin-top: 30px;
      span {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .showpre {
    display: flex;
    align-items: center;
    padding: 41px 29px;
    .close {
      color: red;
      margin-left: 10px;
    }
  }
}
.active {
  height: 469px;
}
.noactive {
  height: auto;
}
.sub {
  display: flex;
  margin-top: 40px;
  padding-top: 30px;
  .sub-l {
    padding: 0px 60px;
    .subbox {
      // background: #e35111;
      // border-radius: 2px;
      // padding: 12px 0px;
      // margin-top: 10px;
      // p {
      //   font-size: 16px;
      //   font-family: Microsoft YaHei;
      //   font-weight: bold;
      //   color: #ffffff;
      //   text-align: center;
      // }
      .mainBg {
        background: #e35111;
        color: #fff;
      }
      .subBg {
        background-color: #e35111;
        border-color: #e35111;
        color: #ffffff;
        width: 100%;
        margin-top: 10px;
      }
      .is-disabled:hover {
        color: #ffffff;
        cursor: not-allowed;
        background-image: none;
        background-color: rgba(227, 81, 17, 0.5);
        border-color: rgba(227, 81, 17, 0.5);
      }
      .is-disabled {
        background-color: rgba(227, 81, 17, 0.5) !important;
        border-color: rgba(227, 81, 17, 0.5) !important;
      }
    }
  }
  .uptxt {
    margin-top: 10px;
    p {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
  .block {
    width: 240px;
    height: 135px;
    background-color: aliceblue;

    .image-box {
      width: 100%;
      height: 100%;
      .nopic {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f7fa;
      }
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 240px;
      height: 180px;
      // line-height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .avatar {
      width: 240px;
      height: 180px;
      display: block;
    }
  }
  .sub-r {
    flex: 1;
    padding: 0 60px 0 0;
    .w100 {
      width: 100%;
    }
    .tagm {
      margin-right: 10px;
    }
    .subbutton {
      text-align: end;
      span {
        background: #e35111;
        border-radius: 2px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        padding: 12px 25px;
      }
    }
  }
}
.main-three {
  background-color: #ffffff;
}
.timebox {
  .img-box {
    text-align: center;
    img {
      width: 500px;
    }
    // display: flex;
    // .item {
    //   width: 20%;
    // }
  }
}

.subBg {
  background-color: #e35111;
  border-color: #e35111;
  color: #ffffff;
  padding: 12px 20px;
}
// 隐藏的video标签
// .hideVideoBox {
// }
</style>
<style lang="scss">
.uploadmedia {
  .el-upload {
    width: 100%;
    height: 100%;
  }
  .el-upload-dragger {
    width: 100%;
    height: 100%;
    border: none;
  }
}
.main-four {
  background-color: #ffffff;
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: center;
  padding: 29px;
  span {
    display: inline-block;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    background: #e35111;
    border-radius: 2px;
    padding: 9px 32px;
  }
}
.solid-block {
  .slidestyle {
    .el-slider__runway {
      .el-slider__bar {
        background-color: #e35111;
      }
      .el-slider__button-wrapper {
        .el-slider__button {
          border: none !important;
          background-image: url("../../assets/img/range.png");
          background-size: contain;
          width: 30px;
          height: 30px;
          // background:url('../../assets/img/range.png') no-repeat cover;
        }
      }
    }
  }
}
</style>
