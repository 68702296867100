<template>
  <div class="all">
    <div class="main">
      <div class="titleone">
        <p
          v-for="(item, x) in nav"
          :key="x"
          @click="navChange(item)"
          :class="activeNav == item.index ? 'navactive' : 'nonavactive'"
          class="cursorstyle"
        >
          {{ item.name }} {{numList[x]}}
        </p>
      </div>
      <div class="main-one">
        <div class="main-one-l">
          <!-- 粉丝 -->
          <div v-show="activeNav == '0'">
            <div class="item-box" v-for="(item, y) in fansList" :key="y">
              <div class="item">
                <div class="item-l cursorstyle"  @click="toCreCenter(item.user_id)">
                  <div class="item-l-img">
                    <img v-lazy="item.user_info.avatar" alt="" />
                  </div>
                  <div class="item-l-info">
                    <p class="info-one">{{ item.user_info.username }}</p>
                    <p class="info-two">
                      <span>粉丝 {{ item.user_info.fans }}</span>
                      <span style="margin-left:10px">关注 {{ item.user_info.follow }}</span>
                    </p>
                    <p class="info-three">{{ item.user_info.introduction }}</p>
                  </div>
                </div>
                <div class="item-r" v-if="myId !== item.user_id">
                  <span
                    class="active cursorstyle"
                    v-show="item.is_follow == 0"
                    @click.stop="follow(item, item.user_id, '0')"
                    >关注</span
                  >
                  <span
                    class="noactive cursorstyle"
                    v-show="item.is_follow == 1"
                    @click.stop="canclefollow(item, item.user_id, '0')"
                    >已关注</span
                  >
                </div>
              </div>
            </div>
          </div>

          <!--  关注-->
          <div v-show="activeNav == '1'">
            <div class="item-box" v-for="(item, z) in followList" :key="z">
              <div class="item">
                <div class="item-l cursorstyle"  @click="toCreCenter(item.followed_id)">
                  <div class="item-l-img">
                    <img v-lazy="item.followed_info.avatar" alt="" />
                  </div>
                  <div class="item-l-info">
                    <p class="info-one">{{ item.followed_info.username }}</p>
                    <p class="info-two">
                      <span>粉丝 {{ item.followed_info.fans }}</span>
                      <span style="margin-left:10px">关注 {{ item.followed_info.follow }}</span>
                    </p>
                    <p class="info-three">
                      {{ item.followed_info.introduction }}
                    </p>
                  </div>
                </div>
                <div class="item-r" v-if="myId !== item.followed_id">
                  <span
                    class="active cursorstyle"
                    v-show="item.is_follow == 0"
                    @click.stop="follow(item, item.followed_id, '1')"
                    >关注</span
                  >
                  <span
                    class="noactive cursorstyle"
                    v-show="item.is_follow == 1"
                    @click.stop="canclefollow(item, item.followed_id, '1')"
                    >已关注</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="page" v-show="!emptyShow && last_page > 1">
            <el-pagination
              background
              layout=" pager, next"
              :total="totalNUm"
              :current-page="page"
              @current-change="handleCurrentChange"
              @next-click="handleNext"
            >
            </el-pagination>
          </div>

          <div class="empty" v-show="emptyShow">
            <div class="emptyone">
              <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
            </div>
            <div class="emptytxt">
              <p>暂时没有数据，过会再来看看吧</p>
            </div>
          </div>
        </div>
        <div class="main-one-r">
          <div
            class="r-one "
           
            :style="{
              backgroundImage: 'url(' + user.background_img + ')',
              backgroundSize: 'cover',
            }"
          ></div>
          <div class="r-two">
            <div class="r-two-img cursorstyle"  @click="toCreCenter(user.id)">
              <img :src="user.avatar" alt="" />
            </div>
            <div class="r-two-info">
              <p class="title">{{ user.nickname }}</p>
              <p class="area">{{ user.identity_text }}/ {{ user.area_text }}</p>
              <p class="desc">
                <span>{{ user.introduction }}</span>
              </p>

              <p class="info">
                <span
                  >关注 <span class="myinfotxt">{{ user.follow }}</span></span
                >
                <span style="margin-left: 10px;"
                  >粉丝<span class="myinfotxt"> {{ user.fans }}</span></span
                >
              </p>
              <div style="height:37px;margin-top:10px">
                <div class="followbox" v-if="myId !== user.id">
                  <span
                    class="active cursorstyle"
                    v-show="user.is_follow == 0"
                    @click.stop="follow(user, id, '2')"
                    >关注</span
                  >
                  <span
                    class="noactive cursorstyle"
                    v-show="user.is_follow == 1"
                    @click.stop="canclefollow(user, id, '2')"
                    >已关注</span
                  >
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  creatorFans,
  creatorFollows,
  followCreator,
  CanclefollowCreator,
  creatorinfo,
} from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      nav: [
        { name: "TA的粉丝", index: "0" },
        { name: "TA的关注", index: "1" },
      ],
      activeNav: "0",
      id: "",
      page: 1,
      fansList: [],
      followList: [],
      user: "",
      myId: "",
      totalNUm: null,
      emptyShow: false,
      last_page: 0,
      numList:[]
    };
  },
  watch: {},
  computed: {},
  methods: {
    navChange: function(e) {
      this.activeNav = e.index;
      this.page = 1
      this.getList();
    },
    getList: function() {
      switch (this.activeNav) {
        case "0":
          this.getFans();
          break;
        case "1":
          this.getFollow();
          break;
        default:
          break;
      }
    },
    getFans: function() {
      creatorFans({
        creator: this.id,
        page: this.page,
      }).then((res) => {
        this.fansList = res.data.data.filter(item=>item.user_info);
        this.last_page = res.data.last_page;
        if (res.data.data == 0) {
          this.emptyShow = true;
        } else {
          this.emptyShow = false;
        }
        this.totalNUm = res.data.total;
      });
    },
    getFollow: function() {
      creatorFollows({
        creator: this.id,
        page: this.page,
      }).then((res) => {
        this.followList = res.data.data.filter(item=>item.followed_info);
        this.last_page = res.data.last_page;
        if (res.data.data == 0) {
          this.emptyShow = true;
        } else {
          this.emptyShow = false;
        }
        this.totalNUm = res.data.total;
      });
    },
    follow: function(e, id, type) {
      followCreator({
        creator: id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 1;
          switch (type) {
            case "0":
              e.user_info.fans++;
              // this.numList[0]++
              break;
            case "1":
              e.followed_info.fans++;
              break;
            case "2":
              this.user.fans++;
               this.numList[0]++
               this.getList()
              break;
            default:
              break;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    canclefollow: function(e, id, type) {
      CanclefollowCreator({
        creator: id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 0;

          switch (type) {
            case "0":
              if (e.user_info.fans > 0) {
                e.user_info.fans--;
              }
           
              break;
            case "1":
              if (e.followed_info > 0) {
                e.followed_info.fans--;
              }

              break;
            case "2":
              if (this.user.fans > 0) {
                this.user.fans--;
              }
              if ( this.numList[0] > 0) {
                 this.numList[0]--
              }
              this.getList()
              break;
            default:
              break;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    getMe: function() {
      creatorinfo({
        creator: this.id,
      }).then((res) => {
        this.user = res.data.info;
        this.numList[0] = res.data.info.fans
         this.numList[1] = res.data.info.follow
      });
    },

      handleNext: function(val) {
        this.page = val;
        this.getList()
      },
      handleCurrentChange:function(){
         this.page = val;
         this.getList()
      },
        // 跳转创作者中心
    toCreCenter:function(e){
      // this.$router.push({
      //   path:'/creatorcenter',
      //   query:{
      //     id:e
      //   }
      // })

      const { href } = this.$router.resolve({
        path:'/creatorcenter',
        query: {
          id: e   
        }
      });
      window.open(href, "_blank");
    },
  },
  created() {
    let user = localStorage.getItem("user");
    if (user) {
      let info = JSON.parse(user);
      this.myId = info.id;
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.activeNav = this.$route.query.type;
    this.$nextTick(function() {
      this.getList();
      this.getMe();
    });
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  padding-bottom: 30px;
    min-height: 700px;
  .main {
    width: 1200px;
    margin: 0 auto;
    .titleone {
      display: flex;
      padding: 40px 0 20px 0;
      p {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-right: 61px;
      }
    }
  }
}

.navactive {
  color: #e35111;
}
.nonavactive {
  color: #666666;
}

.main-one {
  display: flex;
  .main-one-l {
    flex: 1;
    margin-right: 20px;
    .item-box {
      display: flex;
      flex-direction: column;
      padding: 25px 30px 25px 22px;
      background-color: #ffffff;
      border-bottom: 1px solid #f1f1f1;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-l {
          display: flex;
        }
        .item-l-img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          img {
            max-width: 100%;
            height: 100%;
          }
        }
        .item-l-info {
          padding-left: 15px;
          .info-one {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1d1d1d;
          }
          .info-two {
            margin-top: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
          .info-three {
            width: 500px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .item-r {
          span {
            display: inline-block;
            width: 52px;
            height: 28px;
            border-radius: 2px;
            line-height: 28px;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
          }
          .active {
            background-color: #e35111;
            color: #ffffff;
          }
          .noactive {
            background-color: #ffffff;
            color: #333333;
            border: 1px solid #767676;
          }
        }
      }
    }
  }
  .main-one-r {
    width: 300px;
    height: 360px;
    background-color: #ffffff;
    .r-one {
      height: 135px;
    }
    .r-two {
      margin-top: -40px;
      .r-two-img {
        display: flex;
        justify-content: center;

        overflow: hidden;
        img {
          width: 86px;
          height: 86px;
          border-radius: 50%;
          background-color: #ffffff;
        }
      }
      .r-two-info {
        p {
          text-align: center;
        }
        .title {
          margin-top: 11px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .area {
          margin-top: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .desc {
          margin-top: 4px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          display: flex;
          justify-content: center;
          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 67%;
            display: inline-block;
          }
        }
        .info {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-top: 10px;
        }
      }
    }
  }
}
.myinfotxt {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.followbox {
  margin: 6px;
  display: flex;
  justify-content: center;
  span {
    display: inline-block;
    width: 52px;
    height: 28px;
    border-radius: 2px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  .active {
    background-color: #e35111;
    color: #ffffff;
  }
  .noactive {
    box-sizing: border-box;
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #767676;
  }
}
.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 16px;
    }
  }
}
</style>
