<template>
  <div class="all">
    <div class="main">
      <div class="main-title">
        发布图片
        <span class="one"
          >请上传图片宽高比例不超过2的图片，最多上传12张照片</span
        >
      </div>
      <div class="main-box">
        <div class="box-l">
          <!-- 图拍呢渲染 -->
          <el-upload
            :action="uploadUrl"
            :data="up_param"
            list-type="picture-card"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            ref="pictureUpload"
            multiple
            :limit="12"
            name="file"
            :file-list="uploadFilesList"
            :class="files.length >= 12 ? 'hide_box' : 'show_box'"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div
              slot="file"
              slot-scope="{ file }"
              style="height: 100%;
    display: flex;
    align-items: center;justify-content: center;"
            >
              <img
                class="el-upload-list__item-thumbnail  imgshow"
                :src="file.url"
                alt=""
              />
              <!-- <label class="el-upload-list__item-status-label" :class=[]>
                 <i class="el-icon-upload-success el-icon-check"></i>
                 </label> -->
              <div v-show="isShow">
                <button
                  style="width: 63px;
                    height: 26px;
                    background: #3C6ECF;
                    border-radius: 10px;"
                >
                  ✔封面
                </button>
              </div>
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview cursorstyle"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="cursorstyle"
                  v-if="!disabled"
                  @click="saveImage(file, $event)"
                  style="position: absolute;
                 bottom: 0;
                 left: 0;"
                >
                  <i class="el-icon-picture" style="font-size: 16px"
                    >设为封面</i
                  >
                </span>
                <!-- <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span> -->
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete cursorstyle"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <div
            style="display: flex;
    justify-content: center;
    margin-top: 10px;"
          >
            <div class="coverimage" v-show="cover !== ''">
              <el-image :src="cover"></el-image>
              <span class="coverimagetxt" v-if="cover !== ''">封面</span>
              <div class="triangle-topleft" v-if="cover !== ''"></div>
            </div>
          </div>
        </div>
        <div class="box-r">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="标题" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="desc">
              <el-input
                type="textarea"
                v-model="ruleForm.desc"
                placeholder="分享图片背后的故事"
                resize="none"
              ></el-input>
            </el-form-item>
            <el-form-item label="分类" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择分类">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, y) in downList"
                  :key="y"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签" prop="selectvalue">
              <div>
                <el-select
                  v-model="ruleForm.selectvalue"
                  class="selectwidth"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择文章标签"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <el-tag
                  type="success"
                  class="tagm cursorstyle"
                  v-for="(item, tagindex) in options"
                  :key="tagindex"
                  @click="tagselect(item)"
                  >{{ item.value }}</el-tag
                >
              </div>
              <div>
                <p
                  style="font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;"
                >
                  最多添加5个标签，每个标签内容不能重复
                </p>
              </div>
            </el-form-item>

            <el-form-item label="活动区域" required>
              <el-col :span="10">
                <el-form-item prop="address">
                  <el-select
                    v-model="ruleForm.address"
                    placeholder="所在区域"
                    class="selectwidth"
                  >
                    <el-option
                      :label="item"
                      :value="item"
                      v-for="(item, x) in addressList"
                      :key="x"
                      >{{ item }}</el-option
                    >
                  </el-select>
                </el-form-item>
              </el-col>
              <div
                style="width: 20px;
    height: 20px;
    float: left;"
              ></div>
              <!-- <el-col class="line" :span="1"></el-col> -->
              <el-col :span="10">
                <el-form-item prop="county">
                  <el-select
                    v-model="ruleForm.county"
                    placeholder="详细地址"
                    class="selectwidth"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.name"
                      v-for="(item, clindex) in countyList"
                      :key="clindex"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>

            <el-form-item class="subbox">
              <!-- <el-button type="primary" @click="submitForm('ruleForm')"
                >立即创建</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button> -->
              <div
                class="subbutton cursorstyle"
                @click="submitForm('ruleForm')"
              >
                发布图片
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import {
  cateFen,
  address,
  ossconfig,
  publicphotos,
  editatlas,
  updateatlas,
  atlasTag
} from "@/api";
// import axios from "axios";
import imgSize from "../../assets/js/imgverification";
import { NewArealist } from "../../assets/js/area";
// import { ListType } from 'wangeditor/dist/menus/list';
export default {
  components: {},
  props: {},
  data() {
    var checkTag = (rule, value, callback) => {
      if (value.length == 0) {
        return callback(new Error("标签不能为空"));
      } else {
        callback();
      }
    };
    return {
      options: [
       
      ],
      // options:['标签一', '标签二', '标签三'],
      cover: "",
      uploadFile: "",
      ruleForm: {
        name: "",
        region: "",
        desc: "",
        selectvalue: [],
        address: "",
        county: "",
      },
      up_param: {
        key: "",
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        success_action_status: "",
        callback: "",
      },
      uploadFilesList: [],
      activename: "",
      isShow: false,
      files: [], // 图集数组
      addressOss: "",
      uploadUrl: "", //上传路径
      downList: [], //活动区域
      addressList: [], //所在区域
      fileList: [], //图片列表
      osslist: "", //oss配置信息
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileListNew: [],
      rules: {
        name: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 240,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        desc: [
          { required: false, message: "请输入描述信息", trigger: "blur" },
          {
            min: 1,
            max: 240,
            message: "长度在 1 到 240 个字符",
            trigger: "blur",
          },
        ],
        selectvalue: [
          { required: true, validator: checkTag, trigger: "change" },
        ],
        address: [
          { required: true, message: "请选择所在区域", trigger: "change" },
        ],
        county: [
          { required: true, message: "请选择详细地址", trigger: "change" },
        ],
      },
      id: "",
      allCounty: [],
      countyList: [],
      addressID:''
    };
  },
  watch: {
    "ruleForm.selectvalue": {
      handler(val) {
        var self = this;
        let list = JSON.parse(JSON.stringify(val));
        if (list.length >= 6) {
          self.$message({
            showClose: true,
            message: "最多上传5个标签",
            type: "warning",
          });
          setTimeout(() => {
            self.ruleForm.selectvalue.splice(5, 1);
          }, 1000);
        }
      },
    },
    "ruleForm.address": {
      handler(val) {
        let addressnewList =  this.allCounty.filter((item) => item.name == val);
         this.addressID = addressnewList[0].id
        this.countyList = addressnewList[0].data || []
        if (this.countyList.length == 0) {
          this.ruleForm.county = this.ruleForm.address;
        } else {
          this.ruleForm.county = "";
        }
        //  console.log(this.countyList)
      },
    },
    'ruleForm.county': {
      handler(val){
        if (this.countyList.length > 0  && val !== '') {
           let id = this.countyList.filter(item=>item.name == val)[0].id
           this.addressID = id
        }
        // console.log(val)
      }
    },
  },
  created() {
    let list = NewArealist()
    console.log(list)
    list.map(item=>{
      let a = {}
        a = {
          id:item.id,
           pid: item.pid,
         name: `${item.name}市`,
         level: item.level,
        }
       item.data.push(a)
    })
    var allA = [
      { "id": '',
        "pid": '',
        "name": "河南",
        "level": '',
        "data": [{
            "id": '',
            "pid": '',
            "name": "河南",
            "level": ''
        }],}
    ]
    this.allCounty =  [...allA,...list]
  },
  computed: {},
  methods: {
     getTag:function(){
      atlasTag().then(res=>{
        var arr = []
          res.data.map(item=>{
            let a = {
              value:item.title,
              label:item.title
            }
            arr.push(a)
          })
        this.options = arr
      })
    },

    // 查找ie
    checkID:function(){
      function findKey(obj, value, compare = (a, b) => a === b) {
            return Object.keys(obj).find((k) => compare(obj[k], value));
       }

      // if (this.countyList.length) {
        
      // } else {
        
      // }
    },
    submitForm(formName) {
        function findKey(obj, value, compare = (a, b) => a === b) {
            return Object.keys(obj).find((k) => compare(obj[k], value));
          }
          // this.addressList.filter(item=>item.)
          let id = findKey(this.addressList, this.ruleForm.address);
          console.log(id)
      if (this.files.length == 0) {
        this.$message({
          showClose: true,
          message: "请上传图片",
          type: "error",
        });
        return;
      }
      if (this.cover == "") {
        this.$message({
          showClose: true,
          message: "请选择封面",
          type: "error",
        });
        return;
      }
      // 检测标签内容是否重复
      if (this.ruleForm.selectvalue.length > 6) {
        this.$message({
          showClose: true,
          message: "标签长度最多为5个",
          type: "error",
        });
        return;
      }

      // 检测重复项
      if (this.isAllEqual(this.ruleForm.selectvalue)) {
        this.$message({
          showClose: true,
          message: "标签内容不能重复",
          type: "error",
        });
        return;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          // function findKey(obj, value, compare = (a, b) => a === b) {
          //   return Object.keys(obj).find((k) => compare(obj[k], value));
          // }
          // // this.addressList.filter(item=>item.)
          // let id = findKey(this.addressList, this.ruleForm.address);
          if (this.id !== "") {
            this.$confirm("请确认作品拍摄地是否正确?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "返回修改",
              type: "warning",
            })
              .then(() => {
                updateatlas({
                  title: this.ruleForm.name,
                  files: this.files,
                  cover: this.cover,
                  area: this.addressID,
                  classification: this.ruleForm.region,
                  label: this.ruleForm.selectvalue.join(","),
                  description: this.ruleForm.desc,
                  atlas: this.id,
                }).then((res) => {
                  if (res.code == 200) {
                    this.$message({
                      showClose: true,
                      message: res.msg,
                      type: "success",
                    });
                    setTimeout(() => {
                      this.$router.push({
                        path: "/myworks",
                        query: {
                          type: "3",
                        },
                      });
                    }, 1000);
                  } else {
                    this.$message({
                      showClose: true,
                      message: res.msg,
                      type: "error",
                    });
                  }
                });
              })
              .catch(() => {});
          } else {
            this.$confirm("请确认作品拍摄地是否正确?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "返回修改",
              type: "warning",
            })
              .then(() => {
                publicphotos({
                  title: this.ruleForm.name,
                  files: this.files,
                  cover: this.cover,
                  area: this.addressID,
                  classification: this.ruleForm.region,
                  label: this.ruleForm.selectvalue.join(","),
                  description: this.ruleForm.desc,
                }).then((res) => {
                  if (res.code == 200) {
                    this.$message({
                      showClose: true,
                      message: res.msg,
                      type: "success",
                    });
                    setTimeout(() => {
                      this.$router.push({
                        path: "/myworks",
                        query: {
                          type: "3",
                        },
                      });
                    }, 1000);
                  } else {
                    this.$message({
                      showClose: true,
                      message: res.msg,
                      type: "error",
                    });
                  }
                });
              })
              .catch(() => {});
          }
        } else {
          // console.log("error submit!!");
          this.$message({
            showClose: true,
            message: "请检查提交的内容",
            type: "error",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    tagselect: function(e) {
      let list = this.ruleForm.selectvalue;
      let a = list.filter((item) => item == e.value);
      if (a.length == 0) {
        this.ruleForm.selectvalue.push(e.value);
      } else {
        this.$message({
          showClose: true,
          message: "标签内容不能重复",
          type: "error",
        });
      }
    },
    handleRemove(file, fileList) {
      if (file && file.status === "success") {
        let list = this.$refs.pictureUpload.uploadFiles;
        let newlist = JSON.parse(JSON.stringify(list));
        let index = list.findIndex((fileItem) => {
          return fileItem.uid === file.uid;
        });
        list.splice(index, 1);
        this.files.splice(index, 1);
        // 判断删除北京图片
        if (newlist[index].response) {
          if (newlist[index].response.file_name == this.cover) {
            this.cover = "";
          }
        } else {
          if (newlist[index].url == this.cover) {
            this.cover = "";
          }
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 设为封面
    saveImage(file, event) {
      // this.isShow = !this.isShow;
      // this.$emit("listenToChildEvent", this.uploadFile.uploadImageList);
      let list = this.$refs.pictureUpload.uploadFiles;
      let index = list.findIndex((fileItem) => {
        return fileItem.uid === file.uid;
      });
      console.log(index);
      this.cover = this.files[index];
    },
    handleDownload(file, fileList) {
      // console.log(this.uploadList)
      // console.log(file);
      // this.file.url = ''
    },

    handleUploadSuccess(response, file, fileList) {
      // console.log(response);
      this.files.push(response.file_name);
      if (this.cover == "") {
        this.cover = response.file_name;
      }
    },
    handleUploadError(err, file, fileList) {
      // console.log(err);
    },
    beforeUpload(file) {
      //判断图片的文件大小

      const fileSizeLt2 = file.size / 1024 / 1024 > 20;
      if (fileSizeLt2) {
        console.log("图片不能大于20MB");
        return;
      }

      var _self = this;
      const isSize = new Promise(function(resolve, reject) {
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
          let w = Math.round(img.width / img.height);
          let valid = w > 0.5 && w <= 2;
          var FileExt = file.name.replace(/.+\./, "");
          let flag =
            ["JPG", "PNG", "JPEG", "BMP"].indexOf(FileExt.toUpperCase()) == -1
              ? false
              : true;
          console.log(flag);
          //   if (["JPG", "PNG", "JPEG",'BMP'].indexOf(FileExt.toUpperCase()) === -1) {
          //     this.$message({
          //       type: "warning",
          //       message: "请检查上传文件的类型",
          //     });
          //     this.$refs.videoup.abort();
          //     return ;
          // }
          if (valid && flag) {
            var getSuffix = function(fileName) {
              var pos = fileName.lastIndexOf(".");
              var suffix = "";
              if (pos != -1) {
                suffix = fileName.substring(pos);
              }
              return suffix;
            };
            var filename = new Date().getTime() + getSuffix(file.name);
            _self.up_param.key = _self.osslist.dir + filename;
            _self.up_param.OSSAccessKeyId = _self.osslist.accessid;
            _self.up_param.policy = _self.osslist.policy;
            _self.up_param.Signature = _self.osslist.signature;
            _self.up_param.callback = _self.osslist.callback;
            _self.up_param.success_action_status = 200;
          }
          if (!flag) {
            _self.$message.error("请检查图片上传的格式");
          }

          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("请上传合适比例的图片");
          return Promise.reject();
        }
      );
      return isSize;
      //  const isSize = imgSize(file);
      // const isLt2M = file.size / 1024 / 1024 <= 20;
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不得超过20MB!");
      //   return false;
      // }

      // console.log(isSize)

      //  校验不通过则不上传文件
    },
    async fnUploadSuccess() {
      // TODO
    },
    // 手动上传
    handSub: function() {
      let list = this.$refs.pictureUpload.uploadFiles;
      console.log(list);
    },

    isAllEqual: function(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
        hash[arr[i]] = true;
      }
      return false;
    },
  },
  mounted() {
    cateFen({
      type: 3,
    }).then((res) => {
      if (res) {
        this.downList = res.data;
      }
    });
    address().then((res) => {
      if (res) {
        res.data[0] = '河南'
        this.addressList = res.data;
      }
    }),
    this.getTag()
      this.$nextTick(() => {
        ossconfig({
          file_type: "file",
          media_type: "atlas",
        }).then((res) => {
          this.osslist = res.data;
          this.uploadUrl = res.data.host;
        });
      });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      editatlas({
        atlas: this.$route.query.id,
      }).then((res) => {
        this.ruleForm.name = res.data.title;
        this.cover = res.data.cover;
        this.ruleForm.selectvalue = res.data.label_text.split(",");
        this.ruleForm.desc = res.data.description;
        // this.ruleForm.address = res.data.area;
        let addarray = res.data.area.split('-')
        this.ruleForm.address = addarray[0]
        setTimeout(()=>{
          this.ruleForm.county = addarray[1]
        },200)
        // console.log(addarray)
        this.ruleForm.region = res.data.classification_id;
        this.files = res.data.images;
        let newList = [];
        for (let i = 0; i < res.data.image_info.length; i++) {
          newList.push({
            name: `${res.data.image_info[i].id}`,
            url: `${res.data.image_info[i].url}`,
          });
        }
        this.uploadFilesList = newList;
        //  this.files =
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
}
.main {
  width: 1200px;
  margin: 0 auto;
  .line {
    height: 50px;
    width: 100%;
  }
  .main-title {
    padding-top: 30px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    .one {
      margin-left: 10px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
  .main-box {
    display: flex;
    margin-top: 29px;
    .box-l {
      padding: 20px;
      background-color: #ffffff;
      height: 548px;
      flex: 1;
      margin-right: 10px;
      // overflow: scroll;
      overflow-y: auto;
    }
    .box-r {
      background-color: #fff;
      height: 558px;
      width: 412px;
      padding: 30px 20px 0 20px;
    }
  }
}
.selectwidth {
  width: 100%;
}
.subbox {
  display: flex;
  justify-content: center;
}
.subbutton {
  background: #e35111;
  border-radius: 2px;
  width: 112px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
// 发布图片
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.tagm {
  margin-right: 10px;
}
.show {
  display: block;
}
.hide {
  display: none !important;
}

.coverimage {
  margin-top: 30px;
  width: 200px;
  // height: 200px;
  // border: 1px solid #e35111;
  // border-radius: 5px;

  // overflow: s;
  position: relative;
  .coverimagetxt {
    position: absolute;
    top: 8px;
    left: 4px;
    // background-color: #e35111;
    color: #ffffff;
    z-index: 9;
    transform: rotate(-45deg);
  }
  .triangle-topleft {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 50px solid #e35111;
    border-right: 50px solid transparent;
  }
}
</style>
<style lang="scss">
.box-l {
  .el-upload-list--picture-card .el-upload-list__item {
    margin: 8px;
    width: 154px;
  }
  .el-upload--picture-card {
    margin: 8px;
    width: 153px;
  }
  .el-image {
    border: 1px solid #e35111;
    border-radius: 5px;
  }
}
.hide_box .el-upload--picture-card {
  display: none;
}
.imgshow {
  max-width: 100% !important;
  max-height: 100% !important;
  width: unset !important;
  height: unset !important;
}
// .show_box  .el-upload--picture-card{
//   display: block;
// }
</style>
