<template>
  <div class="all">
    <div class="line" style="height:20px"></div>
    <div class="main">
      <div class="main-l">
        <div class="box-one">
          <div class="one-l">
            <div class="imagebox">
              <div class="circle"></div>
              <img :src="audioInfo.cover" alt="" class="photo" />
            </div>
          </div>
          <div class="one-r">
            <div class="r-one">
              <p>{{ audioInfo.title }}</p>
              <div class="r-one-like">
                <img
                  src="../../assets/img/munlike.png"
                  alt=""
                  class="sound cursorstyle"
                  @click="like"
                  v-show="is_like == 0"
                />
                <img
                  src="../../assets/img/mlike.png"
                  alt=""
                  class="sound cursorstyle"
                  @click="like"
                  v-show="is_like == 1"
                />
                <p class="likenum cursorstyle" @click="like">
                  {{ audioInfo.like_num }}
                </p>
              </div>
            </div>
            <div class="r-two">
              <img src="../../assets/img/soundmax.png" alt="" />
              <p>
                <span>{{ audioInfo.total_scan }}</span>
              </p>

              <img
                src="../../assets/img/time.png"
                alt=""
                style="margin-left:20px; width:11px;height:11px"
              />
              <p>
                <span>
                  {{ audioInfo.created_at }}
                </span>
                <span
                  style="margin-left:5px"
                  class="cursorstyle"
                  @click="showDia(id, 'audio')"
                  >举报</span
                >
              </p>
            </div>
            <div class="r-three">
              <span
                v-for="(item, tagindex) in audioInfo.label_text"
                :key="tagindex"
                >{{ item }}</span
              >
            </div>
            <div class="r-four">
              <img :src="plPic" alt="" class="pl cursorstyle" @click="play()" />
              <!-- <audio
                src="http://benzhongwenhua.oss-cn-beijing.aliyuncs.com/audio/file/1618458123972.mp3"
                controls="controls"
              ></audio> -->
              <audio
                ref="audio"
                controls="controls"
                id="playaudio"
                @timeupdate.stop="updateProgress()"
                @canplay="getAudioTime($event)"
                @ended="audioEnd()"
              >
                <source :src="mediaUrl" />
              </audio>
              <el-slider
                v-model="current"
                :show-tooltip="false"
                @input="handinput()"
                @change="handchange()"
                id="test"
                >{{ sliderTime }}}
              </el-slider>
              <div class="buttonbox">
                <p class="time">{{ carryTime }} / {{ duration }}</p>
                <el-popover v-model="visible" trigger="hover" width="200">
                  <el-slider
                    v-model="voice"
                    :show-tooltip="false"
                    @input="voicechange"
                    :max="1"
                    :step="0.1"
                    class="voice"
                  ></el-slider>
                  <img
                    slot="reference"
                    src="../../assets/img/sound.png"
                    alt=""
                    class="sound"
                    style="    margin-right: 10px;
           margin-left: 10px;"
                  />
                  <!-- <el-button slot="reference">删除</el-button> -->
                </el-popover>
                <!-- <img
                  src="../../assets/img/munlike.png"
                  alt=""
                  class="sound cursorstyle"
                  @click="like"
                  v-show="is_like == 0"
                />
                <img
                  src="../../assets/img/mlike.png"
                  alt=""
                  class="sound cursorstyle"
                  @click="like"
                  v-show="is_like == 1"
                />
                <p class="likenum cursorstyle" @click="like">{{ audioInfo.like_num }}</p> -->
              </div>
            </div>
            <!-- <div class="r-five">
            </div> -->
          </div>
        </div>
        <div class="box-two">
          <el-divider class="driveLine"></el-divider>
          <div v-if="audioInfo.description !== ''">
            <p style="text-indent:2em;letter-spacing: 2px">
              {{ audioInfo.description }}
            </p>
            <el-divider class="driveLine linestyle"></el-divider>
          </div>
        </div>
        <div class="commbox" style="  padding: 0 20px 20px 20px;;">
          <comment :info="info" v-if="info.id !== ''"></comment>
        </div>
      </div>
      <div class="main-r">
        <div style="background-color: #ffffff; padding: 20px;">
          <div class="r-txt">
            <p>创作人</p>
          </div>
          <div class="box cursorstyle">
            <div class="r-one " @click="toUserDetail()">
              <img :src="user.avatar" alt="" />
            </div>
            <div class="r-two" @click="toUserDetail()">
              <p>{{ user.username }}</p>
              <p>{{ user.identity_text }}</p>
            </div>
            <div v-if="Me">
              <div
                class="r-three cursorstyle"
                v-show="is_follow == 0"
                @click.stop="follow"
              >
                <span>关注</span>
              </div>
              <div
                class="r-four cursorstyle"
                v-show="is_follow == 1"
                @click.stop="canfollow"
              >
                <span>已关注</span>
              </div>
            </div>
          </div>
          <!-- <div class="de">
            <p>{{ user.introduction }}</p>
          </div> -->
        </div>
        <!-- 下方 -->
        <div class="main-r-two" v-if="other.length > 0">
          <div class="one-box">
            <span>TA的其他作品</span>
            <span class="cursorstyle" @click="toUserMore()">更多</span>
          </div>
          <div
            class="two-box creatorother cursorstyle"
            v-for="(item, o) in other"
            :key="o"
            @click="toMe(item.id)"
          >
            <div class="two-box-l meiaImg">
              <!-- <img v-lazy="item.cover" alt="" /> -->
              <div
                v-lazy:background-image="item.cover"
                class="bg photoZoomBg"
              ></div>
              <span class="transtime">{{ item.play_time | transTime }}</span>
            </div>
            <div class="two-box-r">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="info" style="padding-top: 36px;">
                <div class="info-l">
                  <img
                    src="../../assets/img/show.png"
                    alt=""
                    class="iconone"
                    style="    width: 20px;"
                  />
                  <span>{{ item.total_scan }}</span>
                </div>
                <div class="info-r">
                  <img src="../../assets/img/time.png" alt="" class="iconone" />
                  <span>{{ item.created_at | timeNew }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-r-two" v-if="likeList.length > 0">
          <div class="one-box">
            <span>猜你喜欢</span>
            <span class="cursorstyle" @click="toMore()">更多</span>
          </div>
          <div
            class="two-box creatorlike cursorstyle"
            v-for="(item, x) in likeList"
            :key="x"
            @click="toMe(item.id)"
          >
            <div class="two-box-l meiaImg">
              <!-- <img v-lazy="item.cover" alt="" /> -->
              <div
                v-lazy:background-image="item.cover"
                class="bg photoZoomBg"
              ></div>
              <span class="transtime">{{ item.play_time | transTime }}</span>
            </div>
            <div class="two-box-r">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="name" style="padding-top: 18px;">
                <p>{{ item.user_info.username }}</p>
              </div>
              <div class="info" style="padding-top: 2px;">
                <div class="info-l">
                  <img
                    src="../../assets/img/show.png"
                    alt=""
                    class="iconone"
                    style="    width: 20px;"
                  />
                  <span>{{ item.total_scan }}</span>
                </div>
                <div class="info-r">
                  <img src="../../assets/img/time.png" alt="" class="iconone" />
                  <span>{{ item.created_at | timeNew }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line" style="height:20px"></div>

    <report ref="rep"></report>
  </div>
</template>

<script>
import {
  mediadetail,
  CanclefollowCreator,
  followCreator,
  mediaLike,
} from "@/api";
import comment from "../../components/comments";
import report from "../../components/report";
export default {
  components: {
    comment,
    report,
  },
  props: {},
  data() {
    return {
      id: "",
      plPic: require("../../assets/img/playm.png"),
      flag: false,
      duration: 0, //
      current: 0,
      sliderTime: null,
      allTime: "",
      carryTime: "0.00",
      is_follow: null,
      is_like: null,
      other: [],
      likeList: [],
      audioInfo: "",
      mediaUrl: "",
      user: "",
      visible: false,
      voice: 0.5,
      info: {
        id: "",
        type: "audio",
      },
      Me: null,
    };
  },
  watch: {
    $route(to, from) {
      // 判断跳转的路径是否你要监听的路径，我这里为 '/search'
      if (to.path == "/mediadetail") {
        // 通过路径获取新的 keywords 值
        this.id = to.query.id;
        // 通过新的参数值获取新的数据
        // this.getDetail();
        this.$router.go(0);
      }
    },
  },
  computed: {},
  methods: {
    showDia: function() {
      let info = {
        id: e,
        type: type,
      };
      this.$refs.rep.show(info);
    },
    commDia: function(e) {
      e.comment_type = "1";
      e.type = "comment";
      this.$refs.rep.show(e);
    },
    toMore: function() {
      this.$router.push({
        path: "/medialist",
      });
    },
    toUserMore: function() {
      this.$router.push({
        path: "/creatorcenter",
        query: {
          type: "2",
          id: this.audioInfo.user_id,
        },
      });
    },
    toUserDetail: function() {
      this.$router.push({
        path: "/creatorcenter",
        query: {
          id: this.audioInfo.user_id,
        },
      });
    },
    getDetail: function() {
      mediadetail({
        audio: this.id,
      }).then((res) => {
        this.audioInfo = res.data.audio;
        this.other = res.data.others;
        this.likeList = res.data.like;
        this.is_follow = res.data.is_follow;
        this.is_like = res.data.is_like;
        this.mediaUrl = res.data.audio.url;
        this.$refs.audio.src = res.data.audio.url;
        this.user = res.data.audio.user_info;
        // this.user = res.data.audio.user_info;
        // if ( res.data.audio) {
        //     this.user = res.data.audio.user_info;
        // }
        let m = JSON.parse(localStorage.getItem("user"));
        if (m) {
          if (res.data.audio.user_id == m.id) {
            this.Me = false;
          } else {
            this.Me = true;
          }
        } else {
          this.Me = true;
        }

        // setTimeout(() => {
        //   this.getAudioTime();
        // }, 1000);
      });
    },
    play: function() {
      let audio = document.getElementById("playaudio");
      console.log(audio.duration);
      if (this.flag) {
        this.plPic = require("../../assets/img/playm.png");
        // this.audio.play();
        audio.pause();
      } else {
        this.plPic = require("../../assets/img/pause.png");
        // this.audio.pause();
        audio.play();
      }
      this.flag = !this.flag;
    },
    voicechange: function(e) {
      // console.log(e)
      let audio = document.getElementById("playaudio");
      audio.volume = e;
    },
    // 获取时长
    getAudioTime: function() {
      let audio = document.getElementById("playaudio");
      // console.log(audio.volume);
      audio.volume = 0.5;
      this.allTime = audio.duration;
      let a = this.transTime(audio.duration);
      this.duration = a;
      //  console.log(audio.duration)
      //  this.duration = this.transTime(audio.duration)
    },
    // 将秒数转化成(分:秒)格式
    transTime(time) {
      let duration = parseInt(time);
      let minute = parseInt(duration / 60)
        .toString()
        .padStart(2, "0");
      let sec = (duration % 60).toString().padStart(2, "0");
      return `${minute}:${sec}`;
    },
    // 进度条播放的事件
    updateProgress() {
      let audio = document.getElementById("playaudio");
      let value = Math.round(
        (Math.floor(audio.currentTime) / Math.floor(audio.duration)) * 100,
        0
      );
      // console.log(value)
      this.current = value;
      this.carryTime = this.transTime(audio.currentTime);
      // this.audioArr[index].currentTime = this.transTime(audio.currentTime)
      // let progressTag = this.audioNodes[index].getElementsByClassName('pgs-play')[0]
      // progressTag.style.left = `${value}%`
      //
    },
    audioEnd: function() {
      this.flag = !this.flag;
      this.plPic = require("../../assets/img/playm.png");
    },
    // 数据改变时触发
    handinput: function(e) {
      let list = document.getElementById("test").lastChild.firstChild.style
        .width;
      //  console.log(list)
      this.sliderTime = list;
      // this.current = this.toPint(list)
    },
    like: function() {
      mediaLike({
        audio: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_like = this.is_like == 0 ? 1 : 0;
          if (this.is_like == 0 && this.audioInfo.like_num !== 0) {
            this.audioInfo.like_num--;
          } else {
            this.audioInfo.like_num++;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 值改变时触发
    handchange: function(e) {
      let num = this.toPint(this.sliderTime);
      this.current = parseInt(num);
      // console.log(typeof num)
      // console.log(num * this.allTime)
      let audio = document.getElementById("playaudio");
      // console.log(num * this.allTime)

      audio.currentTime = num * this.allTime;

      // consoel.log()
      // console.log('songkai shubiao ')
      // console.log(e)
      // console.log(this.changeTime * this.duration)
    },
    // 百分比转换数字
    toPint: function(e) {
      var str = e.replace("%", "");
      str = str / 100;
      return str;
    },
    // 关注
    follow: function() {
      followCreator({
        creator: this.audioInfo.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 1;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 取消关注
    canfollow: function() {
      CanclefollowCreator({
        creator: this.audioInfo.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 0;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    toMe: function(e) {
      this.$router.push({
        path: "/mediadetail",
        query: {
          id: e,
        },
      });
    },
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.info.id = this.$route.query.id;
    this.$nextTick(function() {
      this.getDetail();
    });
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  .main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .main-l {
      flex: 1;
      background-color: #fff;
      margin-right: 20px;
    }
    .main-r {
      width: 400px;
      // background-color: #fff;
    }
  }
}

.main-l {
  .box-one {
    display: flex;
    padding: 20px;
    .one-l {
      .imagebox {
        position: relative;
        .photo {
          width: 182px;
          height: 182px;
          border-radius: 4px;
          position: absolute;
          top: 0;
        }
        .circle {
          width: 182px;
          height: 182px;
          background-color: #000000;
          border-radius: 50%;
          margin-left: 20px;
        }
      }
    }
    .one-r {
      margin-left: 37px;
      // padding: 0px 20px 0px 67px;
      width: 100%;
      .r-one {
        display: flex;
      }
      .r-one p {
        width: 80%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .r-one-like {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        img {
          width: 40px;
        }
      }
      .r-two {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        p {
          margin-left: 10px;
        }
      }
      .r-two img {
        width: 12px;
      }
      .r-two p span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .r-three span {
        margin-top: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        background: #f9f9f9;
        border-radius: 2px;
        padding: 4px 10px;
        margin-right: 10px;
      }
      .r-four {
        margin-top: 35px;
        display: flex;
        align-items: center;
        .pl {
          width: 32px;
          height: 32px;
        }
        #playaudio {
          display: none;
        }
        #test {
          flex: 1;
          padding: 0 20px;
        }
        .time {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .sound {
          width: 19px;
        }

        .buttonbox {
          display: flex;
          align-items: center;
          .likenum {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
  .box-two {
    padding: 20px 20px 0 20px;
    .linestyle {
      margin: 24px 24px 0 24px;
    }
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}

.main-r {
  width: 400px;
  margin-left: 20px;
  // padding: 20px;
  .r-txt {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .box {
    display: flex;
    margin-top: 20px;
    align-items: center;
  }
  .r-one {
    background-color: #ffffff;
    img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
  .r-two {
    margin-left: 13px;
    flex: 1;
  }
  .r-two p:nth-child(1) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .r-two p:nth-child(2) {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .r-three {
    color: #ffffff;
    span {
      padding: 7px 18px;
      background: #e35111;
      border-radius: 2px;
    }
  }
  .r-four {
    //   color: #f7f7f7;
    span {
      padding: 7px 18px;
      background: #ffffff;
      color: #333333;
      border-radius: 2px;
      border: 1px solid #dedede;
    }
  }
  .de {
    p {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
}
.main-r-two {
  margin-top: 20px;
  background-color: #ffffff;
  padding-bottom: 10px;
  .bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .one-box {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: baseline;
  }
  .one-box span:nth-child(1) {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .one-box span:nth-child(2) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .two-box {
    padding: 0 20px 0px 20px;
    .box-item {
      display: flex;
      margin-bottom: 10px;
      .item-l {
        img {
          width: 130px;
          height: 90px;
        }
      }
      .item-r {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .info-box {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
      .item-r p:nth-child(1) {
        flex: 1;
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding-top: 8px;
      }
      .item-r p:nth-child(2) {
        // flex: 1;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
  }
}

.likeactive {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
.unlikeactive {
  background-color: #e35111;
  border: 1px solid #e35111;
  color: #ffffff;
}
</style>

<style lang="scss">
.r-four {
  .el-slider__bar {
    background-color: #e35111;
  }
  .el-slider__button {
    border: 2px solid #e35111;
  }
}
.voice {
  .el-slider__bar {
    background-color: #e35111;
  }
  .el-slider__button {
    border: 2px solid #e35111;
  }
}
</style>
