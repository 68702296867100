<template>
    <div class="all" :style="{minHeight:allHeight+'px',}">
        <div class="main">
            <div class="title">
                <p>{{info.title}}</p>
            </div>
            <div class="time">
                <p>{{info.created_at}}</p>
            </div>
            <div class="co">
                <div v-html="info.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { noticeDetail } from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
        allHeight:0,
        id:'',
        info:''
    };
  },
  watch: {},
  computed: {},
  methods: {
      getD:function(){
          noticeDetail({
              notice:this.id
          }).then(res=>{
              this.info = res.data
          })
      }
  },
  created() {
       this.id = this.$route.query.id || '0'
  },
  mounted() {
        let height = document.documentElement.clientHeight - 290 
     this.allHeight = height < 720 ? 720:height

     this.getD()
  },
};
</script>
<style lang="scss" scoped>
    .all{
        background-color: #f1f1f1;
        padding-top: 20px;
    }
    .main{
        width: 1200px;
        margin:  0 auto;
         background-color: #fff;
         .title{
             padding: 20px;
             font-size: 19px;
             border-bottom: 1px solid #f1f1f1;
         }
         .time{
             padding: 10px 20px;
         }
         .co{
             padding: 20px;
         }
    }
</style>
