<template>
  <div class="all">
     <!-- <ItemTypeList :info="info"></ItemTypeList> -->
    <div class="main">
      <el-row >
        <el-col :span="24" v-for="(item, x) in list" :key="x" class="item-box ">
          <!-- 左边显示的内容 -->
          <div class="col-l">
            <div class="grid-content item  " >
              <div class="item-l cursorstyle" @click="toMe(item.id)">
                <img v-lazy="item.avatar" alt="" />
                 <div class="two" v-if="myId !== item.id">
                  <span
                    @click.stop="follow(item)"
                    v-if="item.is_follow == 0"
                    class="two-one cursorstyle"
                    >关注</span
                  >
                  <span
                    @click.stop="canclefollow(item)"
                    v-if="item.is_follow == 1"
                    class="two-two cursorstyle"
                    >已关注</span
                  >
                </div>
              </div>
              <div class="item-r">
                <div class="one">
                  <p class="txt-one">{{ item.username }}</p>
                  <p class="txt-two">
                    <img
                      src="../../assets/img/hot.png"
                      alt=""
                      class="hoticon"
                    />
                    <span class="hottxt">{{ item.real_pop | numFomart}}</span>
                      <!-- <span class="transtime">{{item.play_time | transTime}}</span> -->
                  </p>
                  <p class="txt-three " >
                    <span @click.stop="toFans(item,'0')" class="cursorstyle" style="    font-weight: normal;
    color: #999999;"> 粉丝</span>
                    <span style="margin-right:5px" class="cursorstyle" @click.stop="toFans(item,'0')">{{ item.fans }}</span> 
                    <span  @click.stop="toFans(item,'1')" class="cursorstyle" style="font-weight: normal;
                      color: #999999;">  关注 </span>
                    <span class="cursorstyle" @click.stop="toFans(item,'1')">{{ item.follow }}</span>
                  </p>
                  <p class="txt-four">
                    {{ item.introduction || "这个人很懒，什么都没写" }}
                  </p>
                </div>
                <!-- <div class="two">
                  <span
                    @click.stop="follow(item)"
                    v-if="item.is_follow == 0"
                    class="two-one cursorstyle"
                    >关注</span
                  >
                  <span
                    @click.stop="canclefollow(item)"
                    v-if="item.is_follow == 1"
                    class="two-two cursorstyle"
                    >已关注</span
                  >
                </div> -->
              </div>
            </div>
          </div>
          <!-- 右边显示内容 -->
          <div class="col-r">
            <div class="r-item">
              <div
                class="r-item-box photoZoom cursorstyle"
                @click="toDetail(item1.media_id,item1.type)"
                v-for="(item1, vi) in item.medias"
                :key="vi"
              >
                 <div v-lazy:background-image="item1.cover +'?x-oss-process=image/resize,w_400'" class="bg photoZoomBg" ></div>
                <span class="r-item-title">{{ item1.title }}</span>
                <div class="typeimg">
                     <img src="../../assets/img/henantab/tab4.png" alt="" srcset="" v-if="item1.type == 2">
                     <img src="../../assets/img/henantab/tab2.png" alt="" srcset="" v-if="item1.type == 4">
                          <img src="../../assets/img/henantab/tab6.png" alt="" srcset="" v-if="item1.type == 3">
                           <img src="../../assets/img/henantab/tab8.png" alt="" srcset="" v-if="item1.type == 1">
                </div>
              </div>

              <!-- 无数据的时候提示 -->
              <div class="nodata" v-if="item.medias.length == 0">
                <div class="n-empty">
                    <img src="../../assets/img/empty.png" alt="">
                  <p>该创作者还没有发布作品哦！</p>
                </div>
                <div></div>
                      <div class="butt" v-if="myId !== item.id">
                      <el-button type="danger" plain size="small" class="show" @click="urgeC(item.id)">催更一下</el-button>
                  </div>
              </div>
            </div>
          </div>
        
        </el-col>
        
      </el-row>

      <div class="page" v-show="!emptyShow && last_page > 1 ">
        <el-pagination
          background
          layout=" pager, next"
          :total="totalNUm"
          :current-page="page"
          @current-change="handleCurrentChange"
          @next-click="handleNext"
        >
        </el-pagination>
      </div>

       <div class="empty" v-show="emptyShow">
        <div class="emptyone">
          <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
        </div>
        <div class="emptytxt">
          <p>暂时没有数据，过会再来看看吧</p>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
import { creatorList, followCreator, CanclefollowCreator,urge } from "@/api";
export default {
  props: {},
  data() {
    return {
      list: [],
      page: 1,
      totalNUm: null,
      emptyShow:false,
      last_page:0,
      myId:0,
    };
  },
  watch: {},
  computed: {},
  methods: {
     urgeC:function(e){
      urge({
        creator:e
      }).then(res=>{
        if(res.code == 200){
           this.$message({
                      showClose: true,
                      message: res.msg,
                      type: "success",
                   });
        }
      })
    },
    getList: function() {
      creatorList({
        page: this.page,
        per: 9,
      }).then((res) => {
        this.list = res.data.data;
        this.last_page = res.data.last_page
         if (res.data.data == 0) {
          this.emptyShow = true;
        } else {
          this.emptyShow = false;
        }
        // this.list = [
        //   {
        //     avatar: "",
        //     username: "",
        //     real_pop: "",
        //     fans: "",
        //     follow: "",
        //     videos: [{ title: "https://dummyimage.com/500x500/ff6a00/fff" ,cover:'https://cs.xinpianchang.com/uploadfile/article/2020/02/04/5e394005d6a8f.jpeg@468w.jpg'},
        //     { title: "https://dummyimage.com/500x500/ff6a00/fff" ,cover:'https://cs.xinpianchang.com/uploadfile/article/2020/02/04/5e394005d6a8f.jpeg@468w.jpg'},
        //     { title: "https://dummyimage.com/500x500/ff6a00/fff" ,cover:'https://cs.xinpianchang.com/uploadfile/article/2020/02/04/5e394005d6a8f.jpeg@468w.jpg'}],
        //   },
        // ];
        this.totalNUm = res.data.total;
      });
    },
    follow: function(e) {
      followCreator({
        creator: e.id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 1;
          e.fans++;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    canclefollow: function(e) {
      CanclefollowCreator({
        creator: e.id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 0;
          if (e.fans > 0) {
            e.fans--;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    handleCurrentChange: function(val) {
      this.page = val;
      this.getList();
    },
    handleNext: function(val) {
      this.page = val;
      this.getList();
    },
    toMe: function(e) {
      console.log(e);
      this.$router.push({
        path: "/creatorcenter",
        query: {
          id: e,
        },
      });
    },

    // tom
    toDetail: function(e,type) {
       switch (type) {
        case 2:
      
           let fourOne = this.$router.resolve({
            path: "/videodetail",
            query: {
              id: e,
            },
          });
          window.open(fourOne.href, "_blank");
          break;
        case 4:
            let fourTwo = this.$router.resolve({
            path: "/articledetail",
            query: {
              id: e ,
            },
          });
          window.open(fourTwo.href, "_blank");
          break;
        case 3:
       
             let fourThree = this.$router.resolve({
            path: "/photodetail",
            query: {
              id: e,
            },
          });
          window.open(fourThree.href, "_blank");
          break;
        case 1:
      
             let fourF = this.$router.resolve({
            path: "/mediadetail",
            query: {
              id: e ,
            },
          });
          window.open(fourF.href, "_blank");
          break;

        default:
          break;
      }
    },

    toFans:function(e,type){
      this.$router.push({
        path:'/fans',
        query:{
          id:e.id,
          type:type
        }
      })
    }
  },
  created() {
      let user = localStorage.getItem("user");
    if (user) {
      let info = JSON.parse(user);
      this.myId = info.id;
    }
  },
  mounted() {


    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  padding-top: 62px;
  padding-bottom: 30px;
  .main {
    width: 1200px;
    margin: 0 auto;
  }
}

.line{
  width: 100%;
  height: 1px;
  border: 1px solid #f1f1f1;
}

.main {
  .item-box {
    height: 200px;
    // margin-bottom: 30px;
    overflow: hidden;
    display: flex;
     border-bottom: 1px solid #f1f1f1;
  }
  .item {
    display: flex;
    background-color: #fff;
    padding: 36px 29px;
    margin-bottom: 10px;
    height: 100%;
    .item-l {
      img {
        width: 78px;
        height: 78px;
        border-radius: 50%;
      }
       .two {
         text-align: center;
         margin-top: 15px;
        span {
          display: inline-block;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #fefefe;
          border-radius: 2px;
          padding: 8px 17px;
        }
        .two-one {
          background: #e35111;
        }
        .two-two {
          background-color: #dedede;
        }
      }
    }
    .item-r {
      padding-left: 25px;
      width: 92px;
      .two {
        margin-top: 14px;
      }
      .one {
        .txt-one {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .txt-two {
          margin-top: 7px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          display: flex;
          align-items: center;
          .hoticon {
            width: 18px;
          }
          .hottxt {
            margin-left: 15px;
          }
        }
        .txt-three {
          margin-top: 13px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          span {
            font-size: 10px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
        .txt-four {
          // height: 13px;
          margin-top: 11px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
            overflow : hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
        }
      }
      // .two {
      //   span {
      //     display: inline-block;
      //     font-size: 12px;
      //     font-family: Microsoft YaHei;
      //     font-weight: 400;
      //     color: #fefefe;
      //     border-radius: 2px;
      //     padding: 8px 17px;
      //   }
      //   .two-one {
      //     background: #e35111;
      //   }
      //   .two-two {
      //     background-color: #dedede;
      //   }
      // }
    }
  }
  .col-r {
    background-color: #fff;
    flex: 1;
    // padding-left: 154px;
    padding-right: 20px;
    .r-item {
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      padding-left: 48px;
      height: 100%;
      .r-item-box { 
        position: relative;
        width: 272px;
        // flex: 1;
        padding: 0 10px;
        // padding: 30px 5px;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 153px;
.bg{
       background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    }

    img {
      width: 100%;
      //  height: 153px;
    }
        // img {
        //   width: 100%;
        //   height: 153px;
        // }
        .r-item-title {
          position: absolute;
          bottom: 12px;
          left: 25px;
          z-index: 99;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 60%;
        }
        .typeimg{
          position: absolute;
          bottom: 8px;
          right: 17px;
        }

      }
       // 无数据提示
        .nodata{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          color: #808080;
          position: relative;
          padding-top: 100px;
          .n-empty{
            text-align: center;
            // height: 100%;
            img{
              width: 60px;
              
            }
            p{
              margin-top: 10px;
            }
          }
          .butt{
            // position: absolute;
            // top:50%;
            // right:50%;
            display: none;
            text-align: center;
            margin-top: 10px;
          }
          &:hover > .butt{
            display: block;
          }
        }
    }
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 20px;
    }
  }
}
</style>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
