<template>
   <div class="all" :style="{minHeight:allHeight+'px',}">
     <div class="main">
       <div class="title">
        <p class="info">
          <span>通知中心</span>
          <span class="setRead cursorstyle" @click="setAllRead">一键已读所有通知</span>
        </p>
      </div>
      <div class="main-one">
        <div class="item cursorstyle" v-for="(item,x) in noticelist" :key='x' @click="toDetail(item.id)">
          <img src="../../assets/img/notice.png" alt="" class="noticeimg">
          <p class="name" >
            <span :class="item.is_read == 1 ?'read':''">{{item.title}} </span>
            <span class="time">{{item.created_at | timeNew}}</span>
          </p>
        </div>
      </div>
        <div class="nav notice">
             <el-pagination
          background
          layout=" pager, next"
          :total="noticeTotal"
          :current-page="noticeCurrPage"
          :page-count="noticeLastpage"
          :page-size="9"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
        </div>
     </div>
      
   </div>
</template>

<script>
import { noticeList,noticeAllRead } from "@/api";
export default {
  components:{},
  props:{},
  data(){
    return {
      allHeight:0,
      noticelist:[],
      noticeCurrPage:1,
      noticeLastpage:0,
      noticeTotal:null
    }
  },
  watch:{},
  computed:{},
  methods:{
     getNoticeList:function(){
     
      // box.addEventListener('scroll',this.handSceoll,true);
       noticeList({
         page:this.noticeCurrPage,
         per:9
       }).then(res=>{
          this.noticelist = res.data.data
          this.noticeCurrPage = res.data.current_page
          this.noticeLastpage = res.data.last_page
          this.noticeTotal = res.data.total
       })
    },
    handleCurrentChange:function(e){
      this.noticeCurrPage = e
      this.getNoticeList()
    },
    toDetail:function(e){
       let fourF = this.$router.resolve({
            path: "/noticeinfo",
            query:{
              id:e
            }
          });
          window.open(fourF.href, "_blank");
    },
    setAllRead:function(){
       noticeAllRead().then(res=>{
        if (res.code == 200) {
              this.$message({
              message: res.msg,
              type: 'success'
            });
        }
        this.noticeCurrPage = 1
        this.getNoticeList()
      })
    }
  },
  created(){
     let height = document.documentElement.clientHeight - 290 
     this.allHeight = height < 720 ? 720:height
  },
  mounted(){
    this.getNoticeList()
  }
}
</script>
<style lang="scss" scoped>
  .all{
    background-color: #f1f1f1;
  }
    .main{
      width: 960px;
      margin: 0 auto;
       padding-top: 20px;
    }
  .title{
    padding: 20px ;
    font-weight: bold;
    font-size: 20px;
    background-color: #fff;
    .info{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .setRead{
      font-size: 15px;
      color: #a9a9a9;
      font-weight: normal;
      &:hover{
        color: #e35111;
      }
    }
  }
  .nav{
    text-align: center;
    padding: 30px;
  }
  .main-one{
     background-color: #fff;
     margin-top: 20px;
    .item{
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding: 20px;
      .noticeimg{
        width: 40px;
        max-width: 100%;
      }
      .name{
        margin-left: 20px;
        font-size: 17px;
        display: flex;
        justify-content: space-between;
            width: 100%;
        .time{
             font-size: 15px;
             color: #a9a9a9;
        }
      }
    }
  }

  .read{
    color: #808080;
    font-weight: normal;
  }
</style>
<style lang="scss">
.notice .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
.notice .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #e35111;
}
.notice .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #ffffff;
}
</style>