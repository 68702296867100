<template>
  <div class="all">
    <div class="main">
      <div class="main-one">
        <p class="txt-one">{{ content.title }}</p>
        <!-- <p class="txt-two">作者：{{ content.name }}</p> -->
      </div>
      <div class="main-two">
          <!-- <el-divider class="driveLine"></el-divider> -->
          <div  class="conbox" v-html="content.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { agreeContent } from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      type: "",
      content: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    //   获取内容
    getContent: function() {
      agreeContent({
        agreement: this.type,
      }).then((res) => {
        this.content =  this.type == 'user' ? res.data.user : res.data.auth;
      });
    },
  },
  created() {},
  mounted() {
    this.type = this.$route.query.type;
    this.$nextTick(function() {
      this.getContent();
    });
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color:rgb(246, 246, 246);
  padding: 30px 0;
  // height: 100%;
  // min-height: 100%;
  .main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    // background-color: #ffffff;
    // padding: 30px ;
    min-height: calc(100vh - 60px)
  }
}

.main-one {
    // background-color: #ffffff;
    // margin-top: 0px;
  .txt-one {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #484d57;
    text-align: center;
  }

  .txt-two {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
     margin-top: 20px;
  }


}

.conbox{
  padding: 10px 30px;
}
.main-two{
    //  margin-top: 20px;
    padding-top: 30px;
}
</style>
