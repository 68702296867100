<template>
  <div class="all">
    <div class="line" style="height:20px"></div>
    <div class="main">
      <div class="main-l">
        <div class="title">
          <p>{{ article.title }}</p>
        </div>
        <div class="time">
          <!-- <p v-if="article.classification_info">
            {{ article.classification_info.name }}
          </p> -->
            <img src="../../assets/img/time.png" alt="" style=" width:11px;height:11px">
            <p>{{article.created_at}}</p>
            <p style="margin-left:10px" class="cursorstyle" @click="showDia(id,'article')">举报</p>
        </div>
        <div class="content">
          <!-- <p>{{ article.content }}</p> -->
          <div v-html="article.content" class="con"></div>
        </div>
        <div class="de">
          <p>
            本站文字和内容取代了作者原创或翻译，C位河南不会对原创文章作任何编辑。转载前请注意通知本站并指定内容来源
            （C位河南）附以记者链接（https://www.cvhenan.com/articledetail?id={{id}}）。本站内容欢迎分享，但拒绝有商业目的的转载！
          </p>
        </div>
        <div class="like">
          <div
            class="likebutton cursorstyle"
            :class="is_like == 1 ? 'likeactive' : 'nolikeactive'"
            @click="changelike"
          >
            <img src="../../assets/img/likef.png" alt="" class="likeimg" />
            <span>喜欢 | </span>
            <span>{{ article.like_num }}</span>
          </div>
        </div>
        <div class="commbox">
          <comment :info="info" v-if="info.id !== ''"></comment>
        </div>
      </div>
      <div class="main-r">
        <div class="main-r-one cursorstyle" @click="toUserDetail()">
          <div class="one" v-if="article.user_info" >
            <img :src="article.user_info.avatar" alt="" class="avatar" />
          </div>
          <div class="two" v-if="article.user_info">
            <p>{{ article.user_info.username }}</p>
            <p>{{ article.user_info.identity_text }}</p>
            <p>{{ article.user_info.introduction }}</p>
          </div>
          <!-- 未关注 -->
          <div v-if="Me">
            <div
              class="three cursorstyle"
              v-if="is_follow == 0"
              @click.stop="follow()"
            >
              <span>+ 关注</span>
            </div>
            <!-- 已经关注 -->
            <div
              class="four cursorstyle"
              v-if="is_follow == 1"
              @click.stop="cancleFollow()"
            >
              <span> 已关注</span>
            </div>
          </div>
        </div>
        <div class="main-r-two" v-if="other.length > 0">
          <div class="one-box">
            <span>TA的其他作品</span>
            <span class="cursorstyle" @click="toUserMore()">更多</span>
          </div>
          <div class="two-box creatorother cursorstyle" v-for="(item, o) in other"
              :key="o"
              @click="toMe(item.id)">
              <div class="two-box-l articleImg">
                <!-- <img v-lazy="item.cover" alt=""> -->
                   <div v-lazy:background-image="item.cover" class="bg photoZoomBg" ></div>
              </div>
              <div class="two-box-r">
                <div class="title"> <p>{{item.title}}</p></div>
                 <div class="info" style="padding-top: 60px;">
                    <div class="info-l">
                       <img src="../../assets/img/show.png" alt="" class="iconone" style="    width: 20px;"/>
                       <span>{{ item.total_scan }}</span>
                    </div>
                    <div class="info-r">
                      <img src="../../assets/img/time.png" alt="" class="iconone" />
                       <span>{{ item.created_at | timeNew }}</span>
                    </div>
                 </div>
              </div>
          </div>
        </div>
        <!-- 猜你喜欢 -->
        <div class="main-r-two" v-if="like.length > 0">
          <div class="one-box">
            <span>猜你喜欢</span>
            <span class="cursorstyle"  @click="toMore()">更多</span>
          </div>
             <div class="two-box creatorlike cursorstyle"  v-for="(item, x) in like"
              :key="x"
              @click="toMe(item.id)">
              <div class="two-box-l articleImg">
                <!-- <img v-lazy="item.cover" alt=""> -->
                   <div v-lazy:background-image="item.cover" class="bg photoZoomBg" ></div>
              </div>
              <div class="two-box-r">
                <div class="title"> <p>{{item.title}}</p></div>
                <div class="name" style="padding-top: 40px;"><p>{{item.user_info.username}}</p></div>
                 <div class="info">
                    <div class="info-l">
                       <img src="../../assets/img/show.png" alt="" class="iconone" style="    width: 20px;"/>
                       <span>{{ item.total_scan }}</span>
                    </div>
                    <div class="info-r">
                      <img src="../../assets/img/time.png" alt="" class="iconone" />
                       <span>{{ item.created_at | timeNew }}</span>
                    </div>
                 </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line" style="height:50px"></div>
      <report ref="rep"></report>
  </div>
</template>

<script>
import {
  articledetail,
  followCreator,
  CanclefollowCreator,
  articleLike,
} from "@/api";
import comment from "../../components/comments";
import report from "../../components/report";
export default {
  components: {
    comment,
    report
  },
  props: {},
  data() {
    return {
      id: "",
      article: "",
      other: [],
      like: [],
      is_follow: null,
      is_like: null,
      info: {
        id: "",
        type: "article",
      },
      Me: null,
    };
  },
  watch: {
    $route(to, from) {
      // 判断跳转的路径是否你要监听的路径，我这里为 '/search'
      if (to.path == "/articledetail") {
        // 通过路径获取新的 keywords 值
        this.id = to.query.id;
        // 通过新的参数值获取新的数据
        // this.getDetail();
        this.$router.go(0);
      }
    },
  },
  computed: {},
  methods: {
     showDia:function(e,type){
       let info={
          id:e,
          type:type
        }
      this.$refs.rep.show(info)
    },
    commDia:function(e){
       e.comment_type = '4'
       e.type="comment"
       this.$refs.rep.show(e)
    },
      toMore:function(){
      this.$router.push({
        path:'/videolist'
      })
    },
    toUserMore:function(){
      this.$router.push({
        path:'/creatorcenter',
        query:{
          type:'1',
           id:this.article.user_id
        }
      })
    },
     toUserDetail:function(){
      this.$router.push({
        path:'/creatorcenter',
        query:{
          id:this.article.user_id
        }
      })
    },
    getDetail: function() {
      articledetail({
        article: this.id,
      }).then((res) => {
        this.article = res.data.article;
        this.other = res.data.others;
        this.like = res.data.like;
        this.is_follow = res.data.is_follow;
        this.is_like = res.data.is_like;
           let m = JSON.parse(localStorage.getItem("user"))
           if (m) {
              if (res.data.article.user_id == m.id) {
            this.Me = false
          }else{
             this.Me = true
          }
           }else{
               this.Me = true
           }
         
      });
    },
    // 关注
    follow: function() {
      followCreator({
        creator: this.article.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 1;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 去洗哦啊关注
    cancleFollow: function() {
      CanclefollowCreator({
        creator: this.article.user_id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_follow = 0;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },

    // 点击喜欢
    changelike: function() {
      articleLike({
        article: this.article.id,
      }).then((res) => {
        if (res.code == 200) {
          this.is_like = this.is_like == 1 ? 0 : 1;
          if (this.is_like == 0 && this.article.like_num !== 0) {
            this.article.like_num--;
          } else {
            this.article.like_num++;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    toMe: function(e) {
      this.$router.push({
        path: "/articledetail",
        query: {
          id: e,
        },
      });
    },
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.info.id = this.$route.query.id;
 
    this.$nextTick(function() {
      this.getDetail();
    });
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  .main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .main-l {
      flex: 1;
      background-color: #ffffff;
      padding: 50px 58px 60px 59px;
      margin-right: 20px;
      .title {
        p {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
      }
      .time {
        // margin-left: 29px;
        margin-top: 22px;
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-left: 5px;
        }
      }
      .content {
        margin-top: 36px;
        .con{
           font-size: 16px;
        }
      }
      .de {
        margin-top: 60px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
      .like {
        margin-top: 38px;
        display: flex;
        justify-content: center;
        .likebutton {
          display: inline-block;
          align-items: center;
          //   background: #e35111;
          border-radius: 2px;
          padding: 11px 15px;
        }
        .likeimg {
          width: 13px;
        }
      }
    }
    .main-r {
      width: 400px;
      display: flex;
      flex-direction: column;
      .main-r-one {
        background-color: #ffffff;
        padding: 31px 42px 40px 49px;
        .one {
          text-align: center;
          .avatar {
            width: 72px;
            height: 72px;
            border-radius: 50%;
          }
        }
        .two p:nth-child(1) {
          margin-top: 33px;
          text-align: center;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .two p:nth-child(2) {
          margin-top: 10px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .two p:nth-child(3) {
          margin-top: 10px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .three {
          text-align: center;
          margin-top: 26px;
          span {
            padding: 10px 23px;
            background: #e35111;
            border-radius: 2px;
            color: #ffffff;
          }
        }
        .four {
          text-align: center;
          margin-top: 26px;
          span {
            padding:  10px 23px;
        background: #ffffff;
        color: #333333;
        border-radius: 2px;
        border: 1px solid #dedede;
          }
        }
      }
      .main-r-two {
        margin-top: 20px;
        background-color: #ffffff;
            .bg{
     background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
        .one-box {
          display: flex;
          justify-content: space-between;
          padding: 20px;
          align-items: baseline;
        }
        .one-box span:nth-child(1) {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .one-box span:nth-child(2) {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .two-box {
          padding: 0 20px 20px 20px;
          .box-item {
            display: flex;
            margin-bottom: 10px;
            .item-l {
              img {
                width: 130px;
                height: 90px;
              }
            }
          .item-r {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .info-box{
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
      .item-r p:nth-child(1) {
        flex: 1;
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding-top: 8px;
      }
      .item-r p:nth-child(2) {
        // flex: 1;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
            margin-left: 5px;
      }
          }
        }
      }
    }
  }
}
.likeactive {
  background-color: #f7f7f7;
  color: #dedede;
}
.nolikeactive {
  background-color: #e35111;
  color: #ffffff;
}
</style>
