<template>
  <div class="all">
    <div class="main">
      <div class="main-l">
        <div class="l-one">
          <img v-lazy="myInfo.avatar" alt="" class="avatar" />
          <p class="txt-one">{{ myInfo.nickname }}</p>
          <p class="txt-two">
            粉丝 <span>{{ myInfo.fans }}</span> | 关注
            <span>{{ myInfo.follow }}</span>
          </p>
        </div>
        <div class="l-two">
          <p
            class="txt-two cursorstyle"
            v-for="(item, leftindex) in leftNav"
            :key="leftindex"
            :class="leftNavActive == item.index ? 'leftActive' : ''"
            @click="leftNavChange(item.index)"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div class="main-r">
        <!-- 我的喜欢 -->
        <div class="box-one" v-show="leftNavActive == '0'">
          <div class="nav-box">
            <div
              class="item cursorstyle"
              v-for="(item, x) in nav"
              :key="x"
              @click="selectNav(item.index)"
            >
              <p :class="activeNav == item.index ? 'active' : 'noactive'">
                {{ item.name }}
                <span class="line" v-show="activeNav == item.index"></span>
              </p>
            </div>
          </div>

          <!-- 视频列表 -->
          <div class="videoBox" v-show="activeNav == '0'">
            <div class="card-box">
              <el-row :gutter="20">
                <el-col
                  :span="8"
                  v-for="(item, saindex) in videoList"
                  :key="saindex"
                  class="cursorstyle"
                  @click="toVideo(item.video_id)"
                  ><div
                    class="grid-content cursorstyle"
                    style=" margin-bottom: 30px;"
                    @click="toVideo(item.video_id)"
                  >
                    <div class="card-box-one">
                      <img v-lazy="item.video_info.cover" alt="" />
                    </div>
                    <div class="card-box-two">
                      <p>{{ item.video_info.title }}</p>
                    </div>
                    <div class="card-box-three">
                      <span>{{ item.video_info.area }}</span>
                      <span class="line">{{ item.video_info.label_text }}</span>
                    </div>
                    <div class="card-box-play">
                      <img
                        src="../../assets/img/play.png"
                        alt=""
                        class="play"
                      />
                      <span style="margin-left:10px">{{
                        item.video_info.total_scan
                      }}</span>
                      <!-- <img src="../../assets/img/unlike.png" alt="" class="play" style="margin-left:24px">
                      <span style="margin-left:10px">123</span> -->
                    </div>
                    <div class="card-box-four">
                      <div class="card-box-for-img">
                        <img v-lazy="item.video_info.user_info.avatar" alt="" />
                      </div>
                      <div class="card-box-for-author">
                        <span>{{ item.video_info.user_info.username }}</span>
                      </div>
                      <div class="card-box-for-job">
                        <span>{{
                          item.video_info.user_info.identity_text
                        }}</span>
                      </div>
                    </div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>

          <!-- 文章列表 -->
          <div class="article-box" v-show="activeNav == '1'">
            <div
              class="articlebox cursorstyle"
              v-for="(item, z) in articleList"
              :key="z"
              @click="toArticle(item.article_id)"
            >
              <div class="article-l">
                <img v-lazy="item.article_info.cover" alt="" srcset="" />
              </div>
              <div class="article-r">
                <div class="article-r-one">
                  <p>{{ item.article_info.title }}</p>
                </div>
                <div class="article-r-two">
                  <p>
                    {{ item.article_info.description }}
                  </p>
                </div>
                <div class="article-r-three">
                  <div class="three-l">
                    <img
                      :src="item.article_info.user_info.avatar"
                      alt=""
                      class="avatar"
                    />
                    <p>{{ item.article_info.user_info.username }}</p>
                  </div>
                  <div class="three-r">
                    <div class="r-one">
                      <img
                        src="../../assets/img/time.png"
                        alt=""
                        class="iconone"
                      />
                      <p>{{ item.created_at | time }}</p>
                    </div>
                    <div class="r-one" style="margin-left: 10px;">
                      <img
                        src="../../assets/img/show.png"
                        alt=""
                        class="icontwo"
                      />
                      <p>{{ item.article_info.total_scan }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 音频列表 -->
          <div class="mediabox" v-show="activeNav == '2'">
            <div class="item-box" v-show="mediaList.length > 0">
              <div
                class="item cursorstyle"
                v-for="(item, x) in mediaList"
                :key="x"
                @click="tomediaDetail(item.audio_id)"
              >
                <div class="item-one">
                  <img v-lazy="item.audio_info.cover" alt="" />
                </div>
                <div class="item-two">
                  <p>{{ item.audio_info.title }}</p>
                </div>
                <div class="item-three">
                  <img :src="item.audio_info.user_info.avatar" alt="" />
                  <span>{{ item.audio_info.user_info.username }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 图片列白哦 -->
          <div class="photobox" v-show="activeNav == '3'">
            <div class="card-box">
              <el-row :gutter="20">
                <el-col
                  :span="6"
                  v-for="(item, saindex) in photoList"
                  :key="saindex"
                  ><div
                    class="grid-content cursorstyle"
                    style=" margin-bottom: 30px;"
                    @click="tophoto(item.atlas_id)"
                  >
                    <div class="card-box-one">
                      <img v-lazy="item.atlas_info.cover" alt="" />
                    </div>
                    <div class="card-box-two">
                      <p>{{ item.atlas_info.title }}</p>
                    </div>
                    <!-- <div class="card-box-three">
                  <span>{{ item.area }}</span>
                  <span class="line">{{ item.label_text }}</span>
                </div> -->
                    <div class="card-box-play">
                      <img
                        src="../../assets/img/show.png"
                        alt=""
                        class="play"
                      />
                      <span style="margin-left:10px">{{
                        item.atlas_info.total_scan
                      }}</span>
                      <!-- <img src="../../assets/img/unlike.png" alt="" class="play" style="margin-left:24px">
                      <span style="margin-left:10px">123</span> -->
                    </div>
                    <div class="card-box-four" v-if="item.atlas_info.user_info">
                      <div class="card-box-for-img">
                        <img v-lazy="item.atlas_info.user_info.avatar" alt="" />
                      </div>
                      <div class="card-box-for-author">
                        <span>{{ item.atlas_info.user_info.username }}</span>
                      </div>
                      <!-- <div class="card-box-for-job">
                    <span>{{ item.user_info.identity_text }}</span>
                  </div> -->
                    </div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </div>

        <!-- 我的关注 -->
        <div class="box-two" v-show="leftNavActive == '1'">
          <div
            class="item-box"
            v-for="(item, mfindex) in myFollowList"
            :key="mfindex"
          >
            <div class="item">
              <div
                class="item-l cursorstyle"
                @click="toCreCenter(item.user_id)"
              >
                <div class="item-l-img">
                  <img :src="item.followed_info.avatar" alt="" />
                </div>
                <div class="item-l-info">
                  <p class="info-one">{{ item.followed_info.username }}</p>
                  <p class="info-two">
                    <span>粉丝 {{ item.followed_info.fans }}</span>
                    <span style="margin-left:10px"
                      >关注 {{ item.followed_info.follow }}</span
                    >
                  </p>
                  <p class="info-three">
                    {{ item.followed_info.introduction }}
                  </p>
                </div>
              </div>
              <div class="item-r">
                <span
                  class="active cursorstyle"
                  v-show="item.is_follow == 0"
                  @click.stop="follow(item, item.followed_id, '1')"
                  >关注</span
                >
                <span
                  class="noactive cursorstyle"
                  v-show="item.is_follow == 1"
                  @click.stop="canclefollow(item, item.followed_id, '1')"
                  >已关注</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 我的粉丝 -->
        <div class="box-two" v-show="leftNavActive == '2'">
          <div
            class="item-box"
            v-for="(item, mfans) in myFansList"
            :key="mfans"
          >
            <div class="item">
              <div
                class="item-l cursorstyle"
                @click="toCreCenter(item.user_id)"
              >
                <div class="item-l-img">
                  <img v-lazy="item.user_info.avatar" alt="" />
                </div>
                <div class="item-l-info">
                  <p class="info-one">{{ item.user_info.username }}</p>
                  <p class="info-two">
                    <span>粉丝 {{ item.user_info.fans }}</span>
                    <span style="margin-left:10px"
                      >关注 {{ item.user_info.follow }}</span
                    >
                  </p>
                  <p class="info-three">{{ item.user_info.introduction }}</p>
                </div>
              </div>
              <div class="item-r">
                <span
                  class="active cursorstyle"
                  v-show="item.is_follow == 0"
                  @click.stop="follow(item, item.user_id, '0')"
                  >关注</span
                >
                <span
                  class="noactive cursorstyle"
                  v-show="item.is_follow == 1"
                  @click.stop="canclefollow(item, item.user_id, '0')"
                  >已关注</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 我的评论 -->
        <div class="box-three" v-show="leftNavActive == '3'">
          <!-- 上方显示的导航栏 -->
          <div class="nav-box">
            <div
              class="item cursorstyle"
              v-for="(item, xcomm) in commnav"
              :key="xcomm"
              @click="selectCommNav(item)"
            >
              <p :class="activeCommNav == item.index ? 'active' : 'noactive'">
                {{ item.name }}
                <span class="line" v-show="activeCommNav == item.index"></span>
              </p>
            </div>
          </div>

          <!-- 下方显示的评论内容 -->
          <div class="commbox">
            <!-- 视频评论内容 -->
            <div class="item-box" v-show="activeCommNav == '0'">
              <div class="item" v-for="(item, clv) in commentListV" :key="clv">
                <div class="item-one">
                  <div class="one-l">
                    <span class="title">评论 ：</span>
                    <span class="comm">{{ item.content }}</span>
                  </div>
                  <div class="one-r">
                    <el-popconfirm
                      confirm-button-text="好的"
                      cancel-button-text="不用了"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确定要删除该评论吗"
                      @confirm="subDeleteComm(item.id, clv)"
                    >
                      <span class="delete cursorstyle" slot="reference"
                        >删除</span
                      >
                    </el-popconfirm>
                    <span class="time">{{ item.created_at }}</span>
                  </div>
                </div>
                <div
                  class="item-two cursorstyle"
                  @click="toVideo(item.video_id)"
                >
                  <div class="two-l">
                    <p class="title">
                      <span>标题：</span>
                      <span>{{ item.video_info.title }}</span>
                    </p>
                    <p class="title name" v-if="item.video_info.user_info">
                      <span>创作人：</span>
                      <span>{{ item.video_info.user_info.username }}</span>
                    </p>
                  </div>
                  <div class="two-r">
                    <p class="time">{{ item.video_info.created_at }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- 文章评论内容 -->
            <div class="item-box" v-show="activeCommNav == '1'">
              <div class="item" v-for="(item, cla) in commentListA" :key="cla">
                <div class="item-one">
                  <div class="one-l">
                    <span class="title">评论 ：</span>
                    <span class="comm">{{ item.content }}</span>
                  </div>
                  <div class="one-r">
                    <el-popconfirm
                      confirm-button-text="好的"
                      cancel-button-text="不用了"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确定要删除该评论吗"
                      @confirm="subDeleteComm(item.id, cla)"
                    >
                      <span class="delete cursorstyle" slot="reference"
                        >删除</span
                      >
                    </el-popconfirm>
                    <span class="time">{{ item.created_at }}</span>
                  </div>
                </div>
                <div
                  class="item-two cursorstyle"
                  @click="toArticle(item.article_id)"
                >
                  <div class="two-l">
                    <p class="title">
                      <span>标题：</span>
                      <span>{{ item.article_info.title }}</span>
                    </p>
                    <p class="title name">
                      <span>创作人：</span>
                      <span>{{ item.article_info.user_info.username }}</span>
                    </p>
                  </div>
                  <div class="two-r">
                    <p class="time">{{ item.article_info.created_at }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- 音频评论内容 -->
            <div class="item-box" v-show="activeCommNav == '2'">
              <div class="item" v-for="(item, clm) in commentListM" :key="clm">
                <div class="item-one">
                  <div class="one-l">
                    <span class="title">评论 ：</span>
                    <span class="comm">{{ item.content }}</span>
                  </div>
                  <div class="one-r">
                    <el-popconfirm
                      confirm-button-text="好的"
                      cancel-button-text="不用了"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确定要删除该评论吗"
                      @confirm="subDeleteComm(item.id, clm)"
                    >
                      <span class="delete cursorstyle" slot="reference"
                        >删除</span
                      >
                    </el-popconfirm>
                    <span class="time">{{ item.created_at }}</span>
                  </div>
                </div>
                <div
                  class="item-two cursorstyle"
                  @click="tomediaDetail(item.audio_id)"
                >
                  <div class="two-l">
                    <p class="title">
                      <span>标题：</span>
                      <span>{{ item.audio_info.title }}</span>
                    </p>
                    <p class="title name">
                      <span>创作人：</span>
                      <span>{{ item.audio_info.user_info.username }}</span>
                    </p>
                  </div>
                  <div class="two-r">
                    <p class="time">{{ item.audio_info.created_at }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- 图集评论内容 -->
            <div class="item-box" v-show="activeCommNav == '3'">
              <div class="item" v-for="(item, clp) in commentListP" :key="clp">
                <div class="item-one">
                  <div class="one-l">
                    <span class="title">评论 ：</span>
                    <span class="comm">{{ item.content }}</span>
                  </div>
                  <div class="one-r">
                    <el-popconfirm
                      confirm-button-text="好的"
                      cancel-button-text="不用了"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确定要删除该评论吗"
                      @confirm="subDeleteComm(item.id, cla)"
                    >
                      <span class="delete cursorstyle" slot="reference"
                        >删除</span
                      >
                    </el-popconfirm>
                    <span class="time">{{ item.created_at }}</span>
                  </div>
                </div>
                <div
                  class="item-two cursorstyle"
                  @click="tophoto(item.atlas_id)"
                >
                  <div class="two-l">
                    <p class="title">
                      <span>标题：</span>
                      <span>{{ item.atlas_info.title }}</span>
                    </p>
                    <p class="title name">
                      <span>创作人：</span>
                      <span>{{ item.atlas_info.user_info.username }}</span>
                    </p>
                  </div>
                  <div class="two-r">
                    <p class="time">{{ item.atlas_info.created_at }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 下方分页参数 -->
        <div
          class="page"
          v-show="datanum > 0 && allpage > 1"
          style="margin-top:10px"
        >
          <el-pagination
            background
            layout=" pager, next"
            :total="totalNUm"
            :current-page="page"
            :page-count="allpage"
            :page-size="per"
            @current-change="handleCurrentChange"
            @next-click="handleNext"
          >
          </el-pagination>
        </div>

        <!-- 无数据提示 -->
        <div class="empty" v-show="datanum == 0">
          <div class="emptyone">
            <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
          </div>
          <div class="emptytxt">
            <p>暂时没有数据，过会再来看看吧</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyInfo,
  myVideo,
  myMedia,
  myArticle,
  myPhoto,
  myFans,
  myFollows,
  followCreator,
  CanclefollowCreator,
  myComments,
  deleteComment,
} from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      nav: [
        { name: "视频作品", index: "0" },
        { name: "文章素材", index: "1" },
        { name: "音频素材", index: "2" },
        { name: "图片素材", index: "3" },
      ],
      commnav: [
        { name: "视频评论", index: "0", type: "video" },
        { name: "文章评论", index: "1", type: "article" },
        { name: "音频评论", index: "2", type: "audio" },
        { name: "图集评论", index: "3", type: "atlas" },
      ],
      leftNav: [
        { name: "我的喜欢", index: "0", path: "" },
        { name: "我的关注", index: "1", path: "" },
        { name: "我的粉丝", index: "2", path: "" },
        { name: "我的评论", index: "3", path: "" },
        { name: "认证", index: "4", path: "" },
        { name: "账号设置", index: "5", path: "" },
      ],
      leftNavActive: null,
      activeNav: "0",
      activeCommNav: "0",
      myInfo: "",
      videoList: [],
      articleList: [],
      mediaList: [],
      photoList: [],
      page: 1,
      totalNUm: null,
      allpage: 0,
      per: 0,
      datanum: 0,

      myFollowList: [],
      myFansList: [],
      commentListV: [],
      commentListA: [],
      commentListM: [],
      commentListP: [],
    };
  },
  watch: {},
  filters: {
    time: function(val) {
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      let time1 = new Date().getTime(); //当前的时间戳
      let time2 = Date.parse(new Date(val)); //指定时间的时间戳
      let time = time1 - time2;

      let result = null;
      if (time < 0) {
        result = "刚刚";
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
  },
  computed: {},
  methods: {
    leftNavChange: function(e) {
      if (e == "4") {
        const { href } = this.$router.resolve({
          path: "/personalcenter",
          query: {
            type: "2",
          },
        });
        window.open(href, "_blank");

        return;
      }
      if (e == "5") {
        const { href } = this.$router.resolve({
          path: "/personalcenter",
          query: {
            type: "0",
          },
        });
        window.open(href, "_blank");

        return;
      }
      this.leftNavActive = e || this.leftNavActive;
      this.page = 1;
      switch (this.leftNavActive) {
        // 我的喜欢
        case "0":
          this.getList();
          break;
        // 我的关注
        case "1":
          this.getMyFollowList();
          break;
        // 我的粉丝
        case "2":
          this.getMyFansList();
          break;
        // 我的评论
        case "3":
          this.getCommentsList();
          break;
        default:
          break;
      }
    },
    handleCurrentChange: function(val) {
      this.page = val;
      switch (this.leftNavActive) {
        //
        case "0":
          this.getList();
          break;
        case "1":
          this.getMyFollowList();
          break;
        case "2":
          this.getMyFansList();
          break;
        case "3":
          this.getCommentsList();
          break;

        default:
          break;
      }
      // this.getList();
    },
    handleNext: function(val) {
      this.page = val;
      switch (this.leftNavActive) {
        //
        case "0":
          this.getList();
          break;
        case "1":
          this.getMyFollowList();
          break;
        case "2":
          this.getMyFansList();
          break;
        case "3":
          this.getCommentsList();
          break;

        default:
          break;
      }
      // this.getList();
    },
    selectNav: function(e) {
      this.activeNav = e;
      this.page = 1;
      this.getList();
    },
    // 选择视频分类泪飙
    selectCommNav: function(e) {
      this.activeCommNav = e.index;
      this.page = 1;
      this.getCommentsList();
    },
    //  获取列表
    getList: function() {
      switch (this.activeNav) {
        case "0":
          myVideo({
            page: this.page,
          }).then((res) => {
            this.videoList = res.data.data.filter((item) => item.video_info);
            this.totalNUm = res.data.total;
            this.allpage = res.data.last_page;
            this.per = +res.data.per_page;
            this.datanum = this.videoList.length;
          });
          break;
        case "1":
          myArticle({
            page: this.page,
          }).then((res) => {
            this.articleList = res.data.data.filter(
              (item) => item.article_info
            );
            this.totalNUm = res.data.total;
            this.allpage = res.data.last_page;
            this.per = +res.data.per_page;
            this.datanum = this.articleList.length;
          });
          break;
        case "2":
          myMedia({
            page: this.page,
          }).then((res) => {
            this.mediaList = res.data.data.filter((item) => item.audio_info);
            this.totalNUm = res.data.total;
            this.allpage = res.data.last_page;
            this.per = +res.data.per_page;
            this.datanum = this.mediaList.length;
          });
          break;
        case "3":
          myPhoto({
            page: this.page,
          }).then((res) => {
            this.photoList = res.data.data.filter((item) => item.atlas_info);
            this.totalNUm = res.data.total;
            this.allpage = res.data.last_page;
            this.per = +res.data.per_page;
            this.datanum = this.photoList.length;
          });
          break;

        default:
          break;
      }
    },

    toVideo: function(e) {
      // this.$router.push({
      //   path: "/videodetail",
      //   query: {
      //     id: e,
      //   },
      // });
      const { href } = this.$router.resolve({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },

    toArticle: function(e) {
      // this.$router.push({
      //   path: "/articledetail",
      //   query: {
      //     id: e,
      //   },
      // });

      const { href } = this.$router.resolve({
        path: "/articledetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },

    tomediaDetail: function(e) {
      // this.$router.push({
      //   path: "/mediadetail",
      //   query: {
      //     id: e,
      //   },
      // });

      const { href } = this.$router.resolve({
        path: "/mediadetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },

    tophoto: function(e) {
      const { href } = this.$router.resolve({
        path: "/photodetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },

    // 我的关注
    getMyFollowList: function() {
      myFollows({
        page: this.page,
      }).then((res) => {
        res.data.data.map((item) => {
          item.is_follow = 1;
        });
        this.myFollowList = res.data.data.filter((item) => item.followed_info);
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page;
        this.per = +res.data.per_page;
        this.datanum = this.myFollowList.length;
      });
    },

    // 我的粉丝
    getMyFansList: function() {
      myFans({
        page: this.page,
      }).then((res) => {
        this.myFansList = res.data.data.filter((item) => item.user_info);
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page;
        this.per = +res.data.per_page;
        this.datanum = this.myFansList.length;
      });
    },

    follow: function(e, id, type) {
      followCreator({
        creator: id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 1;
          switch (type) {
            case "0":
              e.user_info.fans++;
              //  if (this.myInfo.follow > 0) {
              //   this.myInfo.follow--
              // }
              this.myInfo.follow++;
              break;
            case "1":
              e.followed_info.fans++;
              this.myInfo.follow++;
              break;
            case "2":
              this.user.fans++;
              break;
            default:
              break;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    canclefollow: function(e, id, type) {
      CanclefollowCreator({
        creator: id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 0;

          switch (type) {
            case "0":
              if (e.user_info.fans > 0) {
                e.user_info.fans--;
              }
              if (this.myInfo.follow > 0) {
                this.myInfo.follow--;
              }
              break;
            case "1":
              if (e.followed_info > 0) {
                e.followed_info.fans--;
              }
              if (this.myInfo.follow > 0) {
                this.myInfo.follow--;
              }
              break;
            case "2":
              if (this.user.fans > 0) {
                this.user.fans--;
              }

              break;
            default:
              break;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },

    getCommentsList: function() {
      myComments({
        type: this.commnav[this.activeCommNav].type,
        page: this.page,
      }).then((res) => {
        // this.commentList = res.data.data
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page;
        this.per = +res.data.per_page;
        // this.datanum = res.data.data.length;
        var commlength;
        switch (this.activeCommNav) {
          case "0":
            this.commentListV = res.data.data.filter((item) => item.video_info);
            commlength = this.commentListV.length;
            break;
          case "1":
            this.commentListA = res.data.data.filter(
              (item) => item.article_info
            );
            commlength = this.commentListA.length;
            break;
          case "2":
            this.commentListM = res.data.data.filter((item) => item.audio_info);
            commlength = this.commentListM.length;
            break;
          case "3":
            this.commentListP = res.data.data.filter((item) => item.atlas_info);
            commlength = this.commentListP.length;
            break;

          default:
            break;
        }

        this.datanum = commlength;
      });
    },

    subDeleteComm: function(e, index) {
      deleteComment({
        type: this.commnav[this.activeCommNav].type,
        comment: e,
      }).then((res) => {
        if (res.code == 200) {
          switch (this.activeCommNav) {
            case "0":
              this.commentListV.splice(index, 1);
              break;
            case "1":
              this.commentListA.splice(index, 1);
              break;
            case "2":
              this.commentListM.splice(index, 1);
              break;
            case "3":
              this.commentListP.splice(index, 1);
              break;

            default:
              break;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },

    // 跳转创作者中心
    toCreCenter: function(e) {
      // this.$router.push({
      //   path:'/creatorcenter',
      //   query:{
      //     id:e
      //   }
      // })

      const { href } = this.$router.resolve({
        path: "/creatorcenter",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    this.leftNavActive = this.$route.query.type || "0";
  },
  mounted() {
    getMyInfo({}).then((res) => {
      this.myInfo = res.data;
    });
    this.leftNavChange();
  },
};
</script>
<style lang="scss" scoped>
.all {
  padding-top: 40px;
  background-color: #f7f7f7;

  .main {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
    display: flex;
  }
}
.leftActive {
  color: #e35111 !important;
}
.active {
  font-weight: bold;
  color: #333333;
}
.noactive {
  font-weight: 400;
  color: #333333;
}

.delete:hover {
  color: red !important;
}

.main-l {
  width: 205px;
  .l-one {
    text-align: center;
    height: 205px;
    background-color: #ffffff;
    .avatar {
      margin-top: 30px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    .txt-one {
      margin-top: 21px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .txt-two {
      margin-top: 17px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .l-two {
    height: 393px;
    margin-top: 20px;
    background-color: #ffffff;
    p {
      padding: 15px 35px;
    }
    .txt-one {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .txt-two {
      border-top: 1px solid #f5f5f5;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
}
.main-r {
  flex: 1;
}
// 我的喜欢
.box-one {
  .nav-box {
    padding: 15px 20px;
    margin-left: 20px;
    background-color: #ffffff;
    display: flex;
    .item {
      margin-right: 44px;
      p {
        position: relative;
        font-size: 18px;
        font-family: Microsoft YaHei;
      }
      .line {
        width: 24px;
        height: 4px;
        background-color: #df4936;
        position: absolute;
        left: 24px;
        top: 36px;
      }
    }
  }

  // 视频
  .videoBox {
    padding: 25px 20px;
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      background: #ffffff;
    }
    .card-box {
      overflow: hidden;
      .card-box-one {
        height: 170px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .card-box-two {
        margin-top: 20px;
        margin-left: 16px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .card-box-three {
        margin-top: 10px;
        margin-left: 16px;
        .line {
          margin-left: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
      .card-box-four {
        display: flex;
        padding: 11px 16px;
        align-items: center;
        .card-box-for-img {
          width: 24px;
          height: 24px;
        }
        .card-box-for-img img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .card-box-for-author {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-left: 10px;
        }
        .card-box-for-job {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
          margin-left: 16px;
        }
      }
      .card-box-five {
        text-indent: 2em;
        padding: 3px 16px;
        color: #bebebe;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .card-box-play {
        margin-left: 16px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .play {
          width: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
    }
  }

  // 文章
  .article-box {
    padding: 0px 20px;
    .articlebox {
      height: 240px;
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
      .article-l {
        img {
          width: 360px;
          height: 240px;
        }
      }
      .article-r {
        flex: 1;
        background-color: #ffffff;
        padding: 25px 22px 20px 20px;
        display: flex;
        flex-direction: column;
        .article-r-one {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #1d1d1d;
        }
        .article-r-two {
          flex: 1;
          padding-top: 28px;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
        .article-r-three {
          display: flex;
          .three-l {
            display: flex;
            align-items: center;
            flex: 1;
            // justify-content: flex-end;
            //  justify-content: space-between;
          }
          .three-r {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            //   justify-content: flex-end;
            .r-one {
              display: flex;
              align-items: center;
            }
            .iconone {
              width: 14px;
            }
            .icontwo {
              width: 19px;
            }
            p {
              margin-left: 10px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
          p {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }

  // 音频

  .mediabox {
    //  padding: 25px 20px;
    margin: 25px 0 0 20px;
    .item-box {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      background-color: #ffffff;
      //   padding: 10px;
      .item {
        width: 20%;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .item-one {
          img {
            // width: 100%;
            width: 100%;
            height: 140px;
            border-radius: 8px;
          }
        }
        .item-two {
          margin-top: 20px;
          p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .item-three {
          margin-top: 14px;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  // 图片
  .photobox {
    margin-left: 20px;
    // margin-top: 25px;
    padding-top: 25px;
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      background: #ffffff;
    }
    .card-box {
      overflow: hidden;
      .card-box-one {
        height: 220px;
        // padding: 11px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .card-box-two {
        margin-top: 20px;
        margin-left: 16px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .card-box-three {
        margin-top: 10px;
        margin-left: 16px;
        .line {
          margin-left: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
      .card-box-four {
        display: flex;
        padding: 11px 16px;
        align-items: center;
        .card-box-for-img {
          width: 24px;
          height: 24px;
        }
        .card-box-for-img img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .card-box-for-author {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-left: 10px;
        }
        .card-box-for-job {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
          margin-left: 16px;
        }
      }
      .card-box-five {
        text-indent: 2em;
        padding: 3px 16px;
        color: #bebebe;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .card-box-play {
        margin-left: 16px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .play {
          width: 12px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bebebe;
        }
      }
    }
  }

  .message-box {
    width: 960px;
    margin: 0 auto;
    background-color: #fff;
    padding: 40px;
    .box-one {
      margin-top: 40px;
      display: flex;
      .one-txt {
        width: 10%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .two-txt {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

// 我的管足
.box-two {
  margin-left: 20px;
  .item-box {
    display: flex;
    flex-direction: column;
    padding: 25px 30px 25px 22px;
    background-color: #ffffff;
    border-bottom: 1px solid #f1f1f1;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-l {
        display: flex;
      }
      .item-l-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;

        img {
          max-width: 100%;
          height: 100%;
          background-color: #ffffff;
        }
      }
      .item-l-info {
        padding-left: 15px;
        .info-one {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1d1d1d;
        }
        .info-two {
          margin-top: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .info-three {
          width: 500px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
      .item-r {
        span {
          display: inline-block;
          width: 52px;
          height: 28px;
          border-radius: 2px;
          line-height: 28px;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
        .active {
          background-color: #e35111;
          color: #ffffff;
        }
        .noactive {
          background-color: #ffffff;
          color: #333333;
          border: 1px solid #767676;
        }
      }
    }
  }
}

// 我的评论列表
.box-three {
  margin-left: 20px;
  .nav-box {
    padding: 15px 20px;
    // margin-left: 20px;
    background-color: #ffffff;
    display: flex;
    .item {
      margin-right: 44px;
      p {
        position: relative;
        font-size: 18px;
        font-family: Microsoft YaHei;
      }
      .line {
        width: 24px;
        height: 4px;
        background-color: #df4936;
        position: absolute;
        left: 24px;
        top: 36px;
      }
    }
  }
  .commbox {
    margin-top: 20px;
    background-color: #fff;
    .item-box {
      .item {
        padding: 30px;
        border-bottom: 1px solid #f1f1f1;
        .item-one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .one-l {
            flex: 1;
            display: flex;
            align-items: center;
            .title {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
            }
            .comm {
              margin-left: 10px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #8a8a8a;
              width: 300px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              display: inline-block;
            }
          }
          .one-r {
            flex: 1;
            text-align: right;
            .delete {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              margin-right: 20px;
            }

            .time {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .item-two {
          background-color: #f0f0f0;
          display: flex;
          align-items: center;
          padding: 12px;
          margin-top: 9px;
          .two-l {
            flex: 2;
            display: flex;
            .title {
              display: flex;
              align-items: center;
            }
            .name {
              margin-left: 10px;
            }
            .title span:nth-child(1) {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
            }
            .title span:nth-child(2) {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #8a8a8a;
              width: 200px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              display: inline-block;
            }
          }
          .two-r {
            text-align: right;
            flex: 1;
            .time {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
    }
  }
}

.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 20px;
    }
  }
}
</style>

<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
.el-popconfirm__action {
  margin-top: 10px;
}
</style>
