<template>
  <div class="all" :style="{height:allHeight+'px',}">
    <div class="main">
      <div class="main-l">
        <p class="active ">
          <i class="el-icon-s-tools settingicon"></i>账号设置
        </p>
        <p
          v-for="(item, x) in option"
          :key="x"
          @click="select(item)"
          :class="activeIndex == item.index ? 'active' : ''"
          class="cursorstyle"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="main-r">
        <!-- acconinfo -->
        <div v-show="activeIndex == '2'" style="padding: 40px;">
          <div class="r-one">
            <p>一个账号只可以和一个实名信息绑定，无法解绑或更换</p>
          </div>
          <div class="r-two">
            <!-- 成功 -->
            <div class="two-one" v-show="authSt == 2">
            <p><i class="el-icon-success iconinfo"></i></p>
            <p class="mt20">恭喜您! 您的实名认证已经通过</p>
          </div>

            <!-- 失败 -->
            <div class="two-two" v-show="authSt == 3">
            <p><i class="el-icon-error iconinfo"></i></p>
            <p class="mt20">很抱歉！您的身份信息没有通过审核</p>
            <p style="margin-top:10px"> <span style="color:#a9a9a9;">拒绝原因</span> {{authStTxt}}</p>
            <p><span class="button cursorstyle" @click="changeAuthSt">重新提交</span></p>
          </div>

            <!-- 审核中 -->
            <div class="two-three" v-show="authSt == 1">
            <p><i class="el-icon-warning iconinfo"></i></p>
            <p class="mt20">实名认证审核中</p>
            <p class="txt">审核结果将在1个工作日后，以系统通知形式告知</p>
          </div>

            <!-- 提交审核 -->
            <div class="two-four" v-show="authSt == 0">
              <div class="four-one">
                <p>真实姓名</p>
                <el-input
                  v-model="name"
                  placeholder="请输入真实姓名"
                ></el-input>
              </div>
              <div class="four-two">
                <p>身份证</p>
                <el-input v-model="num" placeholder="请输入身份证号"></el-input>
              </div>
              <div class="four-three">
                <p style="margin-bottom: 10px;">身份证材料</p>
                <div class="uploadbox">
                  <el-upload
                    class="avatar-uploader"
                    :action="uploadUrl"
                    :data="up_param"
                    list-type="picture"
                    :before-upload="beforeUploadOne"
                    name="file"
                    :show-file-list="false"
                  >
                    <img v-if="positive" :src="positive" style="width:100%"/>
                    <div class="info" v-if="positive == ''">
                      <i class="el-icon-plus"></i>
                      <p style="color:#666666">身份证正面</p>
                    </div>
                  </el-upload>
                  <el-upload
                    style="margin-left:21px"
                    class="avatar-uploader"
                    :action="uploadUrl"
                    :data="up_param"
                    list-type="picture"
                    :before-upload="beforeUploadTwo"
                    name="file"
                     :show-file-list="false"
                  >
                    <img v-if="side" :src="side" style="width:100%"/>
                    <div class="info" v-if="side == ''">
                      <i class="el-icon-plus"></i>
                      <p style="color:#666666">身份证反面</p>
                    </div>
                  </el-upload>
                </div>
              </div>
              <el-divider class="driveLine"></el-divider>
              <div class="four-five">
                <el-checkbox v-model="checked"> </el-checkbox>
                <span class="txt-one">我已阅读和同意</span>
                <span class="txt-two cursorstyle"  @click="toAgree">《C位河南实名认证协议》</span>
              </div>
              <div class="four-six">
                <el-button type="primary" :disabled="name == '' || num == '' || positive == '' || side=='' || checked== false" class="subBg"
                  @click="sub">提交</el-button
                >
              </div>

               <!-- <div class="lin" style="height:30px"></div> -->
            </div>
          </div>
        </div>

        <!-- 账号安全 -->
        <div class="acconinfo" v-show="activeIndex == '1'">
          <div class="info-one">
            <p>账号安全 <span>请完善你的账户安全信息</span></p>
          </div>
          <div class="info-two">
            <p>
              <span class="txt-one">手机</span>
              <span class="txt-two" v-show="user.mobile == 0">请设置手机号码</span>
              <span class="txt-two"  v-show="user.mobile !== 0">{{ user.mobile | mobile }}</span>
              <span class="txt-three cursorstyle" @click="changeMobileView = true">换绑</span>
            </p>
            <p>
              <span class="txt-one">密码</span>
              <span class="txt-two">重置/更改密码</span>
              <span
                class="txt-three cursorstyle"
                @click="centerDialogVisible = true"
                >设置</span
              >
            </p>
            <p>
              <span class="txt-one">第三方账号</span>
              <span v-if="thirdInfo.length > 0"  style="display:flex;" class="txt-two">
                 <img src="../../assets/img/weixin.png" alt=""  style="width:20px;margin-right: 10px;">
                {{thirdInfo[0].openname}}
              </span>
              <span v-if="thirdInfo.length == 0" class="txt-two">还没有绑定第三方账号'</span>
              <span class="txt-three cursorstyle" @click="changeQrcActive" v-if="thirdInfo.length == 0">绑定</span>
              <span class="txt-three cursorstyle" @click="removeChat" v-if="thirdInfo.length > 0" slot="reference">解绑</span>
              
            </p>
          </div>
        </div>

        <!-- 个人资料 -->
        <div class="my-info" v-show="activeIndex == '0'">
          <div class="info-one">
            <p>
              个人资料
              <span
                >填写关于你的信息，为了维护健康的社区环境请勿填写手机号、微信等信息</span
              >
            </p>
          </div>
          <div class="info-two">
            <div class="avat">
              <div class="avat-l">
                <p>头像</p>
              </div>
              <div class="avat-r">
                <img :src="user.avatar" alt="" />
                <!-- <el-upload
                  class="upload-demo"
                  :action="uploadUrl"
                  :data="up_param"
                  :before-upload="beforeUploadAvatar"
                  name="file"
                  :show-file-list="false"
                >
                </el-upload> -->
                  <p class="changeavatar cursorstyle" @click="clicp()">修改头像</p>
              </div>
            </div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="昵称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="职业/行业" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择职业"
                  style="width: 100%;"
                >
                  <!-- <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option> -->
                   <el-option :label="item" :value="i"  v-for="(item,i,jobindex) in jobList" :key='jobindex'>{{item}}</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="性别" prop="gender" style="width: 100%;">
                <el-select
                  v-model="ruleForm.gender"
                  placeholder="请选择性别"
                  style="width: 100%;"
                >
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="生日" required style="width: 100%;">
                <el-col :span="11" style="width: 100%;">
                  <el-form-item prop="birthday">
                    <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="ruleForm.birthday"
                      style="width: 100%;"
                      format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item
                label="所在地区"
                prop="address"
                style="width: 100%;"
              >
                <el-select
                  v-model="ruleForm.address"
                  placeholder="请选择所在地区"
                  style="width: 100%;"
                >
                  <el-option :label="item" :value="i" :key='arrindex' v-for="(item,i,arrindex) in arealist" >{{item}}</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关于我" prop="desc">
                <el-input
                  type="textarea"
                  v-model="ruleForm.desc"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="submitForm('ruleForm')"
                  class="subBg"
                  >保存</el-button
                >
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <!-- 设置弹窗 -->
        <div class="diabox">
          <el-dialog
            title="修改密码"
            :visible.sync="centerDialogVisible"
            width="400px"
          >
            <div class="inputbox">
              <p>
                为了保护账号安全，请先完成短信验证（{{ user.mobile | mobile }}）
              </p>
              <div class="input">
                <input
                  type="text"
                  class="inputstyle"
                  style="outline:none;border:none"
                  placeholder="验证码"
                  v-model="passCode"
                />
                <span class="cursorstyle" @click="smsSendCode('resetpwd')">{{
                  codetxt
                }}</span>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button
                @click="openChangeDialof"
                class="subBg subbutton"
                :disabled="this.passCode == ''"
                >下一步</el-button
              >
            </span>
          </el-dialog>
        </div>

        <!-- 密码输入弹窗 -->
        <div class="diabox passbox">
          <el-dialog title="修改密码" :visible.sync="passinputbox" width="400px">
            <div class="inputbox">
              <div class="input">
                <el-input
                  show-password
                  placeholder="输入新密码，6-16位字母、数字、常见特殊字符"
                  v-model="passOne"
                ></el-input>
                <!-- <input type="text" class="inputstyle" style="outline:none;border:none" placeholder="输入新密码，6-16位字母、数字、常见特殊字符" v-model="passOne">  -->
              </div>
              <div class="input" style="margin-top:24px">
                <el-input
                  show-password
                  placeholder="再次输入新密码"
                  v-model="passTwo"
                ></el-input>
                <!-- <input type="text" class="inputstyle" style="outline:none;border:none" placeholder="再次输入新密码" v-model="passTwo"> -->
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button
                @click="subChangePass"
                class="subBg subbutton"
                :disabled="this.passOne == '' || this.passTwo == ''"
                >完成</el-button
              >
            </span>
          </el-dialog>
        </div>

        <!-- 修改绑定手机号码 -->
          <div class="diabox passbox changepwd">
          <el-dialog title="修改绑定手机号码" :visible.sync="changeMobileView" width="400px">
            <div class="inputbox">
              <div class="input">
                <el-input
                  placeholder="输入新手机号码"
                  v-model="newMobile"
                ></el-input>
                <!-- <input type="text" class="inputstyle" style="outline:none;border:none" placeholder="输入新密码，6-16位字母、数字、常见特殊字符" v-model="passOne">  -->
              </div>
                 <div class="input" style="margin-top: 20px;">
                <input
                  type="text"
                  class="inputstyle"
                  style="outline:none;border:none"
                  placeholder="验证码"
                  v-model="mobileCode"
                />
                <span class="cursorstyle" @click="smsSendCodeChange()">{{
                  codetxtMobile
                }}</span>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button
                @click="subChangMobile"
                class="subBg subbutton"
                :disabled="this.newMobile == '' || this.mobileCode == ''"
                >完成</el-button
              >
            </span>
          </el-dialog>
          </div>

        <!-- 绑定第三方账号 -->
        <div class="bindthird">
          <el-dialog
           title="绑定微信"
            :visible.sync="bindwechat"
            width="20%"
             :before-close="handleCloseWechat"
            >
           <div class="bindthird-one">
             <!-- <p>打开微信，扫描下方二维码</p> -->
              <vue-qr :text="qrcurl" :size="180"></vue-qr>
           </div>
           <div class="bindthird-two">
             <p>打开微信，扫一扫绑定</p>
           </div>
            <!-- <span slot="footer" class="dialog-footer">
              <el-button @click="bindwechat = false">取 消</el-button>
              <el-button type="primary" @click="bindwechat = false">确 定</el-button>
            </span> -->
          </el-dialog>
        </div>
         


      </div>
    </div>

      <clip :osslist="osslist" :imgh="imgh" @changeclicp="clicp" @getmsg="getMsgFormSon" ref="clic"></clip>
  </div>
</template>

<script>
import clip from "../../components/clipPhoto"
import {
  ossconfig,
  userprofile,
  resetpwd,
  sms,
  getMyInfo,
  changeProfile,
  address,
  getJob,
  changeMobile,
  authStatus,
  subAuth,
  getAuthUrl,
  thirdLogin,
  thirdUnbind
} from "@/api";
import { mapState } from "vuex";
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr,
    clip
  },
  props: {},
  data() {
    return {
      option: [
        { name: "个人资料", index: "0" },
        { name: "账号安全", index: "1" },
        { name: "实名认证", index: "2" },
      ],
      centerDialogVisible: false,
      passinputbox: false,
      activeIndex: "0",
      name: "",
      num: "",
      positive: "", //身份证正面
      side:'',//反面
      uploadUrl: "",
      up_param: {
        key: "",
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        success_action_status: "",
        callback:''
      },
      osslist: "",
      checked: false,
      ruleForm: {
        name: "",
        region: "",
        birthday: "",
        desc: "",
        gender: "",
        address: '',
      },
      rules: {
        name: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { min: 3, max: 10, message: "长度在 3 到 10 个字符", trigger: "blur" },
        ],
        region: [{ required: true, message: "请选择职业", trigger: "change" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        address: [
          { required: true, message: "请选择所在地区", trigger: "change" },
        ],
        birthday: [
          {
            type: 'string',
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请填写关于我的基本信息",
            trigger: "blur",
          },
           { min: 1, max: 65, message: "长度在 1 到 65 个字符", trigger: "blur" },
        ],
      },
      codetxt: "发送验证码",
      codetxtMobile:'发送验证码',
      user: "",
      useravatar: "",
      smsButtonSt: false,
      smsButtonStmobile:false,
      passCode: "", //验证码
      passOne: "",
      passTwo: "",
      me: "",
      arealist:[],
      jobList:[],
      changeMobileView:false,
      newMobile:'',
      mobileCode:'',
      authSt:'',
      authStTxt:'',
      allHeight:'',
      bindwechat:false,
      thirdInfo:[],
      j:null,
      uid:'',
      qrcurl:'',
      imgh:{
           width:200,
          height:200,
          circle:true
      }
    };
  },
  watch: {},
  computed: {
     ...mapState(["userInfo"]),
  },
  filters: {
    mobile: function(val) {
      let str = val + "";
      return str.substring(0, 3) + "****" + str.substring(7, 11);
    },
  },
  methods: {
         clicp:function(){
      // this.clicpShow = !this.clicpShow
      this.$refs.clic.show()
    },
    getMsgFormSon:function(data){
      console.log(data)
      this.user.avatar = data
      // this.cover = data
      // console.log(this.cover)
    },



    removeChat:function(){
      this.$confirm('确定要解除与该账号的绑定吗？', '提示', {
          confirmButtonText: '确定',
           cancelButtonText: '取消',
         }).then(() => {
          thirdUnbind({
            platform:'wechat'
          }).then(res=>{
            
            if (res.code == 200) {
               this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
            });
                setTimeout(()=>{
                     this.$router.push({
                            path:'/personalcenter',
                            query:{
                              type:'1'
                            }
                          })
                          this.$router.go(0)
                },1000)
            }else{
               this.$message({
              showClose: true,
              message: res.msg,
              type: "warning",
            });
            }
          })

        }).catch(() => {
                  
        });
    },
    handleCloseWechat:function(){
        this.cleanTime()
          this.bindwechat = !this.bindwechat
    },
       // 生成随机数
    creatUid:function(){
      let uid = sessionStorage.getItem('uid')
      if (!uid) {
         let time = new Date().getTime();
        let randomNum = Math.random().toString(36).substr(2);
         uid = `${time}${randomNum}`
         sessionStorage.setItem('uid',uid)
      }
      return uid
    },
     changeQrcActive:function(){
      this.uid = this.creatUid()
      if (!this.bindwechat) {
         
      getAuthUrl({
        platform:'wechat',
        unique:this.uid,
        user:this.user.id
      }).then(res=>{
          if (res.code == 200) {
             this.timeinquire()
             this.bindwechat = !this.bindwechat
            //  this.creatQrCode(res.data)
            this.qrcurl = res.data
          }else{
             this.$message({
              showClose: true,
              message: `${res.msg}`,
              type: "warning",
            });
          }
      })
      }else{
          this.cleanTime()
           this.bindwechat = !this.bindwechat
      }
  
    },
      // 定时器
    timeinquire:function(){
        let time = 20;
         this.j = setInterval(() => {
          time--;
          thirdLogin({
            unique:this.uid
          }).then(res=>{
              if (res.code == 200) {
                 if (res.data && res.data.userinfo) {
                    this.$message({
                      showClose: true,
                      message: `绑定成功`,
                      type: "success",
                    });
                    this.cleanTime()
                     sessionStorage.removeItem('uid')
                    //  this.$store.dispatch("userInfo/saveUserInfo", res.data.userinfo);
                    //   localStorage.setItem("token", res.data.userinfo.token);
                    //   localStorage.setItem("user", JSON.stringify(res.data.userinfo));
                        setTimeout(() => {

                          this.$router.push({
                            path:'/personalcenter',
                            query:{
                              type:'1'
                            }
                          })
                          this.$router.go(0)
                  }, 1000);
                 } else {
                   
                 }
              } else {
                    this.$message({
                    showClose: true,
                    message: `${res.msg}`,
                    type: "warning",
                  });
               this.cleanTime()
              }
          })
          if (time == 0) {
            clearInterval(this.j);
          }
        }, 3000);
    },
    cleanTime:function(){
       clearInterval(this.j);
    },
     // 用户协议
    toAgree:function(){
       const { href } = this.$router.resolve({
      path: `/protocol`,
      query: {
        type:'auth'
      }
    });
    window.open(href, '_blank');
    },
    // 菜单选中
    select: function(e) {
      this.activeIndex = e.index;
      this.changeGetOss()
    },
    // 分别获取oss配置
    changeGetOss: function() {
      switch (this.activeIndex) {
        case "0":
          // 获取头像配置信息

          this.ossconfig("avatar");
          break;
        case "2":
          // 获取头像配置信息
          this.ossconfig("auth");
          // this.getAuthSt()
          break;

        default:
          break;
      }
    },
    // 获取地区列表
    addressList:function(){
      // address().then(res=>{
      //   this.arealist = Object.values(res.data)
      // })
      getJob().then(res=>{
          // this.arealist = Object.values(res.data.area)
          this.arealist =res.data.area
        // this.jobList =  Object.values(res.data.identity)
        this.jobList =  res.data.identity
      })
    },
    // 提交实名
    sub:function(){
      subAuth({
        real_name:this.name,
        id_card:this.num,
        card_positive:this.positive,
        card_side:this.side
      }).then(res=>{
          if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
          setTimeout(()=>{
              //  this.$router.go(0)
               this.$router.push({
                 path:'/personalcenter',
                 query:{
                   type:'2'
                 }
               })
               this.$router.go(0)
          },1000)
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }
      })
    },
    // 上传
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 发送验证码
    smsSendCode: function(e) {
      if (this.smsButtonSt) {
        return;
      }
      sms({
        mobile: this.user.mobile,
        event: e,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }

        let time = 60;
        let j = setInterval(() => {
          time--;
          this.smsButtonSt = true;
          this.codetxt = `${time}S`;
          if (time == 0) {
            this.codetxt = "发送验证码";
            this.smsButtonSt = false;
            clearInterval(j);
          }
        }, 1000);
      });
    },
    // 更换绑定手机
    smsSendCodeChange:function(){
       if (this.smsButtonStmobile) {
        return;
      }
      if (this.newMobile == '') {
         this.$message({
            showClose: true,
            message: `请输入手机号码`,
            type: "warning",
          });
          return
      }
      sms({
        mobile:this.newMobile,
        event: 'changemobile',
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }

        let time = 60;
        let j = setInterval(() => {
          time--;
          this.smsButtonStmobile = true;
          this.codetxtMobile = `${time}S`;
          if (time == 0) {
            this.codetxt = "发送验证码";
            this.codetxtMobile = false;
            clearInterval(j);
          }
        }, 1000);
      });
    },
    // 点击更改手机号码
    subChangMobile:function(){
      changeMobile({
        mobile:this.newMobile,
        captcha:this.mobileCode
      }).then(res=>{
          if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });

           setTimeout(()=>{
                  this.$router.push({
                    path:'/personalcenter',
                    query:{
                      type:'1'
                    }
                  })
                  this.$router.go(0)
              },1000)
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }

        this.changeMobileView = false
        this.newMobile = ''
        this.mobileCode = ''
      })
    },
    // 完成修改密码
    subChangePass: function() {
      if (this.passOne !== this.passTwo) {
        this.$message({
          showClose: true,
          message: `两次输入的密码不一致，请重新输入`,
          type: "warning",
        });
        return;
      }

      resetpwd({
        pwd: this.passOne,
        repwd: this.passTwo,
        captcha: this.passCode,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
            setTimeout(()=>{
                  this.$router.push({
                    path:'/personalcenter',
                    query:{
                      type:'1'
                    }
                  })
                  this.$router.go(0)
              },1000)
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "error",
          });
        }

        this.passinputbox = false;
        this.passCode = "";
        this.passOne = "";
        this.passTwo = "";
      });
    },
    // 打开重置密码窗口
    openChangeDialof: function() {
      if (this.passCode == "") {
        this.$message({
          showClose: true,
          message: `请输入验证码`,
          type: "warning",
        });
      }
      this.centerDialogVisible = false;
      setTimeout(() => {
        this.passinputbox = true;
      });
    },

    // 关闭验证码弹窗
    closeCodeDia: function() {
      if (condition) {
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    ossconfig: function(e) {
      ossconfig({
        file_type: "file",
        media_type: e,
      }).then((res) => {
        this.osslist = res.data;
        this.uploadUrl = res.data.host;
      });
    },
    // 身份正面上传
    beforeUploadOne: function(file,item) {

      if (file.length == 0) {
        alert("请选择文件");
        return false;
      } else {
           var FileExt = file.name.replace(/.+\./, "");
        if (["JPG", "PNG", "JPEG",'BMP'].indexOf(FileExt.toUpperCase()) === -1) {
          this.$message({
            type: "warning",
            message: "请检查上传文件的类型",
          });
          this.$refs.videoup.abort();
          return false;
        }
        var _self = this;
        var getSuffix = function(fileName) {
          var pos = fileName.lastIndexOf(".");
          var suffix = "";
          if (pos != -1) {
            suffix = fileName.substring(pos);
          }
          return suffix;
        };

        var filename = new Date().getTime() + getSuffix(file.name);
        _self.up_param.key = _self.osslist.dir + filename;
        _self.up_param.OSSAccessKeyId = _self.osslist.accessid;
        _self.up_param.policy = _self.osslist.policy;
        _self.up_param.Signature = _self.osslist.signature;
          _self.up_param.callback = _self.osslist.callback;
        _self.up_param.success_action_status = 200;
        var fileUrl = _self.osslist.host + "/" + _self.osslist.dir + filename;
        // _self.positive = fileUrl;
        setTimeout(()=>{
                  _self.positive = fileUrl
        },500)
        console.log(fileUrl)
      }
    },
       // 身份反面上传
    beforeUploadTwo: function(file,) {
      if (file.length == 0) {
        alert("请选择文件");
        return false;
      } else {
           var FileExt = file.name.replace(/.+\./, "");
        if (["JPG", "PNG", "JPEG",'BMP'].indexOf(FileExt.toUpperCase()) === -1) {
          this.$message({
            type: "warning",
            message: "请检查上传文件的类型",
          });
          this.$refs.videoup.abort();
          return false;
        }
        var _self = this;
        var getSuffix = function(fileName) {
          var pos = fileName.lastIndexOf(".");
          var suffix = "";
          if (pos != -1) {
            suffix = fileName.substring(pos);
          }
          return suffix;
        };

        var filename = new Date().getTime() + getSuffix(file.name);
        _self.up_param.key = _self.osslist.dir + filename;
        _self.up_param.OSSAccessKeyId = _self.osslist.accessid;
        _self.up_param.policy = _self.osslist.policy;
        _self.up_param.Signature = _self.osslist.signature;
          _self.up_param.callback = _self.osslist.callback;
        _self.up_param.success_action_status = 200;
        var fileUrl = _self.osslist.host + "/" + _self.osslist.dir + filename;
        // _self.positive = fileUrl;
        setTimeout(()=>{
            _self.side = fileUrl
        },500)
        console.log(fileUrl)
      }
    },
    // 头像上传
    beforeUploadAvatar: function(file) {
      if (file.length == 0) {
        alert("请选择文件");
        return false;
      } else {
          var FileExt = file.name.replace(/.+\./, "");
        if (["JPG", "PNG", "JPEG",'BMP'].indexOf(FileExt.toUpperCase()) === -1) {
          this.$message({
            type: "warning",
            message: "请检查上传文件的类型",
          });
          this.$refs.videoup.abort();
          return false;
        }

        var _self = this;
        var getSuffix = function(fileName) {
          var pos = fileName.lastIndexOf(".");
          var suffix = "";
          if (pos != -1) {
            suffix = fileName.substring(pos);
          }
          return suffix;
        };

        var filename = new Date().getTime() + getSuffix(file.name);
        _self.up_param.key = _self.osslist.dir + filename;
        _self.up_param.OSSAccessKeyId = _self.osslist.accessid;
        _self.up_param.policy = _self.osslist.policy;
        _self.up_param.Signature = _self.osslist.signature;
          _self.up_param.callback = _self.osslist.callback;
        _self.up_param.success_action_status = 200;
        var fileUrl = _self.osslist.host + "/" + _self.osslist.dir + filename;
        // _self.user.avatar = fileUrl;
        setTimeout(() => {
          _self.user.avatar = fileUrl;
        }, 500);
      }
    },
    submitForm(formName) {
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          changeProfile({
            nickname: this.ruleForm.name,
            birthday: this.ruleForm.birthday,
            area: this.ruleForm.address,
            identity: this.ruleForm.region,
            gender: +this.ruleForm.gender,
            introduction: this.ruleForm.desc,
            avatar: this.user.avatar,
          }).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: true,
                message: `修改成功`,
                type: "success",
              });
              this.getmyINfo()
              setTimeout(()=>{
                  this.$router.push({
                    path:'/personalcenter',
                    query:{
                      type:'0'
                    }
                  })
                  this.$router.go(0)
              },1000)
            } else {
              this.$message({
                showClose: true,
                message: `${res.msg}`,
                type: "error",
              });
            }
          });
        } else {
          this.$message({
            showClose: true,
            message: `请检查是否有未填写的内容`,
            type: "warning",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 我的信息资料
    getmyINfo: function() {
      userprofile().then((res) => {
        this.user = res.data.user_info;
        setTimeout(()=>{
      this.$store.dispatch("userInfo/saveUserInfo", res.data.user_info);
             localStorage.setItem("user", JSON.stringify(res.data.user_info));
        },500)
          
      });
    },

    // 获取个人信息
    getMe: function() {
      getMyInfo().then((res) => {
        this.ruleForm.name = res.data.nickname;
        this.ruleForm.gender = res.data.gender ? res.data.gender + '':'';
         this.ruleForm.region = res.data.identity? res.data.identity + '' : ''
         this.ruleForm.desc = res.data.introduction || ''
         this.ruleForm.address =  res.data.area ? res.data.area + '' : ''
         this.ruleForm.birthday = res.data.birthday || ''
         this.thirdInfo = res.data.third_info
        // setTimeout(()=>{
        //     this.ruleForm.region = res.data.identity+''
        // },500)
      
      });
    },

    // 查询实名状态
    getAuthSt:function(){
      authStatus().then(res=>{
          this.authSt = res.data.auth_status
          this.authStTxt = res.data.auth_result
          switch (res.data.auth_status) {
            case 1:
              this.$message({
              message: '实名认证信息审核中！',
              type: 'warning'
            });
              break;
               case 0:
                  this.$message({
              message: '请先完善实名认证信息',
              type: 'warning'
            });
              break;
               case 3:
                  this.$message({
              message: '实名认证信息审核未通过！请重新提交',
              type: 'error'
            });
              break;
          
            default:
              break;
          }
      })
    },
    changeAuthSt:function(){
      this.authSt = 0
    }
  },
  created() {},
  mounted() {
    this.activeIndex = this.$route.query.type || '0'
    
    this.getMe();
    this.getmyINfo();
    this.changeGetOss();
    this.addressList()
    this.getAuthSt()
    let height = document.documentElement.clientHeight - 290 
     this.allHeight = height < 720 ? 720:height
  },
};
</script>
<style lang="scss" scoped>
.all {
  // height: 100vh;
  background-color: #f7f7f7;
  padding-top: 39px;
  padding-bottom: 65px;
  .main {
    width: 960px;
    margin: 0 auto;
    display: flex;
  }
}
.main-l {
  width: 224px;
  height: 214px;
  background-color: #fff;
  p {
    position: relative;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding: 15px 72px 19px 72px;
    // border-top: 1px solid #f5f5f5;
  }
  .settingicon {
    position: absolute;
    left: 59px;
    top: 18px;
  }
}
.active {
  color: #e35111;
}
.main-r {
  flex: 1;
  // padding: 40px;
  background-color: #fff;
  margin-left: 30px;
  .r-one {
    border: 1px solid #efa625;
    border-radius: 2px;
    p {
      padding: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .r-two {
    padding-bottom: 60px;
    .iconinfo {
      font-size: 47px;
    }
    .mt20 {
      margin-top: 20px;
    }
    // margin-top: 90px;
    .two-one {
      margin-top: 90px;
      color: #78c06e;

      p {
        text-align: center;
      }
    }
    .two-two {
      margin-top: 90px;
      color: #f02c2c;

      p {
        text-align: center;
      }
      .button {
        margin-top: 36px;
        display: inline-block;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        background: #e35111;
        border-radius: 2px;
        padding: 8px 28px;
      }
    }
    .two-three {
      margin-top: 90px;
      color: #fbb92e;
      p {
        text-align: center;
      }
      .txt {
        margin-top: 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
    .two-four {
      margin-top: 40px;
      // min-height: 720px;
      .four-one {
        margin-bottom: 10px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 10px;
        }
      }
      .four-two {
        margin-top: 40px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 10px;
        }
      }
      .uploadbox {
        display: flex;
      
      }
      .four-three {
        margin-top: 41px;
      }
      .avatar-uploader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 186px;
        height: 110px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: #fbfbfb;
      }
      .four-five {
        display: flex;
        justify-content: center;
        .txt-one {
          margin-left: 5px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9e9e9e;
        }
        .txt-two {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f0a31b;
        }
      }
      .four-six {
        margin-top: 19px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .acconinfo {
    padding: 20px;
    .info-one {
      padding-bottom: 20px;
      // border-top: 1px solid #f5f5f5;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }

    .info-two {
      p {
        margin-left: 10px;
        display: flex;
        padding: 25px 0;
        border-top: 1px solid #f5f5f5;
      }
      .txt-one {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        flex: 1;
      }
      .txt-two {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c4c4c4;
        flex: 3;
      }
      .txt-three {
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        flex: 1;
        margin-right: 10px;
      }
    }
  }

  .my-info {
    padding: 20px;
    .info-one {
      padding-bottom: 20px;
      border-bottom: 1px solid #f5f5f5;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
    .info-two {
      padding: 20px;
      .avat {
        display: flex;
      }
      .avat-l {
        width: 100px;
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
      .avat-r {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        .changeavatar {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f8a305;
          width: 80px;
          text-align: center;
          margin-bottom: 36px;
        }
      }
    }
  }

  .diabox {
    .inputbox {
      margin-bottom: 10px;
      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c4c4c4;
        margin-bottom: 5px;
      }
    }
    .input {
      display: flex;
      border: 1px solid #e5e5e5;
      .inputstyle {
        flex: 1;
        padding-left: 12px;
      }
      span {
        background: #f7f7f7;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        display: inline-block;
        padding: 12px;
      }
    }
    .subbutton {
      width: 100%;
    }
  }

  .passbox {
    .inputstyle {
      padding: 14px 11px;
    }
  }
}

//
.subBg {
  background-color: #e35111;
  border-color: #e35111;
  color: #ffffff;
  padding: 12px 50px;
}
.is-disabled:hover {
  color: #ffffff;
  cursor: not-allowed;
  background-image: none;
  background-color: rgba(227, 81, 17, 0.5);
  border-color: rgba(227, 81, 17, 0.5);
}
.is-disabled {
  background-color: rgba(227, 81, 17, 0.5) !important;
  border-color: rgba(227, 81, 17, 0.5) !important;
}
// 绑定微信弹窗
.bindthird{
  .bindthird-one{
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .bindthird-two p{
     padding-top: 15px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9e9e9e;
  }
}
</style>
<style lang="scss">
.diabox {
  .el-dialog__header {
    border-bottom: 1px solid #f5f5f5;
  }
  .el-dialog__body {
    margin-top: 20px;
    padding: 0 20px !important;
  }
}
.avatar-uploader{
  .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
