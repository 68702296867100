<template>
  <div class="updateAvatar1">
    <!-- <el-button type="primary" @click="visible = true">修改封面</el-button> -->
    <el-dialog
      :visible.sync="visible"
      title=' 1'
      width="800"
      :before-close="handleClose"
    >
    <div class="tab">
       <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item,tabindex) in tabList" :key='tabindex' :label="item.name" :name="item.index" >

          </el-tab-pane>
        </el-tabs>
    </div>
      <!-- 选择视频封面 -->
      <div class="priviewImg" v-show="activeName == '1'">
          <div class="item cursorstyle" v-for="(item,preindex) in perlist" :key='preindex' @click="subBg(item)">
            <img :src="item" alt="">
            <div class="txt">
               <span>设为背景</span>
            </div>
          </div>
      </div>
      <!-- 本地上传封面 -->
      <el-row v-show="activeName == '0'">
        <el-col :span="12" style="height: 300px;">
          <vue-cropper
            ref="cropper"
           :img="options.img"
            :info="true"
            :autoCrop="options.autoCrop"
            :autoCropWidth="options.autoCropWidth"
            :autoCropHeight="options.autoCropHeight"
            :fixedBox="options.fixedBox"
            @realTime="realTime"
          >
          </vue-cropper>
          <div class="btnS">
            <el-upload
              ref="uploadCropper"
              :before-upload="beforeAvatarUpload"
              :show-file-list="false"
              action="/"
              style="margin-right: 10px;"
            >
              <el-button type="primary" size="small">选择图片</el-button>
            </el-upload>
            <el-tooltip
              class="item"
              effect="dark"
              content="向左旋转"
              placement="top"
            >
              <el-button size="small" @click="rotateLeft"
                ><i class="el-icon-refresh-left"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="向右旋转"
              placement="top"
            >
              <el-button size="small" @click="rotateRight"
                ><i class="el-icon-refresh-right"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="放大"
              placement="top"
            >
              <el-button size="small" @click="changeScale(1)"
                ><i class="el-icon-plus"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="缩小"
              placement="top"
            >
              <el-button size="small" @click="changeScale(-1)"
                ><i class="el-icon-minus"></i
              ></el-button>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="12" style="height: 300px;">
          <div class="upload-preview" :style="{width:options.autoCropWidth+'px',height:options.autoCropHeight+'px'}"
          :class="circle == true ? 'circleborder':''">
            <img
              :src="previews.url"
              :style="previews.img"
              v-show="previews.url"
            />
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer"  v-show="activeName == '0'">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="submitUpdate"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import axios from "axios";
import {
  getUserBg
} from "@/api";
export default {
  name: "updateAvatar1",
  components: {
    VueCropper,
  },
  watch: {
    osslist(val) {
      this.ossConfigList = val;
    },
  },
  props: ["osslist", "imgh"],
  methods: {
    subBg:function(e){
          // this.loading = false
          this.show()
        this.$emit('getmsg',e)
    },
     handleClick(tab, event) {
        console.log(this.activeName)
      },
    //   s
    show:function(){
        this.visible = !this.visible
    
       
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    handleClose() {
      this.$emit("changeclicp");
    },
    beforeAvatarUpload(file) {
      // 此处的上传file有多种处理方式
       console.log(this.ossConfigList)
      this.fileName = file.name;
     
      let type = file.type.split("/")[1];
      if (this.uploadAccept.indexOf(type) > -1) {
        // 上传允许的类型之内
        const reader = new FileReader();
        // 把Array Buffer转化为blob 如果是base64不需要
        // 转化为base64
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.img = reader.result;
        };
      } else {
        this.$message.warning("您上传的图片格式不符，请重新上传");
      }
    },
    changeScale(num) {
      this.$refs.cropper.changeScale(num);
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    submitUpdate() {
      this.loading = true;
      // this.$refs.cropper.getCropData((base64) => {
      //   this.uploadImg(base64);
      // });
    //   console.log(this.ossConfigList);
      let formData = new FormData();
      this.$refs.cropper.getCropBlob((data) => {
        // console.log(data);
        this.uploadImg(data);
      });
    },
    uploadImg(e) {
      // 发送ajax请求
      var getSuffix = function(fileName) {
        var pos = fileName.lastIndexOf(".");
        var suffix = "";
        if (pos != -1) {
          suffix = fileName.substring(pos);
        }
        return suffix;
      };
      let formData = new FormData();
      var filename = new Date().getTime() + getSuffix(this.fileName);
      console.log(this.ossConfigList);
      formData.append("key", this.ossConfigList.dir + filename);
      formData.append("OSSAccessKeyId", this.ossConfigList.accessid);
      formData.append("policy", this.ossConfigList.policy);
      formData.append("Signature", this.ossConfigList.signature);
       formData.append("callback", this.ossConfigList.callback);
      formData.append("success_action_status", 200);
      formData.append("file", e);
      var data = formData;
      console.log(this.ossConfigList)
      axios.post(this.ossConfigList.host, data).then((res) => {
        // console.log(res)
        if (res.status == 200) {
          var fileUrl =
            this.ossConfigList.host + "/" + this.ossConfigList.dir + filename;
          console.log(fileUrl);
          this.loading = false
          this.show()
          this.$emit('getmsg',fileUrl)
          //  console.log(fileUrl)
          //   insert(fileUrl);
        } else {
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        }
      });
      // console.log(formData)
    },

    getLocationPic:function(){
      var arr = []
      getUserBg().then(res=>{
          res.data.map(item=>{
            arr.push(item.url)
          })
          this.perlist = arr
      })
      
    }
  },
  mounted() {
    this.options.autoCropWidth = this.imgh.width;
    this.options.autoCropHeight = this.imgh.height;
    this.circle = this.imgh.circle || ''
    if (this.imgh.tabStatus) {
       this.getLocationPic()
    }else{
      this.tabList.splice(1,1)
    }
    // this.getLocationPic()
  },
  data() {
    return {
       activeName: '0',
       tabList:[
         {"name":'封面截取',"index":'0'},
         {"name":'默认图片',"index":'1'},
       ],
       perlist:[],
      visible: false,
      options: {
        img: "", //裁剪图片的地址
        info: true, //裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 200,
        autoCropHeight: 200,
        fixedBox: true, // 固定截图框大小 是否允许改变
        fixed: true, //是否开启截图框宽高固定比例
        fixedNumber: [4, 4], //截图框的宽高比例
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      previews: {},
      loading: false,
      fileList: [],
      uploadAccept: ["jpeg", "jpg", "png"],
      ossConfigList: {},
      fileName: "",
      circle:''
    };
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .el-dialog {
    width: 960px;
    height: 480px;
  }
  .el-dialog__title{
    height: 24px;
    opacity: 0;
  }
  .el-dialog__body {
    width: 800px;
  }

}
.btnS {
  display: flex;
  padding-top: 20px;
  .i {
    position: relative;
    font-size: 12px;
  }
}
.upload-preview {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  // width: 200px;
  // height: 200px;
  //   border-radius: 50%;
  box-shadow: 0 0 4px #eaeaea;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.circleborder{
  border-radius: 50%;
}

.tab{
  position: absolute;
  top: 20px;
}
.priviewImg{
  height: 350px;
    width: 900px;
    overflow-y: scroll;

  .item{
    margin: 20px 0;
    display: flex;
    justify-content: center;
       position: relative;
    .txt{
      position: absolute;
      top: 20px;
      right: 20px;
      color: #ffffff;
      font-size: 19px;
      display: none;
    }
    &:hover .txt{
      display: block;
    }
    img{
      width: 100%;
    }
  }

}
</style>
