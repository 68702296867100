<template>
  <div class="all">
    <div class="bg cursorstyle"  :style="{
        backgroundImage: 'url(' + user.background_img + ')',
        backgroundSize: 'cover',
        
      }" 
     >
      <!-- <img v-lazy="user.background_img" alt="" /> -->
      <!-- <div  @click="clicp" class="cursorstyle">
            <i class="el-icon-edit editback " ></i>
      </div> -->
      <div class="show" >
          <p @click="clicp"><i class="el-icon-edit cursorstyle"  ></i> <span>更改背景</span></p>
      </div>
    </div>
    <div class="infobox">
      <div class="infobox-l">
        <div class="img-box">
          <img :src="user.avatar" alt="" />
        </div>
        <div class="txt-box">
          <p class="name">{{ user.username }}  <span><img  v-if="user.gender == 1" src="../../assets/img/man.png" alt="" class="gender"> <img  v-if="user.gender == 2" src="../../assets/img/woman.png" alt="" class="gender"></span>  <span class="area" v-if="user.area_text !== ''"> <i class="el-icon-location">{{user.area_text}}</i></span>
            </p>
          <p class="in">{{ user.introduction }}</p>
        </div>
      </div>
      <div class="infobox-r">
        <div class="r-one cursorstyle" @click="toMycenter('1')">
          <p>{{ user.follow }}</p>
          <p>关注</p>
        </div>
        <div class="r-one cursorstyle" @click="toMycenter('2')">
          <p>{{ user.fans }}</p>
          <p>粉丝</p>
        </div>
        <div class="r-one">
          <p>{{ user.real_pop }}</p>
          <p>人气值</p>
        </div>
        <!-- <div class="r-one">
          <p>{{ user.area_text }}</p>
          <p>地区</p>
        </div> -->
        <!-- <div class="r-two">
          <span class="active cursorstyle" @click="follow" v-show="user.is_follow == 0"
            >关注</span
          >
          <span
            class="noactive cursorstyle"
            @click="canclefollow"
            v-show="user.is_follow == 1"
            >已关注</span
          >
        </div> -->
      </div>
    </div>
    <div class="navbox">
      <div class="item">
        <span
        class="cursorstyle"
          v-for="(item, navindex) in tab"
          :key="navindex"
          :class="activeNav == item.index ? 'tabactive' : 'notabactive'"
          @click="selectNav(item)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <div class="main">
      <!-- 文章列白哦 -->
      <div class="article-box" v-show="activeNav == '1'">
        <div
          class="articlebox cursorstyle"
          v-for="(item, z) in artiLIst"
          :key="z"
          @click="toArticle(item.id)"
        >
          <div class="article-l photoZoom">
            <img v-lazy="item.cover" alt="" srcset="" />
          </div>
          <div class="article-r">
            <div class="article-r-one">
              <p>{{ item.title }}</p>
            </div>
            <div class="article-r-two">
              <p>
                {{ item.description }}
              </p>
            </div>
                  <div class="article-r-three">
              <div class="three-r">
                <!-- <div class="r-one">
                  <img src="../../assets/img/time.png" alt="" class="iconone" />
                  <p>刚刚</p>
                </div> -->
                <div class="r-one" >
                  <img src="../../assets/img/show.png" alt="" class="icontwo" />
                  <p>{{ item.total_scan }}</p>
                </div>
              </div>

              <div class="three-l">
                <!-- <img :src="item.user_info.avatar" alt="" class="avatar" /> -->
                <p>{{ item.created_at | time }}</p>
              </div>
            </div>

            <!-- 是否管理员 -->
           
                  <div class="checkbox" style="padding-top: 10px;">
                      <el-popover
                    v-if="item.check_status == 2"
                    placement="top"
                    title="不通过原因"
                    width="200"
                    trigger="hover"
                    :content="item.check_result">
                    <span slot="reference"><i class="el-icon-question" style="color:orange"></i> </span>
                  </el-popover>
                    <p class="checkbox-one"> <span :class="[ item.check_status == 0 ?'waring':'', item.check_status == 2 ?'red':'',]">{{st[item.check_status]}}</span></p>
                     <div > 
                        <p class="checkbox-two" > <span class="edit cursorstyle"  @click.stop="editItem('publicarticle',item,)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('article',item,z)"  >删除</span> </p>
                     </div>
                     <!-- <div v-if="isAdmin !== 1">
                        <p class="checkbox-two" > <span class="edit cursorstyle" v-if="item.check_status !== 1" @click.stop="editItem('publicarticle',item,)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('article',item,z)"  v-if="item.check_status !== 1">删除</span> </p>
                     </div> -->
                    <!-- <p class="checkbox-two" > <span class="edit cursorstyle" v-if="item.check_status !== 1" @click.stop="editItem('publicarticle',item,)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('article',item,z)"  v-if="item.check_status !== 1">删除</span> </p> -->
                  </div>
      
          </div>
        </div>
      </div>
      <!-- 视频列表 -->
      <div class="video-box" v-show="activeNav == '0'">
        <div class="card-box">
          <el-row :gutter="20">
            <el-col
            class="cursorstyle"
              :span="6"
              v-for="(item, saindex) in videoList"
              :key="saindex"
              @click="toVideo(item.id)"
              ><div
                class="grid-content cursorstyle"
                style=" margin-bottom: 30px;"
                @click="toVideo(item.id)"
              >
                <div class="card-box-one photoZoom">
                  <img v-lazy="item.cover" alt="" />
                  <span class="transtime">{{item.play_time | transTime}}</span>
                  <el-popover
                    placement="top"
                    width="50"
                    trigger="hover"
                    offset="60"
                    v-if="item.is_join_activity == 1"
                    >
                    <p style="textAlign: center;color:#409eff" class="cursorstyle" @click="toActive">查看活动</p>
                    <!-- <el-button slot="reference">hover 激活</el-button> -->
                     <div class="activebox" slot="reference">
                      <div class="txt">
                        <p>{{item.activity_label}}</p>
                      </div>
                  </div>
                  </el-popover>
                 
                </div>
                <div class="card-box-two">
                  <p>{{ item.title }}</p>
                </div>
             
                <div class="card-box-play" style="display:flex;justify-content:space-between">
                    <div style="display:flex; align-items: center;">
                     <img src="../../assets/img/show.png" alt="" class="play" />
                  <span style="margin-left:10px">{{ item.total_scan }}</span>
                  </div>
                 
                  <!-- <img src="../../assets/img/unlike.png" alt="" class="play" style="margin-left:24px">
                      <span style="margin-left:10px">123</span> -->
                      <div style="padding-right:10px">
                         <span>{{item.created_at | timeNew}}</span>
                      </div>
                </div>
                   <div class="card-box-three">
                  <!-- <span>{{ item.area }}</span>
                  <span class="line">{{ item.label_text }}</span> -->
                  <div class="checkbox">
                    <el-popover
                    v-if="item.check_status == 2"
                    placement="top"
                    title="不通过原因"
                    width="200"
                    trigger="hover"
                    :content="item.check_result">
                    <span slot="reference"><i class="el-icon-question" style="color:orange"></i> </span>
                  </el-popover>
                    <p class="checkbox-one"> <span :class="[ item.check_status == 0 ?'waring':'', item.check_status == 2 ?'red':'',]">{{st[item.check_status]}}</span></p>

                  <div  >
                    <p class="checkbox-two" style="padding-right: 16px;"> <span class="edit cursorstyle"  @click.stop="editItem('publicvideo',item)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('video',item,saindex)" >删除</span> </p>

                  </div>
                  <!-- <div  v-if="isAdmin !== 1">
                    <p class="checkbox-two" style="padding-right: 16px;"> <span class="edit cursorstyle" v-if="item.check_status !== 1" @click.stop="editItem('publicvideo',item)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('video',item,saindex)" v-if="item.check_status !== 1">删除</span> </p>

                  </div> -->
                  </div>
                </div>
                <!-- <div class="card-box-four" >
                  <div class="card-box-for-img">
                    <img v-lazy="user.avatar" alt="" />
                  </div>
                  <div class="card-box-for-author">
                    <span>{{ user.username }}</span>
                  </div>
                  <div class="card-box-for-job">
                    <span>{{ user.identity_text }}</span>
                  </div>
                </div> -->
              </div></el-col
            >
          </el-row>
        </div>
      </div>

      <!-- 音频列白哦 -->
      <div class="audio-box" v-show="activeNav == '2'">
        <div class="item-box" v-show="mediaList.length > 0">
          <div
            class="item cursorstyle"
            v-for="(item, x) in mediaList"
            :key="x"
            @click="tomediaDetail(item.id)"
          >
            <div class="item-one photoZoom">
              <img v-lazy="item.cover" alt="" />
            </div>
            <div class="item-two">
              <p>{{ item.title }}</p>
            </div>
            <!-- <div class="item-three">
              <img :src="user.avatar" alt="" />
              <span>{{ user.username }}</span>
            </div> -->
            <div class="item-four">
              <p class="four-one"> <img src="../../assets/img/play.png" alt=""> <span>{{item.total_scan}}</span></p>
              <p class="four-two">{{item.created_at | timeNew}}</p>
            </div>
            <div class="item-five">
                           <div class="checkbox">
                    <el-popover
                    v-if="item.check_status == 2"
                    placement="top"
                    title="不通过原因"
                    width="200"
                    trigger="hover"
                    :content="item.check_result">
                    <span slot="reference"><i class="el-icon-question" style="color:orange"></i> </span>
                  </el-popover>
                    <p class="checkbox-one"> <span :class="[ item.check_status == 0 ?'waring':'', item.check_status == 2 ?'red':'',]">{{st[item.check_status]}}</span></p>
                      <div >
                    <p class="checkbox-two" > <span class="edit cursorstyle"  @click.stop="editItem('publicmedia',item)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('audio',item,x)" >删除</span> </p>

                  </div>
                  <!-- <div  v-if="isAdmin !== 1">
                    <p class="checkbox-two" > <span class="edit cursorstyle" v-if="item.check_status !== 1" @click.stop="editItem('publicmedia',item)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('audio',item,x)" v-if="item.check_status !== 1">删除</span> </p>

                  </div> -->
                  </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 图片列表 -->
      <div class="photo-box" v-show="activeNav == '3'">
        <div class="card-box">
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, phindex) in photoList"
              :key="phindex"
              
              ><div class="grid-content cursorstyle" style=" margin-bottom: 30px;" @click="tophoto(item.id)">
                <div class="card-box-one ">
                  <!-- <img v-lazy="item.cover" alt="" /> -->
                   <div v-lazy:background-image="item.cover" class="bg"></div>
                </div>
                <div class="card-box-two">
                  <p>{{ item.title }}</p>
                </div>
                <!-- <div class="card-box-three">
                  <span>{{ item.area }}</span>
                  <span class="line">{{ item.label_text }}</span>
                </div> -->
                <div class="card-box-play" style="display:flex;justify-content:space-between">
                  <div style="display:flex; align-items: center;">
                     <img src="../../assets/img/show.png" alt="" class="play" />
                  <span style="margin-left:10px">{{ item.total_scan }}</span>
                  </div>
                 
                  <!-- <img src="../../assets/img/unlike.png" alt="" class="play" style="margin-left:24px">
                      <span style="margin-left:10px">123</span> -->
                      <div style="padding-right:10px">
                         <span>{{item.created_at | timeNew}}</span>
                      </div>
                </div>
                <!-- <div class="card-box-four" >
                  <div class="card-box-for-img">
                    <img v-lazy="user.avatar" alt="" />
                  </div>
                  <div class="card-box-for-author">
                    <span>{{ user.username }}</span>
                  </div>
                  <div class="card-box-for-job">
                    <span>{{ item.user_info.identity_text }}</span>
                  </div>
                </div> -->
                <div class="card-box-six">
                         <div class="checkbox">
                    <el-popover
                    v-if="item.check_status == 2"
                    placement="top"
                    title="不通过原因"
                    width="200"
                    trigger="hover"
                    :content="item.check_result">
                    <span slot="reference"><i class="el-icon-question" style="color:orange"></i> </span>
                  </el-popover>
                    <p class="checkbox-one"> <span :class="[ item.check_status == 0 ?'waring':'', item.check_status == 2 ?'red':'',]">{{st[item.check_status]}}</span></p>
                      <div  >
                    <p class="checkbox-two" > <span class="edit cursorstyle"  @click.stop="editItem('upload',item)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('atlas',item,phindex)" >删除</span> </p>

                  </div>
                  <!-- <div  v-if="isAdmin !== 1">
                    <p class="checkbox-two" > <span class="edit cursorstyle" v-if="item.check_status !== 1" @click.stop="editItem('upload',item)">编辑</span>   <span class="remove cursorstyle" @click.stop="deleteItem('atlas',item,phindex)" v-if="item.check_status !== 1">删除</span> </p>

                  </div> -->
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>

      <!-- 我的资料 -->
      <div v-show="activeNav == '4'">
        <div class="message-box" v-show="activeNav == '4'">
          <div class="box-one">
            <p class="one-txt">性别</p>
            <p class="two-txt">{{ stgender[user.gender - 1] }}</p>
          </div>
          <div class="box-one">
            <p class="one-txt">生日</p>
            <p class="two-txt">{{ user.birthday || "该用户没有填写生日" }}</p>
          </div>
          <div class="box-one">
            <p class="one-txt">所在地</p>
            <p class="two-txt">{{ user.area_text }}</p>
          </div>
          <div class="box-one">
            <p class="one-txt">关于我</p>
            <p class="two-txt">{{ user.introduction }}</p>
          </div>
        </div>
        <div class="line" style="height:30px"></div>
      </div>

      <!-- 下方分页参数 -->
      <div class="page" v-show="activeNav !== '4' && datanum !== 0 && allpage > 1">
        <el-pagination
          background
          layout=" pager, next"
          :total="totalNUm"
          :current-page="page"
          :page-count="allpage"
          :page-size="per"
          @current-change="handleCurrentChange"
          @next-click="handleNext"
        >
        </el-pagination>
      </div>

      <!-- 无数据提示 -->
      <div class="empty" v-show="activeNav !== '4' && datanum == 0 ">
        <div class="emptyone">
          <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
        </div>
        <div class="emptytxt">
          <p>暂时没有数据，过会再来看看吧</p>
        </div>
      </div>
    </div>
     <clip :osslist="osslist" :imgh="imgh" @changeclicp="clicp" @getmsg="getMsgFormSon" ref="clic"></clip>
  </div>
</template>

<script>
import clip from "../../components/clipPhoto"
import {
 meArticle,
  meVideo,
  meMedia,
  mePhoto,
  getMyInfo,
  followCreator,
  CanclefollowCreator,
  ossconfig,
  changeBack,
  deleteWork
} from "@/api";
export default {
  components: {
      clip
  },
  props: {},
  data() {
    return {
      id: "",
      tab: [
        { name: "视频", index: "0" },
        { name: "文章", index: "1" },
        { name: "音频", index: "2" },
        { name: "图片", index: "3" },
      ],
      activeNav: "0",
      artiLIst: [],
      page: 1,
      totalNUm: null,
      videoList: [],
      mediaList: [],
      photoList: [],
      user: "",
      stgender: ["男", "女"],
      allpage: 0,
      per: 0,
      datanum: 1,
          imgh:{
        width:342,
        height:57,
        tabStatus:true
      },
      clicpShow:false,
      osslist:'',
      st:['待审核','审核通过','审核未通过'],
      editBg:false,
      isAdmin:null
    };
  },
  watch: {},
  filters: {
    time: function(val) {
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      let time1 = new Date().getTime(); //当前的时间戳
      let time2 = Date.parse(new Date(val)); //指定时间的时间戳
      let time = time1 - time2;

      let result = null;
      if (time < 0) {
        result = "刚刚";
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
  },
  computed: {},
  methods: {
    toMycenter:function(e){
          const { href } = this.$router.resolve({
        path:'/userworks',
        query: {
          type: e   
        }
      });
      window.open(href, "_blank");
    },
    toActive:function(){
         const { href } = this.$router.resolve({
        path:'/2021_6',
      });
      window.open(href, "_blank");
    },
    // 编辑项目
    editItem:function(path,item){
      this.$router.push({
        path:`/${path}`,
        query:{
          id:item.id
        }
      })
    },
    // 删除操作
    deleteItem:function(type,item,index){
     
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          deleteWork({
            type:type,
            work:item.id
          }).then(res=>{
            if (res.code == 200) {
              this.$message({
            type: 'success',
            message: '删除成功!'
          });
            switch (type) {
              case 'audio':
                this.mediaList.splice(index,1)
                break;
                 case 'video':
                  this.videoList.splice(index,1)
                break;
                 case 'article':
                  this.artiLIst.splice(index,1)
                break;
                 case 'atlas':
                    this.photoList.splice(index,1)
                break;
            
              default:
                break;
            }
            } else {
                this.$message({
            type: error,
            message: res.msg
          });
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
  
        clicp:function(){
            console.log('13')
      // this.clicpShow = !this.clicpShow
      this.$refs.clic.show()
    },
    getMsgFormSon:function(data){
      // console.log(data)
    //   this.cover = data
      console.log(data)
      changeBack({
          img:data
      }).then(res=>{
          if (res.code == 200) {
              this.user.background_img = data
          }else{
               this.$message({
          showClose: true,
          message: res.msg,
          type: "error",
        });
          }
      })
    },
    selectNav: function(e) {
      this.activeNav = e.index;
      this.pagechange();
    },
    // 分页切换
    pagechange: function() {
      switch (this.activeNav) {
        case "0":
          this.getvideoList();
          break;
        case "1":
          this.articleList();
          break;
        case "2":
          this.getaudioList();
          break;
        case "3":
          this.getPhotoList();
          break;
        case "4":
          break;

        default:
          break;
      }
    },

    articleList: function() {
     meArticle({
        page: this.page,
      }).then((res) => {
        this.artiLIst = res.data.data;
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page;
        this.per = +res.data.per_page;
        this.datanum = res.data.data.length;
      });
    },
    getvideoList: function() {
      meVideo({
        page: this.page,
        per: 8,
      }).then((res) => {
        this.videoList = res.data.data;
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page;
        this.per = +res.data.per_page;
        this.datanum = res.data.data.length;
      });
    },
    getaudioList: function() {
      meMedia({
        page: this.page,
      }).then((res) => {
        this.mediaList = res.data.data;
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page;
        this.per = +res.data.per_page;
        this.datanum = res.data.data.length;
      });
    },
    getPhotoList: function() {
      mePhoto({
        page: this.page,
        per: 8,
      }).then((res) => {
        this.photoList = res.data.data;
        this.totalNUm = res.data.total;
        this.allpage = res.data.last_page;
        this.per = +res.data.per_page;
        this.datanum = res.data.data.length;
      });
    },
    getgetMyInfo: function() {
      getMyInfo({
      }).then((res) => {
        this.user = res.data;
        this.isAdmin = res.data.work_uncheck
      });
    },
    follow: function(e) {
      followCreator({
        creator: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.user.is_follow = 1;
          this.user.fans++;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    canclefollow: function(e) {
      CanclefollowCreator({
        creator: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.user.is_follow = 0;
          if (this.user.fans !== 0) {
            this.user.fans--;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    handleCurrentChange: function(val) {
      this.page = val;
      // this.getList()
      // this.selectNav()
      this.pagechange();
    },
    handleNext: function(val) {
      this.page = val;
      // this.getList()
      this.pagechange();
    },

    toArticle: function(e) {
      this.$router.push({
        path: "/articledetail",
        query: {
          id: e,
        },
      });
    },

    toVideo: function(e) {
      this.$router.push({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
    },

    tomediaDetail: function(e) {
      this.$router.push({
        path: "/mediadetail",
        query: {
          id: e,
        },
      });
    },

    tophoto: function(e) {
      this.$router.push({
        path: "/photodetail",
        query: {
          id: e,
        },
      });
    },
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
     this.activeNav = this.$route.query.type || '0'
    this.$nextTick(function() {
      this.getgetMyInfo();
      this.pagechange();
      //   this.getDetail();
      //  this.getaudioList()
        ossconfig({
        file_type: "file",
        media_type: "background",
      }).then((res) => {
        this.osslist = res.data;
        // this.uploadUrl = res.data.host;
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.all {
  .bg {
      position: relative;
    height: 280px;
    overflow: hidden;
    z-index: 9;
    // pointer-events:none;
    .editback{
        display: inline-block;
        color: #e35111;
        font-size: 26px;
        position: absolute;
        pointer-events:auto;
            z-index: 99;
            // background-color: red;
            right: 5%;
            top: 10%;
    }
    img {
      width: 100%;
    }
    .show{
      opacity:0;
          width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 20px;
    }
    &:hover .show{
      // display: block;
      opacity: 1;
    }
  }
  .main {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: #f7f7f7;
  }
}

.infobox {
  display: flex;
  margin: 0 auto;
  width: 1200px;
  margin-top: -31px;
  .infobox-l {
    display: flex;
    align-items: flex-end;
    .img-box {
      img {
        width: 108px;
        height: 108px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: relative;
         z-index: 9;
          background-color: #fff;
      }
    }
    .txt-box {
      padding-left: 23px;

      padding-bottom: 10px;

      .name {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1d1d1d;
           padding-bottom: 5px;
        .gender{
          width: 20px;
          margin-left: 5px;
          
        }
        .area{
          color: #a9a9a9;
           margin-left: 10px;
        }
        .el-icon-location{
          font-size: 15px;
          color: #a9a9a9;
        }
      }
      .in {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .infobox-r {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 31px;
    .r-one {
      margin-left: 55px;
    }
    .r-one p:nth-child(1) {
      text-align: center;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .r-one p:nth-child(2) {
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
    .r-two {
      margin-left: 104px;
      .active {
        display: inline-block;
        background: #e35111;
        border-radius: 4px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fffefe;
        padding: 9px 30px;
      }
      .noactive {
        display: inline-block;
        background: #dedede;
        border-radius: 4px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fffefe;
        padding: 9px 30px;
      }
    }
  }
}

//
.navbox {
  .item {
    display: flex;
    justify-content: center;
    span {
      display: inline-block;
      padding: 20px 0 14px 0;
      margin: 0 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
  }
}
.tabactive {
  color: #e35111;
  border-bottom: 2px solid #e35111;
}
.notabactive {
  color: #666666;
}
// 文章
.article-box {
  width: 960px;
  margin: 0 auto;
  // 文章列表
  .articlebox {
    height: 240px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    .article-l {
      img {
        width: 360px;
        height: 240px;
      }
    }
    .article-r {
      flex: 1;
      background-color: #ffffff;
      padding: 25px 22px 20px 20px;
      display: flex;
      flex-direction: column;
      .article-r-one {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #1d1d1d;
      }
      .article-r-two {
        flex: 1;
        padding-top: 28px;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
      .article-r-three {
        display: flex;
        .three-l {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: flex-end;
          //  justify-content: space-between;
        }
        .three-r {
          flex: 1;
          display: flex;
          align-items: center;
          //   justify-content: flex-end;
          //   justify-content: flex-end;
          .r-one {
            display: flex;
            align-items: center;
          }
          .iconone {
            width: 14px;
          }
          .icontwo {
            width: 19px;
          }
          p {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        p {
          margin-left: 10px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}

// 视频
.video-box {
  width: 960px;
  margin: 0 auto;
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    background: #ffffff;
    padding-bottom: 15px;
  }
  .card-box {
    overflow: hidden;
    .card-box-one {
      height: 126px;
      overflow: hidden;
      position: relative;
      .activebox{
        position: absolute;
    top: 17px;
    left: -28px;
      .txt{
        background-color: orange;
            padding: 4px 30px;
       color: #fff;
          transform: rotate(
      315deg
      );
      
      }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .card-box-two {
      margin-top: 20px;
      margin-left: 16px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card-box-three {
      margin-top: 10px;
      margin-left: 16px;
         overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .line {
        margin-left: 12px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
      }
    }
    .card-box-four {
      display: flex;
      padding: 11px 16px;
      align-items: center;
      .card-box-for-img {
        width: 24px;
        height: 24px;
      }
      .card-box-for-img img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .card-box-for-author {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
      }
      .card-box-for-job {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
        margin-left: 16px;
      }
    }
    .card-box-five {
      text-indent: 2em;
      padding: 3px 16px;
      color: #bebebe;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .card-box-play {
      margin-left: 16px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .play {
        width: 12px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
      }
    }
  }
}

// 音频
.audio-box {
  width: 960px;
  margin: 0 auto;
  background-color: #ffffff;

  .item-box {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    //   padding: 10px;
    .item {
      width: 20%;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      .item-one {
        img {
          // width: 100%;
          width: 100%;
          height: 140px;
          border-radius: 8px;
        }
      }
      .item-two {
        margin-top: 20px;
        p {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .item-three {
        margin-top: 14px;
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        span {
          margin-left: 5px;
        }
      }

      .item-four{
        display: flex;
        margin-top: 5px;
        align-items: center;
        justify-content: space-between;
  .four-one{
    display: flex;
    align-items: center;
      img{
        width: 12px;
      }
      span{
        font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #bebebe;
    margin-left: 5px;
      }
  }
  .four-two{
       font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #bebebe;
    margin-left: 5px;
  }
      }

      .item-five{
        margin-top: 5px;
      }
    }
  }
}

// 图片
.photo-box {
  width: 960px;
  margin: 0 auto;
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    background: #ffffff;
    overflow: hidden;
  }
  .card-box {
    overflow: hidden;
    .card-box-one {
      height: 150px;
      padding: 11px;
      overflow: hidden;
      display: flex;
      align-items: center;
      .bg{
       background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    }
      img {
       
      }
    }
    .card-box-two {
      margin-top: 20px;
      margin-left: 16px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card-box-three {
      margin-top: 10px;
      margin-left: 16px;
      .line {
        margin-left: 12px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
      }
    }
    .card-box-four {
      display: flex;
      padding: 11px 16px;
      align-items: center;
      .card-box-for-img {
        width: 24px;
        height: 24px;
      }
      .card-box-for-img img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .card-box-for-author {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
      }
      .card-box-for-job {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
        margin-left: 16px;
      }
    }
    .card-box-five {
      text-indent: 2em;
      padding: 3px 16px;
      color: #bebebe;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .card-box-play {
      margin-left: 16px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .play {
        width: 12px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bebebe;
      }
    }
    .card-box-six{
          padding: 5px 16px;
    }
  }
}


.message-box {
  width: 960px;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px;
  .box-one {
    margin-top: 40px;
    display: flex;
    .one-txt {
      width: 10%;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .two-txt {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
}

.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 20px;
    }
  }
}

// 审核状态
.checkbox{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #bebebe;
  .checkbox-one{
    flex: 1;

  }
  .checkbox-two{
     flex: 1;
     text-align: right;
    //  padding-right: 16px;
     .edit:hover{
       color: #4FC3F7;
     }
     .remove:hover{
       color: red;
     }
  
  }
}
 .waring{
       color: orange !important;
     }
     .red{
       color: red !important;
     }
</style>

<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
