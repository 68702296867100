<template>
  <div class="main">
    <!-- 中间登陆 -->
    <div class="box">
      <div class="box-one">
        <!-- <p class="title-one">C位河南</p>
        <p class="title-two">河南文化传播交流平台</p> -->
        <img src="../../assets/img/loginlogo.png" alt="" style="width:100%">
      </div>
      <div v-show="!bindMobile">
          <!-- 登陆框 -->
      <div class="box-two" v-show="changeActive">
        <!-- 二维码登录显示又上角 -->
        <div class="qrcbox">
            <div class="topright">
                <img src="../../assets/img/qrc.png" alt="" class="cursorstyle" @click="changeQrcActive()" v-show="!qrcactive">
                 <img src="../../assets/img/phone.png" alt="" class="cursorstyle" @click="changeQrcActive()" v-show="qrcactive">
            </div>
        </div>
        <!-- 显示登录 -->
        <div v-show="!qrcactive">
             <div class="tab">
          <div class="tabitem">
            <div
              v-for="(item, x) in tab"
              :key="x"
              @click="select(x)"
              class="tab-item cursorstyle"
              :class="activeTab == x ? 'tabactive' : ''"
            >
              <div class="tabtxt" :class="activeTab == x ? 'txtactive' : ''">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="inputbox">
          <div class="input-box-one">
            <el-input
              v-model="phone"
              placeholder="请输入手机号码"
              maxlength="11"
            ></el-input>
            <div class="phonetxt">{{ phonetxt }}</div>
          </div>
          <div class="input-box-one" v-if="activeTab == '0'">
            <el-input
              v-model="password"
              placeholder="请输入密码"
              maxlength="20"
              show-password
            ></el-input>
            <div class="phonetxt">{{ passtxt }}</div>
          </div>
          <div class="input-box-one" v-if="activeTab == '1'">
            <div class="codebox">
              <el-input placeholder="请输入验证码" v-model="smscode">
              </el-input>
              <el-button class="smsbg" @click="smsSend"  :disabled="smsButtonSt">{{smstxt}}</el-button>
            </div>

            <div class="phonetxt">{{ codetxt }}</div>
          </div>
          <div class="loginbutton">
            <div class="logintxt cursorstyle" @click="login">
              登录
            </div>
          </div>
          <div class="forget">
            <p>忘记密码</p>
          </div>
        </div>
        </div>

        <!-- 显示二维码 -->
        <div v-show="qrcactive" class="qrcshow">
          <!-- <div class="qrcode" ref="qrCodeUrl"></div> -->
          <div class="qr">
             <vue-qr :text="qrcurl" :size="180"></vue-qr>
          </div>
         
          <p>打开微信，扫一扫直接登录</p>
        </div>

        <!-- 最下方内容 -->
        <div class="message cursorstyle" @click="changebox">
          <p>没有账号？ 注册</p>
        </div>
      </div>

      <!-- 注册框 -->
      <div
        class="box-two"
        v-show="!changeActive"
        :class="!changeActive ? 're-height' : ''"
      >
        <div class="re-title">
          注册
        </div>
        <div class="inputbox">
          <div class="input-box-one">
            <el-input
              v-model="nickname"
              placeholder="请输入昵称"
              maxlength="25"
            ></el-input>
            <div class="phonetxt">
              {{ nicknametxt }}
            </div>
          </div>
          <div class="input-box-one">
            <el-input
              v-model="phone"
              placeholder="请输入手机号码"
              maxlength="11"
            ></el-input>
            <div class="phonetxt">{{ phonetxt }}</div>
          </div>
          <div class="input-box-one">
            <div class="codebox">
              <el-input
                placeholder="请输入验证码"
                v-model="smscode"
                maxlength="4"
              >
              </el-input>
              <el-button
                class="smsbg"
                @click="smsSend"
                :disabled="smsButtonSt"
                >{{ smstxt }}</el-button
              >
            </div>
            <div class="phonetxt">{{ codetxt }}</div>
          </div>
          <div class="input-box-one">
            <el-input
              v-model="password"
              placeholder="请输入密码"
              maxlength="20"
              show-password
            ></el-input>
            <div class="phonetxt">{{ passtxt }}</div>
          </div>

          <div class="loginbutton cursorstyle" @click="toRegister">
            <div class="logintxt">
              注册
            </div>
            <!-- <el-button class="rebutton">登录</el-button> -->
            <!-- <button class="rebutton logintxt" disabled="false">登录</button> -->
          </div>
          <div class="re-forget cursorstyle" @click="toAgree">
            <p>注册即代表同意C位河南<span style="color:#e35111" >《用户协议》</span></p>
          </div>
        </div>
        <!-- 最下方内容 -->
        <div class="message cursorstyle" @click="changebox">
          <p>已有账号？ 登录</p>
        </div>
      </div>
      </div>
    

      <!-- 绑定手机号码 -->
      <div v-show="bindMobile">
        <div class="box-two">
            <div class="re-title">
          绑定手机号码
        </div>
        <div class="inputbox">
         
          <div class="input-box-one">
            <el-input
              v-model="phone"
              placeholder="请输入手机号码"
              maxlength="11"
            ></el-input>
            <div class="phonetxt">{{ phonetxt }}</div>
          </div>
          <div class="input-box-one">
            <div class="codebox">
              <el-input
                placeholder="请输入验证码"
                v-model="smscode"
                maxlength="4"
              >
              </el-input>
              <el-button
                class="smsbg"
                @click="BindsmsSend"
                :disabled="smsButtonSt"
                >{{ smstxt }}</el-button
              >
            </div>
            <div class="phonetxt">{{ codetxt }}</div>
          </div>
          <div class="loginbutton cursorstyle" @click="toBindMobile">
            <div class="logintxt">
              提交
            </div>
            <!-- <el-button class="rebutton">登录</el-button> -->
            <!-- <button class="rebutton logintxt" disabled="false">登录</button> -->
          </div>
          <!-- <div class="re-forget cursorstyle" @click="toAgree">
            <p>注册即代表同意C位河南<span style="color:#e35111" >《用户协议》</span></p>
          </div> -->
          
        </div>
           <div class="message cursorstyle" @click="changeBindBox">
          <p>已有账号？ 登录</p>
        </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { register, sms, login, codelogin ,getAuthUrl,thirdLogin,changeMobile} from "@/api";
import { mapState } from "vuex";
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr
  },
  props: {},
  data() {
    return {
      tab: [
        { name: "密码登陆", value: "0" },
        { name: "短信登陆", value: "1" },
      ],
      activeTab: "0",
      phone: "",
      phonetxt: "",
      password: "",
      smstxt: "发送验证码",
      smscode: "",
      nickname: "",
      changeActive: true,
      smsButtonSt: false,
      nicknametxt: "",
      codetxt: "",
      passtxt: "",
      flag: true,
      qrcactive:false,
      j:null,
      uid:'',
      qrcode:null,
      qrcurl:'',
      bindMobile:false,
      third:'',
      snapUserinfo:''
    };
  },
  watch: {},
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    toBindMobile:function(){
       if (this.phone == "") {
        this.phonetxt = "请输入手机号码";
        return;
      }
        if (this.smscode == "") {
          this.codetxt = "请输入验证码";
          return;
        }

        changeMobile({
        mobile:this.phone,
        captcha:this.smscode,
        third:this.third
      }).then(res=>{
          if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `登录成功`,
            type: "success",
          });
          if (res.data && res.data.userinfo) {
              this.$store.dispatch("userInfo/saveUserInfo", res.data.userinfo);
                      localStorage.setItem("token", res.data.userinfo.token);
                      localStorage.setItem("user", JSON.stringify(res.data.userinfo));
                        setTimeout(() => {
                        this.$router.go(-1);
                       }, 1000);
          }
          
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }

      })
    },
    BindsmsSend:function(){
       if (this.phone == "") {
        this.phonetxt = "手机号码不能为空";
        return;
      } else if (
        !/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
          this.phone
        )
      ) {
        this.phonetxt = "请检查输入的手机号码";
        return;
      }
        // 通过验证
      this.phonetxt = "";
      sms({
        mobile: this.phone,
        event: 'changemobile',
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }

        let time = 60;
        let j = setInterval(() => {
          time--;
          this.smsButtonSt = true;
          this.smstxt = `${time}S`;
          if (time == 0) {
            this.smstxt = "发送验证码";
            this.smsButtonSt = false;
            clearInterval(j);
          }
        }, 1000);
      });
    },
    changeBindBox:function(){
      this.bindMobile = !this.bindMobile
        this.phone = "";
      this.password = "";
      this.smscode = "";
      this.nickname = "";
      this.phonetxt = "";
      this.codetxt = "";
    },
    // 生成随机数
    creatUid:function(){
      let uid = sessionStorage.getItem('uid')
      if (!uid) {
         let time = new Date().getTime();
        let randomNum = Math.random().toString(36).substr(2);
         uid = `${time}${randomNum}`
         sessionStorage.setItem('uid',uid)
      }
      return uid
      // let time = new Date().getTime();
      // let randomNum = Math.random().toString(36).substr(2);
      // let uid = `${time}${randomNum}`
      // sessionStorage.setItem('uid',uid)
      
    },
    changeQrcActive:function(){
      // this.qrcactive = !this.qrcactive
        // console.log(this.$refs.qrCodeUrl.val)
      this.uid = this.creatUid()
      if (!this.qrcactive) {
          
      getAuthUrl({
        platform:'wechat',
        unique:this.uid
      }).then(res=>{
          if (res.code == 200) {
            this.timeinquire()
             this.qrcactive = !this.qrcactive
            //  this.creatQrCode(res.data)
            this.qrcurl = res.data
          }else{
             this.$message({
              showClose: true,
              message: `${res.msg}`,
              type: "warning",
            });
          }
      })
      }else{
          this.cleanTime()
           this.qrcactive = !this.qrcactive
      }
  
    },
    // 定时器
    timeinquire:function(){
        let time = 20;
         this.j = setInterval(() => {
          time--;
          thirdLogin({
            unique:this.uid
          }).then(res=>{
              if (res.code == 200) {
                 if (res.data && res.data.userinfo) {
                    this.$message({
                      showClose: true,
                      message: `扫码成功`,
                      type: "success",
                    });
                    this.third = res.data.thirdinfo
                    this.snapUserinfo = res.data.userinfo
                    this.cleanTime()
                     sessionStorage.removeItem('uid')
                       // 判断有没有绑定手机号码  0为没有绑定
                    if (res.data.userinfo.mobile == 0) {
                      this.bindMobile = !this.bindMobile
                    } else {
                         this.$store.dispatch("userInfo/saveUserInfo", res.data.userinfo);
                      localStorage.setItem("token", res.data.userinfo.token);
                      localStorage.setItem("user", JSON.stringify(res.data.userinfo));
                        setTimeout(() => {
                          // sessionStorage.removeItem('uid')
                        this.$router.go(-1);
                       }, 1000);
                    }
                   
                  
                 } else {
                   
                 }
              } else {
                    this.$message({
                    showClose: true,
                    message: `${res.msg}`,
                    type: "warning",
                  });
               this.cleanTime()
              }
          })
          if (time == 0) {
            clearInterval(this.j);
            console.log('end')
          }
        }, 3000);
    },
    cleanTime:function(){
       clearInterval(this.j);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    select: function(x) {
      this.activeTab = x;
    },
    changebox: function() {
      this.changeActive = !this.changeActive;
      this.phone = "";
      this.password = "";
      this.smscode = "";
      this.nickname = "";
      this.phonetxt = "";
      this.codetxt = "";
    },
    //发送验证码
    smsSend: function() {
      let st = this.changeActive ? "login" : "register";
      if (this.phone == "") {
        this.phonetxt = "手机号码不能为空";
        return;
      } else if (
        !/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
          this.phone
        )
      ) {
        this.phonetxt = "请检查输入的手机号码";
        return;
      }
      // 通过验证
      this.phonetxt = "";
      sms({
        mobile: this.phone,
        event: st,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }

        let time = 60;
        let j = setInterval(() => {
          time--;
          this.smsButtonSt = true;
          this.smstxt = `${time}S`;
          if (time == 0) {
            this.smstxt = "发送验证码";
            this.smsButtonSt = false;
            clearInterval(j);
          }
        }, 1000);
      });
    },
    // 注册
    toRegister: function() {
      if (!this.flag) {
        return;
      }
      if (this.nickname == "") {
        this.nicknametxt = "请输入用户名";
        return;
      }
      if (this.phone == "") {
        this.phonetxt = "请输入用户名";
        return;
      }
      if (this.code == "") {
        this.codetxt = "请输入验证码";
        return;
      }
      if (this.password == "" || this.password.length < 6) {
        this.passtxt = "请检查输入的密码，密码长度在6—20位之间";
        return;
      }

      register({
        username: this.nickname,
        password: this.password,
        mobile: this.phone,
        code: this.smscode,
      }).then((res) => {
       
        if (res.code == 200) {
           this.flag = true;
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
          
          setTimeout(() => {
            this.passlogin()
            // this.changebox();
            // this.flag = true;
          }, 1000);
        } else {
          this.flag = true;
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }
      });
    },
    // 登录
    login: function() {
      if (this.phone == "") {
        this.phonetxt = "请输入手机号码";
        return;
      }
      if (this.activeTab == "0") {
        // 密码登录
        if (this.password == "" || this.password.length < 6) {
          this.passtxt = "请检查输入的密码，密码长度在6—20位之间";
          return;
        }
        this.passlogin();
      } else {
        // 验证码登录
        if (this.smscode == "") {
          this.codetxt = "请输入验证码";
          return;
        }
        this.smslogin();
      }
    },
    // 密码登录
    passlogin: function() {
      login({
        account: this.phone,
        password: this.password,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
             
            this.$store.dispatch("userInfo/saveUserInfo", res.data.userinfo);
          localStorage.setItem("token", res.data.userinfo.token);
          localStorage.setItem("user", JSON.stringify(res.data.userinfo));
          setTimeout(() => {
            this.$router.go(-1);
             this.socketLogin(res.data.userinfo.id)
          }, 1000);
        } else {
          this.flag = true;
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }
      });
    },
    // 验证码dengue
    smslogin: function() {
      codelogin({
        mobile: this.phone,
        captcha: this.smscode,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "success",
          });
         
            this.$store.dispatch("userInfo/saveUserInfo", res.data.userinfo);
          localStorage.setItem("token", res.data.userinfo.token);
         localStorage.setItem("user", JSON.stringify(res.data.userinfo));

          setTimeout(() => {
            this.$router.go(-1);
             this.socketLogin(res.data.userinfo.id)
          }, 1000);
        } else {
          this.flag = true;
          this.$message({
            showClose: true,
            message: `${res.msg}`,
            type: "warning",
          });
        }
      });
    },
    // 用户协议
    toAgree:function(){
      this.$router.push({
        path:'/protocol',
        query:{
          type:'user'
        }
      })
    },

    // 登录websocket
    socketLogin:function(e){
       this.$sock.sockinit()
    }
  },
  created() {},
  mounted() {
    localStorage.clear()

    this.$store.state.userInfo.userinfo = ''
     this.$sock.sockClose()
  },
  destroyed(){
    
    this.cleanTime()
  }
};
</script>
<style lang="scss" scoped>
.main {
  background: url("../../assets/img/loginbg.jpeg") no-repeat;
  background-size:100% 100%;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  min-height: 800px;
  overflow-y: scroll;
    overflow-x: hidden;
}
.box {
  height: 380px;
  width: 360px;
  margin-left: 50vw;
}
.box {
  .box-one {
    margin-top: 100px;
  }
  .title-one {
    text-align: center;
    font-size: 59px;
    color: #ffffff;
  }
  .title-two {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align-last: justify;
    text-align: center;
  }
  .box-two {
    position: relative;
    // height: 319px;
    min-height: 319px;
    // 三角
    .qrcbox{
      position: absolute;
      top: 0;
      right:0;
      .topright{
        width: 0;
        height: 0;
        border-top: 80px solid #FFE4B5;
        border-left: 80px solid transparent; 
        //  border-bottom: 80px solid orange;
        // border-right: 80px solid transparent;
        img{
          position: absolute;
          top: 0;
          right: 0;
          width: 41px;
        }
      }
    }
    .qrcshow{
      height: 319px;
        .qr{
      display: flex;
    justify-content: center;
        padding-top: 40px;
    }
      .qrcode{
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        canvas{
          display: none !important;
        }
          img {
        width: 132px;
        height: 132px;
        background-color: #fff; //设置白色背景色
        padding: 6px; // 利用padding的特性，挤出白边
        box-sizing: border-box;
    }
  
      }
      p{
         padding-top: 15px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9e9e9e;
      }


    }
    .rebutton {
      margin: 0;
      padding: 0;
      border: 1px solid transparent; //自定义边框
      outline: none;
      width: 100%;
      height: 40px;
      background: #e35111;
      color: #ffffff;
    }
    .rebutton:disabled {
      background-color: #fdf6ec;
    }
    .rebutton:active {
      //   border-color: #e35111;
      border: 0;
      outline: none;
    }
    margin-top: 40px;
    //   注册框相关内容
    .re-title {
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding-top: 44px;
    }
    .re-forget {
      margin-top: 16px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9e9e9e;
      }
    }

    //   通用
    background-color: #ffffff;
    width: 360px;

    position: relative;
    .message {
      height: 46px;
      background: #fafafa;
      position: absolute;
      width: 100%;
      bottom: 0;
      p {
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9e9e9e;
      }
    }
    .tab {
      .tabitem {
        display: flex;
        justify-content: center;
        .tab-item {
          width: 96px;
          padding-bottom: 11px;
          padding-top: 44px;
          //   border: 1px sloid #ffffff;
          .tabtxt {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-align: center;
          }
        }
      }
    }
    .inputbox {
      padding: 15px 30px;
      .codebox {
        display: flex;
        .smsbg {
          background: #f7f7f7;
          //   border: 1px solid #e5e5e5;
          //   padding: 13px 12px;
          //  width: 94px;
        }
      }
      .phonetxt {
        height: 27px;
        line-height: 27px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e35111;
      }
      .logintxt {
        height: 40px;
        background: #e35111;
        border-radius: 2px;
        color: #ffffff;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 40px;
      }
      .forget {
        text-align: right;
        margin-top: 16px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9e9e9e;
        }
      }
    }
  }
}

.tabactive {
  border-bottom: 2px solid #df4936;
}
.txtactive {
  color: #e35111 !important;
}
.re-height {
  height: 480px;
}
.login-height {
  height: 380px;
}
</style>
