<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import axios from 'axios';
import { ossconfig } from "@/api";
import E from "wangeditor";
export default {
  name: "editoritem",
  data() {
    return {
      // uploadPath,
      editor: null,
      info_: null,
      osslist: "",
      uploadUrl: "",
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isClear: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info_ = null;
      }
    },
    value: function(value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value);
      }
    },
    //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  mounted() {
    this.oss();
  },
  methods: {
    seteditor(e) {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.customConfig = this.editor.customConfig
        ? this.editor.customConfig
        : this.editor.config;
      this.editor.customConfig.uploadImgShowBase64 = false;
      this.editor.customConfig.pasteFilterStyle = false

      this.editor.customConfig.pasteIgnoreImg = true
      // console.log(this.editor.customConfig);
      // base 64 存储图片
      this.editor.customConfig.uploadImgServer = e; // 配置服务器端地址(自己更换为自己项目的服务器)
      this.editor.customConfig.uploadImgHeaders = {}; // 自定义 header
      this.editor.customConfig.uploadFileName = "file"; // 后端接受上传文件的参数名
      this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024; // 将图片大小限制为 2M
      this.editor.customConfig.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
      this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间

        this.editor.customConfig.pasteTextHandle = function (content) {
            if (content == '' && !content) return ''
            var str = content
            str = str.replace(/(<\/?a.*?>)/g,'')
            return str

        }
      // 配置菜单
      this.editor.customConfig.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "video", // 插入视频
        "code", // 插入代码
        "undo", // 撤销
        "redo", // 重复
        "fullscreen", // 全屏
      ];
      this.editor.customConfig.customUploadImg = (files, insert) => {
        // console.log(files);
        var getSuffix = function(fileName) {
          var pos = fileName.lastIndexOf(".");
          var suffix = "";
          if (pos != -1) {
            suffix = fileName.substring(pos);
          }
          return suffix;
        };
        //   获取文件
        var fileObj = files[0];
        //   cosnole.log(result.File[0])
        var formFile = new FormData();
        var filename = new Date().getTime() + getSuffix(fileObj.name);
        // 添加内容
        formFile.append("key", this.osslist.dir + filename);
        formFile.append("OSSAccessKeyId", this.osslist.accessid);
        formFile.append("policy", this.osslist.policy);
        formFile.append("Signature", this.osslist.signature);
         formFile.append("callback", this.osslist.callback);
        formFile.append("success_action_status", 200);
        formFile.append("file", fileObj);
        var data = formFile;
        // this.editor.customConfig.uploadImgParams = data;
        axios.post(e,data).then(res=>{
          // console.log(res)
          if (res.status == 200) {
             var fileUrl = e + "/" + this.osslist.dir + filename;
            //  console.log(fileUrl)
              insert(fileUrl);
          }else{

          }
        })
      };
      //   this.editor.customConfig.uploadImgHooks = {
      //     before: (xhr, editor, result) => {
      //       console.log(xhr);
      //       console.log(editor);
      //       console.log(result[0]);
      //       var _self = this;
      //       var getSuffix = function(fileName) {
      //         var pos = fileName.lastIndexOf(".");
      //         var suffix = "";
      //         if (pos != -1) {
      //           suffix = fileName.substring(pos);
      //         }
      //         return suffix;
      //       };
      //     //   获取文件
      //       var fileObj = result[0];
      //     //   cosnole.log(result.File[0])
      //     var formFile = new FormData();
      //      var filename = new Date().getTime() + getSuffix(fileObj.name);
      //     // 添加内容
      //     formFile.append("file", fileObj);
      //      formFile.append("key", this.osslist.dir+filename);
      //     formFile.append("OSSAccessKeyId", this.osslist.accessid);
      //     formFile.append("policy", this.osslist.policy);
      //     formFile.append("Signature", this.osslist.signature);
      //     formFile.append("success_action_status", 200);
      //     var data = formFile
      //      this.editor.customConfig.uploadImgParams = data
      //     },
      //     fail: (xhr, editor, result) => {
      //       // 插入图片失败回调
      //     },
      //     success: (xhr, editor, result) => {
      //       // 图片上传成功回调
      //     },
      //     timeout: (xhr, editor) => {
      //       // 网络超时的回调
      //     },
      //     error: (xhr, editor) => {
      //       // 图片上传错误的回调
      //     },
      //     customInsert: (insertImg, result, editor) => {
      //       // 图片上传成功，插入图片的回调
      //       //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
      //       // console.log(result.data[0].url)
      //       //insertImg()为插入图片的函数
      //       //循环插入图片
      //       // for (let i = 0; i < 1; i++) {
      //       // console.log(result)
      //       let url = result.url;
      //       //这里插一句, 如果项目在本地开发的,图片在服务器存着的
      //       //在编辑器里面回显的话url要加上ip,ip是看你们开发的时候怎么拿的,
      //       //我们是store存的直接拿来拼接上的
      //       insertImg(url);
      //       // }
      //     },
      //   };
      this.editor.customConfig.onchange = (html) => {
        this.info_ = html; // 绑定当前逐渐地值
        this.$emit("change", this.info_); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.editor.create();
    },
    oss: function() {
      ossconfig({
        file_type: "file",
        media_type: "article",
      }).then((res) => {
        this.osslist = res.data;
        this.uploadUrl = res.data.host;
        this.seteditor(this.uploadUrl);
        this.editor.txt.html(this.value);
      });
    },
  },
};
</script>

<style lang="css">
.editor {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}
.toolbar {
  border: 1px solid #ccc;
}
.text {
  border: 1px solid #ccc;
  min-height: 500px;
  max-height: 600px;
  overflow-y: auto;
}
</style>
