<template>
  <div class="all">
    <div class="main">
        <!-- 轮播图片 -->
      <div class="main-one">
        <el-carousel  height="600px" :autoplay="false">
          <el-carousel-item v-for="(item,x) in bananaList" :key="x" class="photoZoom cursorstyle" >
              <img v-lazy="item.url" alt="" class="bananaimg" @click="swiperTo(item)">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 活动列表 -->
      <div class="main-two">
          <el-row :gutter="20">
              <el-col :span="8" v-for="(item,y) in activeItem" :key='y' >
                  <div class="item" @click="activeTo(item)">
                      <div class="item-one photoZoom cursorstyle">
                          <img v-lazy="item.url" alt="" class="activeimg">
                      </div>
                      <div class="item-two">
                          <p class="title">{{item.title}}</p>
                          <p class="time"><i class="el-icon-timer " style="padding-right:5px"></i>{{item.created_at}}</p>
                      </div>
                  </div>
              </el-col>
          </el-row>
      </div>
      <!-- 分页 -->
      <div class="main-three">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-count="allPage"
            :current-page="page"
            :page-size="12"
             @current-change="handleCurrentChange"
             :hide-on-single-page="true"
            >
            </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
 activebanana,
 activelist
} from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
        bananaList:[],
        activeItem:[],
        page:1,
        total:null,
        allPage:null,
        perPpage:12
    };
  },
  watch: {},
  computed: {},
  methods: {
      getBanana:function(){
          activebanana().then(res=>{
              this.bananaList = res.data
          })
      },
      getActiveList:function(){
          activelist({
              page:this.page,
              per:12
          }).then(res=>{
              this.activeItem = res.data.data
              this.page = res.data.current_page
              this.allPage = res.data.last_page
              this.total = res.data.total
              this.perPpage=+res.data.per_page
          })
      },
      handleCurrentChange:function(val){
          this.page = val
          this.getActiveList()
      },
      swiperTo:function(e){
          if (e.link_url !== '') {
               window.open(e.link_url, "_blank");
          }
          
          	// switch (e.link_type){
			// 		// 外部链接
			// 		case 1:
			// 			if(e.link_url !== ''){
			// 				uni.navigateTo({
			// 					url:`/pages/other/openurl?url=${e.link_url}`
			// 				})
			// 			}
			// 			break;
			// 			// 内部链接
			// 			case 2:
			// 				if(e.link_url !== ''){
			// 					uni.navigateTo({
			// 						url:e.link_url
			// 					})
			// 				}
			// 				break;
			// 		default:
			// 			break;
			// 	}
      },
      activeTo:function(e){
           if (e.address !== '') {
               let fourF = this.$router.resolve({
                    path: "/2021_6",
                    query: {
                    id: e.id ,
                    },
                });
                 window.open(fourF.href, "_blank");
            //    window.open(e.address, "_blank");
          }
      }
  },
  created() {},
  mounted() {
      this.getBanana()
      this.getActiveList()
  },
};
</script>
<style lang="scss" scoped>
.all{
    background: #f7f7f7;
}
.main {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  .bananaimg{
      max-width: 100%;
  }
}

.main-two{
    margin-top: 70px;
    .item{
        margin-bottom: 30px;
        overflow: hidden;
        .item-one{
            .activeimg{
                max-width: 100%;
            }
        }
        .item-two{
            background-color: #ffffff;
            padding: 10px;
            .title{
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 16px;
            }
            .time{
                padding-top: 10px;
                color: #999;
            }
        }
    }
}
.main-three{
    text-align: center;
    padding: 50px 0;
}
</style>
</style>
<style lang="scss">
.main-three .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
