<template>
    <div class="all">
        <div class="main-one hidden-xs-only" >
            <!-- 中间显示的内容 -->
            <div class="center">
                <div class="center-one">
                    <img src="../../../src/assets/img/loginlogo.png" alt="">
                </div>
                <div class="center-two">
                    <a href="https://apps.apple.com/cn/app/c%E4%BD%8D%E6%B2%B3%E5%8D%97/id1575305871" class="ios" >
                        <div class="two-one  cursorstyle" >
                                <img src="../../../src/assets/img/iphone.png" alt="" class="">
                                <span>IPhone</span>
                    </div>
                    </a>
                    
                    <div class="iosd">
                         <img src="../../../src/assets/img/iosDown.png" alt="" class="">
                    </div>
                    
                    <a href="https://benzhongwenhua.oss-cn-beijing.aliyuncs.com/app/1.0.9.apk" class="android" >
                         <div class="two-one  cursorstyle" style="marginLeft:20px">
                            <img src="../../../src/assets/img/Android.png" alt="" class="">
                            <span>Android</span>
                     </div>
                    </a>
                       <!-- <div class="two-one  cursorstyle" style="marginLeft:20px" @click="androidDownload">
                            <img src="../../../src/assets/img/Android.png" alt="" class="">
                            <span>Android</span>
                     </div> -->
                    
                       <div class="and">
                         <img src="../../../src/assets/img/androidDown.png" alt="" class="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 手机端 -->
        <div class="main-two hidden-sm-and-up">
            <div class="center">
                  <div class="center-one">
                    <img src="../../../src/assets/img/loginlogo.png" alt="">
                </div>
                <div class="center-two">
                    <a href="https://apps.apple.com/cn/app/c%E4%BD%8D%E6%B2%B3%E5%8D%97/id1575305871">
                        <div class="two-one ios cursorstyle" >
                                <img src="../../../src/assets/img/iphone.png" alt="" class="">
                                <span>IPhone</span>
                     </div>
                    </a>
                    <a href="https://benzhongwenhua.oss-cn-beijing.aliyuncs.com/app/1.0.9.apk" download="cvhenan">
                        <div class="two-one android cursorstyle">
                         <img src="../../../src/assets/img/Android.png" alt="" class="">
                        <span>Android</span>
                     </div>
                    </a>
                     
                     
                </div>
            </div>
            <!-- 在微信里面 -->
            <div class="wechatinfo" v-if="st">
                <!-- <img src="" alt=""> -->
            </div>
        </div>
    </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
export default {
  components: {},
  props: {},
  data() {
    return {
        h:0,
        st:false
    };
  },
  watch: {},
  computed: {},
  methods: {
      androidDownload:function(){
           window.location.href = "https://benzhongwenhua.oss-cn-beijing.aliyuncs.com/app/1.0.0.apk"
      },
      isWechat:function(){
          var agent = navigator.userAgent.toLowerCase();
                if (agent.match(/MicroMessenger/i) == "micromessenger") {
                    return true;
                } else {
                    return false;
                }
      },
  },
  created() {
    //    let height = document.documentElement.clientHeight - 230 
    //    this.h = height
  },
  mounted() {
      var st = this.isWechat()
      this.st = st
  },
};
</script>
<style lang="scss" scoped>
    .all{
        .main-one{
            height: 100vh;
            background-image: url(../../../src/assets/img/downbg.jpeg);
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            a{
                text-decoration: none;
            }
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0,0,0,.5);
                   z-index: 2;
            }
            .center{
                position: absolute;
                z-index: 9;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                .center-two{
                    display: flex;
                    margin-top: 100px;
                    justify-content: center;
                    position: relative;
                    .two-one{
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        background: rgba(0,0,0,.3);
                        width: 150px;
                        justify-content: center;
                        padding: 8px 0;
                        border-radius: 1rem;
                        
                        img{
                            max-width: 100%;
                             width: 30px;
                        }
                        span{
                            padding-left: 10px;
                        }
                        .er{
                            img{
                                max-width: 100%;
                                width: 150px;
                            }
                        }
                    }
                     .iosd{
                            position: absolute;
                            /* top: 0; */
                            left: 16px;
                            top: 57px;
                            width: 150px;
                            display: none;
                            img{
                                max-width: 100%;
                            }
                        }
                        .and{
                             position: absolute;
                            /* top: 0; */
                            right: 16px;
                            top: 57px;
                            width: 150px;
                            display: none;
                            img{
                                max-width: 100%;
                            }
                        }
                        .ios:hover + .iosd{
                            display: block;
                        }
                        .android:hover + .and{
                            display: block;
                        }
                }
            }
        }
        .main-two{
             height: 100vh;
            background-image: url(../../../src/assets/img/mobile.jpeg);
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            a{
                text-decoration: none;
            }
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0,0,0,.5);
                z-index: 2;
            }
             .center{
                position: absolute;
                z-index: 9;
                top: 40%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                .center-one{
                    // padding: 0 40px;

                    display: flex;
         justify-content: center;
                    img{
                        max-width: 100%;
                    }
                }
                .center-two{
                    display: flex;
                    justify-content: center;
                    position: relative;
                    flex-direction: column;
                    align-items: center;
                    .two-one{
                        color: #ffffff;
                        display: flex;
                        margin-top: 20px;
                        align-items: center;
                        background: rgba(0,0,0,.3);
                        width: 150px;
                        justify-content: center;
                        padding: 8px 0;
                        border-radius: 1rem;
                        
                        img{
                            max-width: 100%;
                             width: 30px;
                        }
                        span{
                            padding-left: 10px;
                        }
                        .er{
                            img{
                                max-width: 100%;
                                width: 150px;
                            }
                        }
                    }
                     .iosd{
                            position: absolute;
                            /* top: 0; */
                            left: 16px;
                            top: 57px;
                            width: 150px;
                            display: none;
                            img{
                                max-width: 100%;
                            }
                        }
                        .and{
                             position: absolute;
                            /* top: 0; */
                            right: 16px;
                            top: 57px;
                            width: 150px;
                            display: none;
                            img{
                                max-width: 100%;
                            }
                        }
                      
                }
            }
        }



        .wechatinfo{
            width: 100%;
            height: 100%;
            background: url(../../../src/assets/img/wechatcover.png);
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            z-index: 999;
        }
    }
</style>
