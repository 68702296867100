<template>
  <div class="all">
    <!-- 上方图片 -->
    <div class="bg-box hidden-xs-only">
      <img src="../../assets/img/schoolbg.jpeg" alt="" />
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="photosm hidden-sm-and-up">
          <img src="../../assets/img/schoolbgmobile.jpeg" alt="" />
        </div>
      </el-col>
    </el-row>

    <div class="main">
      <!-- <div class="main-one">
        <div class="main-one-l">
          <img src="../../assets/img/school.png" alt="" />
        </div>
        <div class="main-one-r">
          <p class="title">高校教育协会简介</p>
          <p class="info">
            河南高校影视联盟是在河南高校影视、动漫、新媒体等相关专业范围内，以省内相关专业院校为组织团体、以高校专业老师为组织成员的专业机构。本团体宗旨是响应习总书记文艺座谈会精神以及国家大力发展文化产业的政策，着力推进河南高校影视教育事业，大力整合河南高校影视教育资源，并开展影视专业教育方面的学术交流，为河南高校影视教育行业搭建一个交流、合作、创作、学习的平台，以此提高河南整体的影视教育水平，提高师生的专业创作能力，创作出更优秀的影视作品。
          </p>
        </div>
      </div> -->
    </div>
    <div class="bgff hidden-xs-only" v-if="recommend.length > 0">
      <div class="main-two">
        <div class="main-two-l">
          <p class="title">活动资讯</p>
          <p
            class="info cursorstyle"
            v-for="(item, y) in recommend"
            :key="y"
            @click="toDetail(item)"
          >
            <img src="../../assets/img/circle.png" alt="" />
            {{ item.title }}
          </p>
        </div>
        <div class="main-two-r">
          <div
            class="two-box cursorstyle"
            style="margin-right:17px"
            v-for="(item, index) in select"
            :key="index"
            @click="toDetail(item)"
          >
            <img v-lazy="item.cover" alt="" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden-xs-only other-four">
      <div class="other-one  ">
        <p class="title">活动风采</p>
        <div class="item-box">
          <div class="item" v-for="(item, x) in 10" :key="x">
            <img
              :src="require(`../../assets/img/school/school${x + 1}.png`)"
              alt=""
              class="cover"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="hidden-sm-and-up other-three">
      <el-row :gutter="24">
        <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 14, offset: 5 }">
          <div class="other-one">
            <p class="title" style=" text-align: center;">活动风采</p>
            <div class="item-box">
              <el-col v-for="(item, x) in 10" :key="x" :xs="12" :sm="12">
                <div style="display:flex; justify-content: center;">
                  <div class="item" style="height:auto">
                    <img
                      :src="
                        require(`../../assets/img/school/school${x + 1}.png`)
                      "
                      alt=""
                      class="cover"
                    />
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="pc hidden-xs-only">
      <div class="bgff">
        <div class="  other-two">
          <p class="title">高校名录</p>
          <div class="two-box">
            <div class="item-box">
              <div
                class="item cursorstyle"
                v-for="(item, z) in schoolList"
                :key="z"
                @click="toSchool(item)"
              >
                <img v-lazy="item.img" alt="" class="cover" />
                <div class="info">
                  <img :src="item.logo" alt="" />
                  <p>{{ item.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile hidden-sm-and-up">
      <div class="bgff">
        <div class="  other-two">
          <p class="title">高校名录</p>
          <div class="two-box">
            <div class="item-box">
              <el-col :span="24" v-for="(item, z) in schoolList" :key="z">
                <div style=" overflow: hidden;
    display: flex;
    justify-content: center;">
                  <div class="item cursorstyle" @click="toSchool(item)">
                    <img v-lazy="item.img" alt="" class="cover" />
                    <div class="info">
                      <img :src="item.logo" alt="" />
                      <p>{{ item.title }}</p>
                    </div>
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="line" style="height:50px"></div> -->
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import { school } from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      schoolList: [],
      recommend: [],
      select: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    toSchool: function(e) {
      window.open(e.url, "_blank");
    },
    toDetail: function(e) {
      const { href } = this.$router.resolve({
        path: "/articledetail",
        query: {
          id: e.id,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {},
  mounted() {
    school().then((res) => {
      this.schoolList = res.data.links;
      this.recommend = res.data.recommend;
      this.select = res.data.select;
    });
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  overflow: hidden;
  .bg-box {
    // height: 500px;
    // background: url("../../assets/img/schoolbg.jpeg") no-repeat;
    // overflow: hidden;
    img {
      width: 100%;
    }
  }
  .main {
    width: 1080px;
    margin: 0 auto;
  }
}

.main-one {
  display: flex;
  padding: 100px 0;
  .main-one-l {
    width: 379px;
    height: 314px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
  .main-one-r {
    flex: 1;
    padding-top: 72px;
    padding-left: 29px;
    .title {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .info {
      padding-top: 39px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
.bgff {
  background-color: #ffffff;
}
.main-two {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
  .main-two-l {
    .title {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .info {
      height: 17px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 400px;
      // display: inline-block;
      img {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }

  .main-two-r {
    display: flex;
    .two-box {
      img {
        width: 270px;
        height: 180px;
      }
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        width: 265px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
.other-four {
  .other-one {
    width: 1080px;
    margin: 0 auto;
    padding: 100px 0;
    .title {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .item-box {
      margin-top: 49px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 200px;
        height: 160px;
        overflow: hidden;
        margin-bottom: 19px;
        .cover {
          max-width: 100%;
        }
      }
    }
  }
}

.photosm {
  img {
    max-width: 100%;
  }
}

.other-three {
  .other-one {
    padding: 50px 0;
    .title {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .item-box {
      margin-top: 49px;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      .item {
        width: 200px;
        height: 160px;
        overflow: hidden;
        margin-bottom: 19px;
        .cover {
          max-width: 100%;
        }
      }
    }
  }
}

.pc {
  .other-two {
    width: 1080px;
    margin: 0 auto;
    padding: 100px 0;
    .title {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    .item-box {
      margin-top: 49px;
      margin-right: -20px;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
    }
    .item-box::after {
      content: "";
      display: block;
      clear: both;
    }
    .item {
      float: left;
      width: 255px;
      margin-right: 20px;
      height: 214px;
      overflow: hidden;
      box-shadow: 0px 2px 4px 0px rgba(141, 141, 141, 0.36);
      margin-bottom: 19px;
      .cover {
        max-width: 100%;
      }
    }
    .cover {
      width: 255px;
      height: 144px;
    }
    .info {
      display: flex;
      align-items: center;
      padding: 22px 0 18px 10px;

      img {
        width: 29px;
        border-radius: 50%;
        margin-right: 9px;
      }
      p {
        font-size: 14px;
        font-family: Source Han Sans CN;
        //   font-weight: 500;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.mobile {
  .other-two {
    padding: 50px 0;
    .title {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    @media screen and (max-width: 720px){
      .item-box{
         margin-right: 0px !important;
      }
    }
    .item-box {
      margin-top: 49px;
      margin-right: -20px;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
    }
    .item-box::after {
      content: "";
      display: block;
      clear: both;
    }
    .item {
      float: left;
      width: 255px;
      // margin-right: 20px;
      height: 214px;
      overflow: hidden;
      box-shadow: 0px 2px 4px 0px rgba(141, 141, 141, 0.36);
      margin-bottom: 19px;
      .cover {
        max-width: 100%;
      }
    }
    .cover {
      width: 255px;
      height: 144px;
    }
    .info {
      display: flex;
      align-items: center;
      padding: 22px 0 18px 10px;

      img {
        width: 29px;
        border-radius: 50%;
        margin-right: 9px;
      }
      p {
        font-size: 14px;
        font-family: Source Han Sans CN;
        //   font-weight: 500;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
