<template>
  <div class="commentall">
    <div class="commentbox">
      <div class="commentbox-one">
        <span class="txtone">评论</span>
        <span class="txttwo">{{ commNum }}</span>
      </div>
      <div class="commentbox-two">
        <div class="co-one">
          <img v-lazy="user.avatar" alt="" />
        </div>
        <div class="co-two">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入评论内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <div class="commentbox-three">
        <!-- <span>发表评论</span> -->
        <el-button
          type="primary"
          :disabled="textarea == ''"
          @click="sub(1)"
          class="subBg"
          >发表评论</el-button
        >
      </div>
      <el-divider class="driveLine"></el-divider>
      <div class="item">
        <div class="item-box" v-for="(item, cindex) in commList" :key="cindex" 
        @mouseover="item.isShowR = true" 
        @mouseout="item.isShowR = false">
          <div class="commentbox-four">
            <div class="three-l" v-if="item.user_info">
              <img v-lazy="item.user_info.avatar" alt="" />
            </div>
            <div class="three-r">
              <div class="r-user">
                <div class="r-one">
                 
                  <p class="name">{{ item.user_info.username }}</p>
                  <p class="time">{{ item.created_at | timeNew }}</p>
                </div>
                <div class="r-two">
                   <p  @click="repo(item)" style="margin-right:10px" class="cursorstyle" v-show="item.isShowR">举报</p>
                  <p style=" margin-right: 8px;" @click="replyComm(item)" class="cursorstyle">
                    回复
                  </p>
                  <p style=" margin-right: 8px;">|</p>
                  <img
                    src="../assets/img/mlike.png"
                    alt=""
                    class="cursorstyle"
                    v-show="item.is_zan == 1"
                    @click="likeIt(item)"
                  />
                  <img
                    src="../assets/img/munlike.png"
                    alt=""
                    class="cursorstyle"
                    v-show="item.is_zan == 0"
                    @click="likeIt(item)"
                  />
                  <p>
                    ( <span class="num">{{ item.zan_num }}</span> )
                  </p>
                </div>
              </div>
              <div class="r-comm">
                <p>
                  {{ item.content }}
                </p>
              </div>

              <!-- 回复框 -->
              <div class="replybox" v-show="item.status">
                <el-input
                  type="textarea"
                  :rows="4"
                  :placeholder="`回复${item.user_info.username}`"
                  v-model="item.myComm"
                >
                </el-input>
                <div class="replybutton">
                  <!-- <span >发表评论</span> -->
                  <el-button
                    type="primary"
                    :disabled="item.myComm == ''"
                    class="subBg"
                    @click="sub(2, item)"
                    >发表评论</el-button
                  >
                </div>
              </div>

              <!--回复内容显示 -->
              <div v-for="(item1, reindex) in item.reply_info" :key="reindex">
                <div class="replylistbox">
                  <p class="one">
                    {{ item1.user_info.username }}
                    <span class="time">{{ item1.created_at | timeNew }}</span>
                  </p>
                  <p class="two">{{ item1.content }}</p>
                </div>
              </div>
            </div>
          </div>

          <el-divider class="driveLine"></el-divider>
        </div>

        <!-- 翻页 -->
        <div class="page" v-show="empty && allpage > 1">
          <el-pagination
            background
            layout=" pager, next"
            :total="commNum"
            :current-page="page"
            @current-change="handleCurrentChange"
            @next-click="handleNext"
          >
          </el-pagination>
        </div>
        <div class="empty" v-show="!empty">
          <!-- <div class="emptyone">
            <img src="../assets/img/empty.png" alt="" class="emptyimg" />
          </div> -->
          <div class="emptytxt">
            <p>暂时没有评论，过会再来看看吧</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  articleComm,
  articleCommSub,
  articleCommLike,
  photoleComm,
  photoCommSub,
  photoCommLike,
  videoleComm,
  videoCommSub,
  videoCommLike,
  audioleComm,
  audioCommSub,
  audioCommLike,
} from "@/api";
export default {
  components: {},
  props: {
    info: Object,
  },
  data() {
    return {
      textarea: "",
      commList: [],
      commNum: 0,
      page: 1,
      empty: false,
      user:JSON.parse(localStorage.getItem('user')) || '',
      allpage:0,
    };
  },
  watch: {},
  filters: {
    time: function(val) {
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      let time1 = new Date().getTime(); //当前的时间戳
      let time2 = Date.parse(new Date(val)); //指定时间的时间戳
      let time = time1 - time2;

      let result = null;
      if (time < 0) {
        result = "刚刚";
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
  },
  computed: {},
  methods: {
    repo:function(e){
      // this.$parent.showDia(e,type)
      let list = {
        comment:this.infoMation.id,
        comment_content:e.content,
        comment_user:e.user_id
      }
      this.$parent.commDia(list)
    },
    test: function() {
      
      this.infoMation = JSON.parse(JSON.stringify(this.info));
      this.getCommLIst();
    },
    //   发表评论
    sub: function(item, index) {
      let id = item == 1 ? "" : index.id;
      let con = item == 1 ? this.textarea : index.myComm;
      switch (this.infoMation.type) {
        // 文章
        case "article":
          articleCommSub({
            article: this.infoMation.id,
            content: con,
            comment: id,
          }).then((res) => {
            if (res.code == 200) {
              if (res.data == 1) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success",
                });
                this.getCommLIst();
                
              } else {
                this.$message({
                  showClose: true,
                  message: "监测到评论内容包含敏感词汇，发表失败",
                  type: "error",
                });
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;
        // 图片
        case "photo":
          photoCommSub({
            atlas: this.infoMation.id,
            content: con,
            comment: id,
          }).then((res) => {
            if (res.code == 200) {
              if (res.data == 1) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success",
                });
                this.getCommLIst();
              } else {
                this.$message({
                  showClose: true,
                  message: "监测到评论内容包含敏感词汇，发表失败",
                  type: "error",
                });
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;
        // 视频
        case "video":
          videoCommSub({
            video: this.infoMation.id,
            content: con,
            comment: id,
          }).then((res) => {
            if (res.code == 200) {
              if (res.data == 1) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success",
                });
                this.getCommLIst();
              } else {
                this.$message({
                  showClose: true,
                  message: "监测到评论内容包含敏感词汇，发表失败",
                  type: "error",
                });
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;
        // 视频
        case "audio":
          audioCommSub({
            audio: this.infoMation.id,
            content: con,
            comment: id,
          }).then((res) => {
            if (res.code == 200) {
              if (res.data == 1) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success",
                });
                this.getCommLIst();
              } else {
                this.$message({
                  showClose: true,
                  message: "监测到评论内容包含敏感词汇，发表失败",
                  type: "error",
                });
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;

        default:
          break;
      }
      this.textarea = ''
    },

    // 获取评论列表
    getCommLIst: function() {
      switch (this.infoMation.type) {
        // 文章
        case "article":
          articleComm({
            article: this.infoMation.id,
            page: this.page,
          }).then((res) => {
            res.data.data.map((item) => {
              item.status = false;
              item.myComm = "";
              item.isShowR = false
            });
            this.commList = res.data.data;
            this.commNum = res.data.total;
            this.allpage = res.data.last_page
            if (res.data.data.length == 0) {
              this.empty = false;
            } else {
              this.empty = true;
            }
          });
          break;

        // 图片
        case "photo":
          photoleComm({
            atlas: this.infoMation.id,
            page: this.page,
          }).then((res) => {
            res.data.data.map((item) => {
              item.status = false;
              item.myComm = "";
                item.isShowR = false
            });
            this.commList = res.data.data;
            this.commNum = res.data.total;
             this.allpage = res.data.last_page
            if (res.data.data.length == 0) {
              this.empty = false;
            } else {
              this.empty = true;
            }
          });
          break;

        // video
        case "video":
          videoleComm({
            video: this.infoMation.id,
            page: this.page,
          }).then((res) => {
            res.data.data.map((item) => {
              item.status = false;
              item.myComm = "";
                item.isShowR = false
            });
            this.commList = res.data.data;
            this.commNum = res.data.total;
             this.allpage = res.data.last_page
            if (res.data.data.length == 0) {
              this.empty = false;
            } else {
              this.empty = true;
            }
          });
          break;

        // 音频
        case "audio":
          audioleComm({
            audio: this.infoMation.id,
            page: this.page,
          }).then((res) => {
            res.data.data.map((item) => {
              item.status = false;
              item.myComm = "";
                item.isShowR = false
            });
            this.commList = res.data.data;
            this.commNum = res.data.total;
             this.allpage = res.data.last_page
            if (res.data.data.length == 0) {
              this.empty = false;
            } else {
              this.empty = true;
            }
          });
          break;
        default:
          break;
      }
    },
    // 发表回复
    replyComm: function(e) {
      e.status = !e.status;
    },
    likeIt: function(e) {
      switch (this.infoMation.type) {
        // 文章
        case "article":
          articleCommLike({
            comment: e.id,
          }).then((res) => {
            if (res.code == 200) {
              e.is_zan = e.is_zan == 1 ? 0 : 1;
              if (e.is_zan == 0 && e.zan_num !== 0) {
                e.zan_num--;
              } else {
                e.zan_num++;
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;
        // 图片
        case "photo":
          photoCommLike({
            comment: e.id,
          }).then((res) => {
            if (res.code == 200) {
              e.is_zan = e.is_zan == 1 ? 0 : 1;
              if (e.is_zan == 0 && e.zan_num !== 0) {
                e.zan_num--;
              } else {
                e.zan_num++;
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;

        // 图片
        case "video":
          videoCommLike({
            comment: e.id,
          }).then((res) => {
            if (res.code == 200) {
              e.is_zan = e.is_zan == 1 ? 0 : 1;
              if (e.is_zan == 0 && e.zan_num !== 0) {
                e.zan_num--;
              } else {
                e.zan_num++;
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;

        // 图片
        case "audio":
          audioCommLike({
            comment: e.id,
          }).then((res) => {
            if (res.code == 200) {
              e.is_zan = e.is_zan == 1 ? 0 : 1;
              if (e.is_zan == 0 && e.zan_num !== 0) {
                e.zan_num--;
              } else {
                e.zan_num++;
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
          break;
        default:
          break;
      }
    },
    handleCurrentChange: function(val) {
      this.page = val;
      this.getCommLIst();
    },
    handleNext: function(val) {
      this.page = val;
      this.getCommLIst();
    },
  },
  created() {},
  mounted() {
    this.test();
  },
};
</script>
<style lang="scss" scoped>
.commentbox {
  margin-top: 40px;
  .txtone {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .txttwo {
    margin-left: 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #999999;
  }
  .commentbox-two {
    display: flex;
    margin-top: 24px;
    .co-one {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    .co-two {
      flex: 1;
      padding-left: 15px;
    }
  }
  .commentbox-three {
    text-align: right;
    margin-top: 14px;
    span {
      display: inline-block;
      background: #e35111;
      border-radius: 2px;
      padding: 9px 21px;
      color: #fff;
    }
  }

  .commentbox-four {
    display: flex;
    .three-l {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    .three-r {
      padding-left: 15px;
      flex: 1;
      .r-user {
        display: flex;
        justify-content: space-between;
      }
      .r-comm {
        margin-top: 14px;
            word-break: break-all;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8a8a8a;
        }
      }
      //
      .replylistbox {
        margin-top: 10px;
        background: #f8f8f8;
        border-radius: 2px;
        padding: 20px;
        .one {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          .time {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .two {
          margin-top: 5px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8a8a8a;
        }
      }
      //
      .replybox {
        margin-top: 15px;
        .replybutton {
          text-align: right;
          margin-top: 10px;
          span {
            display: inline-block;
            margin-top: 10px;
            background: #e35111;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            padding: 9px 21px;
            color: #fff;
          }
        }
      }
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      .r-one {
        display: flex;
        align-items: center;
        .name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .time {
          margin-left: 10px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
      .r-two {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          margin-right: 8px;
        }
        p {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8a8a8a;
        }
        .num {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8a8a8a;
        }
      }
    }
  }
}

.mainBg {
  background: #e35111;
  color: #fff;
}
.subBg {
  background-color: #e35111;
  border-color: #e35111;
  color: #ffffff;
}
.is-disabled:hover {
  color: #ffffff;
  cursor: not-allowed;
  background-image: none;
  background-color: rgba(227, 81, 17, 0.5);
  border-color: rgba(227, 81, 17, 0.5);
}
.is-disabled {
  background-color: rgba(227, 81, 17, 0.5) !important;
  border-color: rgba(227, 81, 17, 0.5) !important;
}

//
.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 16px;
    }
  }
}
</style>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
