<template>
  <div class="all" id="allcomm">
    <!-- 奖品设置 -->
    <el-col :span="24" class="logo ">
      <el-col :xs="{ span: 22, offset: 1 }" :sm="{ span: 16, offset: 4 }">
        <div class="tab">
          <img src="../../assets/active20216/tab14.jpg" alt="" />
        </div>
        <p class="info">
          欢迎大家在下方留言板踊跃讨论，也可自荐作品，共同学习。
        </p>
      </el-col>
    </el-col>
    <!-- 林论 -->
    <el-col
      :xs="{ span: 24, offset: 0 }"
      :sm="{ span: 16, offset: 4 }"
      class="main activecomm"
    >
      <!--  输入框-->
      <el-col :xs="{ span: 22, offset: 1 }">
        <!-- <div class="rebox "  v-show="reStatus">
          <p class="rebox-l">回复 @ <span class="name">{{reInfo.name}}</span> :{{reInfo.content}}</p>
          <p class="rebox-r cursorstyle" @click="canaleRe"><i class="el-icon-circle-close cl"></i></p>
        </div> -->
        <el-input
          type="textarea"
          :rows="5"
          :placeholder="pl"
          v-model="textarea"
        >
        </el-input>
        <!--  -->
        <div class="sub">
          <el-button
            type="primary"
            :disabled="textarea == ''"
            class="subBg"
            @click="sub"
            >发表评论</el-button
          >
        </div>
        <!--  -->
        <div class="num">
          <p>全部讨论({{total}})</p>
        </div>
        <!-- 下方评论 -->
        <div class="commlist">
          <div class="item" v-for="(item, x) in commList" :key="x">
            <div class="item-one">
              <div class="one-l hidden-sm-and-down cursorstyle nozan" @click="like(item)" v-show="item.is_zan == 0">
                <i class="el-icon-caret-top"></i>
                <p style="whiteSpace: nowrap">赞同</p>
              </div>
               <div class="one-l hidden-sm-and-down cursorstyle zan" @click="like(item)" v-show="item.is_zan == 1">
                <i class="el-icon-caret-top"></i>
                <p style="whiteSpace: nowrap">赞同</p>
              </div>
              <div class="one-c cursorstyle" >
                <div class="avatar">
                  <img v-lazy="item.user_info.avatar" alt="" />
                </div>
              </div>
              <div class="one-r">
                <div class="r-one ">
                  <p class="name cursorstyle" >{{item.user_info.username}}</p>
                  <p class="other">{{item.user_info.identity_text}}</p>
                </div>
                <div class="r-two">
                  <p class="comminfotxt">
                   {{item.comment}}
                  </p>
                   <!-- 这个地方显示回复的内容 -->
                  <div class="replybox" v-if="item.reply_info.length > 0">
                    <!-- 回复内容信啊会死 -->
                    <div class="replyone" v-for="(item1,rex) in item.reply_info" :key='rex'>
                      <p style="marginBottom:5px"> <span class="name">{{item1.user_info.username}}</span><span class="replytime ">{{item1.created_at | timeNew}}</span></p>
                      <p class="comminfotxt" >{{item1.comment}}</p>
                    </div>
                  </div>
                  <!-- 这个地方显示回复的输入框 -->
                  <div class="replyinput"  v-show="item.flag">
                     <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="请输入回复的内容"
                        v-model="item.recon"
                      >
                      </el-input>
                      <div class="rebutton">
                         <el-button
                            type="primary"
                            :disabled="item.recon == ''"
                            class="subBg"
                            @click="subRe(item)"
                            >回复评论</el-button
                          >
                      </div>
                  </div>
                  <p class="time">
                    <span>{{item.created_at | timeNew}}</span>
                    <span @click="repo(item)" class="cursorstyle">回复</span>
                  </p>
                </div>

               
              </div>
            </div>
          </div>

        </div>
        <!-- 分类 -->
        <div class="commnav">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="12"
            :page-count="last_page"
            :current-page="currpage"
            :hide-on-single-page="true"
            @current-change="handlecommCurrentChange">
          </el-pagination>
        </div>
        <!-- 无数据提示 -->
        <div class="videoem" v-show="commList.length == 0">
          <div class="emptyone">
            <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
          </div>
          <div class="emptytxt">
            <p>还没有评论哦！</p>
          </div>
        </div>
        <div style="height:100px"></div>
      </el-col>
    </el-col>
  </div>
</template>

<script>
import { activCommList, activComm ,activCommZan} from "@/api";
import "element-ui/lib/theme-chalk/display.css";
export default {
  components: {},
  props: {},
  data() {
    return {
      textarea: "",
      commList: [],
      total: 0,
      currpage: 1,
      last_page: 0,
      pid: "",
      reStatus:false,
      reInfo:{},
      pl:'快来发表评论吧',
      subTxt:'发表评论',
      replyContent:'',
    };
  },
  computed: {},
  methods: {
    handlecommCurrentChange:function(e){
      this.currpage = e
      this.getComm()
    },
    getComm: function() {
      activCommList({
        page:this.currpage,
      }).then((res) => {
        res.data.data.map(item=>{
          item.flag = false
          item.recon = ''
        })
        this.commList = res.data.data;
        this.total = res.data.total;
        this.currpage = res.data.current_page;
        this.last_page = res.data.last_page;
  
      });
    },

    sub: function() {
      
      activComm({
        content: this.textarea,
        pid: this.reInfo.id || '',
        page:this.currpage
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.textarea = ''
          this.currpage = 1
          this.getComm()
        }
      });
    },

    repo:function(e){
      e.flag = !e.flag
      // this.reStatus = true
      // this.textarea = ''
      // this.reInfo.name = e.user_info.username
      // this.reInfo.id = e.id
      // this.reInfo.content = e.comment
      // this.pl = `回复 ${this.reInfo.name}`
    },
    canaleRe:function(){
      this.reStatus = false
      this.textarea = ''
       this.reInfo = {}
        this.pl ="快来发表评论吧"
    },

    subRe:function(e){
       activComm({
        content: e.recon,
        pid: e.id ,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          e.recon = ''
            this.currpage = 1
          this.getComm()
        }
      });
    },
    like:function(e){
      console.log(e)
      activCommZan({
        comment:e.id
      }).then(res=>{
        if (res.code == 200) {
          if (e.is_zan == 0) {
              e.is_zan = 1
          }else{
             e.is_zan = 0
          }
        }
      })
    },
    
  },
  created() {},
  mounted() {
    this.getComm();
  },
};
</script>
<style lang="scss" scoped>
.logo {
  margin-top: 70px;
  text-align: center;
  .info {
    margin: 30px 0;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
}

.main {
  .sub {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
  .num {
    p {
      color: #e35111;
    }
  }

  .commlist {
    margin-top: 20px;
    .item {
      border-bottom: 1px dashed #999999;
      margin-bottom: 30px;
      .item-one {
        display: flex;
        // align-items: center;
        .one-l {
          display: inline-block;
          border: 1px solid #e35111;
          text-align: center;
          padding: 0 8px;
          // width: 40px;
          max-width: 25px;
          height: 40px;
          color: #e35111;
          border-radius: 3px;
          margin-right: 20px;
          transition: all 0.5s;

        }
        .nozan{
            &:hover {
            background-color: #e35111;
            color: #ffffff;
            }
          }
        .zan{
          background-color: #e35111;
            color: #ffffff;
            &:hover{
              background-color: #ffffff;
              color: #e35111;
            }
        }
        .one-c {
         
          .avatar {
            img {
              border-radius: 50%;
              overflow: hidden;
              width: 45px;
            }
          }
        }
        .one-r {
           flex: 1;
          padding-left: 20px;
          .name {
            color: #333;
            font-size: 17px;
          }
          .other {
            color: #999999;
          }
          .r-two {
            padding: 10px 0;

            .time {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              color: #999999;
            }
          }

          .replybox{
           
            .replyone{
               background-color: #f1f1f1;
                padding: 20px;
                margin: 10px 0;
                .replytime{
                  margin-left: 20px;
                  font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999;
                }
                .name{
                   font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 700;
                  color: #333;
                }
            }
           
          }

          .replyinput{
            margin-top: 10px;
            .rebutton{
              display: flex;
              justify-content: flex-end;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
.subBg {
  background-color: #e35111;
  border-color: #e35111;
  color: #ffffff;
}
.is-disabled:hover {
  color: #ffffff;
  cursor: not-allowed;
  background-image: none;
  background-color: rgba(227, 81, 17, 0.5);
  border-color: rgba(227, 81, 17, 0.5);
}
.is-disabled {
  background-color: rgba(227, 81, 17, 0.5) !important;
  border-color: rgba(227, 81, 17, 0.5) !important;
}
.videoem {
  text-align: center;
  .emptyone {
    text-align: center;
  }
  .emptyimg {
    max-width: 100%;
    height: 100px;
  }
  .emptytxt {
    color: #999;
  }
}
.reH{
  
}
.rebox{
  margin: 10px 0 ;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  transition: all .5s;
  .rebox-r{
      margin-left: 15px;
    .cl{

    }
  }
  .name{
    background-color: orange;
    padding: 0 3px;
    color: #ffffff;
  }
}

.comminfotxt{
      font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #8a8a8a;
    word-break: break-all;
}

.commnav{
  text-align: center;
}
</style>

<style>
.activecomm .el-textarea__inner:focus {
  border-color: #e35111;
}
.commnav .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
.commnav .el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: #e35111 ;
}
.commnav .el-pagination.is-background .el-pager li:not(.disabled).active{
  color: #ffffff;
}
</style>
