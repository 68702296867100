<template>
  <div class="all">
    <div class="main">
      <el-dialog
        title="请选择举报理由"
        :visible.sync="centerDialogVisible"
        width="380px"
      >
      <div class="repotbox">
          <div class="item-box">
              <div class="item" v-for="(item,x) in list" :key='x'>
                    <el-radio v-model="radio" :label="item" >{{item}}</el-radio>
              </div>
          </div>
         <div class="">
             <el-input
                type="textarea"
                :rows="5"
                resize="none"
                placeholder="请选择说明举报理由（100字以内）"
                v-model="textarea">
                </el-input>
         </div>
      </div>
        <span slot="footer" class="dialog-footer">
             <el-button class="subbox" 
            @click="sub" :disabled ="radio == ''">确 定</el-button
          >
          <el-button @click="cancle" class="subBg">取 消</el-button>
         
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
    articleReport,videoReport,audioReport,atlasReport,commReport
} from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      centerDialogVisible: false,
      list:['违法违禁','政治敏感','色情低俗','血腥暴力','人身攻击','作品侵权','其他问题'],
      radio:'',
      textarea:'',
      info:''
    };
  },
  watch: {},
  computed: {},
  methods: {
      handleClose:function(){

      },
      dialogVisible:function(){

      },
      show:function(e){
          this.info = e
           this.centerDialogVisible =   !this.centerDialogVisible 
      },
      cancle:function(){
            this.radio = ''
          this.textarea = ''
         this.centerDialogVisible =   !this.centerDialogVisible 
      },
      sub:function(){
          let con = this.radio + this.textarea

          switch (this.info.type) {
              case 'article':
                   articleReport({
                      article:this.info.id,
                      reason:con
                  }).then(res=>{
                      if (res.code == 200) {
                            this.$message({
                                showClose: true,
                                message: '举报成功',
                                type: "success",
                            });
                      }else{
                             this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                      }
                  })
                  break;
                   case 'audio':
                       audioReport({
                      audio:this.info.id,
                      reason:con
                  }).then(res=>{
                      if (res.code == 200) {
                            this.$message({
                                showClose: true,
                                message: '举报成功',
                                type: "success",
                            });
                      }else{
                             this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                      }
                  })
                  break;
                   case 'video':
                  videoReport({
                      video:this.info.id,
                      reason:con
                  }).then(res=>{
                      if (res.code == 200) {
                            this.$message({
                                showClose: true,
                                message: '举报成功',
                                type: "success",
                            });
                      }else{
                             this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                      }
                  })
                  break;
                   case 'atlas':
                           atlasReport({
                      atlas:this.info.id,
                      reason:con
                  }).then(res=>{
                      if (res.code == 200) {
                            this.$message({
                                showClose: true,
                                message: '举报成功',
                                type: "success",
                            });
                      }else{
                             this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                      }
                  })
                  break;
                   case 'comment':
                           commReport({
                      comment:this.info.comment,
                      comment_type:this.info.comment_type,
                      comment_content:this.info.comment_content,
                      comment_user:this.info.comment_user,
                      reason:con
                  }).then(res=>{
                      if (res.code == 200) {
                            this.$message({
                                showClose: true,
                                message: '举报成功',
                                type: "success",
                            });
                      }else{
                             this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                      }
                  })
                  break;
          
              default:
                  break;
          }
          this.info = ''
          this.radio = ''
          this.textarea = ''
           this.centerDialogVisible = !this.centerDialogVisible 
            // this.centerDialogVisible =   !this.centerDialogVisible 
      }
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.all {
  .main {
  }
}
.item-box{
    display: flex;
    flex-wrap: wrap;
    .item{
        width: 33.33%;
        display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
    }
}
.subBg {
  background-color: #e35111;
  border-color: #e35111;
  color: #ffffff;
  padding: 12px 40px;
}
.subBg .is-disabled:hover {
  color: #ffffff;
  cursor: not-allowed;
  background-image: none;
  background-color: rgba(227, 81, 17, 0.5);
  border-color: rgba(227, 81, 17, 0.5);
}
.subBg .is-disabled {
  background-color: rgba(227, 81, 17, 0.5) !important;
  border-color: rgba(227, 81, 17, 0.5) !important;
}
.subbox{
     padding: 12px 40px;
}
.dialog-footer{
    text-align: center;
}
</style>
<style lang="scss">
.repotbox{
    .el-radio__input.is-checked .el-radio__inner{
        border-color:#e35111 ;
    background: #e35111 !important;
    }
    .el-radio__input.is-checked+.el-radio__label{
        color: #e35111;
    }
    .el-radio__inner:hover{
              border-color: #e35111;
    }
    .el-textarea__inner:focus{
            outline: 0;
    border-color: #e35111;
    }
   
}
</style>
