<template>
  <div class="all">
    <div class="line" style="height:40px"></div>
    <div class="main">
      <div class="main-one" style="margin-top: 30px; padding-top:10px">
        <div class="one-l">
          <span
            class="one-item tab cursorstyle"
            :class="activeAdd == '' ? 'addActive tabactive' : ''"
            @click="selectAdd('')"
            >全部地区</span
          >
        </div>
        <div class="one-r">
          <span
            v-for="(item, x) in addressList"
            :key="x + 1"
            class="one-item cursorstyle"
            :class="activeAdd == x ? 'addbackActive' : ''"
            @click="selectAdd(x)"
            >{{ item }}</span
          >
        </div>
      </div>

         <!-- 这个地方显示二级行政区域 -->
       <div class="country" v-show="countyList.length > 0 && activeAdd !== ''"> 
            <div class="main-one " style="padding-top: 10px;">
          <div class="one-l" style="padding: 4px 2px;">
            <span
              class="one-item tab cursorstyle"
              :class="countyActive == '' ? 'countryActive' : ''"
              @click="selectCounty('')"
              >全部地区</span
            >
          </div>
          <div class="one-r">
            <span
              v-for="(item, y) in countyList"
              :key="y"
              class="one-item cursorstyle"
              :class="countyActive == item.id ? 'countryActive' : ''"
              @click="selectCounty(item.id)"
              >{{ item.name }}</span
            >
          </div>
        </div>
       </div>

        <div style=height:10px;background:#ffffff></div>
        <div class="imgtab">
          <div class="item cursorstyle" @click="toPage('videolist')">
              <img src="../../assets/tabimg/kan.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('photolist')">
              <img src="../../assets/tabimg/shang.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('medialist')">
              <img src="../../assets/tabimg/ting.png" alt="">
          </div>
        </div>
        <div class="dropmenu">
          <el-dropdown @command="handleClickDown" placement="bottom">
              <span class="hottxt cursorstyle">
                {{downactiveTxt}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,downx) in downList" :key='downx' :command="item">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>



      <!-- <div class="main-one" style="padding-top: 20px;">
        <div class="one-l">
          <span
            class="one-item tab cursorstyle"
            :class="downactive == '' ? 'addActive tabactive' : ''"
            @click="selectDow('')"
            >全部分类</span
          >
        </div>
        <div class="one-r">
          <span
            v-for="(item, y) in downList"
            :key="y"
            class="one-item cursorstyle"
            :class="downactive == item.id ? 'addActive' : ''"
            @click="selectDow(item.id)"
            >{{ item.name }}</span
          >
        </div>
      </div> -->
     
      <!-- 文章下方列表 -->
      <div class="articlebox cursorstyle" v-for="(item, z) in artiLIst" :key="z" @click="toDetail(item.id)">
        <div class="article-l photoZoom">
          <img v-lazy="item.cover" alt="" srcset="" />
        </div>
        <div class="article-r">
          <div class="article-r-one">
            <p>{{ item.title }}</p>
          </div>
          <div class="article-r-two">
            <p>
              {{ item.description }}
            </p>
          </div>
          <div class="article-r-three">
            <div class="three-l">
              <img :src="item.user_info.avatar" alt="" class="avatar" />
              <p>{{ item.user_info.username }}</p>
            </div>
            <div class="three-r">
              <div class="r-one">
                <img src="../../assets/img/time.png" alt="" class="iconone" />
                <p> {{item.created_at | timeNew}}</p>
              </div>
              <div class="r-one" style="margin-left: 10px;">
                <img src="../../assets/img/show.png" alt="" class="icontwo" />
                <p>{{ item.total_scan }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page" v-show="!emptyShow && totalPage > 1">
        <el-pagination 
        background 
        layout=" pager, next"
         :total="totalNUm"
         :current-page="page" 
         @current-change="handleCurrentChange"
         @next-click="handleNext">
        </el-pagination>
      </div>

      <!-- 淑女为空 -->
        <div class="empty" v-show="emptyShow">
          <div class="emptyone">
            <img src="../../assets/img/empty.png" alt="" class="emptyimg">
          </div>
          <div class="emptytxt">
            <p>暂时没有数据，过会再来看看吧</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cateFen, address, worklist } from "@/api";
import {NewArealist} from "../../assets/js/area"
export default {
  components: {},
  props: {},
  data() {
    return {
      downList: [], //
      addressList: [], //
      activeAdd: "",
      downactive: "",
      page: 1,
      artiLIst: [],
      emptyShow:false,
      totalPage:'',
      totalNUm:0,
      allCounty:[],
      countyList:[],
      countyActive:'',
      downactiveTxt:'全部分类'
    };
  },
  watch: {},
  computed: {},
  methods: {
        toPage:function(e){
   
       const { href } = this.$router.resolve({
        path: `/${e}`,

      });
      window.open(href, "_blank");
    },
    handleClickDown:function(e){
      // console.log(e)
      this.downactive = e.id;
      this.downactiveTxt = e.name
      this.page = 1;
      this.getList();
    },
    selectAdd: function(x) {
      this.activeAdd = x;
      this.countyActive = ''
      this.page = 1;
    
      if (this.activeAdd !== '') {
          this.getCounty()
      }
      this.getList()
    },
    selectDow: function(e) {
      this.downactive = e;
      this.page = 1;
      this.getList()
    },
    selectCounty:function(e){
      this.countyActive = e
       this.getList()
    },
    getList: function() {
      this.artiLIst = []
      let address = this.countyActive !== '' ?  this.countyActive : this.activeAdd
      worklist({
        area: address,
        classification: this.downactive,
        page: this.page,
      }).then((res) => {
          this.totalPage = res.data.last_page
          this.totalNUm = +res.data.total
          this.artiLIst = res.data.data;
        if (this.artiLIst == 0) {
            this.emptyShow = true
        }else{
            this.emptyShow = false
        }
      });
    },
    handleCurrentChange:function(val){
        this.page= val
        this.getList()
    },
    handleNext:function(val){
         this.page= val
        this.getList()
    },
    toDetail:function(e){
      this.$router.push({
        path:'/articledetail',
        query:{
          id:e
        }
      })
    },
    getCounty:function(){
       if (this.activeAdd !== '') {
      this.countyList = this.allCounty.filter(item => item.id == this.activeAdd)[0].data || []

          }
    }
  },
  created() {
     this.activeAdd = this.$route.query.id || '';
      this.allCounty = NewArealist()
    //  console.log(NewArealist())
  },
  mounted() {
    cateFen({
      type: 4,
    }).then((res) => {
      if (res) {
         let a = {
          name:'全部分类',
          id:''
        }
        res.data.unshift(a)
        this.downList = res.data;
      }
    });
    address().then((res) => {
      if (res) {
        this.addressList = res.data;
      }
    });
    this.$nextTick(function() {
      this.getList();
    });
    this.getCounty()
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  padding-bottom: 30px;
  min-height: 800px;
}
.main {
  width: 1200px;
  margin: 0 auto;
}
.main-one {
  display: flex;
  background-color: #ffffff;
  //   margin: 0 10px;
  .one-l {
    padding: 4px 8px;
  }
  .tab {
    white-space: nowrap;
    padding: 4px 8px;
  }
  .one-r {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .one-item {
      margin-right: 16px;
    padding: 4px 10px 10px 10px;
    }
  }
}
.addActive {
  background: #e35111;
  border-radius: 2px;
  color: #fff;
}
.addbackActive{
 background-color: #f5f4f4;  
}

.countryActive{
  color: #e35111;
}
// .tabactive{
//      padding: 4px 8px;
// }
#content {
  position: absolute; /*必须*/
  top: 32px; /*top必须，大小可控制*/
  bottom: 0; /*bottom必须，一直延申到当前块底部*/
  width: 100%;
}

.main-container {
  display: flex;
  .main-content {
    flex: 1;
    background: #66677c;
    height: 100vh;
    overflow: auto;
  }
  .slide-menu {
    flex: 0 0 200px;
    width: 200px;
    .operations {
      position: fixed;
      right: 0;
      width: 200px;
      top: 0;
      bottom: 0;
      background-color: #333;
      box-sizing: border-box;
      padding: 20px;
      .item {
        margin-bottom: 20px;
        .title {
          padding-bottom: 10px;
          text-align: left;
          color: #fff;
        }
      }
      .github {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
      }
    }
  }
}
//
.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 20px;
    }
  }
}

// 文章列表
.articlebox {
  height: 240px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .article-l {
    img {
      width: 360px;
      height: 240px;
    }
  }
  .article-r {
    flex: 1;
    background-color: #ffffff;
    padding: 25px 22px 20px 20px;
    display: flex;
    flex-direction: column;
    .article-r-one {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #1d1d1d;
    }
    .article-r-two {
      flex: 1;
      padding-top: 28px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
    .article-r-three {
      display: flex;
      .three-l {
        display: flex;
        align-items: center;
        flex: 1;
        //  justify-content: space-between;
      }
      .three-r {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .r-one {
          display: flex;
          align-items: center;
        }
        .iconone {
          width: 14px;
        }
        .icontwo {
          width: 19px;
        }
        p {
          margin-left: 10px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      p {
        margin-left: 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

// .el-pagination.is-background .el-pager li:not(.disabled).active{
//     background-color: #E35111 !important;
// }

.country{
  background-color: #fff;
  padding-left: 80px;
      padding-right: 20px;
  .main-one {
    background-color: #f7f7f7;
  }
}

.dropmenu{
  padding: 20px 0;
  .hottxt{
    color: #333333;
    font-size: 12px;
  }
}
.imgtab{
  margin-top: 20px;
  display: flex;
      justify-content: space-between;
  // justify-content: space-around;
  // .item{
  //   flex: 1;

  // }
}
</style>
<style lang='scss'>
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #E35111 !important;
}
</style>
