<template>
  <div class="all">
    <div class="main">
      <div class="title">
        <p>发布文章</p>
      </div>
      <div class="content">
        <div class="tit">
          <input
            type="text"
            placeholder="请输入文章标题（5-30个字）"
            class="titinput"
            minlength="5"
            maxlength="30"
            v-model="title"
          />
        </div>
        <editor-bar
          v-model="detail"
          :isClear="isClear"
          @change="change"
          class="inputBox"
        ></editor-bar>
      </div>
      <!--  -->
      <div style=" padding: 0px 60px">
        <el-divider class="driveLine"></el-divider>
      </div>

      <!--  -->
      <div class="sub">
        <div class="sub-l">
          <div class="block">
            <div class="image-box cursorstyle" @click="clicp">
              <div slot="error" class="image-slot nopic" v-if="cover == ''">
                <i class="el-icon-picture-outline"></i>
                <br/>
                <p>点击选择图片</p>
              </div>
              <img :src="cover" alt="" v-if="cover !== ''" style="width: 100%;">
              
            </div>
            <!-- <el-upload
              class="avatar-uploader nopic"
              :action="uploadUrl"
              :data="up_param"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              ref="pictureUpload"
              accept=".jpg, .png"
              name="file"
              :auto-upload="false"
            >
              <img v-if="cover" :src="cover" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload> -->
          </div>
          <!-- <div class="subbox" @click="uploadPic">
            <p>支持JPG、JPEG、PNG格式</p>
          </div> -->
            <div class="uptxt">
              <p>支持JPG、JPEG、PNG格式</p>
            </div>
        </div>
        <div class="sub-r">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="left"
          >
            <!-- <el-form-item label="标题" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item> -->
            <el-form-item label="描述" prop="desc">
              <el-input
                type="textarea"
                v-model="ruleForm.desc"
                placeholder="分享文章背后的故事"
                resize="none"
              ></el-input>
            </el-form-item>
            <el-form-item label="分类" prop="region">
              <el-select
                v-model="ruleForm.region"
                placeholder="请选择分类"
                class="w100"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, y) in downList"
                  :key="y"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签" prop="selectvalue">
              <div class="w100">
                <el-select
                  v-model="ruleForm.selectvalue"
                  class="selectwidth w100"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择文章标签"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <el-tag
                  type="success"
                  class="tagm cursorstyle"
                  v-for="(item, tagindex) in options"
                  :key="tagindex"
                  @click="tagselect(item)"
                  >{{ item.value }}</el-tag
                >
              </div>
                 <div>
                <p
                  style="font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;"
                >
                  最多添加5个标签，每个标签内容不能重复
                </p>
              </div>
            </el-form-item>
            <!-- <el-form-item label="活动区域" prop="address">
              <el-select
                v-model="ruleForm.address"
                placeholder="所在区域"
                class="selectwidth w100"
              >
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, x) in addressList"
                  :key="x"
                  >{{ item }}</el-option
                >
              </el-select>
            </el-form-item> -->
             <el-form-item label="活动区域" required>
              <el-col :span="11">
                <el-form-item prop="address">
                  <el-select
                    v-model="ruleForm.address"
                    placeholder="所在区域"
                    class="selectwidth"
                    style="width: 100%;"
                  >
                    <el-option
                      :label="item"
                      :value="item"
                      v-for="(item, x) in addressList"
                      :key="x"
                      >{{ item }}</el-option
                    >
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <div
                style="width: 20px;
    height: 20px;
    float: left;"
              ></div> -->
              <el-col class="line" :span="2" style=" text-align: center;">-</el-col>
              <el-col :span="11">
                <el-form-item prop="county">
                  <el-select
                    v-model="ruleForm.county"
                    placeholder="详细地址"
                    class="selectwidth"
                    style="width: 100%;"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.name"
                      v-for="(item, clindex) in countyList"
                      :key="clindex"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>

            <el-form-item class="subbox">
              <!-- <el-button type="primary" @click="submitForm('ruleForm')"
                >立即创建</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button> -->
              <!-- <div class="subbutton" @click="submitForm('ruleForm')">
                <span> 发布文章</span>
              </div> -->
            </el-form-item>
          </el-form>
        </div>
        
      </div>
         <div class="main-four">
            <span  class="cursorstyle preview" @click="previewST = true">预览文章</span>
         <span @click="submitForm('ruleForm')" class="cursorstyle">发布文章</span>
      </div>
        <clip :osslist="osslist" :imgh="imgh" @changeclicp="clicp" @getmsg="getMsgFormSon" ref="clic"></clip>
     
    </div>
    <div class="line" style="height:40px"></div>
     
      <el-dialog title="预览文章" :visible.sync="previewST">
            <div>
              <div v-html="detail"></div>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import { cateFen, address, ossconfig, publicarticle,editArticle,updateArticle,articleTag} from "@/api";
import EditorBar from "../../components/wang";
import clip from "../../components/clipPhoto"
import { NewArealist } from "../../assets/js/area";
export default {
  components: { EditorBar ,clip},
  props: {},
  data() {
    var checkTag = (rule, value, callback) => {
      if (value.length == 0) {
        return callback(new Error("标签不能为空"));
      } else {
        callback();
      }
    };
    return {
      previewST:false,
       options: [
       
      ],
      isClear: false,
      detail: "",
      rules: {
        name: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 255,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        desc: [{ required: false, message: "请输入描述信息", trigger: "blur" },
        {
            min: 1,
            max:240,
            message: "长度在 1 到 255 个字符",
            trigger: "blur",
          },],
        selectvalue: [
          { required: true, validator: checkTag, trigger: "change" },
        ],
        address: [
          { required: true, message: "请选择所在区域", trigger: "change" },
        ],
         county: [
          { required: true, message: "请选择详细地址", trigger: "change" },
        ],
      },
      ruleForm: {
        name: "",
        region: "",
        desc: "",
        selectvalue: [],
        address: "",
        county:''
      },
      downList: "",
      addressList: "",
      cover: "",
      osslist: "",
      uploadUrl: "",
      up_param: {
        key: "",
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        success_action_status: "",
        callback:''
      },
      title: "",
      imgh:{
        width:280,
        height:210
      },
      clicpShow:false,
      editStstus:false,
      id:'',
          allCounty: [],
      countyList: [],
      addressID:''
    };
  },
  watch: {
    'ruleForm.selectvalue':{
       handler(val){
         var self = this
         let list = JSON.parse(JSON.stringify(val))
          if (list.length >= 6) {
               self.$message({
              showClose: true,
              message: '最多上传5个标签',
              type: 'warning'
            });
            setTimeout(()=>{
              self.ruleForm.selectvalue.splice(5,1)
            },1000)
          }
        }
   
    },
            "ruleForm.address": {
      handler(val) {
        let addressnewList =  this.allCounty.filter((item) => item.name == val);
         this.addressID = addressnewList[0].id
        this.countyList = addressnewList[0].data || []
        if (this.countyList.length == 0) {
          this.ruleForm.county = this.ruleForm.address;
        } else {
          this.ruleForm.county = "";
        }
        //  console.log(this.countyList)
      },
    },
    'ruleForm.county': {
      handler(val){
        if (this.countyList.length > 0  && val !== '') {
           let id = this.countyList.filter(item=>item.name == val)[0].id
           this.addressID = id
        }
        // console.log(val)
      }
    },
   
  },
  computed: {},
  methods: {
         getTag:function(){
      articleTag().then(res=>{
        var arr = []
          res.data.map(item=>{
            let a = {
              value:item.title,
              label:item.title
            }
            arr.push(a)
          })
        this.options = arr
      })
    },
     isAllEqual: function(arr) {

      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
        hash[arr[i]] = true;
      }
      return false;
    },
    clicp:function(){
      // this.clicpShow = !this.clicpShow
      this.$refs.clic.show()
    },
    getMsgFormSon:function(data){
      // console.log(data)
      this.cover = data
      console.log(this.cover)
    },
    change(val) {
      // console.log(val);
    },
    tagselect: function(e) {
       let list =  this.ruleForm.selectvalue
      let a = list.filter(item => item == e.value)
      if (a.length == 0) {
          this.ruleForm.selectvalue.push(e.value);
      }else{
         this.$message({
          showClose: true,
          message: "标签内容不能重复",
          type: "error",
        });
      }
    },
    submitForm(formName) {
      if (this.cover == "") {
        this.$message({
          showClose: true,
          message: "请选择封面",
          type: "error",
        });
        return;
      }
      if (this.title == "" || this.title.length < 5) {
        this.$message({
          showClose: true,
          message: "请检查输入的标题",
          type: "error",
        });
        return;
      }
           // 检测标签内容是否重复
      if (this.ruleForm.selectvalue.length > 6) {
        this.$message({
          showClose: true,
          message: "标签长度最多为5个",
          type: "error",
        });
        return;
      }

        // 检测重复项
      if (this.isAllEqual(this.ruleForm.selectvalue)) {
        this.$message({
          showClose: true,
          message: "标签内容不能重复",
          type: "error",
        });
        return;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          // function findKey(obj, value, compare = (a, b) => a === b) {
          //   return Object.keys(obj).find((k) => compare(obj[k], value));
          // }
          // // this.addressList.filter(item=>item.)
          // let id = findKey(this.addressList, this.ruleForm.address);
          // console.log(id);
          if (this.id !== '') {
                 this.$confirm('请确认作品拍摄地是否正确?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '返回修改',
          type: 'warning'
        }).then(() => {
            // 修改文章
             updateArticle({
            title: this.title,
            cover: this.cover,
            area: this.addressID,
            classification: this.ruleForm.region,
            label: this.ruleForm.selectvalue.join(","),
            description: this.ruleForm.desc,
            content: this.detail,
            article:this.id
          }).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "success",
              });
              // setTimeout(() => {}, 1000);
                setTimeout(() => {
                 this.$router.push({
                  path:'/myworks',
                  query:{
                    type:'1'
                  }
                  })
              }, 1000);
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
        }).catch(() => {
                 
        });
         
          }else{
                 this.$confirm('请确认作品拍摄地是否正确?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '返回修改',
          type: 'warning'
        }).then(() => {
            // 发布文章
             publicarticle({
            title: this.title,
            cover: this.cover,
            area: this.addressID,
            classification: this.ruleForm.region,
            label: this.ruleForm.selectvalue.join(","),
            description: this.ruleForm.desc,
            content: this.detail,
          }).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "success",
              });
              // setTimeout(() => {}, 1000);
                setTimeout(() => {
                 this.$router.push({
                  path:'/myworks',
                  query:{
                    type:'1'
                  }
                  })
              }, 1000);
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
              });
            }
          });
        }).catch(() => {
                 
        });
         
          }
         
        } else {
          // console.log("error submit!!");
          this.$message({
            showClose: true,
            message: "请检查提交的内容",
            type: "error",
          });
          return false;
        }
      });
    },
    uploadPic: function() {},
    beforeAvatarUpload: function(file) {
      if (file.length == 0) {
        alert("请选择文件");
        return false;
      } else {
        var _self = this;
        var getSuffix = function(fileName) {
          var pos = fileName.lastIndexOf(".");
          var suffix = "";
          if (pos != -1) {
            suffix = fileName.substring(pos);
          }
          return suffix;
        };

        var filename = new Date().getTime() + getSuffix(file.name);
        _self.up_param.key = _self.osslist.dir + filename;
        _self.up_param.OSSAccessKeyId = _self.osslist.accessid;
        _self.up_param.policy = _self.osslist.policy;
        _self.up_param.Signature = _self.osslist.signature;
          _self.up_param.callback = _self.osslist.callback;
        _self.up_param.success_action_status = 200;
        var fileUrl = _self.osslist.host + "/" + _self.osslist.dir + filename;
        // _self.files.push(fileUrl);
        setTimeout(() => {
          this.cover = fileUrl;
        }, 500);
        // _self.cover = fileUrl;
        // setTimeout(()=>{
        //     _self.cover = fileUrl;
        // },1000)
        //console.log("66666666", files);
      }
    },
  },
  created() {
                let list = NewArealist()
   
    list.map(item=>{
       let a = {
          id:item.id,
           pid: item.pid,
        name: `${item.name}市`,
        level: item.level,
        }
       item.data.push(a)
    })
      var allA = [
      { "id": '',
        "pid": '',
        "name": "河南",
        "level": '',
        "data": [{
            "id": '',
            "pid": '',
            "name": "河南",
            "level": ''
        }],}
    ]
    this.allCounty =  [...allA,...list]
  },
  mounted() {
    cateFen({
      type: 4,
    }).then((res) => {
      if (res) {
        this.downList = res.data;
      }
    });
    address().then((res) => {
      if (res) {
          res.data[0] = '河南'
        this.addressList = res.data;
      }
    });
    this.getTag()
    this.$nextTick(() => {
      ossconfig({
        file_type: "file",
        media_type: "article",
      }).then((res) => {
        this.osslist = res.data;
        this.uploadUrl = res.data.host;
      });
    });

    if (this.$route.query.id) {
       this.id = this.$route.query.id
       editArticle({
         article:this.$route.query.id
       }).then(res=>{
         this.title = res.data.title
         this.cover =  res.data.cover
         this.detail = res.data.content
          this.ruleForm.selectvalue= res.data.label_text.split(',')
          this.ruleForm.desc = res.data.description
          // this.ruleForm.address = res.data.area
                 let addarray = res.data.area.split('-')
        this.ruleForm.address = addarray[0]
        setTimeout(()=>{
          this.ruleForm.county = addarray[1]
        },200)
           this.ruleForm.region = res.data.classification_id
       })
    }
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  .main {
    width: 960px;
    margin: 0 auto;
    background-color: #ffffff;
    .title {
          background-color: #f7f7f7;
    padding: 30px 0;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
.content {
  padding: 45px 60px;
  background-color: #ffffff;
  .titinput {
    width: 100%;
    background: none;
    outline: none;
    border: 0px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin-bottom: 20px;
    border-bottom: 1px solid #dedede;
  }
}

.sub {
  display: flex;
  margin-top: 40px;
  .sub-l {
    padding: 0px 60px;
    .subbox {
      background: #e35111;
      border-radius: 2px;
      padding: 12px 0px;
      margin-top: 10px;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
      }
    }
  }
    .uptxt {
    margin-top: 10px;
    p {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
  .block {
    width: 240px;
    height: 180px;
    background-color: aliceblue;
    .image-box {
      width: 100%;
      height: 100%;
      .nopic {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f7fa;
      }
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 240px;
      height: 180px;
      // line-height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .avatar {
      width: 240px;
      height: 180px;
      display: block;
    }
  }
  .sub-r {
    flex: 1;
    padding: 0 60px 0 0;
    .w100 {
      width: 100%;
    }
    .tagm {
      margin-right: 10px;
    }
    .subbutton {
      text-align: end;
      span {
        background: #e35111;
        border-radius: 2px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        padding: 12px 25px;
      }
    }
  }
}

.main-four {
  background-color: #ffffff;
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: center;
  padding: 29px;
  span {
    display: inline-block;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    background: #e35111;
    border-radius: 2px;
    padding: 9px 32px;
  }
  .preview{
    margin-right: 20px;
       color: #e35111;
       background-color: #ffffff;
         border: 1px solid #e35111;
  }
}
</style>
