<template>
  <div class="all">
    <div class="line" style="height:40px"></div>
    <div class="main">
        <div class="main-one" style="margin-top: 30px; padding-top:10px">
        <div class="one-l">
          <span
            class="one-item tab cursorstyle"
            :class="activeAdd == '' ? 'addActive tabactive' : ''"
            @click="selectAdd('')"
            >全部地区</span
          >
        </div>
        <div class="one-r">
          <span
            v-for="(item, x) in addressList"
            :key="x + 1"
            class="one-item cursorstyle"
            :class="activeAdd == x ? 'addbackActive' : ''"
            @click="selectAdd(x)"
            >{{ item }}</span
          >
        </div>
      </div>

         <!-- 这个地方显示二级行政区域 -->
       <div class="country" v-show="countyList.length > 0 && activeAdd !== ''"> 
            <div class="main-one " style="padding-top: 10px;">
          <div class="one-l" style="padding: 4px 2px;">
            <span
              class="one-item tab cursorstyle"
              :class="countyActive == '' ? 'countryActive' : ''"
              @click="selectCounty('')"
              >全部地区</span
            >
          </div>
          <div class="one-r">
            <span
              v-for="(item, y) in countyList"
              :key="y"
              class="one-item cursorstyle"
              :class="countyActive == item.id ? 'countryActive' : ''"
              @click="selectCounty(item.id)"
              >{{ item.name }}</span
            >
          </div>
        </div>
       </div>

        <div style=height:10px;background:#ffffff></div>
        <div class="imgtab">
          <div class="item cursorstyle" @click="toPage('videolist')">
              <img src="../../assets/tabimg/kan.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('photolist')">
              <img src="../../assets/tabimg/shang.png" alt="">
          </div>
            <div class="item cursorstyle" @click="toPage('articlelist')">
              <img src="../../assets/tabimg/du.png" alt="">
          </div>
        </div>
        <div class="dropmenu">
          <el-dropdown @command="handleClickDown" placement="bottom">
              <span class="hottxt cursorstyle">
                {{downactiveTxt}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,downx) in downList" :key='downx' :command="item">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
      <div class="mian-two">
        <div class="main-two-l" >
          <!-- <div class="title">
            <p>全部分类</p>
          </div> -->
          <div class="item-box">
            <div class="item cursorstyle" v-for="(item, x) in mediaList" :key="x" @click="toDetail(item.id)">
              <div class="item-one">
                <img v-lazy="item.cover" alt="" />
              </div>
              <div class="item-two">
                <p>{{ item.title }}</p>
              </div>
              <div class="item-three">
                <img :src="item.user_info.avatar" alt="" />
                <span>{{ item.user_info.username }}</span>
              </div>
            </div>
          </div>

           <!-- 淑女为空 -->
      <div class="empty" v-show="emptyShow">
        <div class="emptyone">
          <img src="../../assets/img/empty.png" alt="" class="emptyimg" />
        </div>
        <div class="emptytxt">
          <p>暂时没有数据，过会再来看看吧</p>
        </div>
      </div>
        </div>
        <div class="main-two-r" >
          <div class="r-one" v-if="hotList.length > 0">
            <p>热播榜</p>
            <p>更多</p>
          </div>
          <div style=" padding: 0 42px 20px 20px;  background-color: #fff;">
            <div class="r-two cursorstyle" v-for="(item,index) in hotList" :key='index' @click="toDetail(item.id)">
              <div style="width: 88px;" >
                <div class="two-one">
                  <img
                    v-lazy="item.cover"
                    alt=""
                    class="imgone"
                  />
                  <div class="circle"></div>
                </div>
              </div>
              <div class="two-two">
                <p class="txt-one">{{item.title}}</p>
                <p class="txt-two">{{item.user_info.username}}</p>
              </div>
            </div>
          </div>
          <!--  -->
            <div class="r-one" style=" margin-top: 20px;" v-if="newList.length > 0">
            <p>新品</p>
            <p>更多</p>
          </div>
          <div style=" padding: 0 42px 20px 20px;  background-color: #fff;">
            <div class="r-two cursorstyle" v-for="(item,indexNew) in newList" :key='indexNew' @click="toDetail(item.id)">
              <div style="width: 88px;" >
                <div class="two-one">
                  <img
                    v-lazy="item.cover"
                    alt=""
                    class="imgone"
                  />
                  <div class="circle"></div>
                </div>
              </div>
              <div class="two-two">
                <p class="txt-one">{{item.title}}</p>
                <p class="txt-two">{{item.user_info.username}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page" v-show="!emptyShow && totalPage > 1">
        <el-pagination
          background
          layout=" pager, next"
          :total="totalNUm"
          :current-page="page"
          @current-change="handleCurrentChange"
          @next-click="handleNext"
        >
        </el-pagination>
      </div>

     
    </div>
  </div>
</template>

<script>
import { cateFen, medialist, mediaHot ,mediaNew,address} from "@/api";
import {NewArealist} from "../../assets/js/area"
export default {
  components: {},
  props: {},
  data() {
    return {
      downList: "",
      downactive: "",
       addressList: "",
        activeAdd: "",
      page: 1,
      totalPage: null,
      totalNUm: null,
      mediaList: [],
      emptyShow: false,
      hotList:[],
      newList:[],
         allCounty:[],
      countyList:[],
      countyActive:'',
        downactiveTxt:'全部分类'
    };
  },
  watch: {},
  computed: {},
  methods: {
        toPage:function(e){
   
       const { href } = this.$router.resolve({
        path: `/${e}`,
    
      });
      window.open(href, "_blank");
    },
    handleClickDown:function(e){
      // console.log(e)
      this.downactive = e.id;
      this.downactiveTxt = e.name
      this.page = 1;
      this.getList();
    },
     selectAdd: function(x) {
        this.activeAdd = x;
      this.countyActive = ''
      this.page = 1;
    
      if (this.activeAdd !== '') {
          this.getCounty()
      }
      this.getList()
    },
       selectCounty:function(e){
      this.countyActive = e
       this.getList()
    },
    selectDow: function(e) {
      this.downactive = e;
      this.page = 1;
      this.getList();
    },
    getList: function() {
      var _this = this;
      _this.emptyShow = false;
       let address = _this.countyActive !== '' ?  _this.countyActive : _this.activeAdd
      medialist({
          area: address,
        classification: _this.downactive || "",
        page: _this.page,
        per: 10,
      }).then((res) => {
        this.totalPage = res.data.last_page;
        this.totalNUm = +res.data.total;
        _this.mediaList = res.data.data;
        if (this.mediaList.length == 0) {
          this.emptyShow = true;
        } else {
          this.emptyShow = false;
        }
      });
    },
    handleCurrentChange: function(val) {
      this.page = val;
      this.getList();
    },
    handleNext: function(val) {
      this.page = val;
      this.getList();
    },
    toDetail:function(e){
       this.$router.push({
        path:'/mediadetail',
        query:{
          id:e
        }
      })
    },
        getCounty:function(){
          if (this.activeAdd !== '') {
      this.countyList = this.allCounty.filter(item => item.id == this.activeAdd)[0].data || []

          }
    }
  },
  created() {
      this.activeAdd = this.$route.query.id || '';
        this.allCounty = NewArealist()
  },
  mounted() {
    cateFen({
      type: 1,
    }).then((res) => {
      if (res) {
          let a = {
          name:'全部分类',
          id:''
        }
        res.data.unshift(a)
        this.downList = res.data;
      }
    });
     address().then((res) => {
      if (res) {
        this.addressList = res.data;
      }
    });
    this.$nextTick(function() {
      this.getList();
         mediaHot().then((res) => {
        this.hotList = res.data
    });
      mediaNew().then(res=>{
        this.newList = res.data
      })
    });
   this.getCounty()
  },
};
</script>
<style lang="scss" scoped>
.all {
  background-color: #f7f7f7;
  padding-bottom: 20px;
  .main {
    width: 1200px;
    margin: 0 auto;
  }
}

.main-one {
  display: flex;
  background-color: #ffffff;
  //   margin: 0 10px;
  .one-l {
    padding: 4px 8px;
  }
  .tab {
    white-space: nowrap;
    padding: 4px 8px;
  }
  .one-r {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .one-item {
        margin-right: 16px;
    padding: 4px 10px 10px 10px;
    }
  }
}
.addActive {
  background: #e35111;
  border-radius: 2px;
  color: #fff;
}
.addbackActive{
 background-color: #f5f4f4;  
}

.countryActive{
  color: #e35111;
}
//
.mian-two {
  display: flex;
  margin-top: 20px;
  .main-two-l {
    background-color: #fff;
    flex: 1;
    .title {
      padding: 20px 0 0px 20px;
      p {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
    }
    .item-box {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      .item {
        width: 20%;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .item-one {
          img {
            // width: 100%;
            width: 100%;
            height: 140px;
            border-radius: 8px;
          }
        }
        .item-two {
          margin-top: 20px;
          p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .item-three {
          margin-top: 14px;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .main-two-r {
    margin-left: 20px;
    width: 360px;
    .r-one {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
       background-color: #fff;
    }
    .r-one p:nth-child(1) {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .r-one p:nth-child(2) {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .r-two {
      display: flex;
      height: 80px;
      margin-bottom: 20px;
      .two-one {
        position: absolute;
        z-index: 99;
        .imgone {
          width: 80px;
          height: 80px;
          border-radius: 4px;
          z-index: 10;
        }
        .circle {
          position: absolute;
          top: 10px;
          right: -10px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #000000;
          z-index: -1;
        }
      }
      .two-two {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 10px;
        p {
          display: inline-block;
          flex: 1;
          // line-height: 40px;
          margin-left: 10px;
        }
        .txt-one {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; // 控制多行的行数
          -webkit-box-orient: vertical;
        }
       .txt-two {
         margin-top: 5px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}

//
//
.empty {
  padding: 60px 0;
  .emptyone {
    display: flex;
    justify-content: center;
  }
  .emptyimg {
    width: 250px;
  }
  .emptytxt {
    text-align: center;
    margin-top: 10px;
    p {
      color: #757575;
      font-size: 20px;
    }
  }
}

.dropmenu{
  padding: 20px 0;
  .hottxt{
    color: #333333;
    font-size: 12px;
  }
}
.imgtab{
  margin-top: 20px;
  display: flex;
      justify-content: space-between;
  // justify-content: space-around;
  // .item{
  //   flex: 1;

  // }
}
</style>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e35111 !important;
}
</style>
